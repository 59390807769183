import { Question } from 'types/question';

export type QuestionStatusi18nKeys =
  | 'team'
  | 'lawyer'
  | 'answered'
  | 'rated'
  | 'additional_request_in_progress'
  | 'additional_request_answered';

export const formatQuestionStatusi18nKey = (
  status: Question['status']
): QuestionStatusi18nKeys => {
  const questionStatusCode = status;
  if (
    questionStatusCode === 5 ||
    questionStatusCode === 10 ||
    questionStatusCode === 20
  ) {
    return 'lawyer';
  }

  if (questionStatusCode === 30) {
    return 'answered';
  }

  if (questionStatusCode === 31) {
    return 'additional_request_in_progress';
  }

  if (questionStatusCode === 32) {
    return 'additional_request_answered';
  }

  if (questionStatusCode === 40) {
    return 'rated';
  }

  return 'lawyer';
};
