import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from 'context';

import {
  createFunnelLoginOtp,
  createFunnelProLoginOtp,
  createLoginOtp,
  createMagicLink,
  requestPasswordReset,
  updatePassword
} from './api';

export function useCreateLoginOtpMutation() {
  return useMutation(({ email }: { email: string }) => createLoginOtp(email));
}

export function useCreateFunnelLoginOtpMutation() {
  return useMutation(
    ({
      email,
      userType,
      skuCode,
      partnerSlug,
      context,
      phone,
      questionContent
    }: {
      email: string;
      userType: string | undefined;
      skuCode: string | undefined;
      partnerSlug: string | undefined;
      context: string;
      phone: string;
      questionContent: string | undefined;
    }) =>
      createFunnelLoginOtp(
        email,
        userType,
        skuCode,
        partnerSlug,
        context,
        phone,
        questionContent
      )
  );
}

export function useCreateFunnelProLoginOtpMutation() {
  return useMutation(
    ({
      email,
      companyName,
      userType,
      jobTitle,
      skuCode,
      partnerSlug,
      context,
      phone,
      questionContent
    }: {
      email: string;
      skuCode: string | undefined;
      userType: string | undefined;
      partnerSlug: string | undefined;
      context: string;
      phone: string;
      companyName: string;
      jobTitle: string | undefined;
      questionContent: string | undefined;
    }) =>
      createFunnelProLoginOtp(
        email,
        userType,
        companyName,
        skuCode,
        partnerSlug,
        context,
        phone,
        questionContent,
        jobTitle
      )
  );
}

export function useCreateMagicLinkMutation(
  partnerKey: string | null,
  fromOnboarding = false
) {
  const [, setSearchParams] = useSearchParams();
  return useMutation(
    ({ email }: { email: string }) =>
      createMagicLink(email, partnerKey, fromOnboarding),
    {
      onSuccess: () => setSearchParams({ submitted: 'true' })
    }
  );
}

export function useSendMagicTokenMutation() {
  const { loginMagicLink } = useAuth();
  return useMutation(({ token }: { token: string }) => loginMagicLink(token));
}

export function useSendFunnelOtpMutation() {
  const { loginOtpCode } = useAuth();
  return useMutation(
    ({
      methodId,
      code,
      email
    }: {
      methodId: string;
      code: string;
      email: string;
    }) => loginOtpCode(methodId, code, email)
  );
}

export function useSendCredentialsMutation() {
  const { loginPassword } = useAuth();
  return useMutation(
    ({ username, password }: { username: string; password: string }) =>
      loginPassword({ username, password })
  );
}

export function useRequestNewPasswordMutation() {
  return useMutation(({ email }: { email: string }) =>
    requestPasswordReset(email)
  );
}

export function useSendGoogleCredentialsMutation() {
  const { loginGoogleCode } = useAuth();

  return useMutation(({ code }: { code: string }) => loginGoogleCode(code));
}

export function useSendPartnerCredentialsMutation() {
  const { loginPartnerCode } = useAuth();

  return useMutation(
    ({
      email,
      partnerKey,
      password
    }: {
      email: string;
      partnerKey: string;
      password: string;
    }) => loginPartnerCode(email, partnerKey, password)
  );
}

export function useUpdatePasswordMutation() {
  return useMutation(
    ({
      email,
      token,
      newPassword
    }: {
      email: string;
      token: string;
      newPassword: string;
    }) => updatePassword(email, token, newPassword)
  );
}
