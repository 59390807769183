import { ANALYTICS, INTENT_QUESTION_SOURCES } from 'constants/analytics';
import { useEffect } from 'react';

import { useCreateQuestionMutation } from 'api/customer/questions';

import { useCustomer } from 'hooks';

import { useTracking } from 'context';

import {
  QuestionFunnelIndications,
  QuestionFunnelStep
} from 'features/customer/funnels/FunnelStepIndications';
import { ConfirmationSuccessQuestion } from 'features/customer/funnels/confirmation/success';
import { LoadingCard } from 'features/shared/loading';

import { Subscription } from 'types/subscription';

interface CustomerQuestionNoOrderProps {
  subscriptionId: Subscription['id'];
  questionContent: string;
}

export default function ConfirmationQuestionWithoutOrder({
  subscriptionId,
  questionContent
}: CustomerQuestionNoOrderProps) {
  const { analytics } = useTracking();
  const { id: customerId } = useCustomer();

  const { mutate: createQuestionMutate, isSuccess: isCreateQuestionSuccess } =
    useCreateQuestionMutation();

  const handleTrackQuestionSuccess = () =>
    analytics?.track(ANALYTICS.QUESTION_SUCCESS, {
      intentQuestionSource: INTENT_QUESTION_SOURCES.FUNNEL
    });

  useEffect(() => {
    createQuestionMutate(
      {
        customerId,
        subscriptionId,
        questionContent
      },
      {
        onSuccess: () => handleTrackQuestionSuccess()
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuestionMutate, customerId, questionContent, subscriptionId]);

  if (!isCreateQuestionSuccess) {
    return (
      <>
        <QuestionFunnelIndications
          step={QuestionFunnelStep.ConfirmationPayment}
        />
        <LoadingCard />
      </>
    );
  }

  return <ConfirmationSuccessQuestion />;
}
