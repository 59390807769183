import { Title } from 'components';

interface TitleCardProps {
  title: string[];
}

export default function TitleCard({ title }: TitleCardProps) {
  return (
    <div className="w-full md:max-w-3xl">
      <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6 flex flex-col items-center text-center">
        <img
          className="h-24 w-24 rounded-full border-2 border-white shadow-lg"
          src="/images/charlotte.jpeg"
          alt="charlotte"
        />
        <div className="px-4 pt-4">
          <Title variant="h1">
            {title.map((t, idx) => (
              <span key={t}>
                {t} {idx === -1 ? null : <br />}
              </span>
            ))}
          </Title>
        </div>
      </div>
    </div>
  );
}
