import { FullCard } from 'components';
import { ArticleListSkeleton } from 'components/skeleton';
import { useQuery } from 'react-query';
import {
  getSuggestedArticlesByQuestion,
  useGetMemberSuggestedArticles
} from 'services/dato/articles';

import { useAuth } from 'context';

import { TrackedLink } from 'features/shared/buttons';

interface QuestionSuggestedArticlesProps {
  questionId: string;
}

const QuestionSuggestedArticles = ({
  questionId
}: QuestionSuggestedArticlesProps) => {
  const { roles } = useAuth();
  const {
    data: questionSuggestedArticlesData,
    isLoading: isQuestionSuggestedArticlesLoading,
    isSuccess: isQuestionSuggestedArticlesSuccess
  } = useQuery(['memberSuggestedArticles', questionId], () =>
    getSuggestedArticlesByQuestion(questionId)
  );

  const { data, isLoading } = useGetMemberSuggestedArticles({
    articlesIds: questionSuggestedArticlesData?.ids,
    isCustomerCSE: roles.includes('ROLE_CSE_MEMBER'),
    enabled: isQuestionSuggestedArticlesSuccess,
    first: 2
  });

  if (isQuestionSuggestedArticlesLoading || isLoading) {
    return <ArticleListSkeleton number={3} />;
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 xl:mb-8">
      {data?.allArticles.map((article) => (
        <div className="flex">
          <TrackedLink
            key={article.id}
            to={`/account/resources/${article.slug}`}
            name="article"
            metadata={{ slug: article.slug }}
          >
            <FullCard
              imgHeight="h-48"
              title={article.title}
              image={article.featuredMedia?.responsiveImage}
              tags={[article.tags[0]?.name]}
            />
          </TrackedLink>
        </div>
      ))}
    </div>
  );
};

export default QuestionSuggestedArticles;
