import { TimelineEvent } from '../timeline.types';

interface TimelineEventItemProps {
  item: TimelineEvent;
  itemIdx: number;
  timelineLength: number;
}

export default function TimelineEventItem({
  item,
  itemIdx,
  timelineLength
}: TimelineEventItemProps) {
  return (
    <li>
      <div className="flex space-x-3 items-center">
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-purple-800 ring-8 ring-white">
          <item.icon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
        <div className="flex flex-1 justify-between items-center">
          <p className="text-md">{item.content}</p>
          <div className="whitespace-nowrap text-right text-sm font-medium text-gray-700">
            {item.date}
          </div>
        </div>
      </div>
    </li>
  );
}
