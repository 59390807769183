import { ReactNode } from 'react';

import { usePartnerQuery } from 'api/customer/queries.index';

import { NavbarFunnel } from '../navbar';
import LayoutLoadingSkeleton from './LayoutLoadingSkeleton';

interface LayoutFunnelPartnerProps {
  partnerSlug: string;
  children: ReactNode;
}

export default function LayoutFunnelPartner({
  partnerSlug,
  children
}: LayoutFunnelPartnerProps) {
  const partner = usePartnerQuery({ partnerSlug });

  if (!partner.isSuccess) {
    return <LayoutLoadingSkeleton />;
  }

  return (
    <div className="min-h-screen w-full bg-gray-paper bg-brush bg-no-repeat bg-top sm:bg-center pb-12">
      <NavbarFunnel partnerLogoUrl={partner.data.partnerLogoUrl} />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <main>
          <div className="flex flex-col items-center gap-6">{children}</div>
        </main>
      </div>
    </div>
  );
}
