import { useTranslation } from 'react-i18next';

import { StarIcon } from 'features/shared/icons';

export default function StarRatings() {
  const { t } = useTranslation('customer');
  return (
    <div className="flex flex-col md:flex-row items-center justify-center gap-2">
      <div className="flex items-center gap-1">
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </div>
      <span className="font-semibold">
        {t('funnel.subscription.offer.usersCount')}
      </span>
    </div>
  );
}
