import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { otpCodeSchema } from 'schemas';

import { useSendFunnelOtpMutation } from 'api/shared/mutations.index';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { OtpFormInputs } from 'types/inputs';

function OtpLoginCodeForm() {
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { mutate, isLoading } = useSendFunnelOtpMutation();

  const { t } = useTranslation('customer');

  const [searchParams] = useSearchParams();

  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<OtpFormInputs>({
    resolver: yupResolver(otpCodeSchema(tForm))
  });

  useEffect(() => {
    if (isLoading) {
      setCustomLoading(true);
    }

    if (!isLoading) {
      setTimeout(() => {
        setCustomLoading(false);
      }, 2100);
    }
  }, [isLoading]);

  const onSubmit: SubmitHandler<OtpFormInputs> = async (formData) => {
    const { code } = formData;
    const email = searchParams.get('email') as string;
    const methodId = '';
    mutate(
      { methodId, code, email },
      {
        onError: () => {
          setError('code', {
            message: `${t('otp.code.invalid')}`,
            type: 'focus'
          });
        },
        onSuccess: async () => {
          await setTimeout(() => {
            navigate(`/account/dashboard`);
          }, 2100);
        }
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        autofocus
        type="Code"
        placeholder="123456"
        label="Code"
        name="code"
        register={register}
        error={errors.code}
        fullWidth
      />
      <Button
        fullWidth
        submit
        size="medium"
        variant="primary"
        label={t('general.continue')}
        isLoading={customLoading}
      />
    </form>
  );
}

export { OtpLoginCodeForm };
