import { useUnguardedSearchParams } from 'hooks';

import { LayoutDefault } from 'features/shared/layout';
import { MagicLoginCard, MagicPartnerLogin } from 'features/shared/login/magic';
import { OtpLoginCard } from 'features/shared/login/otp/OtpLoginCard';
import { PartnerLoginCard } from 'features/shared/login/partner';
import PasswordLoginCard from 'features/shared/login/password/PasswordLoginCard';

export default function LoginPage() {
  const isPasswordLogin =
    useUnguardedSearchParams({ param: 'password' }) === 'true';

  const isPartnerLogin =
    useUnguardedSearchParams({ param: 'partner' }) === 'true';
  const isMagicPartnerLogin = useUnguardedSearchParams({ param: 'email' });

  const isOtpLogin = useUnguardedSearchParams({ param: 'otp' }) === 'true';

  return (
    <LayoutDefault minimal>
      {isMagicPartnerLogin && <MagicPartnerLogin />}
      {isPasswordLogin && <PasswordLoginCard />}
      {isOtpLogin && <OtpLoginCard />}
      {isPartnerLogin && <PartnerLoginCard />}

      {!isPasswordLogin &&
        !isOtpLogin &&
        !isPartnerLogin &&
        !isMagicPartnerLogin && <MagicLoginCard />}
    </LayoutDefault>
  );
}
