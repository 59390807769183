import { endOfDay, format } from 'date-fns';

import {
  SubscribePremiumPlusAdditionalInputs,
  SubscribePremiumPlusRequiredInputs
} from 'types/inputs';
import {
  ApiLegalProtection,
  LegalProtection,
  NewLegalProtection
} from 'types/legal-protection';

export const formatLegalProtectionForApi = (
  requiredData: SubscribePremiumPlusRequiredInputs,
  additionalData: SubscribePremiumPlusAdditionalInputs
) => {
  const spouse = requiredData.withPartnerData
    ? {
        spouse: {
          firstName: requiredData.partnerFirstName ?? null,
          lastName: requiredData.partnerLastName ?? null,
          birthday: requiredData.partnerBirthDate ?? null,
          birthCity:
            requiredData?.partnerBirthCity?.length !== 0
              ? (requiredData.partnerBirthCity as string)
              : null,
          birthZipCode: requiredData?.partnerBirthZipCode ?? null,
          birthCountry: requiredData?.partnerBirthCountry ?? null,
          // Not in the form on Figma - default tu null
          csp: additionalData.partnerSocioeconomicStatus ?? null,
          job: additionalData.partnerJobTitle ?? null
        }
      }
    : {};

  const legalProtectionForApi: NewLegalProtection = {
    civility: requiredData.civility,
    email: requiredData.email,
    phoneNumber: requiredData.phoneCellphone,
    firstName: requiredData.firstName,
    lastName: requiredData.lastName,
    birthday: format(new Date(requiredData.birthDate), 'yyyy-MM-dd'),
    birthCity: requiredData.birthCity,
    birthZipCode: requiredData.birthZipCode,
    // Force number conversion on select value
    csp: parseInt(additionalData.socioeconomicStatus, 10),
    job: additionalData.jobTitle,
    address: {
      addressLine: requiredData.address,
      city: requiredData.city,
      country: requiredData.city,
      zipCode: requiredData.zipCode
    },
    // Spouse is sent but data inside is completely optional
    // Gestion à revoir - Demande au backend que ces champs ne soit plus reuqired
    ...spouse,
    contractStartDate: endOfDay(
      new Date(additionalData.warrantyDate)
    ).toISOString(),
    ongoingDispute: additionalData.isInLitigation === 'true',
    birthCountry: requiredData.birthCountry,
    zipCode: requiredData.zipCode,
    city: requiredData.city,
    country: requiredData.country
  };

  return {
    legalProtectionForApi
  };
};

export const formatLegalProtection = ({
  apiLegalProtection
}: {
  apiLegalProtection: ApiLegalProtection;
}): { legalProtection: LegalProtection } => ({
  legalProtection: {
    customer: { id: apiLegalProtection.customer.id },
    signatureLink: apiLegalProtection.signatureLink,
    paymentIntentSecret:
      apiLegalProtection.subscription.order.paymentData.payment_intent
        .client_secret
  }
});
