/* eslint-disable no-nested-ternary */
import { CheckIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { FunnelStep } from 'types/funnel';

interface StepsProps {
  funnelSteps: FunnelStep[];
  variant?: 'default' | 'vertical';
}

export default function Steps({ funnelSteps, variant }: StepsProps) {
  return (
    <nav
      aria-label="Progress"
      className="shadow-base rounded bg-white w-full md:max-w-3xl"
    >
      <ol className="divide-y divide-gray-400 border-gray-400 md:flex md:divide-y-0 md:items-start">
        {funnelSteps.map((step, _) => (
          <li key={step.label} className="w-full flex justify-center">
            {/*  */}
            {step.status === 'complete' ? (
              <div className="hidden md:flex w-full justify-center">
                <span
                  className={clsx(
                    'flex items-center px-6 py-4 text-sm font-medium',
                    variant === 'vertical' && 'flex-col'
                  )}
                >
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-black bg-opacity-50">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span
                    className={clsx(
                      'text-sm font-medium text-gray-800',
                      variant === 'vertical' ? 'text-center mt-2' : 'ml-4'
                    )}
                  >
                    {step.label}
                  </span>
                </span>
              </div>
            ) : null}
            {/*  */}
            {step.status === 'current' ? (
              <div className="flex justify-center">
                <div
                  className={clsx(
                    'flex items-center px-6 py-4 text-sm font-medium',
                    variant === 'vertical' && 'flex-col'
                  )}
                  aria-current="step"
                >
                  <div className="flex h-10 w-10 items-center justify-center bg-black rounded-full">
                    <span className="text-white">{step.id}</span>
                  </div>
                  <span
                    className={clsx(
                      'text-sm font-medium text-black',
                      variant === 'vertical' ? 'text-center mt-2' : 'ml-4'
                    )}
                  >
                    {step.label}
                  </span>
                </div>
              </div>
            ) : null}
            {/*  */}
            {step.status === 'upcoming' ? (
              <div className="hidden md:flex group justify-center items-center">
                <span
                  className={clsx(
                    'flex items-center px-6 py-4 text-sm font-medium',
                    variant === 'vertical' && 'flex-col'
                  )}
                >
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-400 ">
                    <span className="text-gray-700">{step.id}</span>
                  </span>
                  <span
                    className={clsx(
                      'text-sm font-medium text-gray-700',
                      variant === 'vertical' ? 'text-center mt-2' : 'ml-4'
                    )}
                  >
                    {step.label}
                  </span>
                </span>
              </div>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}
