import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useEffectOnce } from 'usehooks-ts';

import { createPartnerMagicLink } from 'api/shared/login/api';

import { useSafedGuardedSearchParams } from 'hooks/useSearchParams';

import { LayoutCard } from 'features/shared/layout';

function MagicPartnerLogin() {
  const { t } = useTranslation('customer');
  const email = useSafedGuardedSearchParams({ param: 'email' });
  const partnerKey = useSafedGuardedSearchParams({ param: 'partnerKey' });

  const { mutate, isSuccess } = useMutation(
    (data: { email: string; partnerKey: string }) =>
      createPartnerMagicLink(data.email, data.partnerKey)
  );

  useEffectOnce(() => mutate({ email, partnerKey }));

  return (
    <div>
      {isSuccess && (
        <LayoutCard
          title={t('login.partnerMagicLink.title')}
          body={
            <Trans
              ns="customer"
              i18nKey="login.partnerMagicLink.submitted"
              values={{ email }}
              components={{ 1: <strong /> }}
            />
          }
        />
      )}
    </div>
  );
}

export default MagicPartnerLogin;
