import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { ANALYTICS } from 'constants/analytics';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useSendGoogleCredentialsMutation } from 'api/shared/login';

import { useTracking } from 'context';

import { TrackedButton } from 'features/shared/buttons';
import { Toast } from 'features/shared/toasts';

const GOOGLE_OAUTH_CLIENT_ID = process.env
  .REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string;

const GoogleAuthContainer = () => {
  const { t } = useTranslation('customer');
  const { analytics } = useTracking();
  const { mutate } = useSendGoogleCredentialsMutation();

  const onSubmit = async (code: string) => {
    mutate(
      { code },
      {
        onSuccess: () => {
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('login.title.google.success')}
            />,
            { position: 'top-right', duration: 3000 }
          );
        },
        onError: () => {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('error.occurred')}
            />,
            { position: 'top-right', duration: 3000 }
          );
        }
      }
    );
  };

  const onOauthConnect = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      analytics?.track(ANALYTICS.LOGIN_GOOGLE_AUTH_SUCCESS);
      onSubmit(codeResponse.code);
    },
    onError: () => {
      analytics?.track(ANALYTICS.LOGIN_GOOGLE_AUTH_ERROR);
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('error.occurred')}
        />,
        { position: 'top-right', duration: 3000 }
      );
    }
  });

  return (
    <TrackedButton
      name="google_auth"
      onClick={onOauthConnect}
      size="medium"
      variant="transparent"
      fullWidth
      className="hover:text-black border-0"
    >
      <div className="w-5 h-5 fill-[#545454] mr-2">
        <img src="/images/google_icon.png" alt="Icon google" />
      </div>
      <p>{t('login.title.google')}</p>
    </TrackedButton>
  );
};

const GoogleAuth = () => (
  <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
    <GoogleAuthContainer />
  </GoogleOAuthProvider>
);

export default GoogleAuth;
