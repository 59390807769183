import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Answer } from 'types/answer';
import {
  NewAnswerVote,
  NewUnauthenticatedAnswerVote,
  Question
} from 'types/question';

import {
  createAnswerVote,
  createUnauthenticatedAnswerVote,
  getAnswerVoteLink,
  handleFormalNoticeProposal
} from './api';

export function useAnswerVoteLinkQuery({ voteLinkId }: { voteLinkId: string }) {
  return useQuery(['answer-vote-link', voteLinkId], () =>
    getAnswerVoteLink(voteLinkId)
  );
}

export function useCreateAnswerVoteMutation() {
  return useMutation(
    ({
      answerId,
      newAnswerVote
    }: {
      answerId: Answer['id'];
      newAnswerVote: NewAnswerVote;
    }) => createAnswerVote(answerId, newAnswerVote)
  );
}

export function useHandleFormalNoticeProposalMutation() {
  return useMutation(
    ({
      relatedAnswerId,
      formalNoticeId,
      proposalResponse
    }: {
      relatedAnswerId: number;
      formalNoticeId: string;
      proposalResponse: 'accepted' | 'refused';
    }) =>
      handleFormalNoticeProposal(
        relatedAnswerId,
        formalNoticeId,
        proposalResponse
      )
  );
}

export function useCreateUnauthenticatedAnswerVoteMutation() {
  return useMutation(
    ({
      answerId,
      newAnswerVote
    }: {
      answerId: Answer['id'];
      newAnswerVote: NewUnauthenticatedAnswerVote;
    }) => createUnauthenticatedAnswerVote(answerId, newAnswerVote)
  );
}

export function useCreateAnswerVoteOptimisticMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      questionId,
      answerId,
      newAnswerVote
    }: {
      questionId: Question['id'];
      answerId: Answer['id'];
      newAnswerVote: NewAnswerVote;
    }) => createAnswerVote(answerId, newAnswerVote),
    {
      onMutate: async ({ questionId, newAnswerVote }) => {
        await queryClient.cancelQueries(['question', questionId]);
        const previousQuestion = queryClient.getQueryData<Question>([
          `question-${questionId}`
        ]);
        if (previousQuestion) {
          queryClient.setQueryData<Question>([`question-${questionId}`], {
            ...previousQuestion,
            answers: [
              {
                ...previousQuestion.answers[0],
                answerVotes: [
                  {
                    newAnswerVote
                  } as any
                ]
              }
            ]
          });
        }

        return { previousQuestion };
      },
      onError: (error, { questionId }, context: any) => {
        if (context?.previousQuestion) {
          queryClient.setQueryData<Question>(
            ['question', questionId],
            context?.previousQuestion
          );
        }
      },
      onSettled: (data, error, variables) => {
        const { questionId } = variables;
        queryClient.invalidateQueries([`question-${questionId}`]);
      }
    }
  );
}
