import { SVGProps } from 'react';

export default function ShieldIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2108_25844)">
        <path
          d="M3.75 10.0031V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V10.0031C20.25 17.8781 13.5656 20.4844 12.2344 20.925C12.0831 20.981 11.9169 20.981 11.7656 20.925C10.4344 20.4844 3.75 17.8781 3.75 10.0031Z"
          stroke="black"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9754 15.3844L12.0004 10.5L5.02539 15.3844"
          stroke="black"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2108_25844">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
