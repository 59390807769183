import { RatingStars } from 'components/statistic';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useCancelFormalNoticeProposalMutation } from 'api/lawyer/answers';
import { getAnswerById } from 'api/lawyer/answers/api';

import AdditionalRequestCard from 'features/customer/question/AdditionalRequestCard';
import {
  AnswerCard,
  QuestionCard,
  QuestionTitle
} from 'features/lawyer/account/questions/answered';
import AdditionalRequestAnswerCard from 'features/lawyer/account/questions/answered/AdditionalRequestAnswerCard';
import {
  ModalCancelConfirmation,
  ModalCancelSuccess
} from 'features/lawyer/account/questions/answered/modals';
import { BackQuestions } from 'features/lawyer/account/questions/nav';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

export default function AnsweredPage() {
  const { answerId = '' } = useParams();
  const queryClient = useQueryClient();
  const { data: answer } = useQuery(['answerById', answerId], () =>
    getAnswerById(answerId)
  );
  const [isModalCancelConfirmationOpen, setIsModalCancelConfirmationOpen] =
    useState<boolean>(false);

  const [isModalCancelSuccessOpen, setIsModalCancelSuccessOpen] =
    useState<boolean>(false);

  const [isCancelAnswerSettled, setIsCancelAnswerSettled] =
    useState<boolean>(true);

  const {
    mutate: cancelProposalMutate,
    isLoading: isCancelProposalLoading,
    isSuccess: isCancelProposalSuccess
  } = useCancelFormalNoticeProposalMutation();

  if (!answer) return <LayoutLoadingSkeleton />;

  const isRated = Boolean(answer.answerVotes.length);

  const handleCancelProposal = async () => {
    setIsCancelAnswerSettled(true);
    cancelProposalMutate(
      {
        relatedAnswerId: answer.id,
        formalNoticeId: answer!.formalNotice!.id
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['answers']);
          setIsModalCancelConfirmationOpen(false);
          setIsModalCancelSuccessOpen(true);
        },
        onSettled: () => setIsModalCancelConfirmationOpen(false)
      }
    );
  };

  const onTriggerModal = () => setIsModalCancelConfirmationOpen(true);

  return (
    <div className="overflow-y-auto max-h-screen">
      <div className="z-40 sticky top-0 bg-gray-paper">
        <div className="pt-6 lg:pt-10 pb-6 px-6 border-b border-gray-500">
          <div className="block pb-4 lg:hidden lg:pb-0">
            <BackQuestions />
          </div>
          <QuestionTitle question={answer.question!} />
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col gap-4 p-6">
          <div>
            <QuestionCard question={answer.question!} />
          </div>
          <div>
            <AnswerCard answer={answer} handleCancelProposal={onTriggerModal} />
          </div>
          {answer.additionalRequest && (
            <AdditionalRequestCard
              additionalRequest={answer.additionalRequest}
            />
          )}
          {answer.additionalRequest?.answer && (
            <AdditionalRequestAnswerCard
              additionalRequest={answer.additionalRequest}
            />
          )}
          {isRated && (
            <div className="self-end">
              <div className="italic text-sm text-gray-600">
                {answer.answerVotes[0].reason}
              </div>
              <Trans
                parent="div"
                ns="lawyer"
                className="flex items-center text-sm font-semibold mt-1"
                i18nKey="account.questions.answered.votedBy"
                components={{
                  Stars: (
                    <RatingStars
                      className="ml-2"
                      value={answer.answerVotes[0].note}
                    />
                  )
                }}
                values={{
                  fullName:
                    answer.answerVotes[0].customer.firstName &&
                    answer.answerVotes[0].customer.lastName
                      ? `${answer.answerVotes[0].customer.firstName} ${answer.answerVotes[0].customer.lastName}`
                      : 'Utilisateur anonyme'
                }}
              />
            </div>
          )}
        </div>
      </div>
      {!isCancelProposalSuccess ? (
        <ModalCancelConfirmation
          setIsOpen={setIsModalCancelConfirmationOpen}
          isOpen={isModalCancelConfirmationOpen}
          isLoading={isCancelProposalLoading && !isCancelAnswerSettled}
          handleConfirm={handleCancelProposal}
        />
      ) : (
        <ModalCancelSuccess
          isOpen={isModalCancelSuccessOpen}
          setIsOpen={setIsModalCancelSuccessOpen}
        />
      )}
    </div>
  );
}
