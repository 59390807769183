/* eslint-disable react/button-has-type */
import { RadioGroup } from '@headlessui/react';
import { CheckIcon, MicrophoneIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import FormHelper from 'components/FormHelper';
import { ANALYTICS } from 'constants/analytics';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import SpeechRecognition, {
  useSpeechRecognition
} from 'react-speech-recognition';
import { funnelQuestionSchema } from 'schemas';
import { createQuestionFromText } from 'services/customer/create-question-from-text';

import { useTracking } from 'context';

import { Button } from 'features/shared/buttons';

import { Toast } from '../toasts';
import QuestionTextArea from './QuestionTextArea';

export interface QuestionFormInput {
  question: string;
  source: 'cse' | 'personal' | null;
}

interface QuestionFormProps {
  ctaLabel?: string;
  ctaIsLoading?: boolean;
  personalOnly?: boolean;
  questionContent?: string;
  handleQuestionFormSubmit: (question: QuestionFormInput) => void;
}

const plans = [
  {
    name: 'account.ask.step1.choice.personal.title',
    value: 'personal',
    description: 'account.ask.step1.choice.personal.description',
    image: '/images/illu_personal_question.png'
  },
  {
    name: 'account.ask.step1.choice.cse.title',
    value: 'cse',
    description: 'account.ask.step1.choice.cse.description',
    image: '/images/illu_additional_request.png'
  }
];

const Dictaphone = ({ onEnd }: { onEnd: any }) => {
  const { t } = useTranslation('customer');
  const { analytics } = useTracking();

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const { mutate, isLoading } = useMutation(
    (content: string) => createQuestionFromText(content),
    {
      onSuccess: (data) => {
        analytics?.track(ANALYTICS.MICROPHONE_ASK_FORMAT_SUCCESS);
        onEnd(data.content);
      },
      onError: () => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('general.error')}
          />,
          { position: 'top-right', duration: 3000 }
        );
      }
    }
  );

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const onMicrophoneClick = () => {
    analytics?.track(ANALYTICS.MICROPHONE_ASK_BUTTON_ACTIVATION);

    if (listening) {
      transcript && mutate(transcript);
      return SpeechRecognition.stopListening();
    }

    resetTranscript();
    return SpeechRecognition.startListening({ continuous: true });
  };

  return (
    <div
      className={classNames(
        'flex gap-2 items-center h-8 flex-row-reverse ',
        listening && 'my-2'
      )}
    >
      <Button
        size="small"
        className={classNames(
          '!p-0',
          listening &&
            'transition duration-1000 ease-in-out transform w-full !p-3 !border border-gray-500 !justify-center !bg-white'
        )}
        variant="transparent"
        onClick={onMicrophoneClick}
      >
        <div className="relative inline-flex items-center">
          <div
            className={classNames(
              'w-8 h-8 flex items-center justify-center rounded-full',
              listening
                ? 'bg-purple-800 text-white'
                : 'bg-white border border-purple-800 text-purple-900'
            )}
          >
            <MicrophoneIcon className="w-5 h-5" />
          </div>
          {listening && (
            <div className="text-sm text-left italic font-medium text-gray-800 ml-2">
              Enregistrement en cours... <br /> Appuyer de nouveau pour arrêter.
            </div>
          )}
          {listening && (
            <div className="w-8 h-8 bg-purple-500 rounded-full absolute top-0 left-0 animate-ping" />
          )}
        </div>
      </Button>
      {!listening && !isLoading && (
        <div className="text-sm italic font-medium text-gray-800">
          Appuyer pour parler
        </div>
      )}

      {!listening && isLoading && (
        <div className="text-sm italic font-medium text-gray-800">
          La retranscription de votre question est en cours. Veuillez
          patienter...
        </div>
      )}
    </div>
  );
};

export default function QuestionForm({
  ctaLabel = undefined,
  ctaIsLoading = false,
  questionContent = '',
  personalOnly = false,
  handleQuestionFormSubmit
}: QuestionFormProps) {
  const { t } = useTranslation('customer');
  const [step, setStep] = useState(personalOnly ? 2 : 1);
  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<QuestionFormInput>({
    resolver: yupResolver(funnelQuestionSchema(tForm)),
    defaultValues: {
      question: questionContent,
      source: 'personal'
    }
  });

  return (
    <div className="flex flex-col gap-8">
      <form
        noValidate
        onSubmit={handleSubmit(handleQuestionFormSubmit)}
        className="flex w-full flex-col gap-4"
      >
        {!personalOnly && step === 1 && (
          <>
            <p>{t('account.ask.step1.paragraph')}</p>
            <Controller
              control={control}
              name="source"
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  value={value}
                  onChange={onChange}
                  className="mb-4 flex flex-col sm:flex-row justify-between gap-4"
                >
                  {plans.map((plan) => (
                    <RadioGroup.Option
                      key={plan.name}
                      value={plan.value}
                      className={({ active }) =>
                        classNames(
                          'relative flex cursor-pointer rounded-lg focus:outline-none bg-white border-gray-300 border hover:ring-2 hover:ring-purple-800/40',
                          active &&
                            'ring-2 ring-purple-800/60 ring-offset-2 ring-offset-purple-800'
                        )
                      }
                    >
                      {({ checked }) => (
                        <div>
                          <div className="relative bg-purple-200 rounded-lg h-32 sm:h-52 flex justify-center">
                            <img
                              alt="additional request"
                              className="max-h-full"
                              src={plan.image}
                            />

                            {checked && (
                              <div className="absolute top-0 right-0 m-6 rounded-full block bg-purple-800 ml-12 p-1 ">
                                <CheckIcon className="h-5 w-5 text-white" />
                              </div>
                            )}
                          </div>
                          <div className="p-4 flex flex-col gap-4">
                            <RadioGroup.Label className="font-bold text-lg">
                              {t(plan.name as any)}
                            </RadioGroup.Label>
                            <RadioGroup.Description>
                              {t(plan.description as any)}
                            </RadioGroup.Description>
                          </div>
                        </div>
                      )}
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              )}
            />
            <Button
              fullWidth
              size="medium"
              variant="tertiary"
              onClick={() => setStep(2)}
              label={t('general.continue')}
            />
          </>
        )}
        {step === 2 && (
          <>
            <FormHelper
              variant="transparent"
              title={t('account.ask.advices.howTo.title')}
              footer={t('account.ask.advices.howTo.footer')}
              items={[
                t('account.ask.advices.howTo.advice1'),
                t('account.ask.advices.howTo.advice2'),
                t('account.ask.advices.howTo.advice3')
              ]}
            />
            <div className="flex justify-between items-center mt-4">
              <p>{t('account.ask.step2.paragraph')}</p>
            </div>
            <QuestionTextArea register={register} error={errors.question} />
            <Dictaphone
              onEnd={(transcript: string) => setValue('question', transcript)}
            />
            <Button
              fullWidth
              submit
              size="medium"
              variant="tertiary"
              isLoading={ctaIsLoading}
              label={ctaLabel ?? t('general.continue')}
            />
          </>
        )}
      </form>
    </div>
  );
}
