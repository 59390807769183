import { CheckCircleIcon } from '@heroicons/react/solid';

import { ContainerCard } from 'features/shared/container';

import { Perk } from 'types/product';

interface FunnelCardCtaProps {
  perks?: Perk[];
  button: React.ReactNode;
}

export default function FunnelCardCta({ perks, button }: FunnelCardCtaProps) {
  return (
    <ContainerCard>
      <div className="flex flex-col items-center gap-8 text-center">
        {perks ? (
          <div className="justify-left flex flex-col gap-2">
            {perks.map((perk) => (
              <div key={perk.id} className="flex gap-2 md:items-center">
                {perk.icon ? (
                  perk.icon
                ) : (
                  <CheckCircleIcon className="h-6 w-6 flex-shrink-0 text-purple-700" />
                )}
                <span>{perk.title}</span>
              </div>
            ))}
          </div>
        ) : null}
        {button}
      </div>
    </ContainerCard>
  );
}
