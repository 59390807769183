import { useQuery } from 'react-query';
import { graphQLRequest } from 'services/dato';

import { AllDocuments } from 'types/cms';

const GET_DOCUMENTS = `{
  allDocuments {
      id
      name
      cseOnly
      file {
        format
        title
        url
        size
      }
    }
}`;

export function useGetDocuments() {
  return useQuery(
    [`documents`],
    (): Promise<AllDocuments | null> => graphQLRequest(GET_DOCUMENTS, {})
  );
}
