import { Transition } from '@headlessui/react';
import { ArrowLeftIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { NotificationBadge } from 'containers/notification';
import { ReactNode, useState } from 'react';
import { Link, To } from 'react-router-dom';

interface PageLayoutProps {
  children: ReactNode;
  extra?: ReactNode;
  reverse?: Boolean;
  previousHref?: To;
  previousLabel?: String;
}

export default function PageLayout({
  children,
  extra,
  reverse = false,
  previousHref,
  previousLabel
}: PageLayoutProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mx-2">
      {previousHref && (
        <Link to={previousHref}>
          <div className="flex items-center tracking-wider uppercase gap-3 my-2">
            <div className="h-8 w-8 rounded-full bg-gray-200 stroke-black p-1 flex justify-center items-center hover:opacity-80">
              <ArrowLeftIcon className="w-4 h-4" />
            </div>
            {previousLabel || 'Retour'}
          </div>
        </Link>
      )}
      <div
        className={classNames(
          'flex gap-2 flex-col-reverse',
          reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'
        )}
      >
        <div className={classNames('w-full', { 'hidden lg:block': isOpen })}>
          {children}
        </div>
        {extra && (
          <div
            className={classNames(
              'rounded-2xl lg:bg-gray-100 py-3 px-3 flex flex-col'
            )}
          >
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="lg:bg-white flex justify-center items-center h-10 w-10 border-2 border-gray-600 rounded-full self-end"
            >
              {!isOpen ? (
                <NotificationBadge />
              ) : (
                <XIcon className="block w-6" aria-hidden="true" />
              )}
            </button>
            <Transition
              show={isOpen}
              enter="lg:transition-[width] lg:duration-500 lg:ease-out"
              enterFrom="lg:transform lg:w-0 lg:opacity-0"
              enterTo="lg:transform lg:w-[270px]"
              leave="lg:transition-[width] lg:duration-500 lg:ease-out"
              leaveFrom="lg:transform lg:w-[270px] lg:opacity-100"
              leaveTo="lg:transform lg:w-0 lg:opacity-0"
            >
              <div className="w-full lg:w-[270px]">{extra}</div>
            </Transition>
          </div>
        )}
      </div>
    </div>
  );
}
