import { Divider, Title } from 'components';
import { Card, CardContent } from 'components/card';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';
import { formatQuestionStatusi18nKey } from 'utils/question';

import { Question } from 'types/question';

import SanitizedContent from './SanitizedContent';

interface QuestionLayoutProps {
  question: Question;
}

export default function QuestionLayout({ question }: QuestionLayoutProps) {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();
  const { title, createdDate, status, content, customer } = question;

  const formattedCapitalizedStatus = capitalize(
    t(`account.questions.status.${formatQuestionStatusi18nKey(status)}`)
  );

  const emptyTitlePlaceholder = `${t('account.question.of')} ${formatDateToIntl(
    createdDate,
    'long',
    locale
  )}`;

  const dateAsked = formatDateToIntl(createdDate, 'long', locale);

  return (
    <Card>
      <CardContent>
        <Title variant="h2" className="truncate">
          {title || emptyTitlePlaceholder}
        </Title>
        <div className="mt-4 max-w-2xl">
          <p className="text-sm font-medium text-gray-700">Statut</p>
          <p className="text-md text-sm">{formattedCapitalizedStatus}</p>
        </div>
        <Divider />
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <p className="text-sm font-medium text-gray-700">
              {t('account.question.askedthe')}
            </p>
            <p className="mt-1 text-sm">{dateAsked}</p>
          </div>
          <div className="sm:col-span-2">
            <p className="text-sm font-medium text-gray-700">
              {t('account.question.title')}
            </p>
            <div className="text-sm mt-1">
              <SanitizedContent content={content} />
            </div>
          </div>
          {question.source === 'cse' && (
            <div className="sm:col-span-1">
              <p className="text-sm font-medium text-gray-700">
                {t('account.question.by')}
              </p>
              <p className="mt-1 text-sm">{`${customer?.firstName} ${customer?.lastName}`}</p>
            </div>
          )}
        </dl>
      </CardContent>
    </Card>
  );
}
