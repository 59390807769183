import { InformationCircleIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import { Title } from 'components';
import { Card, CardContent } from 'components/card';
import { capitalize } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocale } from 'hooks';

import { Button } from 'features/shared/buttons';
import { CrossIcon } from 'features/shared/icons';

import { formatDateToIntl } from 'utils/date';

import { AdditionalRequest, Answer } from 'types/answer';
import { Lawyer } from 'types/lawyer';

import { EkieTeamInfo, LawyerMediaInfo } from './AnswerCard';
import SanitizedContent from './SanitizedContent';

interface AdditionalRequestAnswerCardProps {
  additionalRequest: AdditionalRequest;
  lawyer: Lawyer | null;
}

const AdditionalRequestAnswerCard = ({
  additionalRequest,
  lawyer
}: AdditionalRequestAnswerCardProps) => {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();

  return (
    <Card>
      <CardContent>
        <Title gutterBottom variant="h3">
          {t('account.question.answer.title')}
        </Title>
        <div className="my-6 flex items-center">
          {lawyer !== null ? (
            <LawyerMediaInfo lawyer={lawyer} />
          ) : (
            <EkieTeamInfo />
          )}
        </div>
        <div>
          {additionalRequest.answer && (
            <SanitizedContent content={additionalRequest.answer} />
          )}
          {additionalRequest.answeredAt && (
            <div className="mt-2 text-right text-sm font-medium text-gray-700">
              {formatDateToIntl(additionalRequest.answeredAt, 'long', locale)}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AdditionalRequestAnswerCard;
