import { useTranslation } from 'react-i18next';

import { useUnguardedSearchParams } from 'hooks';

import { LayoutCard } from 'features/shared/layout';

import { RequestResetPasswordForm } from './form';

export default function RequestResetPasswordCard() {
  const { t } = useTranslation('customer');
  const isSubmitted =
    useUnguardedSearchParams({ param: 'submitted' }) === 'true';

  return (
    <LayoutCard
      title={t('password.reset.request.title')}
      body={
        !isSubmitted ? (
          <div className="w-full">
            <div className="pb-4">
              <p>{t('password.request.p')}</p>
            </div>
            <RequestResetPasswordForm />
          </div>
        ) : (
          <div className="w-full">
            <p>{t('general.emailSent.p')}</p>
          </div>
        )
      }
    />
  );
}
