import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MenuItem } from 'types/account';

import LinkNav from './LinkNav';

interface DesktopNavProps {
  accountTabs: MenuItem[];
  buttonLink?: string;
  translation?: 'customer' | 'lawyer';
}

export default function DesktopNav({
  accountTabs,
  buttonLink,
  translation = 'customer'
}: DesktopNavProps) {
  const { t } = useTranslation(translation);

  return (
    <nav className="flex flex-col w-52" aria-label="accountLinks">
      {buttonLink && (
        <Link
          className="font-bold text-center mb-3 bg-purple-900 hover:bg-purple-9000 text-white rounded text-lg px-4 focus:outline-none sm:py-2"
          to={buttonLink}
        >
          {t('account.sidebar.ask')}
        </Link>
      )}
      {accountTabs.map((item) => (
        <LinkNav translation={translation} item={item} key={item.name} />
      ))}
    </nav>
  );
}
