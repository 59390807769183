import { Tab } from '@headlessui/react';
import { Section, Tabs } from 'components';
import { NotificationList } from 'containers/notification';
import QuestionsCseList from 'containers/questions/QuestionsCseList';
import QuestionsPersonalList from 'containers/questions/QuestionsPersonalList';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useCustomer, useUnguardedSearchParams } from 'hooks';

import { useAuth } from 'context';

import { PageLayout } from 'features/customer/account/content';

const TABS_MAP = [
  { name: 'account.questions.tabs.personal.title', id: 'personal' },
  { name: 'account.questions.tabs.cse.title', id: 'cse' }
];

export default function QuestionsPage() {
  const { t } = useTranslation('customer');
  const [, setSearchParams] = useSearchParams();
  const defaultPage = useUnguardedSearchParams({ param: 'page' }) || '1';
  const type = useUnguardedSearchParams({ param: 'type' }) || 'personal';
  const { roles } = useAuth();
  const { id } = useCustomer();
  const isCse = roles.includes('ROLE_CSE_MEMBER');

  return (
    <PageLayout extra={<NotificationList />}>
      <Section>
        <Tabs
          className={!isCse ? 'hidden' : ''}
          defaultIndex={TABS_MAP.findIndex((item) => item.id === type)}
          items={TABS_MAP.map((item) => ({
            ...item,
            onClick: () => {
              setSearchParams({ page: defaultPage, type: item.id });
            },
            name: t(item.name as any)
          }))}
        >
          <Tab.Panels className={!isCse ? '' : 'mt-8'}>
            <Tab.Panel>
              <QuestionsPersonalList
                defaultPage={Number(defaultPage)}
                customerId={id}
              />
            </Tab.Panel>
            <Tab.Panel>
              <QuestionsCseList
                memberCseId={id}
                defaultPage={Number(defaultPage)}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tabs>
      </Section>
    </PageLayout>
  );
}
