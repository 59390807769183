import { isAfter, subMonths } from 'date-fns';
import { Navigate, Outlet } from 'react-router-dom';

import {
  useConsultationsQuery,
  useCustomerUsageMeterQuery,
  useQuestionsQuery,
  useSubscriptionQuery
} from 'api/customer/queries.index';

import { useCustomer } from 'hooks';

import {
  isConsultationStatusOngoing,
  isQuestionStatusOngoing
} from 'features/customer/account/account.utils';
import { AccountNav } from 'features/customer/account/nav';
import { SKIP_ONBOARDING_COMPLETION } from 'features/customer/profile/constants';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

const LIMIT_DATE = subMonths(new Date(), 2);

export default function AccountPage() {
  const {
    id: customerId,
    isB2b,
    firstName,
    lastName,
    phoneCellphone,
    onboardingStatus
  } = useCustomer();

  const subscription = useSubscriptionQuery({ customerId });
  const questions = useQuestionsQuery({ customerId });
  const consultations = useConsultationsQuery({ customerId });
  const customerUsageMeter = useCustomerUsageMeterQuery({ customerId });

  const isSuccess =
    subscription.isSuccess &&
    questions.isSuccess &&
    consultations.isSuccess &&
    customerUsageMeter.isSuccess;

  if (!isSuccess) return <LayoutLoadingSkeleton />;

  const shouldStartOnboarding =
    isB2b && !!(!firstName || !lastName || !phoneCellphone);

  if (shouldStartOnboarding) {
    return <Navigate to="/onboarding/profile" />;
  }

  const shouldSkipOnboarding =
    localStorage.getItem(SKIP_ONBOARDING_COMPLETION) === 'true';

  const onboardingHasNotStarted = onboardingStatus === 'not_started';

  const shouldCompleteOnboarding = isB2b && onboardingHasNotStarted;
  const currentQuestions =
    questions.data.questions.filter(
      (question) =>
        isQuestionStatusOngoing(question.status) &&
        isAfter(new Date(question.createdDate), LIMIT_DATE)
    ) || [];
  const futureConsultations =
    consultations.data.filter(
      (consultation) =>
        isConsultationStatusOngoing(consultation.status) &&
        isAfter(new Date(consultation.createdDate), LIMIT_DATE)
    ) || [];

  const totalNotifications =
    currentQuestions.length + futureConsultations.length;

  if (shouldCompleteOnboarding && !shouldSkipOnboarding) {
    return <Navigate to="/onboarding/situation" />;
  }

  return (
    <div className="min-h-screen bg-gray-200 md:flex">
      <div
        id="sidebar"
        className="md:my-4 md:ml-2 rounded-2xl bg-white md:w-[256px] md:min-w-[256px]"
      >
        <AccountNav />
      </div>
      <div
        id="content"
        className="mt-[72px] md:mt-0 md:col-span-2 bg-gray-200 z-10 pt-4 md:pt-4 pb-6 w-full"
      >
        <Outlet
          context={{
            subscription: subscription.data,
            questions: questions.data,
            consultations: consultations.data,
            customerUsageMeter: customerUsageMeter.data,
            currentQuestions,
            futureConsultations,
            totalNotifications
          }}
        />
      </div>
    </div>
  );
}
