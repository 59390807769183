import { yupResolver } from '@hookform/resolvers/yup';
import { Title } from 'components';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { premiumPlusConfirmationDataSchema } from 'schemas';

import { useCreateLegalProtection } from 'api/customer/legal-protection';

import { Button } from 'features/shared/buttons';
import { CheckboxInputGroup } from 'features/shared/forms';
import { CheckCircleIcon } from 'features/shared/icons';

import { FunnelStep, StepStatus } from 'types/funnel';

import { Steps, TitleCard } from '../funnels';
import {
  SubscribePremiumPlusStep,
  useSubscribePremiumPlusContext
} from './premium-plus.context';

export default function ConfirmationStep() {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const { requiredData, additionalData, setConfirmationData, setCurrentStep } =
    useSubscribePremiumPlusContext();

  const funnelSteps = useMemo(
    (): FunnelStep[] => [
      {
        id: 1,
        label: t('subscribe.premiumPlus.step.1'),
        status: StepStatus.COMPLETE
      },
      {
        id: 2,
        label: t('subscribe.premiumPlus.step.2'),
        status: StepStatus.COMPLETE
      },
      {
        id: 3,
        label: t('subscribe.premiumPlus.step.3'),
        status: StepStatus.CURRENT
      },
      {
        id: 4,
        label: t('subscribe.premiumPlus.step.4'),
        status: StepStatus.CURRENT
      }
    ],
    [t]
  );

  const funnelTitle = [
    'Merci pour votre confiance !',
    "Plus qu'une étape avant d'être protégé"
  ];

  const guarantees = [
    { id: 1, label: 'Assistance juridique illimité' },
    { id: 2, label: 'Consultation par téléphone en illimité' },
    { id: 3, label: 'Assurance protection judiciaire' }
  ];

  const formattedBirthDate = new Intl.DateTimeFormat('fr', {
    dateStyle: 'medium'
  }).format(new Date(requiredData.birthDate));

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ cguConsent: boolean }>({
    resolver: yupResolver(premiumPlusConfirmationDataSchema(tForm))
  });

  const { mutateAsync, isLoading } = useCreateLegalProtection();

  const handlePreviousStep = () =>
    setCurrentStep(SubscribePremiumPlusStep.AdditionalData);

  const onSubmit = () => {
    mutateAsync(
      {
        requiredData,
        additionalData
      },
      {
        onSuccess: (legalProtection) => {
          const { signatureLink, paymentIntentSecret } = legalProtection;
          setConfirmationData({
            signatureLink,
            paymentIntentSecret
          });
          setCurrentStep(SubscribePremiumPlusStep.Payment);
        }
      }
    );
  };

  return (
    <>
      <Steps funnelSteps={funnelSteps} variant="vertical" />
      <TitleCard title={funnelTitle} />
      <div className="w-full md:max-w-3xl mb-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6 mb-2">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:divide-x lg:divide-gray-700">
              <div className="lg:pr-4 col-span-1">
                <div className="mb-4">
                  <Title variant="h3">Vos avantages</Title>
                </div>
                <div className="flex flex-col gap-2 mb-2">
                  {guarantees.map((g) => (
                    <div key={g.id} className="flex items-center gap-2">
                      <CheckCircleIcon className="w-5 h-5" />
                      <span>{g.label}</span>
                    </div>
                  ))}
                </div>
                <div className="mb-4 lg:mb-0">
                  <span className="text-sm text-gray-700">
                    Abonnement annuel à tacite reconduction, la cotisation
                    annuelle est de 178,8€ pour l'Assistance Juridique et 60€
                    pour l'assurance Protection Juridique, soit 19,90€ / mois
                    TTC.
                  </span>
                </div>
              </div>
              <div className="lg:pl-4 col-span-1">
                <div className="mb-4">
                  <Title variant="h3">Vos coordonnées</Title>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <span className="font-semibold">
                      {requiredData.firstName} {requiredData.lastName}
                    </span>
                    <span>{formattedBirthDate}</span>
                  </div>
                  <div className="flex flex-col">
                    <span>{requiredData.address}</span>
                    <span>
                      {requiredData.zipCode} - {requiredData.city}
                    </span>
                    <span>{requiredData.country}</span>
                  </div>
                  <div className="flex flex-col">
                    <span>{requiredData.email}</span>
                    <span>{requiredData.phoneCellphone}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6 mb-2">
            <div className="mb-4">
              <Title variant="h3">Vos garanties</Title>
            </div>
            <div className="mb-2">
              <p className="text-sm">
                Avec les 6 garanties suivantes : Habitation et Consommation
                liées à la résidence principale, Automobile, Famille, Défense
                pénale, Mesures de protection de la personne majeure. Prestation
                d'assurance Protection Juridique proposée par la SAS AFAAFF!
                dans le cadre d’un contrat collectif souscrit auprès de Matmut
                Protection Juridique, SA au capital de 7 500 000 € entièrement
                libéré, N° 423 499 391 RCS Rouen. Entreprise régie par le Code
                des Assurances, 66 rue de Sotteville 76100 Rouen. Garantie
                applicable dans les limites, plafonds et conditions applicables
                au contrat
              </p>
              <br />
              <p>
                Les informations recueillies font l’objet de traitements
                informatiques par la SAS AFAAFF! et ses partenaires pour les
                finalités de souscription à l’abonnement et gestion de
                l’assistance juridique, de passation et gestion des adhésions au
                contrat collectif d’assurance de Protection Juridique, et par
                Matmut Protection Juridique pour la gestion des sinistres. Vous
                pouvez exercer vos droits auprès du Délégué à la Protection des
                Données d’ekie : CABINET BOUCHARA – AVOCATS, Service DPO, 17 rue
                du Colisée – 75008 PARIS - contact-avocat@ekie.co Pour en savoir
                plus sur l’usage de vos données et vos droits, consultez la
                Charte de la SAS AFAAFF! sur le respect de la vie privée
                disponible sur le site www.avostart.fr., rubrique «Vie privée».
              </p>
              <br />
              <p>
                Enfin, vous avez la faculté de vous inscrire gratuitement sur la
                liste d’opposition au démarchage téléphonique sur
                www.bloctel.gouv.fr. Néanmoins nous pouvons toujours vous
                téléphoner lorsqu’il s’agit de sollicitations intervenant dans
                le cadre de l’exécution d’un contrat en cours et ayant un
                rapport avec l’objet de ce contrat, y compris lorsqu’il s’agit
                de vous proposer des produits ou des services afférents ou
                complémentaires à l’objet du contrat en cours ou de nature à
                améliorer ses performances ou sa qualité.
              </p>
            </div>
            <CheckboxInputGroup
              name="cguConsent"
              register={register}
              error={errors.cguConsent}
              label={
                <span className="text-sm">
                  J’ai lu et j’accepte les{' '}
                  <a
                    href="https://www.ekie.co/cgu"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    CGUV
                  </a>{' '}
                  et je reconnais les accepter sans réserve
                </span>
              }
            />
          </div>
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6">
            <div className="flex items-center gap-4">
              <div className="w-1/3">
                <Button
                  size="medium"
                  variant="secondary"
                  fullWidth
                  label={t('general.back')}
                  disabled={isLoading}
                  onClick={handlePreviousStep}
                />
              </div>
              <div className="w-2/3">
                <Button
                  submit
                  size="medium"
                  variant="tertiary"
                  isLoading={isLoading}
                  fullWidth
                  label={t('general.continue')}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
