import { useTranslation } from 'react-i18next';

import { LocalProduct } from 'types/product';

import { localProducts } from './funnel.constants';

export function useLocalProductBySkuCode(skuCode: string): LocalProduct {
  const { t } = useTranslation('customer');
  const products = localProducts(t);
  return products.find(
    (product) => product.skuCode === skuCode
  ) as LocalProduct;
}
