import { SVGProps } from 'react';

export default function LeParisienLogo({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="E-LOGO-LP-192x60.AI"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 192 60"
      {...props}
    >
      <style type="text/css">
        {`
   .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
   .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#1EA0E6;}
   .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#F03333;}
   .st3{fill:#FFFFFF;}`}
      </style>
      <g fill="currentColor" id="E-LOGO-LP-192x60">
        <rect id="Cartouche_blanc_2_" className="st0" width="192" height="60" />
        <rect id="Cartouche_bleu_2_" className="st1" width="192" height="54" />
        <rect
          id="Filet_rouge_3_"
          y="56"
          className="st2"
          width="192"
          height="4"
        />
        <g id="Le_Parisien_2_">
          <polygon
            id="L_2_"
            className="st3"
            points="35,52 21,52 21,19 27,19 27,48 35,48 35,52 		"
          />
          <path
            id="e_5_"
            className="st3"
            d="M48.3,33.2c0,6.5-6.1,9.4-8.7,9.6c0.3,3.8,2,5.3,3.8,5.3c0.7,0,1.2,0,1.7-0.1v3.9
       c-0.5,0.1-1.3,0.2-2.1,0.2c-3.9,0-9.1-2.2-9.1-13.3c0-11.5,4.8-13.2,7.9-13.2C44.6,25.7,48.3,27.7,48.3,33.2L48.3,33.2z
        M43.2,32.9c0-2.4-0.6-3.3-1.5-3.3c-1.3,0-2.4,1.5-2.4,7.9c0,0.5,0,0.9,0.1,1.3C40.9,38.6,43.2,36.4,43.2,32.9L43.2,32.9z"
          />
          <path
            id="P_2_"
            className="st3"
            d="M73.8,20.7c0,12-9.8,16.2-12.8,16.8V52h-7V7h5.9C67.1,7,73.8,10.5,73.8,20.7L73.8,20.7z M66.7,20.7
       c0-6.8-2.7-8.6-5.7-8.6v19.7C63,31.6,66.7,28.8,66.7,20.7L66.7,20.7z"
          />
          <path
            id="a_2_"
            className="st3"
            d="M88,52h-6v-2l-1.8,2c-0.4,0.1-0.6,0.1-0.8,0.1c-4,0-7.6-4.1-7.6-9.8c0-9.3,7.8-12.6,10.3-13v-0.6
       c0-1.7-1-2.5-2.7-2.5c-1.6,0-2.6,0.2-4,0.5l0.1-4.6c0,0,1.5-0.5,4.3-0.5c4.8,0,8.3,2.1,8.3,8.7V52L88,52z M82,45.7V34.1
       c-1,0.1-4.1,2.7-4.1,8.3c0,3.6,1.2,5,2.3,5C81.1,47.4,82,46.4,82,45.7L82,45.7z"
          />
          <path
            id="r_2_"
            className="st3"
            d="M102,27L102,27c-3.1,0-5,2.5-5,6v19h-6V22.3l5-0.3v2.4c1-0.8,2.5-2.3,5.5-2.3h0.5V27L102,27z"
          />
          <path
            id="i_5_"
            className="st3"
            d="M104,19v-6h6v6H104z M110,52V22h-6v30H110L110,52z"
          />
          <path
            id="s_1_"
            className="st3"
            d="M126.9,43.4c0,5.2-4.5,8.7-9.8,8.7c-2.3,0-4.2-0.3-5.8-1l2.5-4.5c0.9,0.4,1.7,0.5,2.7,0.5
       c2,0,3.6-1.3,3.6-3.1c0-2.1-0.8-3.6-3.1-6.3c-4-3.8-5-5.6-5-8.7c0-4.2,3.8-7.5,8.6-7.5c2.2,0,3.7,0.4,5,0.9l-2.3,4.2
       c-0.6-0.3-1.3-0.4-2.4-0.4c-1.4,0-2.6,1-2.6,2.3c0,1.2,0.5,2.3,3.1,4.7C125.1,36.7,126.9,39.2,126.9,43.4L126.9,43.4z"
          />
          <path
            id="i_4_"
            className="st3"
            d="M128,19v-6h6v6H128z M134,52V22h-6v30H134L134,52z"
          />
          <path
            id="e_4_"
            className="st3"
            d="M152.5,30.3c0,7.5-7.1,10.8-10.1,11.1c0.3,4.4,1.9,6.1,4,6.1c0.8,0,1.5,0,1.5-0.1v4.5
       c0,0.1-1.1,0.2-2.1,0.2c-4.5,0-10.3-2.5-10.3-15.3c0-13.3,5.6-15.2,9.2-15.2C148.2,21.7,152.5,24,152.5,30.3L152.5,30.3z
        M146.6,29.9c0-2.7-0.6-3.8-1.8-3.8c-1.5,0-2.7,1.7-2.7,9.1c0,0.6,0,1,0.1,1.4C144,36.6,146.6,34,146.6,29.9L146.6,29.9z"
          />
          <path
            id="n_1_"
            className="st3"
            d="M171,52h-6V30.5c0-2.1-1-3.9-2.5-3.9c-1.5,0-2.5,1.8-2.5,3.9V52h-6V22h5v1.5l2.2-1.8
       c0.3-0.1,0.5-0.1,1.1-0.1c5.4,0,8.8,3.3,8.8,10.4V52L171,52z"
          />
        </g>
      </g>
    </svg>
  );
}
