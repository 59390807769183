import { useTranslation } from 'react-i18next';

import { useUnguardedSearchParams } from 'hooks';

import { Alert } from 'features/shared/alerts';
import { ContainerCard } from 'features/shared/container';

import { FunnelSharedLoginConsistencyMetadata } from 'types/funnel';

import {
  QuestionFunnelProductType,
  QuestionFunnelStep
} from '../../FunnelStepIndications';
import { EmailForm, EmailProForm } from './email';
import { FunnelOtpForm } from './otp';

export interface FunnelLoginFormProps {
  handleChangeProductTypeSubmit?: (
    currentType: QuestionFunnelProductType
  ) => void;
  handleChangeStep?(
    step: QuestionFunnelStep.Content | QuestionFunnelStep.Login
  ): void;
  consistencyMetadata: FunnelSharedLoginConsistencyMetadata;
  isPro?: boolean;
}

export default function FunnelLoginForm({
  consistencyMetadata,
  handleChangeProductTypeSubmit,
  handleChangeStep,
  isPro
}: FunnelLoginFormProps) {
  const methodId = useUnguardedSearchParams({ param: 'methodId' });
  const { t } = useTranslation('customer');

  if (methodId) {
    return (
      <ContainerCard>
        <div className="mb-4">
          <Alert>
            <p>{t('funnel.otp.submitted')}</p>
          </Alert>
        </div>
        <FunnelOtpForm consistencyMetadata={consistencyMetadata} />
      </ContainerCard>
    );
  }

  if (isPro)
    return (
      <EmailProForm
        consistencyMetadata={consistencyMetadata}
        handleChangeProductTypeSubmit={handleChangeProductTypeSubmit}
      />
    );
  return (
    <EmailForm
      handleChangeProductTypeSubmit={handleChangeProductTypeSubmit}
      handleChangeStep={handleChangeStep}
      consistencyMetadata={consistencyMetadata}
    />
  );
}
