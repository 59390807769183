import { Stripe } from '@stripe/stripe-js';

import { StripeForm } from 'features/customer/payment';
import { PaymentType } from 'features/customer/payment/StripeForm';
import { ContainerCard } from 'features/shared/container';

import { LocalProduct } from 'types/product';

import { PaymentProductDescription } from '../../contact/subscribe';
import { BrandsBanner, StarRatings } from '../credentials';

interface TrustworthyPaymentFormProps {
  localProduct: LocalProduct;
  stripePromise: Promise<Stripe | null>;
  intentClientSecret: string;
  handlePaymentSuccess: () => void;
}

export default function TrustworthyPaymentForm({
  localProduct,
  stripePromise,
  intentClientSecret,
  handlePaymentSuccess
}: TrustworthyPaymentFormProps) {
  return (
    <>
      <ContainerCard>
        <PaymentProductDescription localProduct={localProduct} />
      </ContainerCard>
      <ContainerCard>
        <div className="pb-4">
          <StripeForm
            paymentType={PaymentType.Subscription}
            stripePromise={stripePromise}
            intentClientSecret={intentClientSecret as string}
            handlePaymentSuccess={handlePaymentSuccess}
          />
        </div>
        <div className="flex flex-col">
          <StarRatings />
          <BrandsBanner />
        </div>
      </ContainerCard>
    </>
  );
}
