/* eslint-disable global-require */
import * as Sentry from '@sentry/react';
import ScrollToTop from 'components/ScrollToTop';
import * as React from 'react';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { MixpanelProvider } from 'react-mixpanel-browser';
import { BrowserRouter } from 'react-router-dom';

import { ErrorPage } from 'pages/customer';

import {
  AuthProvider,
  ReactQueryProvider,
  TrackingProvider
} from 'context/index';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <Suspense fallback={<LayoutLoadingSkeleton />}>
        <ErrorBoundary
          onError={(error) => Sentry.captureException(error)}
          FallbackComponent={ErrorPage}
        >
          <ReactQueryProvider>
            <MixpanelProvider>
              <TrackingProvider>
                <AuthProvider>
                  <ScrollToTop />
                  {children}
                  <Toaster />
                </AuthProvider>
              </TrackingProvider>
            </MixpanelProvider>
          </ReactQueryProvider>
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppProviders;
