import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { CompleteProfileForm } from 'features/customer/profile';
import { LayoutModal } from 'features/shared/layout';

import { SetState } from 'types/core';

interface ModalCompleteProfileProps {
  isModalOpen: boolean;
  setIsModalOpen: SetState<boolean>;
}

export default function ModalCompleteProfile({
  isModalOpen,
  setIsModalOpen
}: ModalCompleteProfileProps) {
  const { t } = useTranslation('customer');

  const handleModalClose = () => setIsModalOpen(false);

  return (
    <LayoutModal
      isClosable={false}
      isModalOpen={isModalOpen}
      handleModalClose={handleModalClose}
      persisting
    >
      <div>
        <div className="flex flex-col items-center">
          <Title variant="h3">{t('account.profile.finish.title')}</Title>
        </div>
        <div className="text-center">
          <p className="text-md mt-2 font-medium ">
            {t('account.profile.finish.p')}
          </p>
          <div className="mt-4">
            <CompleteProfileForm setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
