import { usePartnerQuery } from 'api/customer/queries.index';

import {
  QuestionFunnelIndications,
  QuestionFunnelStep
} from 'features/customer/funnels';
import { FunnelLoginForm } from 'features/customer/funnels/contact/login';
import { ContainerCard } from 'features/shared/container';
import { QuestionForm } from 'features/shared/forms';
import { QuestionFormInput } from 'features/shared/forms/QuestionForm';
import {
  LayoutFunnelPartner,
  LayoutLoadingSkeleton
} from 'features/shared/layout';

import { FunnelPartnerLoginConsistencyMetadata } from 'types/funnel';

interface ContactQuestionPartnerProps {
  questionContent: string;
  formStep: QuestionFunnelStep;
  handleQuestionFormSubmit: (question: QuestionFormInput) => void;
  handleChangeStep(
    step: QuestionFunnelStep.Content | QuestionFunnelStep.Login
  ): void;
  partnerSlug: string;
}

export default function ContactQuestionPartner({
  partnerSlug,
  questionContent,
  formStep,
  handleQuestionFormSubmit,
  handleChangeStep
}: ContactQuestionPartnerProps) {
  const isFormStepContent = formStep === QuestionFunnelStep.Content;

  const partner = usePartnerQuery({ partnerSlug });

  if (!partner.isSuccess) {
    return <LayoutLoadingSkeleton />;
  }

  const consistencyMetadata: FunnelPartnerLoginConsistencyMetadata = {
    context: 'landing_funnel',
    questionContent,
    partnerSlug
  };

  return (
    <LayoutFunnelPartner partnerSlug={partnerSlug}>
      {isFormStepContent ? (
        <>
          <QuestionFunnelIndications step={QuestionFunnelStep.Content} />
          <ContainerCard>
            <QuestionForm
              personalOnly
              handleQuestionFormSubmit={handleQuestionFormSubmit}
              questionContent={questionContent}
            />
          </ContainerCard>
        </>
      ) : (
        <>
          <QuestionFunnelIndications step={QuestionFunnelStep.Login} />
          <FunnelLoginForm
            consistencyMetadata={consistencyMetadata}
            handleChangeStep={handleChangeStep}
          />
        </>
      )}
    </LayoutFunnelPartner>
  );
}
