import { Card, CardContent } from 'components/card';
import { INTENT_CONSULTATION_SOURCES } from 'constants/analytics';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCreateHref } from 'hooks';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

import { Lawyer } from 'types/lawyer';
import { Question } from 'types/question';

interface ConsultationCtaCardProps {
  questionId: Question['id'];
  lawyerId: Lawyer['id'];
}

export default function ConsultationCtaCard({
  questionId,
  lawyerId
}: ConsultationCtaCardProps) {
  const { t } = useTranslation('customer');

  const { href } = useCreateHref({
    path: '/account/consultations/booking',
    params: {
      questionId,
      lawyerId,
      intentConsultationSource: INTENT_CONSULTATION_SOURCES.QUESTION_BOTTOM
    }
  });

  return (
    <Card>
      <CardContent className="gap-4">
        <Paragraph className="text-md text-center">
          {t('account.question.consultation.hook')}
        </Paragraph>
        <Link to={href}>
          <Button
            variant="tertiary"
            size="large"
            fullWidth
            label={t('account.question.consultation.cta')}
          />
        </Link>
      </CardContent>
    </Card>
  );
}
