import { useOutletContext } from 'react-router-dom';

import { Consultation } from 'types/consultation';
import { CustomerUsageMeter } from 'types/customer';
import { PaginationQuestion, Question } from 'types/question';
import { Subscription } from 'types/subscription';

interface OutletContext {
  subscription: Subscription;
  questions: { questions: Question[]; pagination: PaginationQuestion };
  consultations: Consultation[];
  futureConsultations: Consultation[];
  currentQuestions: Question[];
  customerUsageMeter: CustomerUsageMeter;
}

export default function useCustomerAccount() {
  return useOutletContext<OutletContext>();
}
