import { SVGProps } from 'react';

export default function StarIcon({
  fill = '#F5D7B3',
  stroke = 'transparent',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.84877 13.3356L12.8791 15.8945C13.3989 16.2224 14.0387 15.7346 13.8867 15.1348L12.7192 10.5447C12.6876 10.4175 12.6926 10.2839 12.7337 10.1594C12.7747 10.0349 12.8501 9.92454 12.9511 9.84101L16.5656 6.82624C17.0374 6.4344 16.7975 5.64272 16.1818 5.60274L11.4637 5.29886C11.335 5.29137 11.2112 5.24654 11.1075 5.16985C11.0039 5.09317 10.9248 4.98794 10.8799 4.86704L9.12066 0.436842C9.07409 0.308816 8.98926 0.198222 8.87767 0.120071C8.76608 0.0419207 8.63315 0 8.49692 0C8.36069 0 8.22775 0.0419207 8.11617 0.120071C8.00458 0.198222 7.91974 0.308816 7.87317 0.436842L6.11389 4.86704C6.06905 4.98794 5.98997 5.09317 5.8863 5.16985C5.78262 5.24654 5.65886 5.29137 5.53013 5.29886L0.812051 5.60274C0.196302 5.64272 -0.0435999 6.4344 0.428208 6.82624L4.04273 9.84101C4.14376 9.92454 4.21913 10.0349 4.26016 10.1594C4.30119 10.2839 4.30621 10.4175 4.27464 10.5447L3.19508 14.799C3.01115 15.5187 3.77884 16.1025 4.39459 15.7106L8.14506 13.3356C8.25023 13.2687 8.37228 13.2332 8.49692 13.2332C8.62155 13.2332 8.7436 13.2687 8.84877 13.3356V13.3356Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
}
