import { BriefcaseIcon, CalendarIcon } from '@heroicons/react/solid';
import { Title } from 'components';
import { Card, CardContent } from 'components/card';
import { INTENT_CONSULTATION_SOURCES } from 'constants/analytics';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateHref } from 'hooks';

import { ConsultationVoteForm } from 'features/customer/consultation/forms';
import { ModalRescheduledConfirmation } from 'features/customer/consultation/modal';
import { ConsultationCardDocumentSection } from 'features/lawyer/account/consultations/card';
import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { CameraIcon } from 'features/shared/icons';

import { getDateAsDdMmYyyy, getDateAsHhMm } from 'utils/date';

import { Appointment, Consultation } from 'types/consultation';
import { BookingConsultationOption } from 'types/lawyer';

interface ConsultationCardProps {
  consultation: Consultation;
  type: 'future' | 'past';
  withCta?: Boolean;
  handleConsultationVote?: (
    appointmentId: Appointment['id'],
    note: number
  ) => void;
}

export default function ConsultationCard({
  consultation,
  type,
  withCta = true,
  handleConsultationVote
}: ConsultationCardProps) {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const [isModalRescheduledOpen, setIsModalRescheduledOpen] =
    useState<boolean>(false);

  const isSchedulable = useMemo(() => {
    if (type === 'future') {
      return !consultation.appointments.find(
        (appointment) => !appointment.isProcessed
      );
    }
    return false;
  }, [consultation.appointments, type]);

  const lastAppointment = useMemo(() => {
    if (type === 'future') {
      return consultation.appointments.filter(
        (appointment) => !appointment.isProcessed
      )[0];
    }
    return consultation.appointments.filter(
      (appointment) => appointment.isProcessed
    )[0];
  }, [consultation.appointments, type]);

  const { href: scheduleHref } = useCreateHref({
    path: `/account/consultations/booking`,
    params: {
      consultationId: consultation.id,
      questionId: consultation.linkedQuestion.id,
      lawyerId: consultation?.latestAppointment?.lawyer?.id,
      intentConsultationSource:
        INTENT_CONSULTATION_SOURCES.CONSULTATION_SCHEDULE
    }
  });

  const { href: rescheduleHref } = useCreateHref({
    path: `/account/consultations/booking`,
    params: {
      lawyerId:
        lastAppointment?.lawyer?.id ||
        consultation?.latestAppointment?.lawyer?.id,
      questionId: consultation.linkedQuestion.id,
      appointmentId: lastAppointment?.id,
      consultationId: consultation.id
    }
  });

  const lawyerFullName = useMemo(() => {
    if (lastAppointment) {
      return `${lastAppointment?.lawyer?.firstName ?? ''} ${
        lastAppointment?.lawyer?.lastName ?? ''
      }`;
    }
    return `${consultation.latestAppointment?.lawyer.firstName ?? ''} ${
      consultation.latestAppointment?.lawyer.lastName ?? ''
    } `;
  }, [consultation, lastAppointment]);

  const isVideoConsultation =
    lastAppointment?.appointmentType === BookingConsultationOption.BookingVideo;

  const { isConsultationVoted, isConsultationCompleted } = consultation;

  const shouldDisplayVoteCta =
    handleConsultationVote && isConsultationCompleted && !isConsultationVoted;

  const onConfirmRescheduleAppointment = () => navigate(rescheduleHref);
  const onConfirmScheduleAppointment = () => navigate(scheduleHref);
  const onTriggerRescheduleModal = () => setIsModalRescheduledOpen(true);

  return (
    <Card className="max-w-[720px]">
      <CardContent>
        <div className="flex gap-2 mb-3">
          <CalendarIcon className="h-6 w-6" />
          <Paragraph className="font-semibold">
            {t('account.consultations.date', {
              date: getDateAsDdMmYyyy(lastAppointment?.appointmentDate),
              time: getDateAsHhMm(lastAppointment?.appointmentDate)
            })}
          </Paragraph>
        </div>
        <div className="flex gap-2">
          <BriefcaseIcon className="h-6 w-6" />
          <Paragraph className="font-semibold">
            {t('account.consultations.with', {
              attorney: lawyerFullName
            })}
          </Paragraph>
        </div>
      </CardContent>

      <CardContent className="my-2">
        <Title variant="h3" gutterBottom>
          {t('account.consultations.initialQuestion')}
        </Title>
        <Paragraph
          weight="normal"
          className="block line-clamp-2 mb-2 w-full prose border-2 border-gray-300 px-4 py-2 rounded-xl"
        >
          {consultation.linkedQuestion?.content}
        </Paragraph>
      </CardContent>
      {consultation.document && (
        <CardContent>
          <ConsultationCardDocumentSection document={consultation.document} />
        </CardContent>
      )}
      {type === 'future' && (
        <div className="w-full flex flex-row justify-end mt-3 gap-3">
          {isSchedulable ? (
            <div className="group flex relative">
              <Button
                size="medium"
                variant="tertiary"
                onClick={onConfirmScheduleAppointment}
                label={t('account.consultations.schedule.cta')}
              />
              <span className="group-hover:opacity-100 transition-opacity bg-gray-800 px-3 py-2 text-xs text-gray-100 rounded-md absolute w-full left-1/2 -translate-x-1/2 -translate-y-full -top-7 opacity-0 m-4 mx-auto">
                {t('account.consultations.schedule.tooltip')}
              </span>
            </div>
          ) : (
            withCta && (
              <>
                <Button
                  size="medium"
                  variant="outline"
                  onClick={onTriggerRescheduleModal}
                  label={t('account.consultations.reschedule.cta')}
                />
                {isVideoConsultation && (
                  <a
                    href={lastAppointment?.videoRoom?.guestUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      label={
                        <div className="flex items-center">
                          {t('account.consultations.join')}
                          <div className="h-4 ml-2">
                            <CameraIcon color="white" />
                          </div>
                        </div>
                      }
                      variant="tertiary"
                      size="medium"
                      customClassNames="bg-black hover:bg-gray-800 h-full text-white"
                    />
                  </a>
                )}
              </>
            )
          )}
        </div>
      )}

      {shouldDisplayVoteCta && (
        <ConsultationVoteForm
          appointmentId={consultation.latestAppointment!.id}
          handleConsultationVote={handleConsultationVote}
        />
      )}
      {type === 'future' && (
        <ModalRescheduledConfirmation
          setIsOpen={setIsModalRescheduledOpen}
          isOpen={isModalRescheduledOpen}
          handleConfirm={onConfirmRescheduleAppointment}
        />
      )}
    </Card>
  );
}
