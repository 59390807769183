import { Section, Title } from 'components';
import { CustomerEditProfile, CustomerProfile } from 'containers/profile';
import { CustomerSubscription } from 'containers/subscription';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageLayout } from 'features/customer/account/content';

function ProfilePage() {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Section gutterBottom>
        <Title gutterBottom variant="h2">
          {t('account.profile.title')}
        </Title>
        <div className="flex w-full flex-col space-y-2 max-w-[720px]">
          {!isEdit ? (
            <CustomerProfile onEditClick={() => setIsEdit(!isEdit)} />
          ) : (
            <CustomerEditProfile onFinish={() => setIsEdit(false)} />
          )}
        </div>
      </Section>
      <Section>
        <Title gutterBottom variant="h2">
          {t('account.subscription.section')}
        </Title>
        <CustomerSubscription />
      </Section>
    </PageLayout>
  );
}

export default ProfilePage;
