import { CustomerUsageMeter } from 'types/customer';
import { FunnelConsistencyMetadata } from 'types/funnel';

import { ConfirmationPartner } from '.';
import ConfirmationErrorCannotAskQuestion from '../error/ConfirmationErrorCannotAskQuestion';
import { ConfirmationPartnerProps } from './ConfirmationPartner';
import ConfirmationPartnerQuestionWithoutOrder from './ConfirmationPartnerQuestionWithoutOrder';

interface ConfirmationPartnerGuardProps extends ConfirmationPartnerProps {
  isPayable: FunnelConsistencyMetadata['product']['isPayable'];
  skuCode: FunnelConsistencyMetadata['product']['skuCode'];
  hasActiveSubscription: CustomerUsageMeter['hasActiveSubscription'];
  canAskNewQuestion: CustomerUsageMeter['canAskNewQuestion'];
  activeSubscriptionId: CustomerUsageMeter['activeSubscriptionId'];
  partnerSlug: string;
}

export default function ConfirmationPartnerGuard({
  isPayable,
  productId,
  skuCode,
  questionContent,
  hasActiveSubscription,
  canAskNewQuestion,
  activeSubscriptionId,
  partnerSlug
}: ConfirmationPartnerGuardProps) {
  const isAlreadySubscribed = hasActiveSubscription && !!activeSubscriptionId;

  if (isAlreadySubscribed && canAskNewQuestion) {
    return (
      <ConfirmationPartnerQuestionWithoutOrder
        questionContent={questionContent}
        subscriptionId={activeSubscriptionId}
      />
    );
  }

  if (isAlreadySubscribed && !canAskNewQuestion) {
    return <ConfirmationErrorCannotAskQuestion />;
  }

  return (
    <ConfirmationPartner
      skuCode={skuCode}
      isPayable={isPayable}
      productId={productId}
      questionContent={questionContent}
      partnerSlug={partnerSlug}
    />
  );
}
