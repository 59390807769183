import { useEffect, useState } from 'react';

import {
  QuestionFunnelIndications,
  QuestionFunnelProductType,
  QuestionFunnelStep,
  QuestionFunnelType
} from 'features/customer/funnels';
import { FunnelLoginForm } from 'features/customer/funnels/contact/login';
import { ContainerCard } from 'features/shared/container';
import { QuestionForm } from 'features/shared/forms';
import { QuestionFormInput } from 'features/shared/forms/QuestionForm';
import { LayoutFunnelCustomer } from 'features/shared/layout';

import { FunnelCustomerLoginConsistencyMetadata } from 'types/funnel';

const defaultCustomerSkuCode = process.env
  .REACT_APP_PRODUCT_STANDARD_SKU_CODE as string;

const promo1mCustomerSkuCode = process.env
  .REACT_APP_PRODUCT_STANDARD_PROMO_SKU_CODE as string;

interface ContactQuestionCustomerProps {
  questionContent: string;
  productType: string;
  formStep: QuestionFunnelStep.Content | QuestionFunnelStep.Login;
  handleQuestionFormSubmit: (question: QuestionFormInput) => void;
  handleChangeProductTypeSubmit: (
    currentType: QuestionFunnelProductType
  ) => void;
  handleChangeStep(
    step: QuestionFunnelStep.Content | QuestionFunnelStep.Login
  ): void;
}

export default function ContactQuestionCustomer({
  questionContent,
  productType,
  formStep,
  handleQuestionFormSubmit,
  handleChangeProductTypeSubmit,
  handleChangeStep
}: ContactQuestionCustomerProps) {
  const [metadata, setMetadata] =
    useState<FunnelCustomerLoginConsistencyMetadata>({
      userType: QuestionFunnelType.Private,
      context: 'question_funnel',
      questionContent,
      skuCode: defaultCustomerSkuCode
    });
  const isFormStepQuestionContent = formStep === QuestionFunnelStep.Content;

  useEffect(() => {
    setMetadata({
      context: 'question_funnel',
      questionContent,
      userType:
        productType === QuestionFunnelProductType.Standard
          ? QuestionFunnelType.Private
          : QuestionFunnelType.Professional,
      skuCode:
        productType === QuestionFunnelProductType.Standard
          ? defaultCustomerSkuCode
          : promo1mCustomerSkuCode
    });
  }, [productType, questionContent]);

  return (
    <LayoutFunnelCustomer>
      {isFormStepQuestionContent ? (
        <>
          <QuestionFunnelIndications step={QuestionFunnelStep.Content} />
          <ContainerCard>
            <QuestionForm
              personalOnly
              handleQuestionFormSubmit={handleQuestionFormSubmit}
              questionContent={questionContent}
            />
          </ContainerCard>
        </>
      ) : (
        <>
          <QuestionFunnelIndications step={QuestionFunnelStep.Login} />
          <FunnelLoginForm
            consistencyMetadata={metadata}
            handleChangeProductTypeSubmit={handleChangeProductTypeSubmit}
            handleChangeStep={handleChangeStep}
            isPro={productType === QuestionFunnelProductType.Promo}
          />
        </>
      )}
    </LayoutFunnelCustomer>
  );
}
