import { Card, FullCard } from 'components';
import { useLastTemplateB2B } from 'services/dato';

import { TrackedLink } from 'features/shared/buttons';

function CommunicationList() {
  const { data, isLoading } = useLastTemplateB2B();

  if (isLoading)
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <Card compact className="animate-pulse flex flex-row h-72">
          <div className="h-40 w-full rounded-lg bg-gray-300" />
          <div className="h-14 w-full bg-gray-300 rounded" />
          <div className="h-3 w-2/3 bg-gray-300 rounded" />
        </Card>
        <Card compact className="animate-pulse flex flex-row h-72">
          <div className="h-40 w-full rounded-lg bg-gray-300" />
          <div className="h-14 w-full bg-gray-300 rounded" />
          <div className="h-3 w-2/3 bg-gray-300 rounded" />
        </Card>
      </div>
    );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      {data?.allTemplateCommunicationB2bs.map((communication) => (
        <TrackedLink
          key={communication.id}
          name="communication"
          metadata={{ slug: communication.slug }}
          to={`${process.env.REACT_APP_BASE_URL}/content/${communication.slug}`}
          external
        >
          <FullCard
            className="h-64"
            imgHeight="h-40"
            image={communication.image.responsiveImage}
            title={communication.titre}
            text={communication.heading}
          />
        </TrackedLink>
      ))}
    </div>
  );
}

export default CommunicationList;
