import { BellIcon } from '@heroicons/react/outline';
import { Badge } from 'components';

import { useCustomerAccount } from 'hooks';

export default function NotificationBadge() {
  const { currentQuestions, futureConsultations } = useCustomerAccount();
  const totalNotifications =
    currentQuestions.length + futureConsultations.length;

  return (
    <Badge content={totalNotifications}>
      <BellIcon className="block w-6" aria-hidden="true" />
    </Badge>
  );
}
