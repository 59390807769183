import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { newPasswordSchema } from 'schemas';

import { useUpdatePasswordMutation } from 'api/shared/login';

import { useGuardedSearchParams } from 'hooks';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { NewPasswordFormInputs } from 'types/inputs';

export default function NewPasswordForm() {
  const navigate = useNavigate();
  const { mutate, isLoading } = useUpdatePasswordMutation();

  const email = useGuardedSearchParams({ param: 'email' });
  const token = useGuardedSearchParams({ param: 'token' });

  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const handleError = useErrorHandler();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<NewPasswordFormInputs>({
    resolver: yupResolver(newPasswordSchema(tForm))
  });

  const onSubmit: SubmitHandler<NewPasswordFormInputs> = async (formData) => {
    const { password } = formData;
    mutate(
      {
        email,
        token,
        newPassword: password
      },
      {
        onError: (error: any) => {
          const isUnauthorizedError = error?.response?.status === 403;
          if (isUnauthorizedError) {
            setError('password', {
              message: `${t('error.tokenInvalid')}.`,
              type: 'focus'
            });
          } else {
            handleError(error);
          }
        },
        onSuccess: () => {
          const successParams = new URLSearchParams({
            password: 'true',
            newPassword: 'true',
            email
          });
          navigate(`/login?${successParams.toString()}`);
        }
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        autofocus
        fullWidth
        type="password"
        placeholder="**********"
        label={tForm('label.password')}
        name="password"
        register={register}
        error={errors.password}
      />
      <Button
        isLoading={isLoading}
        fullWidth
        submit
        size="medium"
        variant="primary"
        label={t('general.continue')}
      />
    </form>
  );
}
