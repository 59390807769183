import { GoogleRating } from 'components';
import { capitalize } from 'lodash';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { createAdditionalRequest } from 'services/customer/additional-request';

import { useCreateAnswerVoteOptimisticMutation } from 'api/customer/mutations.index';

import { useCustomer } from 'hooks';

import {
  AnswerUnsatisfiedChoice,
  AnswerVoteForm,
  AnswerVoteUnsatisfiedForm
} from 'features/customer/question/vote/forms';
import AnswerAdditionalRequestForm from 'features/customer/question/vote/forms/AnswerAdditionalRequestForm';
import { Toast } from 'features/shared/toasts';

import { Answer } from 'types/answer';
import { Lawyer } from 'types/lawyer';
import { NewAnswerVote, Question } from 'types/question';
import { UnsatisfiedVotingValue, VotingStatus } from 'types/vote';

interface AnswerVoteProps {
  questionId: Question['id'];
  answerId: Answer['id'];
  canCreateAdditionalRequest?: boolean;
  lawyer: Lawyer | null;
}

const AnswerVote = ({
  questionId,
  answerId,
  lawyer,
  canCreateAdditionalRequest = false
}: AnswerVoteProps) => {
  const { t } = useTranslation('customer');
  const { isB2b } = useCustomer();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateAnswerVoteOptimisticMutation();
  const [answerVotingStatus, setAnswerVotingStatus] =
    useState<VotingStatus>('non-voted');
  const [unsatisfiedVotingValue, setUnsatisfiedVotingValue] =
    useState<UnsatisfiedVotingValue>();

  const {
    mutate: createAdditionalRequestMutate,
    isLoading: isCreateAdditionalRequestLoading
  } = useMutation(
    (content: string) => createAdditionalRequest(answerId, content),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['question', questionId]);
        toast.custom(
          <Toast
            type="success"
            title={t('general.success')}
            message={t('account.vote.success')}
          />,
          { position: 'bottom-left', duration: 3000 }
        );
      },
      onError: () => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('account.question.additionalRequest.form.success')}
          />,
          { position: 'top-right', duration: 3000 }
        );
      }
    }
  );

  const handleSatisfiedMutate = (note: NewAnswerVote['note']) => {
    mutate(
      {
        questionId,
        answerId,
        newAnswerVote: {
          note
        }
      },
      {
        onSuccess: () => {
          setAnswerVotingStatus('satisfied');
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.vote.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        }
      }
    );
  };

  const handleUnsatisfiedMutate = (reason: NewAnswerVote['reason']) => {
    if (unsatisfiedVotingValue) {
      mutate(
        {
          questionId,
          answerId,
          newAnswerVote: {
            note: unsatisfiedVotingValue,
            reason
          }
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['question', questionId]);
            toast.custom(
              <Toast
                type="success"
                title={t('general.success')}
                message={t('account.vote.success')}
              />,
              { position: 'bottom-left', duration: 3000 }
            );
          }
        }
      );
    }
  };

  return (
    <>
      {answerVotingStatus === 'satisfied' && <GoogleRating isB2b={isB2b} />}
      {answerVotingStatus === 'unsatisfied-choice' && (
        <AnswerUnsatisfiedChoice
          onConfirmClick={() => setAnswerVotingStatus('unsatisfied')}
          onNextStepClick={() => setAnswerVotingStatus('additional-request')}
          description={t(
            'account.question.vote.choiceUnsatisfied.description',
            {
              fullName: `${capitalize(lawyer?.firstName)} ${capitalize(
                lawyer?.lastName
              )}`
            }
          )}
        />
      )}
      {answerVotingStatus === 'additional-request' && (
        <AnswerAdditionalRequestForm
          isLoading={isCreateAdditionalRequestLoading}
          onSubmitAdditionalRequestForm={createAdditionalRequestMutate}
        />
      )}
      {answerVotingStatus === 'non-voted' && (
        <AnswerVoteForm
          isLoading={isLoading}
          canCreateAdditionalRequest={canCreateAdditionalRequest}
          handleSatisfiedMutate={handleSatisfiedMutate}
          setAnswerVotingStatus={setAnswerVotingStatus}
          setUnsatisfiedVotingValue={setUnsatisfiedVotingValue}
        />
      )}
      {answerVotingStatus === 'unsatisfied' && unsatisfiedVotingValue && (
        <AnswerVoteUnsatisfiedForm
          isLoading={isLoading}
          handleUnsatisfiedMutate={handleUnsatisfiedMutate}
        />
      )}
    </>
  );
};

export default AnswerVote;
