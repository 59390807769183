import { privateAxios } from 'context/auth/auth.utils';

import {
  ApiFunnelConsistencyMetadata,
  FunnelConsistencyMetadata
} from 'types/funnel';

import { formatFunnelConsistencyMetadataFromApi } from './formatting';

export async function getFunnelConsistencyMetadata(
  funnelId: string
): Promise<FunnelConsistencyMetadata> {
  const { data } = await privateAxios.get(`/funnels/${funnelId}`);
  const funnelConsistencyMetadata = formatFunnelConsistencyMetadataFromApi(
    data as ApiFunnelConsistencyMetadata
  );
  return funnelConsistencyMetadata;
}
