import { LayoutDefault } from 'features/shared/layout';
import { RequestResetPasswordCard } from 'features/shared/login/reset-password';

export default function RequestResetPasswordPage() {
  return (
    <LayoutDefault minimal>
      <RequestResetPasswordCard />
    </LayoutDefault>
  );
}
