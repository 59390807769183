import { LayoutDefault } from 'features/shared/layout';
import { NewPasswordCard } from 'features/shared/login/reset-password';

export default function NewPasswordPage() {
  return (
    <LayoutDefault minimal>
      <NewPasswordCard />
    </LayoutDefault>
  );
}
