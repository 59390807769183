import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { SetState } from 'types/core';
import {
  SubscribePremiumPlusAdditionalInputs,
  SubscribePremiumPlusRequiredInputs
} from 'types/inputs';
import { ConfirmationData } from 'types/legal-protection';

// Differents steps of the funnel
export enum SubscribePremiumPlusStep {
  RequiredData = 'required',
  AdditionalData = 'additional',
  Confirmation = 'confirmation',
  Payment = 'payment'
}

export interface SubscribePremiumPlusContext {
  currentStep: SubscribePremiumPlusStep;
  setCurrentStep: SetState<SubscribePremiumPlusStep>;
  requiredData: SubscribePremiumPlusRequiredInputs;
  setRequiredData: SetState<SubscribePremiumPlusRequiredInputs>;
  additionalData: SubscribePremiumPlusAdditionalInputs;
  setAdditionalData: SetState<SubscribePremiumPlusAdditionalInputs>;
  confirmationData: ConfirmationData;
  setConfirmationData: SetState<ConfirmationData>;
}

export const SubscribePremiumPlusCtx = createContext<
  SubscribePremiumPlusContext | undefined
>(undefined);

export default function SubscribePremiumPlusProvider({
  children
}: {
  children: ReactNode;
}) {
  // Initiate currentStep to the first step
  const [currentStep, setCurrentStep] = useState<SubscribePremiumPlusStep>(
    SubscribePremiumPlusStep.RequiredData
  );

  const [requiredData, setRequiredData] =
    useState<SubscribePremiumPlusRequiredInputs>({
      // Required for customer
      civility: 'mr',
      firstName: '',
      lastName: '',
      birthDate: '',
      birthCity: '',
      birthZipCode: '',
      birthCountry: '',
      address: '',
      addressComplement: '',
      zipCode: '',
      city: '',
      country: '',
      email: '',
      phoneCellphone: '',
      withPartnerData: false,
      // Opt. partner
      partnerFirstName: '',
      partnerLastName: '',
      partnerBirthDate: '',
      partnerBirthCity: '',
      partnerBirthZipCode: '',
      partnerBirthCountry: ''
    });

  const [additionalData, setAdditionalData] =
    useState<SubscribePremiumPlusAdditionalInputs>({
      socioeconomicStatus: '',
      jobTitle: '',
      isInLitigation: 'false',
      warrantyDate: ''
    });

  const [confirmationData, setConfirmationData] = useState<ConfirmationData>({
    signatureLink: '',
    paymentIntentSecret: ''
  });

  const values = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      requiredData,
      additionalData,
      setRequiredData,
      setAdditionalData,
      confirmationData,
      setConfirmationData
    }),
    [currentStep, requiredData, additionalData, confirmationData]
  );

  return (
    <SubscribePremiumPlusCtx.Provider value={values}>
      {children}
    </SubscribePremiumPlusCtx.Provider>
  );
}

export const useSubscribePremiumPlusContext = () => {
  const context = useContext(SubscribePremiumPlusCtx);
  if (context === undefined) {
    throw new Error(
      'useSubscribePremiumPlusContext must be used within a Provider'
    );
  }
  return context;
};
