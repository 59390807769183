import { Card, CardContent } from 'components/card';
import { INTENT_CONSULTATION_SOURCES } from 'constants/analytics';
import { capitalize } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCreateHref, useLocale } from 'hooks';

import { Button } from 'features/shared/buttons';

import { formatDateToIntl } from 'utils/date';
import { formatQuestionStatusi18nKey } from 'utils/question';

import { Question } from 'types/question';

interface QuestionCardProps {
  withConsultationCta?: boolean;
  withCta?: Boolean;
  question: Question;
}

export default function QuestionCard({
  withConsultationCta = false,
  withCta = true,
  question
}: QuestionCardProps) {
  const { locale } = useLocale();
  const { t } = useTranslation('customer');
  const { createdDate, content, status, customer, source } = question;
  const { href } = useCreateHref({
    path: '/account/consultations/booking',
    params: {
      questionId: question.id,
      lawyerId: question.answers.filter((answer) => answer.status === 10)[0]
        ?.lawyer?.id,
      intentConsultationSource:
        INTENT_CONSULTATION_SOURCES.QUESTIONS_QUESTION_CARD
    }
  });

  const canCreateConsultation =
    withCta && (withConsultationCta || question.shouldCtaForConsultation);
  const canVote = withCta && question.shouldCtaForAnswerVote;

  return (
    <Card className="max-w-[720px]">
      <CardContent>
        <span className="text-md break-words line-clamp-2 font-normal">
          {capitalize(content)}
        </span>
        <div className="my-5 border-base border-gray-500" />
        <Trans
          parent="div"
          ns="customer"
          className="truncate mb-1 text-gray-700"
          i18nKey="account.questions.askedThe"
          components={{ 1: <span className="font-medium text-gray-800" /> }}
          values={{
            date: formatDateToIntl(new Date(createdDate), 'long', locale)
          }}
        />
        <Trans
          parent="div"
          ns="customer"
          className="truncate mb-1 text-gray-700"
          i18nKey="account.questions.status.label"
          components={{ 1: <span className="font-medium text-gray-800" /> }}
          values={{
            status: t(
              `account.questions.status.${formatQuestionStatusi18nKey(status)}`
            )
          }}
        />
        {source === 'cse' && (
          <Trans
            parent="div"
            ns="customer"
            className="truncate mb-1 text-gray-700"
            i18nKey="account.questions.by"
            components={{ 1: <span className="font-medium text-gray-800" /> }}
            values={{
              name: `${customer?.firstName} ${customer?.lastName}`
            }}
          />
        )}
        {(canCreateConsultation || canVote) && (
          <div className="mt-10 w-full lg:w-fit flex flex-col sm:flex-row gap-4">
            {canCreateConsultation && (
              <Link className="w-full lg:w-fit flex-1" to={href}>
                <Button
                  fullWidth
                  label={t('account.question.askConsultation')}
                  variant="tertiary"
                  size="medium"
                />
              </Link>
            )}
            {canVote && (
              <Link to={`/questions/${question.id}`}>
                <Button
                  fullWidth
                  label={t('account.question.vote.cta')}
                  variant="tertiary"
                  size="medium"
                />
              </Link>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
