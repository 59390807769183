import LoadingSpinner from './LoadingSpinner';

export default function LoadingCard() {
  return (
    <div className="w-full md:max-w-3xl">
      <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6">
        <div className="grid place-items-center">
          <LoadingSpinner purple large />
        </div>
      </div>
    </div>
  );
}
