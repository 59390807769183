/* eslint-disable @typescript-eslint/naming-convention */
import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosInstance } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import qS from 'query-string';

import {
  loginOtpGooglePayload,
  loginOtpMagicLinkPayload,
  loginOtpPayload,
  loginPartnerPayload,
  loginPasswordPayload,
  refreshTokenPayload
} from 'context/auth/auth.constants';

import { AuthApiResponse, AuthLocalStorage } from 'types/auth';

// const API_TIMEOUT = 10000;

const publicAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_TEST_MODE
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const privateAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

privateAxios.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': config.url?.includes('token')
        ? 'application/x-www-form-urlencoded'
        : 'application/json'
    }
  }),
  (error) => {
    Promise.reject(error);
  }
);

privateAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config as AxiosError['config'];
    if (
      error?.response?.status === 401 &&
      !originalRequest.url?.includes('token')
    ) {
      const { accessToken } = await getNewAuthTokens();
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      return privateAxios(originalRequest);
    }
    if (originalRequest.url?.includes('token')) {
      handleClearAuthAndLoginRedirect();
    }
    return Promise.reject(error);
  }
);

const privateAxiosJsonLd: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
  // timeout: API_TIMEOUT
});

privateAxiosJsonLd.interceptors.request.use(
  async (config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      Accept: 'application/ld+json'
    }
  }),
  (error) => {
    Promise.reject(error);
  }
);

const handleClearAuthAndLoginRedirect = () => {
  clearAuthStorage();
  return window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
};

const getExpiresAt = (expires_in: AuthApiResponse['expires_in']): string => {
  const currentTime = new Date(Date.now());
  currentTime.setSeconds(currentTime.getSeconds() + expires_in);
  return new Date(currentTime).toISOString();
};

const clearAuthStorage = () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('expiresAt');
  window.localStorage.removeItem('email');
};

const formatAuthFromApi = (apiResponse: AuthApiResponse) => {
  const { access_token, refresh_token, expires_in } = apiResponse;
  const accessToken = access_token;
  const refreshToken = refresh_token;
  const email = (jwtDecode(access_token) as JwtPayload).sub as string;
  const expiresAt = getExpiresAt(expires_in);
  return {
    accessToken,
    refreshToken,
    expiresAt,
    email
  };
};

const setNewAuthStorage = ({
  accessToken,
  refreshToken,
  expiresAt,
  email
}: AuthLocalStorage) => {
  window.localStorage.setItem('accessToken', accessToken);
  window.localStorage.setItem('refreshToken', refreshToken);
  window.localStorage.setItem('expiresAt', expiresAt);
  window.localStorage.setItem('email', email);
};

const getNewAuthTokens = async (): Promise<
  | {
      access_token: string;
    }
  | any
> => {
  try {
    const { data } = await privateAxios.post(
      '/token',
      qS.stringify({
        ...refreshTokenPayload,
        refresh_token: window.localStorage.getItem('refreshToken')
      })
    );
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token } = data;
    setNewAuthStorage(data);
    return { accessToken: access_token };
  } catch (error) {
    return Promise.reject(error);
  }
};

const loginUserPartnerCode = async (
  emailSaved: string,
  partnerKey: string,
  password: string
): Promise<AuthLocalStorage> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/token`,
    qS.stringify({
      ...loginPartnerPayload,
      email: emailSaved,
      partner_key: partnerKey,
      password
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );
  const { accessToken, refreshToken, expiresAt, email } =
    formatAuthFromApi(data);
  setNewAuthStorage({
    accessToken,
    refreshToken,
    expiresAt,
    email
  });
  return { accessToken, refreshToken, expiresAt, email };
};

const loginUserOtpCode = async (
  methodId: string,
  code: string,
  emailSaved: string
): Promise<AuthLocalStorage> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/token`,
    qS.stringify({ ...loginOtpPayload, methodId, code, email: emailSaved }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );
  const { accessToken, refreshToken, expiresAt, email } =
    formatAuthFromApi(data);
  setNewAuthStorage({
    accessToken,
    refreshToken,
    expiresAt,
    email
  });
  return { accessToken, refreshToken, expiresAt, email };
};

const loginGoogleOtpCode = async (code: string): Promise<AuthLocalStorage> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/token`,
    qS.stringify({ ...loginOtpGooglePayload, code }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );
  const { accessToken, refreshToken, expiresAt, email } =
    formatAuthFromApi(data);
  setNewAuthStorage({
    accessToken,
    refreshToken,
    expiresAt,
    email
  });
  return { accessToken, refreshToken, expiresAt, email };
};

const loginUserOtpMagicLink = async (
  token: string
): Promise<AuthLocalStorage> => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/token`,
      qS.stringify({ ...loginOtpMagicLinkPayload, token }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    const { accessToken, refreshToken, expiresAt, email } =
      formatAuthFromApi(data);
    setNewAuthStorage({
      accessToken,
      refreshToken,
      expiresAt,
      email
    });
    return { accessToken, refreshToken, expiresAt, email };
  } catch {
    throw new Error('Error creating review');
  }
};

export const loginUserPassword = async ({
  username,
  password
}: {
  username: string;
  password: string;
}): Promise<AuthLocalStorage> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/token`,
    qS.stringify({ ...loginPasswordPayload, username, password }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );
  const {
    accessToken,
    refreshToken,
    expiresAt,
    email: receivedEmail
  } = formatAuthFromApi(data);
  setNewAuthStorage({
    accessToken,
    refreshToken,
    expiresAt,
    email: receivedEmail
  });
  return { accessToken, refreshToken, expiresAt, email: receivedEmail };
};

const logoutUser = () => {
  Sentry.configureScope((scope) => scope.setUser(null));
  handleClearAuthAndLoginRedirect();
  return true;
};

export {
  privateAxios,
  privateAxiosJsonLd,
  publicAxios,
  loginUserOtpCode,
  loginUserPartnerCode,
  loginUserOtpMagicLink,
  logoutUser,
  loginGoogleOtpCode,
  handleClearAuthAndLoginRedirect,
  getExpiresAt,
  clearAuthStorage,
  setNewAuthStorage,
  getNewAuthTokens
};
