/* eslint-disable @typescript-eslint/naming-convention */

enum AuthGrants {
  Password = 'password',
  RefreshToken = 'refresh_token',
  Otp = 'otp',
  MagicLink = 'magic_link',
  Google = 'google',
  Partner = 'partner_code'
}

interface AuthPayload {
  grant_type:
    | 'otp'
    | 'refresh_token'
    | 'password'
    | 'magic_link'
    | 'google'
    | 'partner_code';
  client_id: string;
  client_secret: string;
}

export const loginOtpMagicLinkPayload: AuthPayload = {
  grant_type: AuthGrants.MagicLink,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string
};

export const loginOtpGooglePayload: AuthPayload = {
  grant_type: AuthGrants.Google,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string
};

export const loginOtpPayload: AuthPayload = {
  grant_type: AuthGrants.Otp,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string
};

export const loginPartnerPayload: AuthPayload = {
  grant_type: AuthGrants.Partner,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string
};

export const loginPasswordPayload: AuthPayload = {
  grant_type: AuthGrants.Password,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string
};

export const loginPassPayload: AuthPayload = {
  grant_type: AuthGrants.Password,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string
};

export const refreshTokenPayload: AuthPayload = {
  grant_type: AuthGrants.RefreshToken,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET as string
};
