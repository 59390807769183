import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useUnguardedSearchParams } from 'hooks';

import { LayoutOnboarding } from 'features/shared/layout';
import { EmailForm } from 'features/shared/login/magic/form/email';

export default function OnboardingEmailPage() {
  const navigate = useNavigate();
  const { t } = useTranslation('customer');

  const submitted = useUnguardedSearchParams({ param: 'submitted' });

  const handleSuccess = () => {
    navigate('/onboarding/email?submitted=true');
  };

  return (
    <LayoutOnboarding
      leftChild={
        <div className="h-full py-6">
          <div className="h-full grid grid-rows-6">
            <span className="hidden lg:block" />
            <div className="row-span-2 lg:row-span-3">
              <div className="h-full container mx-auto max-w-md">
                <div className="h-full flex flex-col items-center gap-6">
                  <div className="max-w-2xl">
                    <EkieLogo />
                  </div>
                  <div className="w-full text-center">
                    <h3 className="text-4xl font-serif italic mb-2">
                      {submitted ? 'Email envoyé' : 'Activer mon compte Ekie'}
                    </h3>
                    {!submitted ? (
                      <p className="text-gray-800">
                        {t('onboarding.initial.p')}
                      </p>
                    ) : (
                      <p className="text-gray-800">
                        {t('onboarding.submitted.p')}
                      </p>
                    )}
                  </div>
                  {submitted ? null : (
                    <div className="w-full">
                      <EmailForm handleSuccess={handleSuccess} onboarding />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-8 lg:mt-0 row-start-3 lg:row-start-6 row-span-1">
              {submitted ? null : (
                <div className="text-center h-full grid lg:place-items-center">
                  <p className="font-medium text-gray-800">
                    {t('register.switch.login.1')}{' '}
                    <a className="text-purple-900" href="/login?password=true">
                      {t('register.switch.login.2')}
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      rightChild={
        <div className="h-full flex justify-end">
          <img
            src="/images/onboarding-email.webp"
            className="max-h-screen py-6"
            alt="People in a metro"
          />
        </div>
      }
    />
  );
}
