import { ANALYTICS } from 'constants/analytics';
import { useEffect } from 'react';

import { useCustomerUsageMeterQuery } from 'api/customer/customers';
import { useFunnelConsistencyMetadataQuery } from 'api/customer/funnel-consistency-metadata';

import { useCustomer, useGuardedSearchParams } from 'hooks';

import { useTracking } from 'context';

import { ConfirmationQuestionWithoutOrder } from 'features/customer/funnels/confirmation/customer';
import ConfirmationCustomerGuard from 'features/customer/funnels/confirmation/customer/ConfirmationCustomerGuard';
import ConfirmationPartnerGuard from 'features/customer/funnels/confirmation/partner/ConfirmationPartnerGuard';
import {
  LayoutFunnelCustomer,
  LayoutFunnelPartner,
  LayoutLoadingSkeleton
} from 'features/shared/layout';

export default function FunnelConfirmationPage() {
  const { analytics } = useTracking();
  const consistencyId = useGuardedSearchParams({ param: 'consistencyId' });
  const distinctId = useGuardedSearchParams({ param: 'distinctId' });

  const { id, isB2b } = useCustomer();
  const customerUsageMeter = useCustomerUsageMeterQuery({
    customerId: id
  });
  const funnelConsistencyMetadata = useFunnelConsistencyMetadataQuery({
    funnelId: consistencyId
  });

  const isSuccess =
    funnelConsistencyMetadata.isSuccess && customerUsageMeter.isSuccess;

  useEffect(() => {
    if (analytics) {
      onLinkTrackId();
    }
    // eslint-disable-next-line
  }, [analytics, isSuccess]);

  const onLinkTrackId = () => {
    if (distinctId) {
      analytics?.track(ANALYTICS.FUNNEL_CUSTOMER_REGISTER_SUCCESS);
    }
  };

  if (!isSuccess) {
    return <LayoutLoadingSkeleton />;
  }

  const { partnerSlug, context } = funnelConsistencyMetadata.data;

  const isFunnelPartner = !!partnerSlug;

  if (isB2b) {
    return (
      <LayoutFunnelCustomer>
        <ConfirmationQuestionWithoutOrder
          subscriptionId={
            customerUsageMeter.data.activeSubscriptionId as string
          }
          questionContent={
            funnelConsistencyMetadata.data.questionContent as string
          }
        />
      </LayoutFunnelCustomer>
    );
  }

  if (isFunnelPartner) {
    return (
      <LayoutFunnelPartner partnerSlug={partnerSlug}>
        <ConfirmationPartnerGuard
          skuCode={funnelConsistencyMetadata.data.product.skuCode}
          isPayable={funnelConsistencyMetadata.data.product.isPayable}
          productId={funnelConsistencyMetadata.data.product.id}
          canAskNewQuestion={customerUsageMeter.data.canAskNewQuestion}
          hasActiveSubscription={customerUsageMeter.data.hasActiveSubscription}
          activeSubscriptionId={customerUsageMeter.data.activeSubscriptionId}
          questionContent={
            funnelConsistencyMetadata.data.questionContent as string
          }
          partnerSlug={partnerSlug}
        />
      </LayoutFunnelPartner>
    );
  }

  return (
    <LayoutFunnelCustomer>
      <ConfirmationCustomerGuard
        hasActiveSubscription={customerUsageMeter.data.hasActiveSubscription}
        activeSubscriptionId={customerUsageMeter.data.activeSubscriptionId}
        canAskNewQuestion={customerUsageMeter.data.canAskNewQuestion}
        context={context}
        productId={funnelConsistencyMetadata.data.product.id}
        questionContent={
          funnelConsistencyMetadata.data.questionContent as string
        }
        productSkuCode={funnelConsistencyMetadata.data.product.skuCode}
        distinctId={distinctId}
      />
    </LayoutFunnelCustomer>
  );
}
