import { useTranslation } from 'react-i18next';

import { LayoutCard } from 'features/shared/layout';

import { PartnerLoginForm } from './form';

function PartnerLoginCard() {
  const { t } = useTranslation('customer');

  return (
    <div>
      <LayoutCard
        title={t('login.title.partner')}
        body={
          <div className="w-full">
            <p className="text-base font-medium text-gray-800 pb-8">
              {t('login.desc.partner')}
            </p>
            <div className="pb-4">
              <PartnerLoginForm />
            </div>
          </div>
        }
      />
    </div>
  );
}

export { PartnerLoginCard };
