import { SelectorIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';

import { InputErrorMessage } from 'features/shared/forms';

import { LanguageType } from 'utils/languages';

function escapeRegexCharacters(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(
  data: LanguageType[],
  value: string,
  exceptions: LanguageType[]
) {
  const escapedValue = escapeRegexCharacters(value && value.trim());
  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(`${escapedValue}`, 'i');
  return (
    data.filter(
      (item: any) =>
        regex.test(item.name) &&
        !exceptions.find((exception: any) => exception.id === item.id)
    ) || []
  );
}

function getSuggestionValue(suggestion: LanguageType) {
  return suggestion.name;
}

interface InputSearchProps {
  onHandleChangeValue: (suggestion: LanguageType) => void;
  label: string;
  initialData: LanguageType[];
  selected: LanguageType[];
  placeholder: string;
  error: any;
  disabled?: boolean;
}

function AutocompleteSearch({
  onHandleChangeValue,
  label,
  initialData,
  placeholder,
  selected,
  error,
  disabled = false
}: InputSearchProps) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState(initialData);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
    const list = initialData;
    setSuggestions(getSuggestions(list, value, selected));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const onClearRequest = () => {
    setSuggestions([]);
    setInputValue('');
  };

  const onSuggestionSelected = (
    event: React.FormEvent<HTMLFormElement>,
    { suggestion }: { suggestion: LanguageType }
  ) => {
    event.preventDefault();
    onHandleChangeValue(suggestion);
    onClearRequest();
  };

  const renderSuggestion = (suggestion: LanguageType) => (
    <div className="relative text-left cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-purple-900 hover:text-white">
      <h3 className="">{suggestion.name}</h3>
    </div>
  );

  const renderSuggestionsContainer = ({
    containerProps,
    children,
    query
  }: any) => (
    <div
      {...containerProps}
      className={clsx(
        'absolute z-10 mt-1 max-h-36 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-gray-200 ring-opaville-5 focus:outline-none sm:text-sm hover',
        { 'ring-1': suggestions.length > 1 && query.length > 1 }
      )}
    >
      {children}
    </div>
  );

  const renderInputComponent = (inputProps: any) => {
    const { className, ...rest } = inputProps;
    return (
      <div id="input-group" className={clsx({ 'w-full': true })}>
        <label htmlFor="languages" className="input-label">
          {label}
        </label>
        <div className="relative">
          <button
            type="button"
            className="absolute right-3 top-1/2 -translate-y-1/2 z-10"
          >
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
          <input
            type="text"
            placeholder={placeholder}
            className={clsx(
              'input-text focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900 h-12',
              {
                'text-red-800 border-red-800 bg-red-400': error
              },
              { 'text-gray-700': disabled }
            )}
            disabled={false}
            {...rest}
          />
        </div>
        {error && <InputErrorMessage error={error} />}
      </div>
    );
  };

  return (
    <div className="relative w-full">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={{
          onChange: (e) => onChange(e as any),
          value: inputValue
        }}
      />
    </div>
  );
}

export { AutocompleteSearch };
