import { useMutation, useQuery } from 'react-query';

import {
  Appointment,
  NewAppointmentVote,
  NewUnauthenticatedAppointmentVote
} from 'types/consultation';

import {
  createAppointmentVote,
  createUnauthenticatedAppointmentVote,
  getAppointmentVoteLink
} from './api';

export function useAppointmentLinkQuery({
  voteLinkId
}: {
  voteLinkId: string;
}) {
  return useQuery(['appointment-vote-link', voteLinkId], () =>
    getAppointmentVoteLink(voteLinkId)
  );
}

export function useCreateAppointmentVoteMutation() {
  return useMutation(
    ({
      appointmentId,
      newAppointmentVote
    }: {
      appointmentId: Appointment['id'];
      newAppointmentVote: NewAppointmentVote;
    }) => createAppointmentVote(appointmentId, newAppointmentVote)
  );
}

export function useCreateUnauthenticatedAppointmentVoteMutation() {
  return useMutation(
    ({
      appointmentId,
      newAppointmentVote
    }: {
      appointmentId: Appointment['id'];
      newAppointmentVote: NewUnauthenticatedAppointmentVote;
    }) =>
      createUnauthenticatedAppointmentVote(appointmentId, newAppointmentVote)
  );
}
