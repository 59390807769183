import { ApiQuestion, PaginationQuestion, Question } from 'types/question';

export function formatQuestionPaginationFromApi(
  totalItems: number,
  currentPage: number
): PaginationQuestion {
  const last = totalItems <= 0 ? 0 : Math.ceil(totalItems / 5);
  return {
    first: 1,
    last: last === 0 ? 1 : last,
    next: currentPage + 1,
    totalItems
  };
}

export function formatQuestionFromApi(apiQuestion: ApiQuestion): Question {
  const hasLinkedConsultation = Boolean(
    apiQuestion.linkedConsultations?.length ?? false
  );

  const computeShouldCtaForAnswerVote = () => {
    if (!apiQuestion.answers?.length) return false;
    if (
      !apiQuestion.answers[0]?.answerVotes?.length &&
      apiQuestion.answers[0].status === 10
    )
      return true;
    return false;
  };

  const computeShouldCtaForConsultation = () => {
    if (!apiQuestion.isAnswered) return false;
    if (
      hasLinkedConsultation &&
      apiQuestion.linkedConsultations.filter(
        (c) => c.status === 'status_pending' || c.status === 'status_cancelled'
      ).length === apiQuestion.linkedConsultations.length
    )
      return true;
    if (!hasLinkedConsultation) return true;
    return false;
  };

  const shouldCtaForAnswerVote = computeShouldCtaForAnswerVote();
  const shouldCtaForConsultation = computeShouldCtaForConsultation();

  return {
    id: apiQuestion.id.toString(),
    customer: apiQuestion.customer,
    isPrivate: apiQuestion.isPrivate,
    status: apiQuestion.status,
    content: apiQuestion.content,
    source: apiQuestion.source,
    anonymizedContent: apiQuestion.anonymizedContent,
    consumerTag: apiQuestion.consumerTag,
    answers: apiQuestion.answers,
    createdDate: apiQuestion.createdDate,
    title: apiQuestion.title,
    anonymizedTitle: apiQuestion.anonymizedTitle,
    qualifiedDate: apiQuestion.qualifiedDate,
    isAnswered: apiQuestion.isAnswered,
    isAnsweredByLawyer: apiQuestion.isAnsweredByLawyer,
    answerHelper: apiQuestion.answerHelper,
    hasLinkedConsultation,
    linkedConsultation: hasLinkedConsultation
      ? apiQuestion.linkedConsultations[0]
      : null,
    shouldCtaForAnswerVote,
    shouldCtaForConsultation,
    answerDeadLine: apiQuestion.answerDeadLine
  };
}
