import { SVGProps } from 'react';

export default function GiftIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5625 8.74902H4.4375C4.0578 8.74902 3.75 9.05683 3.75 9.43652V12.1865C3.75 12.5662 4.0578 12.874 4.4375 12.874H19.5625C19.9422 12.874 20.25 12.5662 20.25 12.1865V9.43652C20.25 9.05683 19.9422 8.74902 19.5625 8.74902Z"
        stroke="current"
        strokeWidth="1.03125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.875 12.874V19.0615C18.875 19.2439 18.8026 19.4187 18.6736 19.5477C18.5447 19.6766 18.3698 19.749 18.1875 19.749H5.8125C5.63016 19.749 5.4553 19.6766 5.32636 19.5477C5.19743 19.4187 5.125 19.2439 5.125 19.0615V12.874"
        stroke="current"
        strokeWidth="1.03125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.74902V19.749"
        stroke="current"
        strokeWidth="1.03125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.893 7.77799C14.9133 8.74909 12 8.74909 12 8.74909C12 8.74909 12 5.8358 12.9711 4.85612C13.3586 4.46865 13.8841 4.25098 14.432 4.25098C14.98 4.25098 15.5055 4.46865 15.893 4.85612C16.2804 5.24358 16.4981 5.7691 16.4981 6.31705C16.4981 6.86501 16.2804 7.39053 15.893 7.77799V7.77799Z"
        stroke="current"
        strokeWidth="1.03125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.10709 7.77799C9.08678 8.74909 12.0001 8.74909 12.0001 8.74909C12.0001 8.74909 12.0001 5.8358 11.029 4.85612C10.6415 4.46865 10.116 4.25098 9.56803 4.25098C9.02007 4.25098 8.49456 4.46865 8.10709 4.85612C7.71963 5.24358 7.50195 5.7691 7.50195 6.31705C7.50195 6.86501 7.71963 7.39053 8.10709 7.77799V7.77799Z"
        stroke="current"
        strokeWidth="1.03125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
