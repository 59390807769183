import { Section, Title } from 'components';
import CreateQuestion from 'containers/question/CreateQuestion';
import QuestionWarningBanner from 'containers/question/QuestionWarningBanner';
import { useTranslation } from 'react-i18next';

import { useCustomerUsageMeterQuery } from 'api/customer/customers';

import { useCustomer, useCustomerAccount } from 'hooks';

import {
  AccountContentLayout,
  PageLayout
} from 'features/customer/account/content';
import { Button } from 'features/shared/buttons';

const AskQuestionPage = () => {
  const { t } = useTranslation('customer');
  const { id: customerId } = useCustomer();

  const { subscription } = useCustomerAccount();

  const customerUsageMeter = useCustomerUsageMeterQuery({ customerId });

  if (!customerUsageMeter.isSuccess) {
    return (
      <AccountContentLayout title={t('account.ask.title')}>
        <div />
      </AccountContentLayout>
    );
  }

  const { canAskNewQuestion, hasActiveSubscription } = customerUsageMeter.data;

  return (
    <PageLayout>
      <QuestionWarningBanner />
      <Section>
        <Title component="h1" variant="h2">
          {t('account.ask.title')}
        </Title>
        {hasActiveSubscription && canAskNewQuestion && (
          <CreateQuestion subscriptionId={subscription.id} />
        )}
        {hasActiveSubscription && !canAskNewQuestion && (
          <p>{t('account.ask.error.cannotAskAnotherQuestion')}</p>
        )}
        {!hasActiveSubscription && (
          <div className="mt-4">
            <p>{t('account.ask.error.subscribe')}</p>
            <a
              href={`/funnel/subscribe/contact?skuCode=${
                subscription
                  ? subscription.product.skuCode
                  : process.env.REACT_APP_PRODUCT_STANDARD_SKU_CODE
              }`}
            >
              <Button
                variant="primary"
                size="medium"
                fullWidth
                label={t('general.subscribe')}
              />
            </a>
          </div>
        )}
      </Section>
    </PageLayout>
  );
};

export default AskQuestionPage;
