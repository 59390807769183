import { privateAxios, publicAxios } from 'context/auth/auth.utils';

export async function createFunnelLoginOtp(
  email: string,
  userType: string | undefined,
  skuCode: string | undefined,
  partnerSlug: string | undefined,
  context: string,
  phone: string,
  questionContent: string | undefined
): Promise<{ methodId: string; consistencyId: string }> {
  const res = await publicAxios.post(`/accounts/validate`, {
    redirectBaseUrl: process.env.REACT_APP_BASE_URL,
    validationMethod: 'otp',
    userType,
    email,
    skuCode,
    partnerSlug,
    funnelContext: context,
    consistencyMetadata: {
      phone,
      questionContent
    }
  });

  const { methodId, consistencyId } = res.data;

  return { methodId, consistencyId };
}

export async function createLoginOtp(
  email: string
): Promise<{ methodId: string; consistencyId: string }> {
  const res = await publicAxios.post(`/accounts/validate`, {
    redirectBaseUrl: process.env.REACT_APP_BASE_URL,
    validationMethod: 'otp',
    email,
    funnelContext: 'login'
  });

  const { methodId, consistencyId } = res.data;

  return { methodId, consistencyId };
}

export async function createFunnelProLoginOtp(
  email: string,
  userType: string | undefined,
  companyName: string,
  skuCode: string | undefined,
  partnerSlug: string | undefined,
  context: string,
  phone: string,
  questionContent: string | undefined,
  jobTitle: string | undefined
): Promise<{ methodId: string; consistencyId: string }> {
  const res = await publicAxios.post(`/accounts/validate`, {
    userType,
    redirectBaseUrl: process.env.REACT_APP_BASE_URL,
    validationMethod: 'otp',
    companyName,
    jobTitle: jobTitle || '',
    email,
    skuCode,
    partnerSlug,
    funnelContext: context,
    consistencyMetadata: {
      phone,
      questionContent
    }
  });

  const { methodId, consistencyId } = res.data;

  return { methodId, consistencyId };
}

export async function createMagicLink(
  email: string,
  partnerKey: string | null,
  fromOnboarding = false
): Promise<{ token: string }> {
  const res = await publicAxios.post(`/accounts/validate`, {
    validationMethod: 'magic_link',
    email,
    fromOnboarding,
    funnelContext: 'login',
    partnerMetadata: {
      key: partnerKey
    }
  });
  const { token } = res.data;

  return { token };
}

export async function createPartnerMagicLink(
  email: string,
  partnerKey: string | null
): Promise<{ token: string }> {
  const res = await publicAxios.post(`/accounts/validate`, {
    validationMethod: 'magic_link',
    email,
    funnelContext: 'login',
    partnerMetadata: {
      key: partnerKey,
      partnerReferrer: document.referrer
    }
  });
  const { token } = res.data;

  return { token };
}

export async function requestPasswordReset(email: string): Promise<any> {
  await publicAxios.post('/auth/password/reset', {
    email
  });
}

export async function updatePassword(
  email: string,
  token: string,
  newPassword: string
) {
  await privateAxios.post('/auth/password/process', {
    email,
    token,
    password: newPassword
  });
}
