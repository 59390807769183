import { useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { ANALYTICS } from 'constants/analytics';
import { ModalSubscriptionUpsell } from 'containers/subscription';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  useCreateConsultationMutation,
  useRescheduleConsultationMutation,
  useScheduleConsultationMutation
} from 'api/customer/consultations';

import {
  useCustomerAccount,
  useGuardedSearchParams,
  useLocale,
  useUnguardedSearchParams
} from 'hooks';

import { BookingStep } from 'pages/customer/consultation/ConsultationBookingPage';

import { useTracking } from 'context';

import { StripeForm } from 'features/customer/payment';
import { PaymentType } from 'features/customer/payment/StripeForm';
import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';
import { CameraIcon, FileUploadedIcon } from 'features/shared/icons';
import { Toast } from 'features/shared/toasts';

import { BYTE_IN_MEGABYTES } from 'utils/consultation';
import { formatConsultationTimeSlotDateToIntl } from 'utils/date';

import { ConsultationFile } from 'types/consultation';
import { SetState } from 'types/core';
import {
  BookingConsultationOption,
  BookingOption,
  ConsultationSlot
} from 'types/lawyer';

export default function ConsultationBookingPayment({
  document,
  slot,
  bookingOption,
  stripePromise,
  setBookingStep
}: {
  document?: ConsultationFile;
  slot: ConsultationSlot;
  bookingOption: BookingOption;
  stripePromise: Promise<Stripe | null>;
  setBookingStep: SetState<BookingStep>;
}) {
  const { t } = useTranslation('customer');
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointmentId');
  const consultationId = useUnguardedSearchParams({
    param: 'consultationId'
  });
  const { analytics } = useTracking();
  const { locale } = useLocale();
  const [isModalUpsellSubscriptionOpen, setIsModalUpsellSubscriptionOpen] =
    useState<boolean>(false);
  const [intentClientSecret, setIntentClientSecret] = useState<string>('');
  const [displayStripeForm, setDisplayStripeForm] = useState<boolean>(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  const intentConsultationSource = useUnguardedSearchParams({
    param: 'intentConsultationSource'
  });
  const questionId = useGuardedSearchParams({ param: 'questionId' });

  const { mutateAsync, isLoading: isCreateConsultationLoading } =
    useCreateConsultationMutation();
  const {
    mutateAsync: mutationScheduleAsync,
    isLoading: isScheduleConsultationLoading
  } = useScheduleConsultationMutation();
  const {
    mutateAsync: mutationRescheduleAsync,
    isLoading: isRescheduleConsultationLoading
  } = useRescheduleConsultationMutation();

  const { subscription: currentSubscription, customerUsageMeter } =
    useCustomerAccount();

  const { canHasPaymentInfo, canRequestConsultationForFree } =
    customerUsageMeter;

  const formattedConsultationSlotToIntl = useMemo(
    () => formatConsultationTimeSlotDateToIntl(slot.startAt, locale),
    [locale, slot.startAt]
  );

  const handleStripeConfirmationWithoutCardForm = async () => {
    if (!stripe || !intentClientSecret.length) {
      throw Error('An error occurred.');
    }
    stripe.confirmCardPayment(intentClientSecret).then((result) => {
      const isPaymentSuccess = result.paymentIntent?.status === 'succeeded';
      if (isPaymentSuccess) {
        navigate('/account/consultations');
        toast.custom(
          <Toast
            type="success"
            title={t('general.success')}
            message={t('account.consultations.booking.success')}
          />,
          { position: 'bottom-left', duration: 3000 }
        );
      }
    });
  };

  const handleStripeConfirmationWithCardForm = async () => {
    toast.custom(
      <Toast
        type="success"
        title={t('general.success')}
        message={t('account.consultations.booking.success')}
      />,
      { position: 'bottom-left', duration: 3000 }
    );
    navigate('/account/consultations');
  };

  const handleTrackConsultationSuccess = () =>
    analytics?.track(ANALYTICS.CONSULTATION_SUCCESS, {
      source: intentConsultationSource
    });

  async function handleConfirmConsultationBooking() {
    const consultation = await mutateAsync(
      {
        questionId,
        availabilitySlotId: slot.id,
        appointmentType:
          bookingOption === BookingOption.BookingVideo
            ? BookingConsultationOption.BookingVideo
            : BookingConsultationOption.BookingPhone,
        comment: undefined,
        consultationFile: document
      },
      {
        onSuccess: handleTrackConsultationSuccess
      }
    );
    // free
    if (canRequestConsultationForFree) {
      toast.custom(
        <Toast
          type="success"
          title={t('general.success')}
          message={t('account.consultations.booking.success')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
      navigate('/account/consultations');
    }
    // payment silent or not
    setIntentClientSecret(
      consultation.order.paymentInformations.paymentData.client_secret
    );
    if (canHasPaymentInfo) {
      handleStripeConfirmationWithoutCardForm();
    } else {
      setDisplayStripeForm(true);
    }
  }
  async function handleRescheduleConsultationBooking() {
    await mutationRescheduleAsync(
      {
        availabilitySlotId: slot.id,
        appointmentId: appointmentId || '',
        appointmentType:
          bookingOption === BookingOption.BookingVideo
            ? BookingConsultationOption.BookingVideo
            : BookingConsultationOption.BookingPhone
      },
      {
        onSuccess: () => {
          handleTrackConsultationSuccess();
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.consultations.booking.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
          navigate('/account/consultations');
        }
      }
    );
  }

  async function handleScheduleConsultationBooking() {
    await mutationScheduleAsync(
      {
        availabilitySlotId: slot.id,
        consultationId: Number(consultationId),
        appointmentType:
          bookingOption === BookingOption.BookingVideo
            ? BookingConsultationOption.BookingVideo
            : BookingConsultationOption.BookingPhone
      },
      {
        onSuccess: () => {
          handleTrackConsultationSuccess();
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.consultations.booking.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
          navigate('/account/consultations');
        }
      }
    );
  }

  const onStepBack = () => {
    if (slot?.option === BookingOption?.BookingVideo)
      setBookingStep(BookingStep.SchedulingConfirm);
    else setBookingStep(BookingStep.Scheduling);
  };

  if (canRequestConsultationForFree) {
    return (
      <div className="mt-6 max-w-[720px]">
        <div className="p-8 pb-14 w-full h-auto bg-white rounded shadow-xl mb-6">
          <div className="flex flex-col w-full">
            <h2 className="text-black font-sans font-bold text-xl leading-[24px] mb-6">
              {t('account.consultations.booking.payment.title')}
            </h2>
            <div className="border border-[#CCCCCC] w-full p-6 flex flex-col rounded">
              <div className="flex flex-row justify-between items-center mb-4">
                <p className="font-sans font-bold text-lg">
                  {formattedConsultationSlotToIntl}
                </p>
                {bookingOption === BookingOption.BookingVideo && (
                  <div className="w-5 h-5 mr-2 fill-purple-700">
                    <CameraIcon />
                  </div>
                )}
              </div>

              <span className="flex font-sans text-base text-black">
                {slot?.option === BookingOption?.BookingVideo
                  ? t('account.consultations.videoOffer')
                  : t('account.consultations.generalOffer')}
              </span>
              {document ? (
                <div className="mt-4">
                  <div className="flex justify-between items-center">
                    <div className="flex justify-start items-start gap-x-3">
                      <div className="text-purple-700">
                        <FileUploadedIcon className="h-4 w-4 fill-purple-700" />
                      </div>
                      <div>
                        <span className="block leading-none">
                          {document.name}
                        </span>
                        <span className="text-gray-700 text-sm">
                          {(document.size * BYTE_IN_MEGABYTES).toFixed(2)} Mo
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="mb-5">
          <p className="text-gray-700 text-sm text-center">
            {t('account.consultations.booking.scheduling.agreement')}
          </p>
        </div>
        <div className="flex w-full gap-x-[14px]">
          <Button
            variant="secondary"
            size="large"
            label={t('account.consultations.booking.payment.back')}
            onClick={onStepBack}
          />
          <Button
            onClick={() => {
              if (appointmentId) return handleRescheduleConsultationBooking();
              if (consultationId) return handleScheduleConsultationBooking();
              return handleConfirmConsultationBooking();
            }}
            isLoading={
              isCreateConsultationLoading ||
              isScheduleConsultationLoading ||
              isRescheduleConsultationLoading
            }
            fullWidth
            label={t('account.consultations.confirm')}
            variant="primary"
            size="large"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mt-6 max-w-[720px]">
        <div className="p-8 pb-14 w-full h-auto bg-white rounded shadow-xl mb-6">
          <div className="flex flex-col w-full">
            <h2 className="text-black font-sans font-bold text-xl leading-[24px] mb-6">
              {t('account.consultations.booking.payment.title')}
            </h2>
            <div className="border border-[#CCCCCC] w-full p-6 flex flex-col rounded">
              <div className="flex flex-row justify-between items-center mb-4">
                <p className="font-sans font-bold text-lg">
                  {formattedConsultationSlotToIntl}
                </p>
                <div className="w-5 h-5 mr-2 fill-purple-700">
                  <CameraIcon />
                </div>
              </div>

              <span className="flex font-sans text-base text-black">
                {slot?.option === BookingOption?.BookingVideo
                  ? t('account.consultations.videoOffer')
                  : t('account.consultations.generalOffer')}
              </span>
              <Trans
                parent="div"
                className="mt-4 whitespace-pre-wrap"
                ns="customer"
                i18nKey="account.consultations.price"
                components={{ 1: <strong /> }}
                values={{ price: 39 }}
              />
              {document ? (
                <div className="mt-4">
                  <div className="flex justify-between items-center">
                    <div className="flex justify-start items-start gap-x-3">
                      <div className="text-purple-700">
                        <FileUploadedIcon className="h-4 w-4 fill-purple-700" />
                      </div>
                      <div>
                        <span className="block leading-none">
                          {document.name}
                        </span>
                        <span className="text-gray-700 text-sm">
                          {(document.size * BYTE_IN_MEGABYTES).toFixed(2)} Mo
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* Upsell CTA */}
        {!currentSubscription.isOfferedByPartner ? (
          <div className="mb-6">
            <Alert
              type="info"
              title={t('account.subscription.upsell.cta.consultation')}
            >
              <div>
                <p>{t('account.subscription.upsell.cta.body')}</p>
                <div className="mt-4 flex justify-end">
                  <Button
                    submit
                    variant="secondary"
                    size="small"
                    onClick={() => setIsModalUpsellSubscriptionOpen(true)}
                    label={t('account.subscription.upsell.cta')}
                  />
                </div>
              </div>
            </Alert>
          </div>
        ) : null}
        {canHasPaymentInfo ? (
          <div className="flex items-end justify-end mb-4">
            <p className="text-xs text-gray-300">
              {t('account.consultations.toPay')}
            </p>
          </div>
        ) : null}
        {displayStripeForm ? (
          <StripeForm
            paymentType={PaymentType.OneTime}
            stripePromise={stripePromise}
            intentClientSecret={intentClientSecret}
            handlePaymentSuccess={() => handleStripeConfirmationWithCardForm()}
          />
        ) : (
          <Button
            onClick={() => {
              if (appointmentId) return handleRescheduleConsultationBooking();
              if (consultationId) return handleScheduleConsultationBooking();
              return handleConfirmConsultationBooking();
            }}
            isLoading={isCreateConsultationLoading}
            fullWidth
            label={t('account.consultations.confirm')}
            variant="primary"
            size="large"
          />
        )}
      </div>
      <ModalSubscriptionUpsell
        subscriptionId={customerUsageMeter.activeSubscriptionId as string}
        productSku={process.env.REACT_APP_PRODUCT_PREMIUM_SKU_CODE as string}
        isModalSubscriptionUpsellOpen={isModalUpsellSubscriptionOpen}
        setIsModalSubscriptionUpsellOpen={setIsModalUpsellSubscriptionOpen}
        isFunnelContext
        customerUsageMeter={customerUsageMeter}
      />
    </>
  );
}
