import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Chip, Title } from 'components';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import {
  getSharedMemberCseQuestion,
  shareQuestion
} from 'api/customer/questions/api';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LoadingSpinner } from 'features/shared/loading';
import { Toast } from 'features/shared/toasts';

import { MemberCse } from 'types/customer';

interface ShareQuestionProps {
  onShare?: Function;
  questionId: string;
}
type ShareQuestionInput = {
  questionId: string;
  customersToShareWith: MemberCse[];
};

const ShareQuestion = ({ questionId, onShare }: ShareQuestionProps) => {
  const { t } = useTranslation('customer');
  const [selectedMembers, setSelectedMembers] = useState<MemberCse[]>([]);
  const { handleSubmit, setValue } = useForm<ShareQuestionInput>({
    defaultValues: { customersToShareWith: [], questionId }
  });

  const {
    data: cseMembers,
    isLoading,
    isSuccess
  } = useQuery(
    ['shared-member-cse-question', questionId],
    () => getSharedMemberCseQuestion(questionId),
    {
      cacheTime: 0,
      onSuccess: (response) => {
        const sharedMembers =
          response?.filter((member) => member.isShared) || [];
        setSelectedMembers(sharedMembers);
        setValue('customersToShareWith', sharedMembers);
      }
    }
  );

  const { mutate, isLoading: isShareQuestionLoading } = useMutation(
    (data: ShareQuestionInput) =>
      shareQuestion(questionId, data.customersToShareWith),
    {
      onError: () => {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message="An error occured."
          />,
          { position: 'top-right', duration: 3000 }
        );
      },
      onSuccess: () => {
        onShare && onShare();
        toast.custom(
          <Toast
            type="success"
            title={t('general.success')}
            message="La question a bien été partagé aux membres."
          />,
          { position: 'top-right', duration: 3000 }
        );
      }
    }
  );

  const onSubmitShareQuestion = (formData: ShareQuestionInput) =>
    mutate(formData);

  return (
    <div>
      <Title gutterBottom variant="h3">
        {t('account.question.share.title')}
      </Title>
      <Paragraph gutterBottom className="!text-gray-700 text-sm">
        {t('account.question.share.description')}
      </Paragraph>
      <form
        onSubmit={handleSubmit(onSubmitShareQuestion)}
        className="flex flex-col gap-6 h-96"
      >
        {isLoading && <LoadingSpinner />}
        {isSuccess && (
          <div>
            <Listbox
              value={selectedMembers}
              onChange={(members) => {
                setSelectedMembers(members);
                setValue('customersToShareWith', members);
              }}
              multiple
            >
              <div className="relative mt-1">
                <Listbox.Button
                  placeholder="Nom prénom"
                  className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left g-white/75 input-text h-12 border border-gray-500 focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900"
                >
                  <span className="block truncate">
                    {selectedMembers
                      .slice(0, 3)
                      .map((member: any) => member.fullName)
                      .join(', ')}
                    {selectedMembers.length > 3 && (
                      <Chip color="purple" className="ml-2 text-sm">{`+${
                        selectedMembers.length - 3
                      }`}</Chip>
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
                    {cseMembers.map((member) => (
                      <Listbox.Option
                        key={member.customerId}
                        value={member}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? 'bg-purple-100 text-purple-900'
                              : 'text-gray-900'
                          }`
                        }
                      >
                        {({ selected }) => (
                          <>
                            <div
                              className={`block truncate ml-2 ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              <div>{member.fullName}</div>
                              <div className="text-gray-600 text-sm">
                                {member.email}
                              </div>
                            </div>
                            {selected && (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-600">
                                <CheckIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <Button
              size="medium"
              onClick={() => {
                setSelectedMembers(cseMembers);
                setValue('customersToShareWith', cseMembers);
              }}
              className="!pl-0 text-purple-900 underline leading-3 underline-offset-2"
              variant="transparent"
              label={t('account.question.share.selectAll')}
            />
          </div>
        )}
        <Button
          isLoading={isShareQuestionLoading}
          submit
          fullWidth
          size="medium"
          variant="tertiary"
          className="self-end"
          label={t('account.question.share.submit')}
        />
      </form>
    </div>
  );
};

export default ShareQuestion;
