import { Section, Title } from 'components';
import { Trans, useTranslation } from 'react-i18next';

import { useCustomerAccount } from 'hooks';

import { PageLayout } from 'features/customer/account/content';
import { QuestionCard } from 'features/customer/account/questions';
import { Paragraph } from 'features/shared/body';

export default function ConsultationChoicePage() {
  const { t } = useTranslation('customer');
  const { questions } = useCustomerAccount();

  const questionsEligibleForConsultation = questions.questions
    .filter((q) => q.status === 30 || q.status === 40)
    .filter((q) => !q.hasLinkedConsultation);

  const consultationBookingAvailable =
    !!questionsEligibleForConsultation.length;

  return (
    <PageLayout>
      <Section>
        <Title
          gutterBottom
          variant="h1"
          text={t('account.question.consultation.cta')}
        />
        <Paragraph weight="normal">
          {t('account.consultations.choice.cta')}
        </Paragraph>
        <Paragraph weight="normal">
          {consultationBookingAvailable
            ? t('account.consultations.choice.available')
            : t('account.consultations.choice.unavailable')}
        </Paragraph>
        {consultationBookingAvailable && (
          <div className="grid grid-cols-1 gap-4 mt-8">
            {questionsEligibleForConsultation.map((question) => (
              <QuestionCard
                withConsultationCta
                key={question.id}
                question={question}
              />
            ))}
          </div>
        )}
      </Section>
    </PageLayout>
  );
}
