import { ConsultationStatus } from 'types/consultation';

export const isDateDiffLessThanOneMonth = (dateEnd: Date, dateStart: Date) => {
  if (
    Math.round(
      Math.abs(dateEnd.getTime() - dateStart.getTime()) / (2e3 * 3600 * 365.25)
    ) === 0
  )
    return true;
  return false;
};

export function isQuestionStatusOngoing(status: number) {
  if ([5, 10, 20, 30].includes(status)) return true;
  return false;
}

export function isConsultationStatusOngoing(status: ConsultationStatus) {
  if (
    [
      ConsultationStatus.VALIDATED,
      ConsultationStatus.QUALIFIED,
      ConsultationStatus.SCHEDULED,
      ConsultationStatus.SCHEDULED_APPOINTMENT,
      ConsultationStatus.HAS_INCIDENT
    ].includes(status)
  )
    return true;
  return false;
}
