import { Combobox, Transition } from '@headlessui/react';
import { isString } from 'lodash';
import { Fragment, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { FieldError } from 'react-hook-form';

import InputErrorMessage from './InputErrorMessage';

interface PlacesInputProps {
  label: string;
  onChange: any;
  value: string | undefined;
  onSelectPlace: any;
  placeType?: '(regions)' | 'address';
  error: FieldError | undefined;
}

const getComponent = (placeDetails: any, componentName: string) =>
  placeDetails.address_components.find((component: any) =>
    component.types.includes(componentName)
  );

const PlacesInput = ({
  label,
  onChange,
  onSelectPlace,
  value,
  placeType = '(regions)',
  error
}: PlacesInputProps) => {
  const [selected, setSelected] = useState(value);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      options: {
        types: [placeType],
        componentRestrictions: { country: 'fr' }
      },
      apiKey: 'AIzaSyC04dJYFyJ_jZNOs5mrLg5g_k1gRMH068U'
    });

  const onSelect = (placeId: string) => {
    placesService?.getDetails({ placeId }, (placeDetails: any) => {
      const country = getComponent(placeDetails, 'country').short_name;
      const zipCode =
        getComponent(placeDetails, 'postal_code')?.short_name || '';
      const city = getComponent(placeDetails, 'locality').short_name;
      const number =
        getComponent(placeDetails, 'street_number')?.short_name || '';
      const route = getComponent(placeDetails, 'route')?.short_name || '';

      onChange(placeType === '(regions)' ? city : `${number} ${route}`);
      onSelectPlace({ country, zipCode, city });
      setSelected(placeDetails);
    });
  };

  return (
    <div id="input-group" className="w-full">
      <label htmlFor="places" className="input-label">
        {label}
      </label>
      <Combobox value={selected} onChange={onSelect}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default rounded-lg bg-white text-left">
            <Combobox.Input
              autoComplete="off"
              className="w-full input-text h-12 leading-5 text-gray-900"
              displayValue={(place: any) => {
                if (!isString(place) && placeType === '(regions)') {
                  return getComponent(place, 'locality').long_name;
                }

                if (!isString(place) && placeType === 'address') {
                  const number =
                    getComponent(place, 'street_number')?.short_name || '';
                  const route = getComponent(place, 'route').short_name || '';

                  return `${number} ${route}`;
                }

                return place;
              }}
              onChange={(evt) => {
                onChange(evt.target.value);
                getPlacePredictions({ input: evt.target.value });
              }}
            />
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {placePredictions.map((placePrediction) => (
                <Combobox.Option
                  key={placePrediction.place_id}
                  className={() =>
                    `relative cursor-default select-none py-2 pl-4 pr-4'text-gray-900`
                  }
                  value={placePrediction.place_id}
                >
                  {() => (
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {placePrediction.description}
                    </span>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {error && <InputErrorMessage error={error} />}
    </div>
  );
};

export default PlacesInput;
