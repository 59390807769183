import {
  ModalSubscriptionCancel,
  ModalSubscriptionUpsell
} from 'containers/subscription';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomer } from 'hooks';

import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';

import { formatDateToIntl } from 'utils/date';

import { Subscription } from 'types/subscription';

export default function SubscriptionStatus({
  subscription
}: {
  subscription: Subscription;
}) {
  const { t } = useTranslation('customer');
  const { isB2b } = useCustomer();
  const { id: subscriptionId, active, revokable } = subscription;
  const [isModalCancelSubscriptionOpen, setIsModalCancelSubscriptionOpen] =
    useState<boolean>(false);
  const [isModalUpsellSubscriptionOpen, setIsModalUpsellSubscriptionOpen] =
    useState<boolean>(false);

  const isProductStandard =
    process.env.REACT_APP_PRODUCT_STANDARD_SKU_CODE ===
    subscription.product.skuCode;

  const isProductUpsellable =
    isProductStandard && !subscription.isOfferedByPartner;

  return (
    <>
      <Alert
        type={active ? 'success' : 'warning'}
        title={t(
          active
            ? 'account.subscription.active'
            : 'account.subscription.inactive'
        )}
      >
        <div>
          <p>
            {active &&
              t('account.subscription.active.paragraph', {
                productName: subscription.product.name,
                endDate: formatDateToIntl(new Date(subscription.endDate))
              })}
            {!active &&
              isB2b &&
              t('account.subscription.inactive.b2b.paragraph', {
                endDate: formatDateToIntl(new Date(subscription.endDate))
              })}
            {!active && !isB2b && t('account.subscription.inactive.paragraph')}
          </p>
          <div className="flex justify-end items-center gap-4 pt-2">
            {active ? (
              <div>
                {revokable && (
                  <Button
                    size="small"
                    variant="transparent"
                    onClick={() => setIsModalCancelSubscriptionOpen(true)}
                    className="cursor-pointer text-gray-700 hover:underline"
                  >
                    {t('account.subscription.cancel.cta')}
                  </Button>
                )}
                {isProductUpsellable && (
                  <Button
                    submit
                    variant="primary"
                    size="small"
                    onClick={() => setIsModalUpsellSubscriptionOpen(true)}
                    label={t('account.subscription.upsell.cta')}
                  />
                )}
              </div>
            ) : (
              <a
                href={`/funnel/subscribe/contact?skuCode=${
                  subscription
                    ? subscription.product.skuCode
                    : process.env.REACT_APP_PRODUCT_STANDARD_SKU_CODE
                }`}
              >
                <Button
                  variant="primary"
                  size="medium"
                  label={t('general.subscribe')}
                />
              </a>
            )}
          </div>
        </div>
      </Alert>

      <ModalSubscriptionCancel
        subscriptionId={subscriptionId}
        isModalSubscriptionCancelOpen={isModalCancelSubscriptionOpen}
        setIsModalSubscriptionCancelOpen={setIsModalCancelSubscriptionOpen}
      />

      <ModalSubscriptionUpsell
        subscriptionId={subscriptionId}
        productSku={process.env.REACT_APP_PRODUCT_PREMIUM_SKU_CODE as string}
        isModalSubscriptionUpsellOpen={isModalUpsellSubscriptionOpen}
        setIsModalSubscriptionUpsellOpen={setIsModalUpsellSubscriptionOpen}
        isFunnelContext={false}
      />
    </>
  );
}
