import { GoogleRating } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useCreateUnauthenticatedAnswerVoteMutation } from 'api/customer/mutations.index';
import { useAnswerVoteLinkQuery } from 'api/customer/queries.index';

import { Button } from 'features/shared/buttons';
import {
  LayoutCard,
  LayoutDefault,
  LayoutLoadingSkeleton
} from 'features/shared/layout';
import { LoadingSpinner } from 'features/shared/loading';

export default function QuestionAnswerVotePage() {
  const params = useParams();
  const voteLinkId = params.voteLinkId || '';
  const [searchParams] = useSearchParams();
  const noteFromParams = searchParams.get('note') || '';
  const noteInt = parseInt(noteFromParams, 10);
  const isSatisfied = noteInt > 3;
  const answerVoteLink = useAnswerVoteLinkQuery({
    voteLinkId
  });

  if (!answerVoteLink.isSuccess) {
    return <LayoutLoadingSkeleton />;
  }

  return (
    <LayoutDefault>
      {isSatisfied ? (
        <QuestionAnswerVoteSatisfiedCard
          voteLinkId={voteLinkId}
          noteInt={noteInt}
          answerId={answerVoteLink.data.answerId}
        />
      ) : (
        <QuestionAnswerVoteUnsatisfiedCard
          voteLinkId={voteLinkId}
          noteInt={noteInt}
          answerId={answerVoteLink.data.answerId}
        />
      )}
    </LayoutDefault>
  );
}

interface QuestionAnswerVoteCardProps {
  voteLinkId: string;
  answerId: number;
  noteInt: number;
}

function QuestionAnswerVoteSatisfiedCard({
  voteLinkId,
  answerId,
  noteInt
}: QuestionAnswerVoteCardProps) {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const { mutate, isLoading: isCreateAnswerVoteMutationLoading } =
    useCreateUnauthenticatedAnswerVoteMutation();

  useEffect(() => {
    mutate({
      answerId,
      newAnswerVote: {
        voteLinkId,
        note: noteInt
      }
    });
  }, [answerId, mutate, navigate, noteInt, voteLinkId]);

  return (
    <LayoutCard
      title={`${t('general.thank')} 🥳`}
      body={
        isCreateAnswerVoteMutationLoading ? (
          <LoadingSpinner purple />
        ) : (
          <div className="flex flex-col gap-4">
            <GoogleRating isB2b={false} />
          </div>
        )
      }
    />
  );
}

function QuestionAnswerVoteUnsatisfiedCard({
  voteLinkId,
  answerId,
  noteInt
}: QuestionAnswerVoteCardProps) {
  const { t } = useTranslation('customer');
  const [reasonText, setReasonText] = useState<string>('');
  const { mutate, isLoading: isCreateAnswerVoteMutationLoading } =
    useCreateUnauthenticatedAnswerVoteMutation();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const handleUnsatisfiedMutate = () =>
    mutate(
      {
        answerId,
        newAnswerVote: {
          voteLinkId,
          note: noteInt,
          reason: reasonText
        }
      },
      {
        onSuccess: () => setIsFormSubmitted(true)
      }
    );

  if (isFormSubmitted) {
    return (
      <LayoutCard
        title={t('account.question.vote.unsatisfied.thank')}
        body={
          <p className="text-md text-center font-medium ">
            {t('account.question.vote.unsatisifed.success')}
          </p>
        }
      />
    );
  }
  return (
    <LayoutCard
      title={t('account.question.vote.unsatisfied.title')}
      body={
        isCreateAnswerVoteMutationLoading ? (
          <div className="grid place-items-center">
            <LoadingSpinner purple />
          </div>
        ) : (
          <>
            <p className="text-md text-left font-medium ">
              {t('account.question.vote.unsatisfied.description')}
            </p>
            <div className="mt-4 w-full">
              <textarea
                rows={3}
                name="question"
                id="question"
                minLength={20}
                maxLength={1200}
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
                className="w-full placeholder:text-gray-700 text-sm md:text-base block focus:border-purple-900 focus:drop-shadow-input focus:ring-0 focus:ring-offset-0 focus:shadow-purple-900 focus:outline-none py-3 px-4 rounded"
              />
            </div>
            <div className="mt-2 flex justify-end">
              <Button
                submit
                variant="primary"
                size="small"
                label={t('general.send')}
                onClick={() => handleUnsatisfiedMutate()}
              />
            </div>
          </>
        )
      }
    />
  );
}
