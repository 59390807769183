import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { profileSchema } from 'schemas';

import { useUpdateAccountOptimisticMutation } from 'api/customer/customers';
import { useUpdateCustomerOptimisticMutation } from 'api/customer/mutations.index';

import { useCustomer } from 'hooks';

import { useAuth } from 'context';

import { Alert } from 'features/shared/alerts';
import { Button, TrackedButton } from 'features/shared/buttons';
import { DisabledTextInputGroup, TextInputGroup } from 'features/shared/forms';
import { Toast } from 'features/shared/toasts';

import { ProfileFormInputs } from 'types/inputs';

interface CustomerEditProfileProps {
  onFinish: () => void;
}

function CustomerEditProfile({ onFinish }: CustomerEditProfileProps) {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const {
    id,
    email,
    firstName,
    lastName,
    phoneCellphone,
    secondaryEmail,
    isB2b
  } = useCustomer();
  const [withAccountInput, setWithAccountInput] = useState(
    Boolean(secondaryEmail)
  );

  const auth = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset
  } = useForm<ProfileFormInputs>({
    resolver: yupResolver(profileSchema(tForm))
  });
  const { mutate, isLoading } = useUpdateCustomerOptimisticMutation({
    prevCustomer: {
      id,
      email,
      firstName,
      lastName,
      phoneCellphone
    }
  });
  const { mutate: accountMutation } = useUpdateAccountOptimisticMutation(
    auth.userId as number
  );

  const onSubmit = async (data: ProfileFormInputs) => {
    const profileHasChanged =
      firstName !== data.firstName ||
      lastName !== data.lastName ||
      phoneCellphone !== data.phoneCellphone;

    if (isB2b) {
      accountMutation(
        { secondaryEmail: data.secondaryEmail || null },
        {
          onSuccess: onFinish,
          onError: (error: any) => {
            const { data: errorData } = error.response;
            if (
              errorData.violations[0].code ===
              'email_already_used_by_active_account'
            ) {
              setError('secondaryEmail' as any, {
                message: 'Adresse email déjà utilisée',
                type: 'focus'
              });
            }

            setError('secondaryEmail' as any, {
              message: errorData.violations[0].message,
              type: 'focus'
            });
          }
        }
      );
    }

    if (profileHasChanged) {
      await mutate(
        {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneCellphone: data.phoneCellphone
        },
        {
          onSuccess: () => {
            toast.custom(
              <Toast
                type="success"
                title={t('general.success')}
                message={t('account.profile.update.success')}
              />,
              { position: 'bottom-left', duration: 3000 }
            );

            if (!isB2b) onFinish();
          },
          onError: () => {
            toast.custom(
              <Toast
                type="error"
                title={t('general.error')}
                message={t('error.occurred')}
              />,
              { position: 'bottom-left', duration: 3000 }
            );
          }
        }
      );
    }
  };

  const handleResetForm = () => {
    reset();
    onFinish();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <div className="grid grid-cols-6 gap-3 sm:gap-6">
        <div className="col-span-6 sm:col-span-3">
          <TextInputGroup
            name="firstName"
            label={tForm('label.firstName')}
            register={register}
            placeholder={tForm('placeholder.firstName')}
            defaultValue={firstName}
            error={errors.firstName}
            disabled={isLoading}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInputGroup
            name="lastName"
            label={tForm('label.lastName')}
            register={register}
            placeholder={tForm('placeholder.lastName')}
            defaultValue={lastName}
            error={errors.lastName}
            disabled={isLoading}
          />
        </div>
      </div>
      <TextInputGroup
        name="phoneCellphone"
        label={tForm('label.phone')}
        register={register}
        placeholder="+33601010101"
        defaultValue={phoneCellphone}
        error={errors.phoneCellphone}
        disabled={isLoading}
      />
      <DisabledTextInputGroup
        disabled
        name="email"
        label={tForm('label.email')}
        defaultValue={email}
      />
      {isB2b && withAccountInput ? (
        <div>
          <TextInputGroup
            name="secondaryEmail"
            label={tForm('label.email.perso')}
            register={register}
            defaultValue={secondaryEmail}
            error={errors.secondaryEmail}
            disabled={isLoading}
          />
          <Alert className="mt-2">{tForm('description.email.perso')}</Alert>
        </div>
      ) : (
        <TrackedButton
          className="pl-0 pt-0 text-purple-900"
          name="add_personal_email"
          size="small"
          variant="transparent"
          label="Ajouter une adresse personnelle"
          onClick={() => setWithAccountInput(true)}
        />
      )}
      <div className="flex justify-end">
        <Button
          onClick={handleResetForm}
          variant="transparent"
          size="small"
          label={t('general.cancel')}
          disabled={isLoading}
        />
        <div className="ml-3">
          <Button
            submit
            variant="secondary"
            size="small"
            label={t('general.update')}
            isLoading={isLoading}
          />
        </div>
      </div>
    </form>
  );
}

export default CustomerEditProfile;
