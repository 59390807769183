import { Stripe } from '@stripe/stripe-js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerCard } from 'features/shared/container';
import { CoinIcon, ShieldIcon } from 'features/shared/icons';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { FunnelStep, StepStatus } from 'types/funnel';
import { LocalProduct } from 'types/product';

import { Steps, TitleCard } from '../funnels';
import { BrandsBanner, StarRatings } from '../funnels/confirmation/credentials';
import { useLocalProductBySkuCode } from '../funnels/funnel.utils';
import { StripeForm } from '../payment';
import { FormType, PaymentType } from '../payment/StripeForm';
import { useSubscribePremiumPlusContext } from './premium-plus.context';

export default function PaymentStep({
  stripePromise
}: {
  stripePromise: Promise<Stripe | null>;
}) {
  const { confirmationData } = useSubscribePremiumPlusContext();
  const { t } = useTranslation('customer');
  const localProduct = useLocalProductBySkuCode(
    process.env.REACT_APP_PREMIUMPLUS_SKU_CODE as string
  );

  const funnelSteps = useMemo(
    (): FunnelStep[] => [
      {
        id: 1,
        label: t('subscribe.premiumPlus.step.1'),
        status: StepStatus.COMPLETE
      },
      {
        id: 2,
        label: t('subscribe.premiumPlus.step.2'),
        status: StepStatus.COMPLETE
      },
      {
        id: 3,
        label: t('subscribe.premiumPlus.step.3'),
        status: StepStatus.COMPLETE
      },
      {
        id: 4,
        label: t('subscribe.premiumPlus.step.4'),
        status: StepStatus.CURRENT
      }
    ],
    [t]
  );

  const funnelTitle = [t('funnel.question.title.payment')];

  // handlePaymentSuccess is useless because when the payment is successful, the user is redirected to the confirmation page
  const handlePaymentSuccess = () => null;

  if (!confirmationData) return <LayoutLoadingSkeleton />;

  return (
    <>
      <Steps funnelSteps={funnelSteps} variant="vertical" />
      <TitleCard title={funnelTitle} />
      <CustomPaymentProductDescription localProduct={localProduct} />
      <ContainerCard>
        <div className="pb-4">
          <StripeForm
            formType={FormType.Element}
            paymentType={PaymentType.Subscription}
            stripePromise={stripePromise}
            intentClientSecret={confirmationData.paymentIntentSecret as string}
            handlePaymentSuccess={handlePaymentSuccess}
          />
        </div>
        <div className="flex flex-col">
          <StarRatings />
          <BrandsBanner />
        </div>
      </ContainerCard>
    </>
  );
}

function CustomPaymentProductDescription({
  localProduct
}: {
  localProduct: LocalProduct;
}) {
  return (
    <ContainerCard>
      <div className="flex items-left items-center flex-col gap-2">
        <div className="flex gap-2">
          <CoinIcon />
          Prélèvement 100% sécurisé
        </div>
        <div className="flex gap-2 text-center">
          <ShieldIcon />
          <span className="text-md">
            Premier prélèvement de {localProduct.price}€
          </span>
        </div>
      </div>
    </ContainerCard>
  );
}
