import { ShareIcon } from '@heroicons/react/outline';
import { Card, Section } from 'components';
import { CardContent } from 'components/card';
import QuestionSectionSkeleton from 'components/skeleton/QuestionSectionSkeleton';
import { Fade } from 'components/transition';
import AnswerVote from 'containers/answer/AnswerVote';
import { QuestionSuggestedArticles } from 'containers/articles';
import ShareQuestion from 'containers/question/ShareQuestion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import { useHandleFormalNoticeProposalMutation } from 'api/customer/answer-votes';
import { useQuestionQuery } from 'api/customer/queries.index';

import { useCustomer } from 'hooks';

import { PageLayout } from 'features/customer/account/content';
import {
  AdditionalRequestAnswerCard,
  AnswerCard,
  QuestionLargeCard
} from 'features/customer/question';
import AdditionalRequestCard from 'features/customer/question/AdditionalRequestCard';
import ConsultationCtaCard from 'features/customer/question/ConsultationCtaCard';
import QuestionTimeline from 'features/customer/question/timeline/QuestionTimeline';
import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { Answer, AnswerStatus } from 'types/answer';

export default function QuestionPage() {
  const { t } = useTranslation('customer');
  const { state = {} } = useLocation();
  const { previousPage = 1 } = state || {};
  const { id } = useCustomer();
  const params = useParams();
  const queryClient = useQueryClient();
  const questionId = params.questionId as string;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { mutate: handleProposalMutate, isLoading: isHandleProposalLoading } =
    useHandleFormalNoticeProposalMutation();

  const { data, isLoading, isSuccess } = useQuestionQuery({ questionId });

  const currentAnswer =
    data?.answers.find((item) => item.status === AnswerStatus.PUBLISHED) ||
    null;

  const handleProposal = async (
    accepted: 'accepted' | 'refused',
    answerId: number,
    formalNoticeId: string
  ) => {
    handleProposalMutate(
      {
        relatedAnswerId: answerId,
        formalNoticeId,
        proposalResponse: accepted
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['question']);
          await queryClient.invalidateQueries(['answers']);
        }
      }
    );
  };

  const isQuestionOwner = data?.customer?.id === id;

  return (
    <PageLayout
      previousHref={`/account/questions?page=${previousPage}&type=${data?.source}`}
    >
      {isLoading || !isSuccess ? (
        <QuestionSectionSkeleton />
      ) : (
        <Fade>
          <Section className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-12">
            <div className="space-y-6 lg:col-span-8">
              {data.isPrivate && (
                <section aria-labelledby="alert">
                  <Alert title={t('alert.privateQuestion.title')}>
                    <p>{t('alert.privateQuestion.paragraph')}</p>
                  </Alert>
                </section>
              )}
              <section aria-labelledby="question">
                <QuestionLargeCard question={data} />
              </section>
              {data.isAnswered && currentAnswer && (
                <section aria-labelledby="answer">
                  <AnswerCard
                    answer={currentAnswer as Answer}
                    onHandleProposal={handleProposal}
                    isHandleProposalLoading={isHandleProposalLoading}
                    lawyer={
                      data.isAnsweredByLawyer && currentAnswer
                        ? currentAnswer?.lawyer
                        : null
                    }
                  />
                </section>
              )}
              {currentAnswer && currentAnswer.additionalRequest && (
                <>
                  <section aria-labelledby="answer">
                    <AdditionalRequestCard
                      additionalRequest={currentAnswer.additionalRequest}
                    />
                  </section>

                  {currentAnswer.additionalRequest.answeredAt && (
                    <section aria-labelledby="answer">
                      <AdditionalRequestAnswerCard
                        additionalRequest={currentAnswer.additionalRequest}
                        lawyer={currentAnswer?.lawyer}
                      />
                    </section>
                  )}
                </>
              )}
              {[30, 32].includes(data.status) &&
                currentAnswer &&
                isQuestionOwner &&
                data.shouldCtaForAnswerVote && (
                  <Card>
                    <CardContent>
                      <AnswerVote
                        questionId={data.id}
                        answerId={currentAnswer.id}
                        lawyer={
                          data.isAnsweredByLawyer && currentAnswer
                            ? currentAnswer?.lawyer
                            : null
                        }
                        canCreateAdditionalRequest={
                          !currentAnswer?.additionalRequest
                        }
                      />
                    </CardContent>
                  </Card>
                )}
              {data.shouldCtaForConsultation &&
                currentAnswer &&
                isQuestionOwner && (
                  <section aria-labelledby="consultation">
                    <ConsultationCtaCard
                      questionId={data.id}
                      lawyerId={currentAnswer.lawyer!.id}
                    />
                  </section>
                )}
              <QuestionSuggestedArticles questionId={questionId} />
            </div>
            <section aria-labelledby="timeline" className="lg:col-span-4">
              <QuestionTimeline question={data} isOwner={isQuestionOwner} />
              {isQuestionOwner && data.source === 'cse' && (
                <Card compact className="mt-6">
                  <Button
                    icon={<ShareIcon className="w-5" />}
                    variant="tertiary"
                    size="medium"
                    onClick={() => setIsModalOpen(true)}
                    fullWidth
                    label={t('account.question.share.cta')}
                  />
                  <LayoutModal
                    isModalOpen={isModalOpen}
                    handleModalClose={() => setIsModalOpen(false)}
                  >
                    <ShareQuestion
                      questionId={questionId}
                      onShare={() => setIsModalOpen(false)}
                    />
                  </LayoutModal>
                </Card>
              )}
            </section>
          </Section>
        </Fade>
      )}
    </PageLayout>
  );
}
