export type LanguageType = {
  id: number;
  iso_code: string;
  name: string;
};

export const languages: LanguageType[] = [
  {
    id: 1,
    iso_code: 'aar',
    name: 'Afar'
  },
  {
    id: 2,
    iso_code: 'abk',
    name: 'Abkhaze'
  },
  {
    id: 3,
    iso_code: 'ave',
    name: 'Avestique'
  },
  {
    id: 4,
    iso_code: 'afr',
    name: 'Afrikaans'
  },
  {
    id: 5,
    iso_code: 'aka',
    name: 'Akan'
  },
  {
    id: 6,
    iso_code: 'amh',
    name: 'Amharique'
  },
  {
    id: 7,
    iso_code: 'arg',
    name: 'Aragonais'
  },
  {
    id: 8,
    iso_code: 'ara',
    name: 'Arabe'
  },
  {
    id: 9,
    iso_code: 'asm',
    name: 'Assamais'
  },
  {
    id: 10,
    iso_code: 'ava',
    name: 'Avar'
  },
  {
    id: 11,
    iso_code: 'aym',
    name: 'Aymara'
  },
  {
    id: 12,
    iso_code: 'aze',
    name: 'Azéri'
  },
  {
    id: 13,
    iso_code: 'bak',
    name: 'Bachkir'
  },
  {
    id: 14,
    iso_code: 'bel',
    name: 'Biélorusse'
  },
  {
    id: 15,
    iso_code: 'bul',
    name: 'Bulgare'
  },
  {
    id: 16,
    iso_code: 'bis',
    name: 'Bichelamar'
  },
  {
    id: 17,
    iso_code: 'bam',
    name: 'Bambara'
  },
  {
    id: 18,
    iso_code: 'ben',
    name: 'Bengali'
  },
  {
    id: 19,
    iso_code: 'bod',
    name: 'Tibétain'
  },
  {
    id: 20,
    iso_code: 'bre',
    name: 'Breton'
  },
  {
    id: 21,
    iso_code: 'bos',
    name: 'Bosnien'
  },
  {
    id: 22,
    iso_code: 'ber',
    name: 'Berbère'
  },
  {
    id: 23,
    iso_code: 'cat',
    name: 'Catalan'
  },
  {
    id: 24,
    iso_code: 'che',
    name: 'Tchétchène'
  },
  {
    id: 25,
    iso_code: 'cha',
    name: 'Chamorro'
  },
  {
    id: 26,
    iso_code: 'cos',
    name: 'Corse'
  },
  {
    id: 27,
    iso_code: 'cre',
    name: 'Cri'
  },
  {
    id: 28,
    iso_code: 'ces',
    name: 'Tchèque'
  },
  {
    id: 29,
    iso_code: 'chu',
    name: 'Vieux-slave'
  },
  {
    id: 30,
    iso_code: 'chv',
    name: 'Tchouvache'
  },
  {
    id: 31,
    iso_code: 'cym',
    name: 'Gallois'
  },
  {
    id: 32,
    iso_code: 'dan',
    name: 'Danois'
  },
  {
    id: 33,
    iso_code: 'deu',
    name: 'Allemand'
  },
  {
    id: 34,
    iso_code: 'div',
    name: 'Maldivien'
  },
  {
    id: 35,
    iso_code: 'dzo',
    name: 'Dzongkha'
  },
  {
    id: 36,
    iso_code: 'ewe',
    name: 'Ewe'
  },
  {
    id: 37,
    iso_code: 'ell',
    name: 'Grec'
  },
  {
    id: 38,
    iso_code: 'eng',
    name: 'Anglais'
  },
  {
    id: 39,
    iso_code: 'epo',
    name: 'Espéranto'
  },
  {
    id: 40,
    iso_code: 'spa',
    name: 'Espagnol'
  },
  {
    id: 41,
    iso_code: 'est',
    name: 'Estonien'
  },
  {
    id: 42,
    iso_code: 'eus',
    name: 'Basque'
  },
  {
    id: 43,
    iso_code: 'fas',
    name: 'Persan'
  },
  {
    id: 44,
    iso_code: 'ful',
    name: 'Peul'
  },
  {
    id: 45,
    iso_code: 'fin',
    name: 'Finnois'
  },
  {
    id: 46,
    iso_code: 'fij',
    name: 'Fidjien'
  },
  {
    id: 47,
    iso_code: 'fao',
    name: 'Féroïen'
  },
  {
    id: 48,
    iso_code: 'fra',
    name: 'Français'
  },
  {
    id: 49,
    iso_code: 'fry',
    name: 'Frison occidental'
  },
  {
    id: 50,
    iso_code: 'far',
    name: 'Farsi'
  },
  {
    id: 51,
    iso_code: 'gle',
    name: 'Irlandais'
  },
  {
    id: 52,
    iso_code: 'gla',
    name: 'Écossais'
  },
  {
    id: 53,
    iso_code: 'glg',
    name: 'Galicien'
  },
  {
    id: 54,
    iso_code: 'grn',
    name: 'Guarani'
  },
  {
    id: 55,
    iso_code: 'guj',
    name: 'Gujarati'
  },
  {
    id: 56,
    iso_code: 'glv',
    name: 'Mannois'
  },
  {
    id: 57,
    iso_code: 'hau',
    name: 'Haoussa'
  },
  {
    id: 58,
    iso_code: 'heb',
    name: 'Hébreu'
  },
  {
    id: 59,
    iso_code: 'hin',
    name: 'Hindi'
  },
  {
    id: 60,
    iso_code: 'hmo',
    name: 'Hiri motu'
  },
  {
    id: 61,
    iso_code: 'hrv',
    name: 'Croate'
  },
  {
    id: 62,
    iso_code: 'hat',
    name: 'Créole haïtien'
  },
  {
    id: 63,
    iso_code: 'hun',
    name: 'Hongrois'
  },
  {
    id: 64,
    iso_code: 'hye',
    name: 'Arménien'
  },
  {
    id: 65,
    iso_code: 'her',
    name: 'Héréro'
  },
  {
    id: 66,
    iso_code: 'ina',
    name: 'Interlingua'
  },
  {
    id: 67,
    iso_code: 'ind',
    name: 'Indonésien'
  },
  {
    id: 68,
    iso_code: 'ile',
    name: 'Occidental'
  },
  {
    id: 69,
    iso_code: 'ibo',
    name: 'Igbo'
  },
  {
    id: 70,
    iso_code: 'iii',
    name: 'Yi'
  },
  {
    id: 71,
    iso_code: 'ipk',
    name: 'Inupiak'
  },
  {
    id: 72,
    iso_code: 'ido',
    name: 'Ido'
  },
  {
    id: 73,
    iso_code: 'isl',
    name: 'Islandais'
  },
  {
    id: 74,
    iso_code: 'ita',
    name: 'Italien'
  },
  {
    id: 75,
    iso_code: 'iku',
    name: 'Inuktitut'
  },
  {
    id: 76,
    iso_code: 'jpn',
    name: 'Japonais'
  },
  {
    id: 77,
    iso_code: 'jav',
    name: 'Javanais'
  },
  {
    id: 78,
    iso_code: 'kat',
    name: 'Géorgien'
  },
  {
    id: 79,
    iso_code: 'kon',
    name: 'Kikongo'
  },
  {
    id: 80,
    iso_code: 'kik',
    name: 'Kikuyu'
  },
  {
    id: 81,
    iso_code: 'kua',
    name: 'Kuanyama'
  },
  {
    id: 82,
    iso_code: 'kaz',
    name: 'Kazakh'
  },
  {
    id: 83,
    iso_code: 'kal',
    name: 'Groenlandais'
  },
  {
    id: 84,
    iso_code: 'khm',
    name: 'Khmer'
  },
  {
    id: 85,
    iso_code: 'kan',
    name: 'Kannada'
  },
  {
    id: 86,
    iso_code: 'kor',
    name: 'Coréen'
  },
  {
    id: 87,
    iso_code: 'kau',
    name: 'Kanouri'
  },
  {
    id: 88,
    iso_code: 'kas',
    name: 'Cachemiri'
  },
  {
    id: 89,
    iso_code: 'kur',
    name: 'Kurde'
  },
  {
    id: 90,
    iso_code: 'kom',
    name: 'Komi'
  },
  {
    id: 91,
    iso_code: 'cor',
    name: 'Cornique'
  },
  {
    id: 92,
    iso_code: 'kir',
    name: 'Kirghiz'
  },
  {
    id: 93,
    iso_code: 'lat',
    name: 'Latin'
  },
  {
    id: 94,
    iso_code: 'ltz',
    name: 'Luxembourgeois'
  },
  {
    id: 95,
    iso_code: 'lug',
    name: 'Ganda'
  },
  {
    id: 96,
    iso_code: 'lim',
    name: 'Limbourgeois'
  },
  {
    id: 97,
    iso_code: 'lin',
    name: 'Lingala'
  },
  {
    id: 98,
    iso_code: 'lao',
    name: 'Lao'
  },
  {
    id: 99,
    iso_code: 'lit',
    name: 'Lituanien'
  },
  {
    id: 100,
    iso_code: 'lub',
    name: 'Luba'
  },
  {
    id: 101,
    iso_code: 'lav',
    name: 'Letton'
  },
  {
    id: 102,
    iso_code: 'mlg',
    name: 'Malgache'
  },
  {
    id: 103,
    iso_code: 'mah',
    name: 'Marshallais'
  },
  {
    id: 104,
    iso_code: 'mri',
    name: 'Maori de Nouvelle-Zélande'
  },
  {
    id: 105,
    iso_code: 'mkd',
    name: 'Macédonien'
  },
  {
    id: 106,
    iso_code: 'mal',
    name: 'Malayalam'
  },
  {
    id: 107,
    iso_code: 'mon',
    name: 'Mongol'
  },
  {
    id: 108,
    iso_code: 'mol',
    name: 'Moldave'
  },
  {
    id: 109,
    iso_code: 'mar',
    name: 'Marathi'
  },
  {
    id: 110,
    iso_code: 'msa',
    name: 'Malais'
  },
  {
    id: 111,
    iso_code: 'mlt',
    name: 'Maltais'
  },
  {
    id: 112,
    iso_code: 'mya',
    name: 'Birman'
  },
  {
    id: 113,
    iso_code: 'nau',
    name: 'Nauruan'
  },
  {
    id: 114,
    iso_code: 'nob',
    name: 'Norvégien Bokmål'
  },
  {
    id: 115,
    iso_code: 'nde',
    name: 'Sindebele'
  },
  {
    id: 116,
    iso_code: 'nep',
    name: 'Népalais'
  },
  {
    id: 117,
    iso_code: 'ndo',
    name: 'Ndonga'
  },
  {
    id: 118,
    iso_code: 'nld',
    name: 'Néerlandais'
  },
  {
    id: 119,
    iso_code: 'nno',
    name: 'Norvégien Nynorsk'
  },
  {
    id: 120,
    iso_code: 'nor',
    name: 'Norvégien'
  },
  {
    id: 121,
    iso_code: 'nbl',
    name: 'Nrebele'
  },
  {
    id: 122,
    iso_code: 'nav',
    name: 'Navajo'
  },
  {
    id: 123,
    iso_code: 'nya',
    name: 'Chichewa'
  },
  {
    id: 124,
    iso_code: 'oci',
    name: 'Occitan'
  },
  {
    id: 125,
    iso_code: 'oji',
    name: 'Ojibwé'
  },
  {
    id: 126,
    iso_code: 'orm',
    name: 'Oromo'
  },
  {
    id: 127,
    iso_code: 'ori',
    name: 'Oriya'
  },
  {
    id: 128,
    iso_code: 'oss',
    name: 'Ossète'
  },
  {
    id: 129,
    iso_code: 'pan',
    name: 'Pendjabi'
  },
  {
    id: 130,
    iso_code: 'pli',
    name: 'Pali'
  },
  {
    id: 131,
    iso_code: 'pol',
    name: 'Polonais'
  },
  {
    id: 132,
    iso_code: 'pus',
    name: 'Pachto'
  },
  {
    id: 133,
    iso_code: 'por',
    name: 'Portugais'
  },
  {
    id: 134,
    iso_code: 'que',
    name: 'Quechua'
  },
  {
    id: 135,
    iso_code: 'rcf',
    name: 'Créole Réunionnais'
  },
  {
    id: 136,
    iso_code: 'roh',
    name: 'Romanche'
  },
  {
    id: 137,
    iso_code: 'run',
    name: 'Kirundi'
  },
  {
    id: 138,
    iso_code: 'ron',
    name: 'Roumain'
  },
  {
    id: 139,
    iso_code: 'rus',
    name: 'Russe'
  },
  {
    id: 140,
    iso_code: 'kin',
    name: 'Kinyarwanda'
  },
  {
    id: 141,
    iso_code: 'san',
    name: 'Sanskrit'
  },
  {
    id: 142,
    iso_code: 'srd',
    name: 'Sarde'
  },
  {
    id: 143,
    iso_code: 'snd',
    name: 'Sindhi'
  },
  {
    id: 144,
    iso_code: 'sme',
    name: 'Same du Nord'
  },
  {
    id: 145,
    iso_code: 'sag',
    name: 'Sango'
  },
  {
    id: 146,
    iso_code: 'hbs',
    name: 'Serbo-croate'
  },
  {
    id: 147,
    iso_code: 'sin',
    name: 'Cingalais'
  },
  {
    id: 148,
    iso_code: 'slk',
    name: 'Slovaque'
  },
  {
    id: 149,
    iso_code: 'slv',
    name: 'Slovène'
  },
  {
    id: 150,
    iso_code: 'smo',
    name: 'Samoan'
  },
  {
    id: 151,
    iso_code: 'sna',
    name: 'Shona'
  },
  {
    id: 152,
    iso_code: 'som',
    name: 'Somali'
  },
  {
    id: 153,
    iso_code: 'sqi',
    name: 'Albanais'
  },
  {
    id: 154,
    iso_code: 'srp',
    name: 'Serbe'
  },
  {
    id: 155,
    iso_code: 'ssw',
    name: 'Swati'
  },
  {
    id: 156,
    iso_code: 'sot',
    name: 'Sotho du Sud'
  },
  {
    id: 157,
    iso_code: 'sun',
    name: 'Soundanais'
  },
  {
    id: 158,
    iso_code: 'swe',
    name: 'Suédois'
  },
  {
    id: 159,
    iso_code: 'swa',
    name: 'Swahili'
  },
  {
    id: 160,
    iso_code: 'tam',
    name: 'Tamoul'
  },
  {
    id: 161,
    iso_code: 'tel',
    name: 'Télougou'
  },
  {
    id: 162,
    iso_code: 'tgk',
    name: 'Tadjik'
  },
  {
    id: 163,
    iso_code: 'tha',
    name: 'Thaï'
  },
  {
    id: 164,
    iso_code: 'tir',
    name: 'Tigrigna'
  },
  {
    id: 165,
    iso_code: 'tuk',
    name: 'Turkmène'
  },
  {
    id: 166,
    iso_code: 'tgl',
    name: 'Tagalog'
  },
  {
    id: 167,
    iso_code: 'tsn',
    name: 'Tswana'
  },
  {
    id: 168,
    iso_code: 'ton',
    name: 'Tongien'
  },
  {
    id: 169,
    iso_code: 'tur',
    name: 'Turc'
  },
  {
    id: 170,
    iso_code: 'tso',
    name: 'Tsonga'
  },
  {
    id: 171,
    iso_code: 'tat',
    name: 'Tatar'
  },
  {
    id: 172,
    iso_code: 'twi',
    name: 'Twi'
  },
  {
    id: 173,
    iso_code: 'tah',
    name: 'Tahitien'
  },
  {
    id: 174,
    iso_code: 'uig',
    name: 'Ouïghour'
  },
  {
    id: 175,
    iso_code: 'ukr',
    name: 'Ukrainien'
  },
  {
    id: 176,
    iso_code: 'urd',
    name: 'Ourdou'
  },
  {
    id: 177,
    iso_code: 'uzb',
    name: 'Ouzbek'
  },
  {
    id: 178,
    iso_code: 'ven',
    name: 'Venda'
  },
  {
    id: 179,
    iso_code: 'vie',
    name: 'Vietnamien'
  },
  {
    id: 180,
    iso_code: 'vol',
    name: 'Volapük'
  },
  {
    id: 181,
    iso_code: 'wln',
    name: 'Wallon'
  },
  {
    id: 182,
    iso_code: 'wol',
    name: 'Wolof'
  },
  {
    id: 183,
    iso_code: 'xho',
    name: 'Xhosa'
  },
  {
    id: 184,
    iso_code: 'yid',
    name: 'Yiddish'
  },
  {
    id: 185,
    iso_code: 'yor',
    name: 'Yoruba'
  },
  {
    id: 186,
    iso_code: 'zha',
    name: 'Zhuang'
  },
  {
    id: 187,
    iso_code: 'zho',
    name: 'Chinois'
  },
  {
    id: 188,
    iso_code: 'zul',
    name: 'Zoulou'
  }
];
