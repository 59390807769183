import { useMutation, useQuery } from 'react-query';

import { Customer } from 'types/customer';
import { Question } from 'types/question';
import { Subscription } from 'types/subscription';

import { createQuestion, getQuestion, getQuestions } from './api';

export function useQuestionsQuery({
  customerId,
  page
}: {
  customerId: number;
  page?: number;
}) {
  return useQuery(['questions'], () =>
    getQuestions(customerId, 'personal', page)
  );
}

export function useQuestionQuery({ questionId }: { questionId: string }) {
  return useQuery(['question', questionId], () => getQuestion(questionId));
}

export function useQuestionsCseQuery({
  memberCseId,
  page
}: {
  memberCseId: number;
  page?: number;
}) {
  return useQuery(
    ['questions-cse'],
    () => getQuestions(memberCseId, 'cse', page),
    { cacheTime: 0 }
  );
}

export function useCreateQuestionMutation() {
  return useMutation(
    ({
      customerId,
      subscriptionId,
      questionContent,
      source = 'personal'
    }: {
      customerId: Customer['id'];
      subscriptionId: Subscription['id'];
      questionContent: Question['content'];
      source?: 'personal' | 'cse' | null;
    }) => createQuestion(customerId, subscriptionId, questionContent, source),
    {
      useErrorBoundary: true
    }
  );
}
