import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { useGetFrequentlyAskedQuestions } from 'services/dato/frequently-asked-questions';

const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation('customer');
  const { data, isLoading, isSuccess } = useGetFrequentlyAskedQuestions();

  if (isLoading) return <div />;
  if (!isSuccess)
    return <div>Enable to display frequently asked questions</div>;

  return (
    <div className="max-w-4xl pt-2">
      <Title gutterBottom variant="h1">
        {t('account.contact.frequentlyAskedQuestions.title')}
      </Title>
      <div className="w-full rounded-2xl bg-white p-2 divide-y divide-gray-400 text-lg md:my-8">
        {data?.allFrequentlyAskedQuestions.map((frequentlyAskedQuestion) => (
          <Disclosure as="div">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-6 text-left font-semibold">
                  <span>{frequentlyAskedQuestion.question}</span>
                  {open ? (
                    <MinusIcon className="h-5 w-5" />
                  ) : (
                    <PlusIcon className="h-5 w-5" />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel
                  dangerouslySetInnerHTML={{
                    __html: frequentlyAskedQuestion.content
                  }}
                  className="prose max-w-none px-4 text-lg pb-2 pt-4 text-gray-800 font-medium mb-6"
                />
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
