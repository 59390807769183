import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Lawyer, NewLawyerSubmission } from 'types/lawyer';

import {
  cancelAvailabilitySlot,
  createAvailabilitySlot,
  getLawyer,
  getStripeConfigureUrl,
  submitLawyerRevendication,
  updateLawyerStripeAccountId
} from './api';

// QUERIES
export function useLawyerQuery({ lawyerId }: { lawyerId: Lawyer['id'] }) {
  return useQuery(['lawyer', lawyerId], () => getLawyer(lawyerId));
}

// MUTATIONS
export function useCreateAvailabilitySlotMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      lawyerId,
      startDate,
      endDate,
      appointmentOptions
    }: {
      lawyerId: Lawyer['id'];
      startDate: Date;
      endDate: Date;
      appointmentOptions: string[];
    }) =>
      createAvailabilitySlot(lawyerId, startDate, endDate, appointmentOptions),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['calendar-events']);
      }
    }
  );
}

export function useCancelAvailabilitySlotMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      lawyerId,
      startDate,
      endDate
    }: {
      lawyerId: Lawyer['id'];
      startDate: Date;
      endDate: String;
    }) => cancelAvailabilitySlot(lawyerId, startDate, endDate),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['calendar-events']);
      }
    }
  );
}

export function useStripeConfigureUrl({
  lawyerId,
  redirectUrl
}: {
  lawyerId: Lawyer['id'];
  redirectUrl: string;
}) {
  return useQuery(['stripe-connect-configure'], () =>
    getStripeConfigureUrl(lawyerId, redirectUrl)
  );
}

export function useStripeUpdateAccountId({
  lawyerId,
  code
}: {
  lawyerId: Lawyer['id'];
  code: string | null;
}) {
  const queryClient = useQueryClient();

  return useQuery(
    ['stripe-account-update'],
    () => updateLawyerStripeAccountId(lawyerId, code as string),
    {
      enabled: !!code,
      onSuccess: async () => {
        const currentLawyerQueryKey = ['lawyer', lawyerId];
        // Get current lawyer
        const currentLawyer: Lawyer = queryClient.getQueryData(
          currentLawyerQueryKey
        )!;
        // Mock Stripe connect id
        queryClient.setQueryData<Lawyer>(currentLawyerQueryKey, {
          ...currentLawyer,
          stripeConnectId: 'XXX'
        });
        // Refetch silently the lawyer
        queryClient.invalidateQueries(currentLawyerQueryKey);
      }
    }
  );
}

export function useCreateLawyerRevendication() {
  return useMutation((values: NewLawyerSubmission) =>
    submitLawyerRevendication({ ...values })
  );
}
