import { useMutation } from 'react-query';

import { AdditionalData, RequiredData } from 'types/legal-protection';

import { createLegalProtection } from './api';

export function useCreateLegalProtection() {
  return useMutation(
    ({
      requiredData,
      additionalData
    }: {
      requiredData: RequiredData;
      additionalData: AdditionalData;
    }) => createLegalProtection(requiredData, additionalData),
    {
      useErrorBoundary: true
    }
  );
}
