import { PropsWithChildren } from 'react';

export default function ContainerCard({ children }: PropsWithChildren<{}>) {
  return (
    <div className="w-full md:max-w-3xl">
      <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6">
        {children}
      </div>
    </div>
  );
}
