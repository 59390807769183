import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSendMagicTokenMutation } from 'api/shared/login';

import { useSafedGuardedSearchParams } from 'hooks/useSearchParams';

import {
  LayoutCard,
  LayoutDefault,
  LayoutLoadingSkeleton
} from 'features/shared/layout';
import { EmailForm } from 'features/shared/login/magic/form/email';

export default function AuthenticatePage() {
  const token = useSafedGuardedSearchParams({ param: 'token' });
  const { mutate, status } = useSendMagicTokenMutation();

  const navigate = useNavigate();

  const { t } = useTranslation('customer');

  useEffect(() => {
    mutate({ token });
  }, [mutate, token]);

  const handleSuccess = () => {
    navigate('/login?submitted=true');
  };

  if (status === 'error') {
    return (
      <LayoutDefault minimal>
        <div>
          <LayoutCard
            title="Le lien est invalide"
            body={
              <div className="w-full">
                <p className={clsx('text-base font-medium text-gray-800')}>
                  {`Le lien que vous utilisez n'est pas ou plus valide. `}
                </p>

                <p className={clsx('text-base font-medium text-gray-800 mb-5')}>
                  {`Entrez votre email à nouveau afin de recevoir un nouveau lien `}
                </p>
                {/* {!submitted && ( */}
                <div className="pb-4">
                  <div className="flex flex-col gap-2">
                    <div className="w-full">
                      <EmailForm handleSuccess={handleSuccess} />
                    </div>
                  </div>
                </div>
                <p className="font-medium text-gray-800">
                  {t('login.switch.password')},{' '}
                  <a className="text-purple-900" href="/login?password=true">
                    {t('general.clickhere')}
                  </a>
                </p>
              </div>
            }
          />
        </div>
      </LayoutDefault>
    );
  }
  return <LayoutLoadingSkeleton />;
}
