import { FullCard } from 'components';
import { ArticleListSkeleton } from 'components/skeleton';
import { useGetLastArticles } from 'services/dato';

import { useAuth } from 'context';

import { TrackedLink } from 'features/shared/buttons';

const LastArticlesList = () => {
  const { roles } = useAuth();
  const { data, isLoading } = useGetLastArticles({
    isCustomerCSE: roles.includes('ROLE_CSE_MEMBER')
  });

  if (isLoading) {
    return <ArticleListSkeleton />;
  }

  return (
    <div className="grid sm:grid-cols-4 xl:grid-cols-3 gap-5 mb-10">
      {data?.allArticles.map((article) => (
        <TrackedLink
          key={article.id}
          to={`/account/resources/${article.slug}`}
          name="article"
          metadata={{ slug: article.slug }}
          className="sm:col-span-2 xl:col-span-1"
        >
          <FullCard
            imgHeight="h-48"
            title={article.title}
            image={article.featuredMedia?.responsiveImage}
            tags={[article.tags[0]?.name]}
          />
        </TrackedLink>
      ))}
    </div>
  );
};

export default LastArticlesList;
