import { privateAxios, privateAxiosJsonLd } from 'context/auth/auth.utils';

import { Customer, MemberCse } from 'types/customer';
import { ApiQuestion, PaginationQuestion, Question } from 'types/question';

import {
  formatQuestionFromApi,
  formatQuestionPaginationFromApi
} from './formatting';

export async function getQuestions(
  id: Customer['id'],
  type?: 'personal' | 'cse',
  page = 1
): Promise<{ questions: Question[] | []; pagination: PaginationQuestion }> {
  const res = await privateAxiosJsonLd.get(
    `/questions?${
      type === 'personal'
        ? `customer.id=${id}&source=personal`
        : `memberCseId=${id}`
    }&status[gte]=5&status[lt]=99&page=${page}&itemsPerPage=15`
  );

  const data = res.data['hydra:member'];

  const totalItems = res.data['hydra:totalItems'];

  const pagination = formatQuestionPaginationFromApi(totalItems, page);

  const questions = data.map((apiQuestion: ApiQuestion) =>
    formatQuestionFromApi(apiQuestion)
  );
  return { questions, pagination };
}

export async function getQuestion(questionId: string): Promise<Question> {
  const res = await privateAxios.get(`/questions/${questionId}`);
  const question = formatQuestionFromApi(res.data);
  return question;
}

export async function createQuestion(
  customerId: number,
  subscriptionId: string,
  questionContent: string,
  source?: 'personal' | 'cse' | null
): Promise<Question> {
  const res = await privateAxios.post('/v2/questions', {
    customerId,
    subscriptionId,
    content: questionContent,
    source
  });
  const question = formatQuestionFromApi(res.data);
  return question;
}

export async function getSharedMemberCseQuestion(
  questionId: string
): Promise<MemberCse[]> {
  const res = await privateAxios.get(`/v2/questions/${questionId}/sharing`);

  return res.data;
}

export async function shareQuestion(
  questionId: string,
  customersToShareWith: MemberCse[]
): Promise<void> {
  return privateAxios.put(`/questions/${questionId}/share`, {
    customersToShareWith: customersToShareWith.map(
      (customer) => customer.customerId
    )
  });
}
