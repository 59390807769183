import { publicAxios } from 'context/auth/auth.utils';

import {
  SubscribePremiumPlusAdditionalInputs,
  SubscribePremiumPlusRequiredInputs
} from 'types/inputs';
import { LegalProtection } from 'types/legal-protection';

import {
  formatLegalProtection,
  formatLegalProtectionForApi
} from './formatting';

export async function createLegalProtection(
  requiredData: SubscribePremiumPlusRequiredInputs,
  additionalData: SubscribePremiumPlusAdditionalInputs
): Promise<LegalProtection> {
  const { legalProtectionForApi } = formatLegalProtectionForApi(
    requiredData,
    additionalData
  );

  const res = await publicAxios.post(
    `/legal-protection`,
    legalProtectionForApi
  );

  const { legalProtection } = formatLegalProtection({
    apiLegalProtection: res.data
  });

  return legalProtection;
}
