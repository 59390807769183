import { concat, take } from 'lodash';
import { useInfiniteQuery, useQuery } from 'react-query';

import { privateAxios } from 'context/auth/auth.utils';

import { Article, PaginatedArticles } from 'types/cms';

import { graphQLRequest } from './client';

const CSE_TAG = ['UhrKlBQVQACPkKNRFW7bYw'];
const LABOR_LAW_TAGS = [
  '23444939',
  '23444367',
  '23444450',
  '23444542',
  '23444915',
  '23444819',
  '23444820',
  '23444818',
  '23444905',
  '23444476',
  '23444910',
  '23444911',
  '23444912',
  '23444913',
  '23444909',
  '23444926',
  '23444870',
  '23444397',
  '23444431',
  '23444433',
  '23444806',
  '23444546',
  '23444819',
  '23444820',
  '23444818',
  '23444881',
  '23444366',
  '23444859',
  '23444837',
  '23444445',
  '23444879',
  '23444488',
  '23444424',
  '23444927',
  '23444803'
];

const GET_ARTICLE_BY_SLUG_QUERY = `
  query GetArticleBySlug($slug: String!) {
    article(
      filter: {
        slug: { eq: $slug }
      }
    ) {
      id
      title
      slug
      content
      updatedAt
      tags {
        id
        name
      }
      author {
        name
      } 
      featuredMedia {
        responsiveImage {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

const GET_LAST_ARTICLES_QUERY = `
  query AllArticles($linksFilter: LinksFilter) {
    allArticles(
      first: 6,
      filter: {
        tags: $linksFilter
      },
    ) {
      id
      title
      slug
      updatedAt
      tags {
        id
        name
      }
      featuredMedia {
        responsiveImage(imgixParams: { fm: webp, q: 100, h: 182 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

const GET_LAST_CSE_ARTICLES_QUERY = `{
  allArticles(
    first: 6,
    filter: {
      tags: { eq: UhrKlBQVQACPkKNRFW7bYw }
    }
  ) {
    id
    title
    slug
    updatedAt
    tags {
      id
      name
    }
    featuredMedia {
      responsiveImage(imgixParams: {fm: webp, q: 100, h: 182 }) {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
      }
    }
  }
}`;

const GET_SUGGESTED_ARTICLES_QUERY = `
  query AllArticles($linksFilter: LinksFilter, $idsFilter: ItemIdFilter, $first: IntType = 3) {
    allArticles(
      first: $first,
      filter: {
        id: $idsFilter
        tags: $linksFilter
      }
    ) {
      id
      title
      slug
      updatedAt
      tags {
        id
        name
      }
      featuredMedia {
        responsiveImage(imgixParams: {fm: webp, q: 100, h: 182 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

const GET_PAGINATED_ARTICLES_QUERY = `
  query GetPaginatedArticles($skip: IntType, $first: IntType = 8, $search: String!, $linksFilter: LinksFilter) {
    allArticlesMeta: _allArticlesMeta (
      filter: {
        OR: [
          {
            title: {
              matches: { pattern: $search }
            }
          },
          {
            content: {
               matches: { pattern: $search }
            }
          }
        ],
        AND:{
            tags: $linksFilter
        },
      },
    ) {
      count
    }
    allArticles(
      first: $first,
      filter: {
        OR: [
          {
            title: {
              matches: { pattern: $search }
            }
          },
          {
            content: {
               matches: { pattern: $search }
            }
          }
        ],
        AND: {
          tags: $linksFilter
        }
      },
      skip: $skip
    ) {
      id
      title
      slug
      updatedAt
      tags {
        id
        name
      }
      featuredMedia {
        responsiveImage(imgixParams: {fm: webp, q: 70, h: 182 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

export const useGetLastArticles = ({ isCustomerCSE = false }) => {
  const tagsToFilter = isCustomerCSE ? LABOR_LAW_TAGS : [];

  return useQuery(
    ['articles'],
    (): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_LAST_ARTICLES_QUERY, {
        linksFilter: { notIn: ['UhrKlBQVQACPkKNRFW7bYw', ...tagsToFilter] }
      })
  );
};

export const useGetMemberSuggestedArticles = ({
  articlesIds = null,
  isCustomerCSE = false,
  enabled = true,
  first
}: {
  articlesIds?: string[] | null;
  isCustomerCSE: boolean;
  enabled?: boolean;
  first?: number;
}) => {
  const idsFilter = articlesIds ? { in: articlesIds } : undefined;
  const linksFilter =
    !isCustomerCSE && !idsFilter
      ? { notIn: ['UhrKlBQVQACPkKNRFW7bYw'] }
      : undefined;

  return useQuery(
    ['suggested-articles', articlesIds],
    (): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_SUGGESTED_ARTICLES_QUERY, {
        linksFilter,
        idsFilter,
        first
      }),
    { enabled }
  );
};

export const useGetLastArticlesRead = ({
  articlesIds = null,
  enabled = true
}: {
  articlesIds?: string[] | null;
  enabled?: boolean;
}) => {
  const idsFilter = articlesIds ? { in: articlesIds } : undefined;

  return useQuery(
    ['last-articles-read', articlesIds],
    (): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_SUGGESTED_ARTICLES_QUERY, {
        linksFilter: undefined,
        idsFilter
      }),
    { enabled }
  );
};

export const ARTICLE_BY_PAGE = 12;

export const useGetPaginatedArticles = (
  search: string | null,
  isCustomerCSE = false,
  withLaborLaw = true
) => {
  const withCSETag = isCustomerCSE ? [] : CSE_TAG;
  const withLaborLawTags = withLaborLaw ? [] : LABOR_LAW_TAGS;

  return useInfiniteQuery(
    ['paginated-articles', search, withLaborLaw],
    ({ pageParam = 0 }): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_PAGINATED_ARTICLES_QUERY, {
        search,
        skip: pageParam,
        first: ARTICLE_BY_PAGE,
        linksFilter: { notIn: [...withCSETag, ...withLaborLawTags] }
      }),
    {
      cacheTime: 0,
      getNextPageParam: (_, pages) => {
        const nextCursor =
          pages.length === 1 ? ARTICLE_BY_PAGE : pages.length * ARTICLE_BY_PAGE;

        return nextCursor;
      }
    }
  );
};

export const useGetArticleBySlug = (slug: string) =>
  useQuery(
    ['article', slug],
    (): Promise<Article | null> =>
      graphQLRequest(GET_ARTICLE_BY_SLUG_QUERY, { slug }),
    {
      onSuccess: (data) => {
        const lastArticlesRead: string[] = JSON.parse(
          localStorage.getItem('lastArticlesRead') || '[]'
        );

        if (data && !lastArticlesRead.includes(data.article.id)) {
          localStorage.setItem(
            'lastArticlesRead',
            JSON.stringify(concat(data.article.id, take(lastArticlesRead, 2)))
          );
        }
      }
    }
  );

export const useGetLastCSEArticles = () =>
  useQuery(
    ['articles-cse'],
    (): Promise<PaginatedArticles | null> =>
      graphQLRequest(GET_LAST_CSE_ARTICLES_QUERY, {})
  );

export async function getMemberSuggestedArticles(): Promise<{
  ids: string[] | null;
}> {
  const res = await privateAxios.get(`/me/suggested-articles`);

  return res.data;
}

export async function getSuggestedArticlesByQuestion(
  questionId: string
): Promise<{
  ids: string[] | null;
}> {
  const res = await privateAxios.get(
    `/questions/${questionId}/suggested-articles`
  );

  return res.data;
}
