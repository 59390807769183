/* eslint-disable jsx-a11y/no-autofocus */
import clsx from 'clsx';
import { replace } from 'lodash';
import { InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';

import FrenchFlag from '../icons/FrenchFlag';
import InputErrorMessage from './InputErrorMessage';

interface PhoneInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error: FieldError | undefined;
  onChange: (value: any) => void;
  fullWidth?: boolean;
}

const PHONE_NUMBER_PREFIX = '+33';

const PhoneInput = ({
  label,
  onChange,
  disabled,
  error,
  name,
  placeholder = '',
  fullWidth = false,
  ...props
}: PhoneInputProps) => {
  const removePrefix = (value: any) =>
    isNaN(value) || value === 0
      ? ''
      : replace(value.toString(), PHONE_NUMBER_PREFIX, '');

  const addPrefix = (value: string) => {
    const output = parseInt(value, 10);
    const isNumber = isNaN(output);

    return isNumber ? '' : `${PHONE_NUMBER_PREFIX}${output}`;
  };

  return (
    <div id="input-group" className={clsx({ 'w-full': fullWidth })}>
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      <div className="flex items-center">
        <button
          id="dropdown-phone-button"
          data-dropdown-toggle="dropdown-phone"
          className="input-text w-fit h-12 rounded-r-none inline-flex items-center py-2.5 px-4 text-sm font-medium text-center bg-gray-100 border"
          type="button"
        >
          <FrenchFlag />
          {PHONE_NUMBER_PREFIX}
        </button>
        <input
          {...props}
          type="text"
          className={clsx({
            'input-text border-l-0 rounded-l-none h-12': true,
            'focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900':
              !error,
            'text-red-800 border-red-800 bg-red-200': error,
            'text-gray-700': disabled
          })}
          onChange={(e) => onChange(addPrefix(e.target.value))}
          value={removePrefix(props.value)}
        />
      </div>
      {error && <InputErrorMessage error={error} />}
    </div>
  );
};

export default PhoneInput;
