import { useTranslation } from 'react-i18next';

import { LayoutCard } from 'features/shared/layout';

import { NewPasswordForm } from './form';

export default function NewPasswordCard() {
  const { t } = useTranslation('customer');

  return (
    <LayoutCard
      title={t('password.reset.new.title')}
      body={
        <div className="w-full">
          <div className="pb-4">
            <p>
              {t('password.new.p.1')} <br /> {t('password.new.p.2')}
            </p>
          </div>
          <NewPasswordForm />
        </div>
      }
    />
  );
}
