import { INTENT_CONSULTATION_SOURCES } from 'constants/analytics';
import AnswerVote from 'containers/answer/AnswerVote';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCreateHref } from 'hooks';

import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

import { Answer } from 'types/answer';
import { Question } from 'types/question';

export default function QuestionTimelineCta({
  question,
  hasLinkedConsultation,
  answer
}: {
  question: Question;
  hasLinkedConsultation: boolean;
  answer: Answer;
}) {
  const { t } = useTranslation('customer');
  const { answerVotes } = answer;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { href } = useCreateHref({
    path: '/account/consultations/booking',
    params: {
      questionId: question.id,
      lawyerId: answer.lawyer?.id,
      intentConsultationSource: INTENT_CONSULTATION_SOURCES.QUESTION_TIMELINE
    }
  });

  const isAnswerRated = Boolean(answerVotes.length);

  return (
    <>
      <div className="justify-stretch mt-6 flex flex-col">
        {isAnswerRated && !hasLinkedConsultation && (
          <Link to={href}>
            <Button
              variant="tertiary"
              size="medium"
              fullWidth
              label={t('account.question.consultation.cta')}
            />
          </Link>
        )}
        {[30, 32].includes(question.status) && (
          <Button
            variant="outline"
            size="medium"
            fullWidth
            onClick={() => setIsModalOpen(true)}
            label={t('account.question.voteCta')}
          />
        )}
      </div>
      <LayoutModal
        isModalOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      >
        <AnswerVote
          questionId={question.id}
          answerId={answer.id}
          lawyer={answer ? answer?.lawyer : null}
          canCreateAdditionalRequest={!answer?.additionalRequest}
        />
      </LayoutModal>
    </>
  );
}
