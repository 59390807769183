import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Consultation, ConsultationFile } from 'types/consultation';
import { Customer } from 'types/customer';
import { BookingConsultationOption, Lawyer } from 'types/lawyer';
import { Question } from 'types/question';

import {
  createConsultation,
  getConsultations,
  rescheduleConsultation,
  scheduleConsultation,
  sendNotificationLawyerNoAvailability
} from './api';

// QUERIES
export function useConsultationsQuery({
  customerId
}: {
  customerId: Customer['id'];
}) {
  const queryClient = useQueryClient();
  return useQuery(['consultations'], () => getConsultations(customerId), {
    onSuccess: (consultations) => {
      if (!consultations.length) return;
      for (const consultation of consultations) {
        queryClient.setQueryData(
          ['consultation', consultation.id],
          consultation
        );
      }
    }
  });
}

// MUTATIONS
export function useCreateConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      questionId,
      availabilitySlotId,
      appointmentType = BookingConsultationOption.BookingPhone,
      comment = undefined,
      consultationFile = undefined
    }: {
      questionId: Question['id'];
      availabilitySlotId: string;
      appointmentType: BookingConsultationOption;
      comment?: string;
      consultationFile?: ConsultationFile;
    }) =>
      createConsultation(
        questionId,
        availabilitySlotId,
        appointmentType,
        comment,
        consultationFile
      ),
    {
      useErrorBoundary: true,
      onSettled: () => {
        queryClient.invalidateQueries(['consultations']);
      }
    }
  );
}

export function useScheduleConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      consultationId,
      availabilitySlotId,
      appointmentType = BookingConsultationOption.BookingPhone
    }: {
      consultationId: Consultation['id'];
      availabilitySlotId: string;
      appointmentType: BookingConsultationOption;
    }) =>
      scheduleConsultation(consultationId, availabilitySlotId, appointmentType),
    {
      useErrorBoundary: true,
      onSettled: () => {
        queryClient.invalidateQueries(['consultations']);
      }
    }
  );
}
export function useRescheduleConsultationMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      appointmentId,
      availabilitySlotId,
      appointmentType = BookingConsultationOption.BookingPhone
    }: {
      appointmentId: string;
      availabilitySlotId: string;
      appointmentType: BookingConsultationOption;
    }) =>
      rescheduleConsultation(
        appointmentId,
        availabilitySlotId,
        appointmentType
      ),
    {
      useErrorBoundary: true,
      onSettled: () => {
        queryClient.invalidateQueries(['consultations']);
      }
    }
  );
}

export function useSendNotificationToLawyerMutation() {
  return useMutation(({ lawyerId }: { lawyerId: Lawyer['id'] }) =>
    sendNotificationLawyerNoAvailability(lawyerId)
  );
}
