import { SVGProps } from 'react';

export default function LeFigaroLogo({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Calque_1"
      x="0px"
      y="0px"
      viewBox="0 0 310.3 41.5"
      {...props}
    >
      <path d="M0,8.3h20.8v7h-2.5c-1.3,0-1.6,0.5-1.6,1.7v16c0,0.83,0.67,1.5,1.5,1.5h6.1c0.94,0.06,1.74-0.66,1.8-1.59 c0,0,0,0,0-0.01V27h8.3v14.4H0v-7h2.4c1.1,0,1.7-0.4,1.7-1.5V17c0-1-0.1-1.6-1.5-1.6H0V8.3z M38,8.3h34.3V19h-8.6v-1.7 c0-1.1-0.5-1.9-2.2-1.9H54v6h8.1v6.4h-8v6.7h7.8c0.93,0.07,1.74-0.63,1.81-1.56c0.01-0.11,0.01-0.23-0.01-0.34v-1.7h8.6v10.6H38v-7 h2c1,0,1.6-0.4,1.6-1.4V17c0-1-0.1-1.7-1.5-1.7h-2v-7H38z M89.3,8.3h35V19h-8.5v-1.7c0.07-0.93-0.63-1.74-1.56-1.81 c-0.11-0.01-0.23-0.01-0.34,0.01h-7.8v6h7.2v6.3h-7.2v4.8c0,1.4,0.6,1.9,2.3,1.9h2.7v7H89.3v-7h2.9c0.72,0.06,1.34-0.47,1.4-1.19 c0.01-0.07,0.01-0.14,0-0.21V17c0-1-0.1-1.7-1.5-1.7h-2.8V8.3z M144.2,17c0-1.2,0.4-1.6,1.6-1.6h2.6v-7h-20.7v7h2.6 c1.4,0,1.6,0.6,1.6,1.6v16c0,1.2-0.7,1.5-1.6,1.5h-2.6v7h20.7v-7h-2.5c-1,0-1.7-0.4-1.7-1.5L144.2,17L144.2,17z M178.9,8.3h7.3V20 h-7.4c-1.46-3.1-4.57-5.08-8-5.1c-4.5,0-8,3.2-8,9.2c0,10,5.6,11,8,11c5,0,6.1-3.6,6.1-5h-4.6V24h15.9l-0.1,4.2 c0,0.9-0.9,13.2-18.7,13.2c-12.8,0-19.4-6.7-19.4-17.2c0-7,5.5-15.9,18.5-15.9c7.3,0,10.4,2.5,10.4,2.5V8.3z M227,34.4 c-1.5,0-1.9-1.3-2.7-3.3l-8.8-22.8h-12.7l-9,24.5c-0.44,1.03-1.48,1.68-2.6,1.6h-1.8v7H205v-6.7h-2.2c-1,0-1.1-0.7-0.8-1.7l1-3h9.4 l1,3.2c0.4,1.1-0.8,1.5-1.5,1.5H210v6.7h18.7v-7H227z M204.5,25.5l3.2-9.7h0.1l3.2,9.7H204.5z M271,34.4c-1.3,0-1.5-0.6-2.1-1.5 s-4.6-7-4.6-7c3.44-1.04,5.79-4.21,5.8-7.8c0-6.2-5.2-8.3-7.4-9c-2.71-0.47-5.45-0.73-8.2-0.8h-22.6v7h2.2c1.4,0,1.6,0.8,1.6,1.7 v15.7c0,0.7-0.1,1.7-1.6,1.7h-2.2v7h19.9v-7h-2c-1.4,0-1.6-0.8-1.6-1.6v-5.3h4.5l8,14h12.6v-7.1H271z M252.7,21.7h-4.5V15h5 c1.3,0,3.9,0.5,3.9,3.5c0,2.2-1.7,3.3-4.4,3.3L252.7,21.7z M291.5,8.3c-13.8,0-18.7,8.8-18.7,16.3c0,9.8,7,16.8,18.7,16.8 c10.6,0,18.8-5.2,18.8-16.4C310.3,14.3,302.3,8.3,291.5,8.3z M292,35c-3,0-6.3-1.8-6.3-11c0-5.7,2.2-9.2,6-9.2c3.5,0,6,2.4,6,9.8 C297.7,32.7,295.1,35,292,35z" />
      <path d="M114,32.3c3.22,2.68,6.08,5.78,8.5,9.2l-1.3-1c-1.94-2.16-4.05-4.17-6.3-6c-1.81-1.62-3.83-3-6-4.1l-2.8-1.2 v-1.5h3.4c1.43,1.63,2.97,3.17,4.6,4.6H114z M92.5,8.3c3.98,1.83,7.17,5.02,9,9v10.4c-4.23-1.15-8.23-3.05-11.8-5.6l3.1,0.2 c0,0-7.2-3.7-9.4-6.3c0.97,0.36,1.98,0.63,3,0.8c0,0-5.4-2.9-7.3-10.8c0.41,0.57,0.96,1.01,1.6,1.3c-0.78-2.36-1.22-4.82-1.3-7.3 c0,0,6,6.9,13,9C92.6,9,92.4,8.3,92.5,8.3z" />
    </svg>
  );
}
