import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { Fragment, useRef } from 'react';

import { useClickOutside } from 'hooks';

interface LayoutModalProps {
  isModalOpen: boolean;
  handleModalClose: () => void;
  persisting?: boolean;
  withDefaultPadding?: boolean;
  children: React.ReactNode;
  isClosable?: boolean;
}

export default function LayoutModal({
  isModalOpen,
  handleModalClose,
  children,
  withDefaultPadding = true,
  persisting = false,
  isClosable = true
}: LayoutModalProps) {
  const initialModalRef = useRef(null);

  useClickOutside(initialModalRef, () => handleModalClose());

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={initialModalRef}
        onClose={persisting ? () => {} : handleModalClose}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={clsx(
                'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity',
                !isClosable && 'pointer-events-none'
              )}
            />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                'relative inline-block transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg align-middle',
                withDefaultPadding && 'px-4 pt-5 pb-4 sm:p-6'
              )}
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                {isClosable && (
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => handleModalClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                )}
              </div>
              <div ref={initialModalRef}>{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
