import { links } from 'constants/links';
import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';

import Title from './Title';

interface GoogleRatingProps {
  onClick?: () => void;
  isB2b: Boolean;
}

function GoogleRating({ onClick = () => {}, isB2b }: GoogleRatingProps) {
  const { t } = useTranslation('customer');

  return (
    <div>
      <Title variant="h3">
        {isB2b
          ? t('account.question.vote.b2b.satisfied.title')
          : t('account.question.vote.b2c.satisfied.title')}
      </Title>
      <div>
        <p className="text-md mt-2 text-left font-medium ">
          {isB2b
            ? t('account.question.vote.b2b.satisfied.description')
            : t('account.question.vote.b2c.satisfied.description')}
        </p>
        <div className="mt-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={links.googleRating}
          >
            <Button
              variant="primary"
              size="large"
              fullWidth
              label={t('general.googleRating.cta')}
              onClick={onClick}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default GoogleRating;
