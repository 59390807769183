import {
  AnnotationIcon,
  ChatAlt2Icon,
  DocumentSearchIcon
} from '@heroicons/react/solid';
import { Title } from 'components';
import { Card } from 'components/card';
import { useTranslation } from 'react-i18next';

import { formatDateToIntl } from 'utils/date';

import { AnswerStatus } from 'types/answer';
import { Question } from 'types/question';

import { QuestionTimelineCta } from './cta';
import { TimelineEventItem } from './event';
import { TimelineEvent } from './timeline.types';

interface QuestionTimelineProps {
  question: Question;
  isOwner: boolean;
}

export default function QuestionTimeline({
  question,
  isOwner
}: QuestionTimelineProps) {
  const {
    shouldCtaForAnswerVote,
    createdDate,
    qualifiedDate,
    isAnswered,
    hasLinkedConsultation
  } = question;
  const { t } = useTranslation('customer');
  const currentAnswer =
    question.answers.find((item) => item.status === AnswerStatus.PUBLISHED) ||
    null;

  const timelineEvents: TimelineEvent[] = [
    {
      id: 1,
      content: t('account.question.timeline.asked'),
      icon: AnnotationIcon,
      date: formatDateToIntl(createdDate, 'short')
    }
  ];

  if (qualifiedDate) {
    timelineEvents.push({
      id: 2,
      content: t('account.question.timeline.waiting'),
      icon: DocumentSearchIcon,
      date: formatDateToIntl(qualifiedDate, 'short')
    });
  }
  if (isAnswered) {
    timelineEvents.push({
      id: 3,
      content: t('account.question.timeline.answered'),
      icon: ChatAlt2Icon,
      date: formatDateToIntl(question.answers[0].createdDate, 'short')
    });
  }
  if (question.status === 31 && currentAnswer?.additionalRequest) {
    timelineEvents.push({
      id: 3,
      content: t('account.questions.status.additional_request_in_progress'),
      icon: ChatAlt2Icon,
      date: formatDateToIntl(
        currentAnswer?.additionalRequest.createdAt,
        'short'
      )
    });
  }

  if (question.status === 32 && currentAnswer?.additionalRequest?.answeredAt) {
    timelineEvents.push({
      id: 3,
      content: t('account.questions.status.additional_request_in_progress'),
      icon: ChatAlt2Icon,
      date: formatDateToIntl(
        currentAnswer?.additionalRequest.answeredAt,
        'short'
      )
    });
  }

  return (
    <Card compact>
      <Title variant="h3">{t('account.question.timeline.title')}</Title>
      <div className="mt-6 flow-root">
        <ul className="flex flex-col gap-4">
          {timelineEvents.map((item, itemIdx) => (
            <TimelineEventItem
              item={item}
              key={item.id}
              itemIdx={itemIdx}
              timelineLength={timelineEvents.length}
            />
          ))}
        </ul>
      </div>
      {isAnswered && currentAnswer && isOwner && (
        <QuestionTimelineCta
          question={question}
          hasLinkedConsultation={hasLinkedConsultation}
          answer={currentAnswer}
        />
      )}
    </Card>
  );
}
