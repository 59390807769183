import classNames from 'classnames';
import { FullCard, Title } from 'components';
import { ArticleListSkeleton } from 'components/skeleton';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  getMemberSuggestedArticles,
  useGetMemberSuggestedArticles
} from 'services/dato/articles';

import { useAuth } from 'context';

import { TrackedLink } from 'features/shared/buttons';

const SuggestedArticles = () => {
  const { roles } = useAuth();
  const { t } = useTranslation('customer');
  const {
    data: suggestedArticlesData,
    isLoading: isSuggestedArticlesLoading,
    isSuccess
  } = useQuery(['memberSuggestedArticles'], getMemberSuggestedArticles);
  const hasSuggestedArticles =
    isSuccess &&
    suggestedArticlesData.ids !== null &&
    suggestedArticlesData.ids.length > 0;

  const { data, isLoading } = useGetMemberSuggestedArticles({
    articlesIds: suggestedArticlesData?.ids,
    isCustomerCSE: roles.includes('ROLE_CSE_MEMBER'),
    enabled: hasSuggestedArticles
  });

  if (isSuggestedArticlesLoading || isLoading)
    return <ArticleListSkeleton number={3} />;

  if (!hasSuggestedArticles) {
    return <div />;
  }

  return (
    <>
      <Title className="ml-1.5" variant="h2">
        {t('account.home.h2.suggested')}
      </Title>
      <div className="grid sm:grid-cols-4 lg:grid-cols-3 gap-2 mb-10">
        {data?.allArticles.map((article, index) => (
          <TrackedLink
            key={article.id}
            to={`/account/resources/${article.slug}`}
            name="article"
            metadata={{ slug: article.slug }}
            className={classNames({
              'sm:col-span-4 lg:col-span-1': index === 0,
              'sm:col-span-2 lg:col-span-1': index !== 0
            })}
          >
            <FullCard
              imgHeight="h-48"
              title={article.title}
              image={article.featuredMedia?.responsiveImage}
              tags={[article.tags[0]?.name]}
            />
          </TrackedLink>
        ))}
      </div>
    </>
  );
};

export default SuggestedArticles;
