import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { loginMagicSchema } from 'schemas';

import { useCreateLoginOtpMutation } from 'api/shared/login';

import { useUnguardedSearchParams } from 'hooks';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { LoginMagicFormInputs } from 'types/inputs';

function OtpLoginEmailForm() {
  const { mutate, isLoading } = useCreateLoginOtpMutation();

  const defaultEmail = useUnguardedSearchParams({ param: 'email' });
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const handleError = useErrorHandler();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginMagicFormInputs>({
    resolver: yupResolver(loginMagicSchema(tForm))
  });

  const onSubmit: SubmitHandler<LoginMagicFormInputs> = async (formData) => {
    const { email } = formData;
    mutate(
      { email },
      {
        onSuccess: async () => {
          searchParams.append('code', 'true');
          searchParams.append('email', email);
          setSearchParams(searchParams);
        },
        onError: (error: any) => {
          const isUnauthorizedError =
            error?.response?.status === 403 || error?.response?.status === 400;
          if (isUnauthorizedError) {
            setError('email', {
              message: `${t('error.occurred')}. ${t('error.checkCredentials')}`,
              type: 'focus'
            });
          } else {
            handleError(error);
          }
        }
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        type="email"
        fullWidth
        defaultValue={defaultEmail || ''}
        autofocus={!defaultEmail}
        placeholder={tForm('placeholder.email')}
        label={tForm('label.email')}
        name="email"
        register={register}
        error={errors.email}
      />
      <Button
        isLoading={isLoading}
        fullWidth
        submit
        size="medium"
        variant="primary"
        label={t('general.continue')}
      />
    </form>
  );
}

export { OtpLoginEmailForm };
