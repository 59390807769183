import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Title } from 'components';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loginMagicSchema } from 'schemas';

import { useCreateMagicLinkMutation } from 'api/shared/login';

import { useUnguardedSearchParams } from 'hooks';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import MagicLinkIcon from 'features/shared/icons/MagicLinkIcon';
import { LayoutModal } from 'features/shared/layout';

import { LoginMagicFormInputs } from 'types/inputs';

export default function EmailForm({
  handleSuccess,
  onboarding = false
}: {
  handleSuccess: () => void;
  onboarding?: boolean;
}) {
  const handleError = useErrorHandler();

  const partnerKey = useUnguardedSearchParams({ param: 'partnerKey' });

  const { mutate, isLoading } = useCreateMagicLinkMutation(
    partnerKey,
    onboarding
  );

  const { t } = useTranslation('customer');

  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<LoginMagicFormInputs>({
    resolver: yupResolver(loginMagicSchema(tForm))
  });

  const onSubmit: SubmitHandler<LoginMagicFormInputs> = async ({ email }) => {
    mutate(
      { email },
      {
        onError: (error: any) => {
          const { data: errorData } = error.response;
          if (errorData.error.code === 'customer_not_eligible_for_btob') {
            setError('root', {
              message: t('onboarding.error.forbidden')
            });
            return;
          }
          const isUnauthorizedError =
            error?.response?.status === 403 || error?.response?.status === 400;

          if (isUnauthorizedError) {
            setError('email', {
              message: t('onboarding.error'),
              type: 'focus'
            });
          } else {
            handleError(error);
          }
        },
        onSuccess: () => {
          handleSuccess();
        }
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        autofocus
        type="email"
        fullWidth
        placeholder={tForm('placeholder.email')}
        label={tForm('label.email')}
        name="email"
        register={register}
        error={errors.email}
      />
      <Button
        isLoading={isLoading}
        fullWidth
        submit
        icon={<MagicLinkIcon />}
        size="medium"
        variant="tertiary"
        label={t('login.button.submit.label')}
      />
      {errors.root && (
        <LayoutModal isModalOpen handleModalClose={() => clearErrors('root')}>
          <div className="flex flex-col gap-2 font-medium text-gray-800">
            <Title
              variant="h3"
              className="!font-bold flex items-center"
              gutterBottom
            >
              <ExclamationCircleIcon className="h-7 w-7 fill-red-800 stroke-white mr-2" />
              {t('onboarding.error.modal.title')}
            </Title>
            <p className="mt-2">{errors.root.message}</p>
            <ul className="list-disc font-medium mt-4 list-inside">
              <li className="mb-2">{t('onboarding.error.check1')}</li>
              <li>{t('onboarding.error.check2')}</li>
            </ul>
            <p className="mt-2">{t('onboarding.error.action')}</p>
            <div className="flex gap-4 justify-end mt-2">
              <Button
                onClick={() => clearErrors('root')}
                variant="secondary"
                size="medium"
                label={t('general.close')}
              />
            </div>
          </div>
        </LayoutModal>
      )}
    </form>
  );
}
