/* eslint-disable react-hooks/exhaustive-deps */
import { ANALYTICS_PAGE_VIEW } from 'constants/analytics';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { getAccount } from 'api/shared/account/api';

import {
  loginGoogleOtpCode,
  loginUserOtpCode,
  loginUserOtpMagicLink,
  loginUserPartnerCode,
  loginUserPassword,
  logoutUser
} from 'context/auth/auth.utils';
import { useTracking } from 'context/tracking/tracking.context';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { AuthContext } from 'types/auth';
import { Lawyer } from 'types/lawyer';

const AuthCtx = createContext<AuthContext | undefined>(undefined);
interface AuthProviderProps {
  children: React.ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const [roles, setRoles] = useState<string[]>([]);
  const [lawyerId, setLawyerId] = useState<number | null>(null);
  const [lawyer, setLawyer] = useState<Lawyer | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { analytics } = useTracking();
  const [email, setEmail] = useState<string>(
    window.localStorage.getItem('email') as string
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (analytics) {
        analytics?.track(ANALYTICS_PAGE_VIEW.APP_PAGE_VIEW, {
          id: userId
        });
      }
    }
  }, [pathname, isAuthenticated]);

  const account = useQuery(
    ['account', email],
    () => getAccount(email as string),
    {
      enabled: !!email,
      onSuccess: (a) => {
        // Identify user with accountId
        // analytics?.identify(JSON.stringify(a.id));
        setRoles(a.roles);
        setUserId(a.id);
        setLawyerId(a.lawyerId);
        setLawyer(a.lawyer);
        setIsAuthenticated(true);
      }
    }
  );

  const loginOtpCode = useCallback(
    async (methodId: string, codeId: string, emailSaved: string) => {
      const data = await loginUserOtpCode(methodId, codeId, emailSaved);
      // set timeout glitch to fix a long api call - same in auth.context.tsx
      await setTimeout(() => {
        setEmail(data.email);
      }, 2000);
    },
    []
  );

  const loginPartnerCode = useCallback(
    async (emailSaved: string, partnerKey: string, password: string) => {
      const data = await loginUserPartnerCode(emailSaved, partnerKey, password);
      // set timeout glitch to fix a long api call - same in auth.context.tsx
      await setTimeout(() => {
        setEmail(data.email);
      }, 2000);
    },
    []
  );

  const loginGoogleCode = useCallback(async (code: string) => {
    const data = await loginGoogleOtpCode(code);
    // set timeout glitch to fix a long api call - same in auth.context.tsx
    await setTimeout(() => {
      setEmail(data.email);
    }, 2000);
  }, []);

  const loginMagicLink = useCallback(async (token: string) => {
    const data = await loginUserOtpMagicLink(token);
    setEmail(data.email);
  }, []);

  const loginPassword = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      const data = await loginUserPassword({ username, password });
      setEmail(data.email);
    },
    []
  );

  const logout = useCallback(() => {
    logoutUser();
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated,
      userId,
      lawyerId,
      lawyer,
      roles,
      loginOtpCode,
      loginPartnerCode,
      loginMagicLink,
      loginPassword,
      loginGoogleCode,
      logout
    }),
    [
      isAuthenticated,
      loginOtpCode,
      loginPartnerCode,
      loginMagicLink,
      loginPassword,
      loginGoogleCode,
      logout
    ]
  );

  if (account.isLoading) return <LayoutLoadingSkeleton />;

  return <AuthCtx.Provider value={value}>{children}</AuthCtx.Provider>;
}

const useAuth = () => {
  const context = useContext(AuthCtx);
  if (context === undefined) {
    throw new Error('useAuth must be used within a Provider');
  }
  return context;
};

export { AuthProvider, AuthCtx, useAuth };
