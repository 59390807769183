import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { Title } from 'components';
import { useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';
import { premiumPlusRequiredDataSchema } from 'schemas';

import { Steps, TitleCard } from 'features/customer/funnels';
import { POSTALCODE_LENGTH } from 'features/customer/profile/constants';
import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import PhoneInput from 'features/shared/forms/PhoneInput';
import PlacesInput from 'features/shared/forms/PlacesInput';
import SelectInputGroup from 'features/shared/forms/SelectInputGroup';

import { FunnelStep, StepStatus } from 'types/funnel';
import { SubscribePremiumPlusRequiredInputs } from 'types/inputs';

import {
  SubscribePremiumPlusStep,
  useSubscribePremiumPlusContext
} from './premium-plus.context';

const acceptedCountriesIsoCodes = [
  // France
  'FR',
  // Mayotte
  'YT',
  // Guadeloupe
  'GP',
  // Martinique
  'MQ',
  // Saint-Pierre et Miquelon
  'PM',
  // Saint Barthélémy
  'BL',
  // Guyane
  'GY',
  // Polynésie Française
  'PF',
  // Nouvelle-Calédonie
  'NC',
  // Wallis et Futuna
  'WF',
  // Monaco (is included)
  'MC',
  // Terres australes et antarctiques françaises
  'TF'
];

export default function RequiredDataStep() {
  const { setCurrentStep, requiredData, setRequiredData } =
    useSubscribePremiumPlusContext();

  const countriesOptions = useMemo(() => countryList().getData(), []);

  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const funnelSteps = useMemo(
    (): FunnelStep[] => [
      {
        id: 1,
        label: t('subscribe.premiumPlus.step.1'),
        status: StepStatus.CURRENT
      },
      {
        id: 2,
        label: t('subscribe.premiumPlus.step.2'),
        status: StepStatus.UPCOMING
      },
      {
        id: 3,
        label: t('subscribe.premiumPlus.step.3'),
        status: StepStatus.UPCOMING
      },
      {
        id: 4,
        label: t('subscribe.premiumPlus.step.4'),
        status: StepStatus.UPCOMING
      }
    ],
    [t]
  );

  const funnelTitle = [
    'Bonjour je suis Charlotte, pourriez-vous',
    'renseigner vos coordonnées ?'
  ];

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors }
  } = useForm<SubscribePremiumPlusRequiredInputs>({
    resolver: yupResolver(premiumPlusRequiredDataSchema(tForm)),
    defaultValues: {
      ...requiredData,
      birthDate: requiredData.birthDate
        ? new Date(requiredData.birthDate).toISOString().split('T')[0]
        : '',
      partnerBirthDate: requiredData.partnerBirthDate
        ? new Date(requiredData.partnerBirthDate).toISOString().split('T')[0]
        : ''
    }
  });

  const withPartnerData = watch('withPartnerData');

  const acceptedLivingCountryOptions = useMemo(
    () =>
      countriesOptions
        .filter((country) => acceptedCountriesIsoCodes.includes(country.value))
        .filter((country) =>
          requiredData.country ? country.label !== requiredData.country : true
        ),
    [countriesOptions, requiredData.country]
  );

  const defaultCountry = useMemo(() => {
    if (requiredData.country) {
      const correspondingCountry = acceptedLivingCountryOptions.find(
        (country) => country.value === requiredData.country
      ) as { label: string; value: string; disabled: boolean };
      return {
        label: correspondingCountry.label,
        value: correspondingCountry.value,
        disabled: false
      };
    }
    return undefined;
  }, [acceptedLivingCountryOptions, requiredData.country]);

  const onSubmit = (data: SubscribePremiumPlusRequiredInputs) => {
    setRequiredData({ ...data });
    setCurrentStep(SubscribePremiumPlusStep.AdditionalData);
  };

  console.log(watch('address'));

  return (
    <>
      <Steps funnelSteps={funnelSteps} variant="vertical" />
      <TitleCard title={funnelTitle} />
      <div className="w-full md:max-w-3xl mb-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Personal informations */}
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6 mb-2">
            <div className="mb-4">
              <Title variant="h3">{t('subscribe.personalInformations')}</Title>
            </div>

            <div className="w-full mb-4">
              <SelectInputGroup
                name="civility"
                label={tForm('label.civility')}
                error={errors.civility}
                options={[
                  { label: 'Monsieur', value: 'mr' },
                  { label: 'Madame', value: 'mrs' }
                ]}
                register={register}
              />
            </div>
            <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
              <div className="w-full lg:w-1/2">
                <TextInputGroup
                  label={tForm('label.firstName')}
                  name="firstName"
                  error={errors.firstName}
                  register={register}
                />
              </div>
              <div className="w-full lg:w-1/2">
                <TextInputGroup
                  label={tForm('label.lastName')}
                  name="lastName"
                  error={errors.lastName}
                  register={register}
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
              <div className="w-full lg:w-1/2">
                <TextInputGroup
                  type="date"
                  label={tForm('label.birthDate')}
                  name="birthDate"
                  error={errors.birthDate}
                  register={register}
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <Controller
                control={control}
                name="birthCity"
                render={({ field: { onChange, value } }) => (
                  <PlacesInput
                    onChange={onChange}
                    value={value}
                    error={errors.birthCity}
                    onSelectPlace={(place: any) => {
                      setValue('birthCountry', place.country);
                      setValue('birthZipCode', place.zipCode);
                    }}
                    label={tForm('label.birthCity')}
                  />
                )}
              />
            </div>
            <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
              <div className="w-full lg:w-2/3">
                <SelectInputGroup
                  label={tForm('label.birthCountry')}
                  options={countriesOptions}
                  name="birthCountry"
                  error={errors.birthCountry}
                  register={register}
                />
              </div>
              <div className="w-full lg:w-1/3">
                <TextInputGroup
                  label={tForm('label.zipCode')}
                  name="birthZipCode"
                  error={errors.birthZipCode}
                  register={register}
                  maxLength={POSTALCODE_LENGTH}
                />
              </div>
            </div>
            <div className={clsx('mt-4', withPartnerData && 'mb-4')}>
              <input
                name="partnerData"
                type="checkbox"
                className="cursor-pointer rounded text-purple-900 align-middle"
                checked={withPartnerData}
                onChange={(e) => setValue('withPartnerData', e.target.checked)}
              />
              <label
                htmlFor="partnerData"
                className="ml-2 text-gray-900 align-middle	"
              >
                {t('subscribe.fillPartnerInformations')}
              </label>
            </div>
            {/*  */}
            {withPartnerData && (
              <div>
                <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
                  <div className="w-full lg:w-1/2">
                    <TextInputGroup
                      label={tForm('label.partnerFirstName')}
                      name="partnerFirstName"
                      error={errors.partnerFirstName}
                      register={register}
                    />
                  </div>
                  <div className="w-full lg:w-1/2">
                    <TextInputGroup
                      label={tForm('label.partnerLastName')}
                      name="partnerLastName"
                      error={errors.partnerLastName}
                      register={register}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between gap-4 lg:gap-8 mb-4">
                  <div className="w-full lg:w-1/2">
                    <TextInputGroup
                      type="date"
                      label={tForm('label.partnerBirthDate')}
                      name="partnerBirthDate"
                      error={errors.partnerBirthDate}
                      register={register}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      control={control}
                      name="partnerBirthCity"
                      render={({ field: { onChange, value } }) => (
                        <PlacesInput
                          onChange={onChange}
                          value={value}
                          error={errors.partnerBirthCity}
                          onSelectPlace={(place: any) => {
                            setValue('partnerBirthCountry', place.country);
                            setValue('partnerBirthZipCode', place.zipCode);
                          }}
                          label={tForm('label.partnerBirthCity')}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
                  <div className="w-full lg:w-2/3">
                    <SelectInputGroup
                      label={tForm('label.partnerBirthCountry')}
                      options={countriesOptions}
                      name="partnerBirthCountry"
                      error={errors.partnerBirthCountry}
                      register={register}
                    />
                  </div>

                  <div className="w-full lg:w-1/3">
                    <TextInputGroup
                      label={tForm('label.zipCode')}
                      name="partnerBirthZipCode"
                      error={errors.partnerBirthZipCode}
                      register={register}
                      maxLength={POSTALCODE_LENGTH}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6 mb-2">
            <div className="mb-4">
              <Title variant="h3">{t('subscribe.generalInformations')}</Title>
            </div>
            <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
              <div className="w-full">
                <Controller
                  control={control}
                  name="address"
                  render={({ field: { onChange, value } }) => (
                    <PlacesInput
                      onChange={onChange}
                      placeType="address"
                      value={value}
                      error={errors.address}
                      onSelectPlace={(place: any) => {
                        setValue('country', place.country);
                        setValue('zipCode', place.zipCode);
                        setValue('city', place.city);
                      }}
                      label={tForm('label.address')}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
              <div className="w-full lg:w-2/3">
                <TextInputGroup
                  label={tForm('label.addressComplement')}
                  name="addressComplement"
                  error={errors.addressComplement}
                  register={register}
                />
              </div>
              <div className="w-full lg:w-1/3">
                <TextInputGroup
                  label={tForm('label.zipCode')}
                  name="zipCode"
                  error={errors.zipCode}
                  register={register}
                  maxLength={POSTALCODE_LENGTH}
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
              <div className="w-full lg:w-1/2">
                <SelectInputGroup
                  label={tForm('label.country')}
                  options={acceptedLivingCountryOptions}
                  name="country"
                  error={errors.country}
                  register={register}
                  defaultOption={defaultCountry}
                />
              </div>
              <div className="w-full lg:w-1/2">
                <TextInputGroup
                  label={tForm('label.city')}
                  name="city"
                  error={errors.city}
                  register={register}
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-8 mb-4">
              <div className="w-full lg:w-1/2">
                <TextInputGroup
                  label={tForm('label.email')}
                  name="email"
                  error={errors.email}
                  register={register}
                />
              </div>
              <div className="w-full lg:w-1/2">
                <Controller
                  control={control}
                  name="phoneCellphone"
                  render={({ field }) => (
                    <PhoneInput
                      label={tForm('label.phone')}
                      error={errors.phoneCellphone}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <p>
                Votre adresse e-mail et votre numéro de téléphone sont
                indispensables pour souscrire à une Assurance Protection
                juridique en ligne.
              </p>
            </div>
          </div>
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6">
            <Button
              submit
              size="medium"
              variant="tertiary"
              fullWidth
              label={t('general.continue')}
            />
          </div>
        </form>
        {/*  */}
      </div>
      <div className="text-center w-full md:max-w-3xl">
        <p className="text-sm text-gray-700">
          Les informations recueillies font l’objet de traitements informatiques
          par la SAS AFAAFF! et ses partenaires pour les finalités de
          souscription à l’abonnement et gestion de l’assistance juridique, de
          passation et gestion des adhésions au contrat collectif d’assurance de
          Protection Juridique, et par Matmut Protection Juridique pour la
          gestion des sinistres. Vous pouvez exercer vos droits auprès du
          Délégué à la Protection des Données d’ekie : CABINET BOUCHARA –
          AVOCATS, Service DPO, 17 rue du Colisée – 75008 PARIS -
          contact-avocat@ekie.co Pour en savoir plus sur l’usage de vos données
          et vos droits, consultez la Charte de la SAS AFAAFF! sur le respect de
          la vie privée disponible sur le site www.ekie.co., rubrique «Vie
          privée».
        </p>
        <br />
        <p className="text-sm text-gray-700">
          Enfin, vous avez la faculté de vous inscrire gratuitement sur la liste
          d’opposition au démarchage téléphonique sur www.bloctel.gouv.fr.
          Néanmoins nous pouvons toujours vous téléphoner lorsqu’il s’agit de
          sollicitations intervenant dans le cadre de l’exécution d’un contrat
          en cours et ayant un rapport avec l’objet de ce contrat, y compris
          lorsqu’il s’agit de vous proposer des produits ou des services
          afférents ou complémentaires à l’objet du contrat en cours ou de
          nature à améliorer ses performances ou sa qualité.
        </p>
      </div>
    </>
  );
}
