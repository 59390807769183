import { SVGProps } from 'types/core';

const ClientIcon = ({ ...props }: SVGProps) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5508 12.1992C12.7148 12.3633 12.7148 12.6641 12.5508 12.8281C12.3867 12.9922 12.0859 12.9922 11.9219 12.8281L11.6211 12.5C10.3906 13.5938 8.75 14.25 7 14.25C5.22266 14.25 3.58203 13.5938 2.35156 12.5L2.05078 12.8281C1.88672 12.9922 1.58594 12.9922 1.42188 12.8281C1.25781 12.6641 1.25781 12.3633 1.42188 12.1992L1.75 11.8984C0.65625 10.668 0 9.02734 0 7.25C0 5.5 0.65625 3.85938 1.75 2.62891L1.42188 2.32812C1.25781 2.16406 1.25781 1.86328 1.42188 1.69922C1.58594 1.53516 1.88672 1.53516 2.05078 1.69922L2.35156 2.02734C3.58203 0.933594 5.22266 0.25 7 0.25C8.75 0.25 10.3906 0.933594 11.6211 2.02734L11.9219 1.69922C12.0859 1.53516 12.3867 1.53516 12.5508 1.69922C12.7148 1.86328 12.7148 2.16406 12.5508 2.32812L12.2227 2.62891C13.3164 3.85938 14 5.5 14 7.25C14 9.02734 13.3164 10.668 12.2227 11.8984L12.5508 12.1992ZM8.50391 9.41016C8.06641 9.71094 7.54688 9.875 7 9.875C6.42578 9.875 5.90625 9.71094 5.46875 9.41016L2.98047 11.8984C4.04688 12.8281 5.44141 13.375 7 13.375C8.53125 13.375 9.92578 12.8281 10.9922 11.8984L8.50391 9.41016ZM13.125 7.25C13.125 5.71875 12.5508 4.32422 11.6211 3.25781L9.13281 5.74609C9.43359 6.18359 9.625 6.70312 9.625 7.25C9.625 7.82422 9.43359 8.34375 9.13281 8.78125L11.6211 11.2695C12.5508 10.2031 13.125 8.80859 13.125 7.25ZM7 1.125C5.44141 1.125 4.04688 1.69922 2.98047 2.62891L5.46875 5.11719C5.90625 4.81641 6.42578 4.625 7 4.625C7.54688 4.625 8.06641 4.81641 8.50391 5.11719L10.9922 2.62891C9.92578 1.69922 8.53125 1.125 7 1.125ZM4.83984 8.78125C4.53906 8.34375 4.375 7.82422 4.375 7.25C4.375 6.70312 4.53906 6.18359 4.83984 5.74609L2.35156 3.25781C1.42188 4.32422 0.875 5.71875 0.875 7.25C0.875 8.80859 1.42188 10.2031 2.35156 11.2695L4.83984 8.78125ZM7 5.5C6.01562 5.5 5.25 6.29297 5.25 7.25C5.25 8.23438 6.01562 9 7 9C7.95703 9 8.75 8.23438 8.75 7.25C8.75 6.29297 7.95703 5.5 7 5.5Z"
      fill="black"
    />
  </svg>
);

export default ClientIcon;
