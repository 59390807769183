import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from 'app';
import 'index.css';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

import AppProviders from 'context/AppProviders';

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN_URL,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

const element = document.getElementById('root');

createRoot(element!).render(
  <AppProviders>
    <App />
  </AppProviders>
);

reportWebVitals();
