import {
  ForbesIcon,
  France5Logo,
  LeFigaroLogo,
  LeParisienLogo,
  LesEchosLogo,
  M6Icon
} from 'features/shared/icons';

export default function BrandsBanner() {
  return (
    <div className="grid grid-cols-3 md:grid-cols-6 gap-4 items-center justify-center">
      <div className="flex items-center justify-center">
        <ForbesIcon className="opacity-50 w-16 h-16 lg:w-24 lg:h-24 grayscale" />
      </div>
      <div className="flex items-center justify-center">
        <France5Logo className="opacity-50  w-12 h-12  lg:w-18 lg:h-18 grayscale" />
      </div>
      <div className="flex items-center justify-center">
        <LeFigaroLogo className="opacity-50 w-16 h-16  lg:w-24 lg:h-24 grayscale" />
      </div>
      <div className="flex items-center justify-center">
        <LeParisienLogo className="opacity-50 w-16 h-16  lg:w-24 lg:h-24 grayscale" />
      </div>
      <div className="flex items-center justify-center">
        <LesEchosLogo className="opacity-50 w-16 h-16  lg:w-24 lg:h-24 grayscale" />
      </div>
      <div className="flex items-center justify-center">
        <M6Icon className="opacity-50 w-10 h-10  lg:w-16 lg:h-16 grayscale" />
      </div>
    </div>
  );
}
