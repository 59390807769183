import { useEffect } from 'react';

export default function useRefetchOnChange({
  isRefetchEnabled,
  refetch,
  query
}: {
  isRefetchEnabled: boolean;
  refetch: () => void;
  query: string;
}) {
  useEffect(() => {
    if (isRefetchEnabled) {
      refetch();
    }
  }, [query, refetch, isRefetchEnabled]);
}
