import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { loginPartnerSchema } from 'schemas';

import { useSendPartnerCredentialsMutation } from 'api/shared/login';

import { useUnguardedSearchParams } from 'hooks';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import { Toast } from 'features/shared/toasts';

import { LoginPartnerFormInputs } from 'types/inputs';

function PartnerLoginForm() {
  const { mutate, isLoading } = useSendPartnerCredentialsMutation();

  const defaultEmail = useUnguardedSearchParams({ param: 'email' });

  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const handleError = useErrorHandler();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginPartnerFormInputs>({
    resolver: yupResolver(loginPartnerSchema(tForm))
  });

  const onSubmit: SubmitHandler<LoginPartnerFormInputs> = async (formData) => {
    const { email, password, partnerKey } = formData;
    mutate(
      { email, partnerKey, password },
      {
        onSuccess: async () => {
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('login.title.google.success')}
            />,
            { position: 'top-right', duration: 3000 }
          );
        },
        onError: (error: any) => {
          const isUnauthorizedError =
            error?.response?.status === 403 || error?.response?.status === 400;
          if (isUnauthorizedError) {
            setError('email', {
              message: `${t('error.occurred')}. ${t('error.checkCredentials')}`,
              type: 'focus'
            });
          } else {
            handleError(error);
          }
        }
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        type="email"
        fullWidth
        defaultValue={defaultEmail || ''}
        autofocus={!defaultEmail}
        placeholder={tForm('placeholder.email')}
        label={tForm('label.email')}
        name="email"
        register={register}
        error={errors.email}
      />
      <TextInputGroup
        fullWidth
        type="password"
        placeholder="**********"
        label={tForm('label.create.password')}
        name="password"
        register={register}
        error={errors.password}
      />
      <TextInputGroup
        fullWidth
        type="text"
        placeholder="**********"
        label={tForm('label.partnerKey')}
        name="partnerKey"
        register={register}
        error={errors.partnerKey}
      />
      <Button
        isLoading={isLoading}
        fullWidth
        submit
        size="medium"
        variant="primary"
        label={t('login.submit.partner')}
      />
    </form>
  );
}

export { PartnerLoginForm };
