import classNames from 'classnames';
import { FullCard, Title } from 'components';
import { ArticleListSkeleton } from 'components/skeleton';
import { useTranslation } from 'react-i18next';
import { useGetLastArticlesRead } from 'services/dato/articles';
import { useLocalStorage } from 'usehooks-ts';

import { TrackedLink } from 'features/shared/buttons';

const LastArticlesRead = () => {
  const { t } = useTranslation('customer');
  const [lastArticlesReadIds] = useLocalStorage('lastArticlesRead', []);
  const hasArticlesRead = lastArticlesReadIds.length > 0;
  const { data, isLoading } = useGetLastArticlesRead({
    articlesIds: lastArticlesReadIds,
    enabled: hasArticlesRead
  });

  if (isLoading) return <ArticleListSkeleton number={3} />;
  if (!hasArticlesRead) return <div />;

  return (
    <>
      <Title className="ml-1.5" variant="h2">
        {t('account.home.h2.read')}
      </Title>
      <div className="grid sm:grid-cols-4 lg:grid-cols-3 gap-2 mb-10">
        {data?.allArticles.map((article, index) => (
          <TrackedLink
            key={article.id}
            to={`/account/resources/${article.slug}`}
            name="article"
            metadata={{ slug: article.slug }}
            className={classNames({
              'sm:col-span-4 lg:col-span-1': index === 0,
              'sm:col-span-2 lg:col-span-1': index !== 0
            })}
          >
            <FullCard
              imgHeight="h-48"
              title={article.title}
              image={article.featuredMedia?.responsiveImage}
              tags={[article.tags[0]?.name]}
            />
          </TrackedLink>
        ))}
      </div>
    </>
  );
};

export default LastArticlesRead;
