import { useQuery } from 'react-query';

import { useLocale } from 'hooks';

import { AlphabetQuery, AlphabetSheetQuery } from 'types/cms';

import { graphQLRequest } from './client';

const GET_ALPHABET_BY_LANGUAGE_QUERY = `
  query AllAlphabetSheets($locale: SiteLocale) {
    allAlphabetSheets(locale: $locale, orderBy: letter_ASC, first: 26) {
      id
      title
      letter
      slug
      image {
        responsiveImage(imgixParams: { fm: webp, fit: crop, w: 200, h: 200, q: 60 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
    }
  }
`;

const GET_ALPHABET_SHEET_BY_SLUG_QUERY = `
  query AlphabetSheets($slug: String, $locale: SiteLocale) {
    alphabetSheet(
      locale: $locale,
      filter: { slug: { eq: $slug } }
    ) {
      id
      title
      letter
      slug
      text
      category {
        name
      }
      image {
        responsiveImage(imgixParams: { fm: webp, fit: crop, w: 300, h: 300, q: 60 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          bgColor
          base64
        }
      }
      nextAlphabetSheet {
        slug
        title
        letter
        image {
          responsiveImage(imgixParams: { fm: webp, fit: crop, w: 300, h: 300, q: 60 }) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            bgColor
            base64
          }
        }
      }
    }
  }
`;

export const useGetAlphabet = () => {
  const { locale } = useLocale();

  return useQuery(
    ['alphabet'],
    (): Promise<AlphabetQuery> =>
      graphQLRequest(GET_ALPHABET_BY_LANGUAGE_QUERY, {
        locale: locale.includes('fr') ? 'fr_FR' : 'en'
      })
  );
};

export const useGetAlphabetSheetBySlug = ({ slug }: { slug: string }) => {
  const { locale } = useLocale();

  return useQuery(
    [`alphabet-${slug}`],
    (): Promise<AlphabetSheetQuery> =>
      graphQLRequest(GET_ALPHABET_SHEET_BY_SLUG_QUERY, {
        locale: locale.includes('fr') ? 'fr_FR' : 'en',
        slug
      })
  );
};
