import { privateAxios } from 'context/auth/auth.utils';

export async function createQuestionFromText(
  content: string
): Promise<{ content: string }> {
  const { data } = await privateAxios.post(`/create-question-from-text`, {
    content
  });

  return data;
}
