import { ReactComponent as EkieLogo } from 'assets/ekie.svg';

import NavbarContainer from './NavbarContainer';

interface NavbarFunnelProps {
  partnerLogoUrl?: string;
}

export default function NavbarFunnel({ partnerLogoUrl }: NavbarFunnelProps) {
  if (partnerLogoUrl) {
    return <NavbarFunnelPartner partnerLogoUrl={partnerLogoUrl} />;
  }
  return <NavbarFunnelCustomer />;
}

function NavbarFunnelCustomer() {
  return (
    <NavbarContainer>
      <div className="flex h-16 items-center justify-center">
        <div className="flex items-center justify-center gap-4">
          <div className="my-2 flex justify-center w-20">
            <EkieLogo />
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

function NavbarFunnelPartner({ partnerLogoUrl }: { partnerLogoUrl: string }) {
  return (
    <NavbarContainer>
      <div className="flex h-16 items-center justify-center">
        <div className="flex items-center justify-center gap-4">
          <div className="my-2 flex justify-center w-20">
            <EkieLogo />
          </div>
          <div>
            <p className="block md:hidden">pour</p>
            <p className="hidden md:block">en partenariat avec</p>
          </div>
          <div id="partner-logo" />
        </div>
      </div>
      <style>{`#partner-logo { width: 100px; height: 50px; background-size: contain; background-repeat: no-repeat; background-position: center; background-image: url("${process.env.REACT_APP_MEDIA_URL}/${partnerLogoUrl}")}`}</style>
    </NavbarContainer>
  );
}
