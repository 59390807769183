import { ReactNode } from 'react';

import { NavbarMinimal } from '../navbar';

interface LayoutFunnelCustomerProps {
  children: ReactNode;
}

export default function LayoutFunnelCustomer({
  children
}: LayoutFunnelCustomerProps) {
  return (
    <div className="min-h-screen w-full bg-gray-paper pb-12 bg-brush bg-no-repeat bg-cover bg-top sm:bg-initial">
      <NavbarMinimal />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <main>
          <div className="flex flex-col items-center gap-2">{children}</div>
        </main>
      </div>
    </div>
  );
}
