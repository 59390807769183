import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useProductQuery } from 'api/customer/queries.index';

import { useUnguardedSearchParams } from 'hooks';

import { useTracking } from 'context';

import {
  SubscriptionFunnelIndications,
  SubscriptionFunnelStep
} from 'features/customer/funnels';
import { FunnelLoginForm } from 'features/customer/funnels/contact/login';
import { OfferDetails } from 'features/customer/funnels/contact/subscribe';
import { useLocalProductBySkuCode } from 'features/customer/funnels/funnel.utils';
import { ContainerCard } from 'features/shared/container';
import {
  LayoutFunnelCustomer,
  LayoutLoadingSkeleton
} from 'features/shared/layout';

import { FunnelCustomerLoginConsistencyMetadata } from 'types/funnel';
import { Product } from 'types/product';

export default function FunnelContactPage() {
  const { analytics } = useTracking();

  const [searchParams, setSearchParams] = useSearchParams();
  const skuCode =
    (searchParams.get('skuCode') as Product['skuCode']) ||
    (process.env.REACT_APP_PRODUCT_STANDARD_SKU_CODE as string);

  const localProduct = useLocalProductBySkuCode(skuCode);

  const product = useProductQuery({ skuCode });

  const distinctId = useUnguardedSearchParams({ param: 'distinctId' });

  useEffect(() => {
    if (!searchParams.get('skuCode')) {
      setSearchParams({ skuCode });
    }
  }, [skuCode, searchParams, setSearchParams]);

  useEffect(() => {
    if (analytics) {
      analytics?.identify(distinctId);
    }
  }, [analytics, distinctId]);

  if (!product.isSuccess) return <LayoutLoadingSkeleton />;

  const consistencyMetadata: FunnelCustomerLoginConsistencyMetadata = {
    context: 'subscription_funnel',
    skuCode
  };

  return (
    <LayoutFunnelCustomer>
      <SubscriptionFunnelIndications step={SubscriptionFunnelStep.Login} />
      <ContainerCard>
        <OfferDetails localProduct={localProduct} />
      </ContainerCard>
      <FunnelLoginForm consistencyMetadata={consistencyMetadata} />
    </LayoutFunnelCustomer>
  );
}
