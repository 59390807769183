import { Stripe, loadStripe } from '@stripe/stripe-js';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGuardedSearchParams, useUnguardedSearchParams } from 'hooks';

import { TitleCard } from 'features/customer/funnels';
import AdditionalDataStep from 'features/customer/premium-plus/AdditionalDataStep';
import ConfirmationStep from 'features/customer/premium-plus/ConfirmationStep';
import PaymentStep from 'features/customer/premium-plus/PaymentStep';
import RequiredDataStep from 'features/customer/premium-plus/RequiredDataStep';
import SubscribePremiumPlusProvider, {
  SubscribePremiumPlusStep,
  useSubscribePremiumPlusContext
} from 'features/customer/premium-plus/premium-plus.context';
import { Button } from 'features/shared/buttons';
import { ContainerCard } from 'features/shared/container';
import { CheckCircleIcon } from 'features/shared/icons';
import { LayoutFunnelCustomer } from 'features/shared/layout';

export default function SubscribePremiumPlusPage() {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUB_KEY as string
  );

  const isSuccess = useUnguardedSearchParams({ param: 'success' }) === 'true';

  return (
    <SubscribePremiumPlusProvider>
      <LayoutFunnelCustomer>
        {isSuccess ? (
          <SubscribePremiumPlusSuccess />
        ) : (
          <SubscribePremiumPlusCurrentStep stripePromise={stripePromise} />
        )}
      </LayoutFunnelCustomer>
    </SubscribePremiumPlusProvider>
  );
}

function SubscribePremiumPlusSuccess() {
  const { t } = useTranslation('customer');
  const warrantyDate = useGuardedSearchParams({ param: 'warrantyDate' });
  const signatureLink = useGuardedSearchParams({ param: 'signatureLink' });

  const newFormattedDate = useMemo(
    () => format(new Date(warrantyDate), 'dd/MM/yyyy'),
    [warrantyDate]
  );

  const newLongFormattedDate = useMemo(
    () =>
      Intl.DateTimeFormat('fr', {
        dateStyle: 'long'
      }).format(new Date(warrantyDate)),
    [warrantyDate]
  );

  const funnelTitle = [
    'Merci pour votre confiance',
    `Votre assurance sera effective le ${newFormattedDate}`
  ];

  return (
    <>
      <TitleCard title={funnelTitle} />
      <ContainerCard>
        <div className="mb-4">
          <div className="flex gap-2 md:items-center">
            <CheckCircleIcon className="h-6 w-6 flex-shrink-0 text-purple-700" />
            <span>
              Un premier prélèvement de 19.9€ sera effectué le{' '}
              {newLongFormattedDate}
            </span>
          </div>
        </div>
        <a
          className="w-full"
          href={signatureLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="tertiary"
            size="medium"
            label={t('general.sign')}
            fullWidth
          />
        </a>
      </ContainerCard>
    </>
  );
}

function SubscribePremiumPlusCurrentStep({
  stripePromise
}: {
  stripePromise: Promise<Stripe | null>;
}) {
  const { currentStep } = useSubscribePremiumPlusContext();

  if (currentStep === SubscribePremiumPlusStep.RequiredData) {
    return <RequiredDataStep />;
  }
  if (currentStep === SubscribePremiumPlusStep.AdditionalData) {
    return <AdditionalDataStep />;
  }
  if (currentStep === SubscribePremiumPlusStep.Confirmation) {
    return <ConfirmationStep />;
  }
  if (currentStep === SubscribePremiumPlusStep.Payment) {
    return <PaymentStep stripePromise={stripePromise} />;
  }
  // Default is a fallback on the first step
  return <RequiredDataStep />;
}
