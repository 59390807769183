import { publicAxios } from 'context/auth/auth.utils';

import { ApiProduct, Product } from 'types/product';

import { formatProductFromApi } from './formatting';

export async function getProductBySkuCode(skuCode: string): Promise<Product> {
  const res = await publicAxios.get(`/v2/products/${skuCode}`);
  const { data } = res;
  const product = formatProductFromApi(data as ApiProduct);
  return product;
}
