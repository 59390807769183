/* eslint-disable no-console */
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import 'styles/datepicker.css';

import useRefetchOnChange from 'hooks/useRefetchOnChange';

import { RevendicationForm } from 'features/lawyer/account/revendication/form';
import { Alert } from 'features/shared/alerts';
import { LayoutCard, LayoutDefault } from 'features/shared/layout';

export interface Ville {
  nom: string;
  code: string;
  codesPostaux: string[];
}

export default function LawyerSubmissionPage() {
  const { t } = useTranslation('lawyer');
  const [query, setQuery] = useState<string>('');

  const shouldFetchCities = query.length >= 3;

  const {
    isLoading,
    data: citiesFromGeoApi,
    refetch: refetchCities
  } = useQuery(
    ['cities'],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/cities/autocomplete?name=${query}`
        )
        .then(
          (res) => res.data as { id: number; name: string; zipCode: string }[]
        ),
    {
      enabled: shouldFetchCities
    }
  );

  useRefetchOnChange({
    query,
    refetch: refetchCities,
    isRefetchEnabled: shouldFetchCities
  });

  return (
    <LayoutDefault>
      <div>
        <LayoutCard
          size="xl"
          title={t('revendication.submission.title')}
          body={
            <div>
              <div className="flex flex-col gap-4 text-left w-full my-6">
                <p>{t('revendication.submission.p')}</p>
                <Alert>
                  <p>
                    {t('revendication.submission.a.1')}{' '}
                    <a
                      className="text-purple-900"
                      href="https://www.ekie.co/trouver-un-avocat"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('revendication.submission.a.2')}
                    </a>
                    {t('revendication.submission.a.3')}
                  </p>
                </Alert>
              </div>
              <div>
                <RevendicationForm
                  isLoading={isLoading}
                  query={query}
                  setQuery={setQuery}
                  cities={citiesFromGeoApi as any}
                />
              </div>
            </div>
          }
        />
      </div>
    </LayoutDefault>
  );
}
