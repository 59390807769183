import { useState } from 'react';
import { Image } from 'react-datocms';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTemplateB2B } from 'services/dato';

import { NotFoundErrorPage } from 'pages/shared/errors/not-found';

import { useAuth } from 'context';

import { EmailForm } from 'features/customer/content';
import IllustrationSwitcher from 'features/customer/content/IllustrationSwitcher';
import { Button } from 'features/shared/buttons';
import {
  LayoutContent,
  LayoutLoadingSkeleton,
  LayoutModal
} from 'features/shared/layout';

import { TemplateB2B } from 'types/cms';

export default function PrivateContentPage() {
  const { t } = useTranslation('customer');
  const { isAuthenticated } = useAuth();
  const { slug } = useParams();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(!isAuthenticated);

  const { data, isLoading } = useTemplateB2B({ slug: slug as string });

  if (isLoading) return <LayoutLoadingSkeleton />;

  if (!data || !data.templateCommunicationB2b) return <NotFoundErrorPage />;

  const handleSuccess = () => setIsModalOpen(false);

  return (
    <LayoutContent>
      <div className="flex flex-col gap-4">
        <Hero communication={data.templateCommunicationB2b} />
        <KeyFigures communication={data.templateCommunicationB2b} />
        <Content communication={data.templateCommunicationB2b} />
        <Footer communication={data.templateCommunicationB2b} />
      </div>
      <LayoutModal
        withDefaultPadding={false}
        isClosable={false}
        isModalOpen={isModalOpen}
        handleModalClose={() => null}
      >
        <div className="container mx-auto">
          <div className="max-w-3xl">
            <div className="grid place-items-center bg-purple-300">
              <img src="/images/content-paywall.jpeg" alt="People in a metro" />
            </div>
            <div className="px-4 pt-5 pb-4 sm:px-12">
              <div className="flex flex-col items-center mb-4">
                <div className="text-2xl lg:text-4xl font-serif italic">
                  <span className="block text-center">
                    {t('exclusiveContent.title')}
                  </span>
                </div>
              </div>
              <div className="mt-2">
                <p className="text-center">{t('exclusiveContent.p')}</p>
              </div>
              <div className="text-center mb-8">
                <div className="mt-4">
                  <div className="flex flex-col gap-4">
                    <EmailForm handleSuccess={handleSuccess} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutModal>
    </LayoutContent>
  );
}

export function Hero({
  communication
}: {
  communication: TemplateB2B['templateCommunicationB2b'];
}) {
  return (
    <div className="w-full bg-white rounded-xl overflow-hidden">
      <div className="w-full lg:grid lg:grid-cols-2">
        <div className="py-12 lg:py-24 px-6 lg:px-12">
          <div className="flex flex-col items-start">
            <div className="mb-4">
              <div className="shadow-lg rounded-full p-4">
                <IllustrationSwitcher
                  illustrationId={communication.illustration}
                />
              </div>
            </div>
            <h1 className="text-3xl lg:text-4xl font-serif">
              {communication.heading}
            </h1>
          </div>
        </div>
        <div className="overflow-hidden">
          <Image data={communication.image.responsiveImage} />
        </div>
      </div>
    </div>
  );
}

export function KeyFigures({
  communication
}: {
  communication: TemplateB2B['templateCommunicationB2b'];
}) {
  return (
    <div className="bg-white rounded-xl">
      <div className="my-12 text-center">
        <h2 className="text-3xl font-serif italic">
          {communication.titleKeyFigures}
        </h2>
      </div>
      <div className="px-6 mb-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 space-y-8 divide-y lg:space-y-0 lg:divide-y-0 lg:divide-x divide-gray-600">
          {communication.keyFigure.map((keyFigure) => (
            <div className="flex flex-col items-center">
              <div className="p-6 w-fit mb-4">
                <div className="bg-purple-400 w-24 h-24 rounded-full">
                  <div className="h-full grid place-items-center">
                    <span className="italic text-2xl font-serif">
                      {keyFigure.value}
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-center mb-2">
                <h3 className="text-lg font-bold">{keyFigure.heading}</h3>
              </div>
              <div className="text-center">
                <p>{keyFigure.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function Content({
  communication
}: {
  communication: TemplateB2B['templateCommunicationB2b'];
}) {
  return (
    <div className="bg-white rounded-xl">
      <div className="container mx-auto px-6 py-12">
        <div
          className="max-w-3xl mx-auto prose-communication"
          dangerouslySetInnerHTML={{ __html: communication.content }}
        />
      </div>
    </div>
  );
}

export function Footer({
  communication
}: {
  communication: TemplateB2B['templateCommunicationB2b'];
}) {
  return (
    <div className="text-white rounded-xl bg-communication object-cover object-center bg-top sm:bg-initial px-6">
      <div className="my-12">
        <div className="container mx-auto flex flex-col items-center">
          <div className="max-w-3xl">
            <div
              className="text-center font-serif text-3xl mb-6"
              dangerouslySetInnerHTML={{ __html: communication.titleFooter }}
            />
            <div
              className="text-center mb-6"
              dangerouslySetInnerHTML={{
                __html: communication.descriptionFooter
              }}
            />
            <div className="grid place-items-center">
              <a href={communication.ctaUrl} target="_blank" rel="noreferrer">
                <Button
                  label={communication.ctaFooter}
                  size="large"
                  variant="tertiary"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
