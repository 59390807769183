import { ANALYTICS } from 'constants/analytics';
import { useState } from 'react';

import { useUnguardedSearchParams } from 'hooks';

import { useTracking } from 'context';

import {
  QuestionFunnelProductType,
  QuestionFunnelStep
} from 'features/customer/funnels';
import {
  ContactQuestionCustomer,
  ContactQuestionPartner
} from 'features/customer/funnels/contact/question';
import { QuestionFormInput } from 'features/shared/forms/QuestionForm';

export default function QuestionContactPage() {
  const { analytics } = useTracking();

  const [questionContent, setQuestionContent] = useState<string>('');

  const [formStep, setFormStep] = useState<
    QuestionFunnelStep.Content | QuestionFunnelStep.Login
  >(QuestionFunnelStep.Content);

  const [productType, setProductType] = useState<
    QuestionFunnelProductType.Standard | QuestionFunnelProductType.Promo
  >(QuestionFunnelProductType.Standard);

  const partnerSlug = useUnguardedSearchParams({
    param: 'partnerSlug'
  });
  const distinctId = useUnguardedSearchParams({ param: 'distinctId' });

  const handleQuestionFormSubmit = (data: QuestionFormInput) => {
    if (partnerSlug) {
      analytics?.identify(distinctId);
      analytics?.track(ANALYTICS.FUNNEL_BUSINESS_QUESTION_CONTENT, {
        partnerSlug
      });
    }
    if (!partnerSlug) {
      // To implement in Next.js marketing website
      analytics?.identify(distinctId);
      analytics?.track(ANALYTICS.FUNNEL_CUSTOMER_QUESTION_CONTENT, {
        // Add origin - ie. fiches-pratiques slug for ex.
      });
    }
    setQuestionContent(data.question);
    setFormStep(QuestionFunnelStep.Login);
  };

  const handleChangeProductTypeSubmit = (
    currentProductType: QuestionFunnelProductType
  ) => {
    analytics?.track(ANALYTICS.FUNNEL_CUSTOMER_QUESTION_PROMO);
    setProductType(currentProductType);
  };

  const handleChangeStep = (
    step: QuestionFunnelStep.Content | QuestionFunnelStep.Login
  ) => {
    setFormStep(step);
  };

  if (partnerSlug) {
    return (
      <ContactQuestionPartner
        formStep={formStep}
        partnerSlug={partnerSlug}
        questionContent={questionContent}
        handleChangeStep={handleChangeStep}
        handleQuestionFormSubmit={handleQuestionFormSubmit}
      />
    );
  }

  return (
    <ContactQuestionCustomer
      formStep={formStep}
      questionContent={questionContent}
      productType={productType}
      handleChangeStep={handleChangeStep}
      handleQuestionFormSubmit={handleQuestionFormSubmit}
      handleChangeProductTypeSubmit={handleChangeProductTypeSubmit}
    />
  );
}
