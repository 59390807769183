import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loginPasswordMagicSchema } from 'schemas';

import { useSendCredentialsMutation } from 'api/shared/login';

import { useUnguardedSearchParams } from 'hooks';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { LoginPasswordFormInputs } from 'types/inputs';

export default function PasswordLoginForm() {
  const { mutate, isLoading } = useSendCredentialsMutation();

  const defaultEmail = useUnguardedSearchParams({ param: 'email' });

  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const handleError = useErrorHandler();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginPasswordFormInputs>({
    resolver: yupResolver(loginPasswordMagicSchema(tForm))
  });

  const onSubmit: SubmitHandler<LoginPasswordFormInputs> = async (formData) => {
    const { username, password } = formData;
    mutate(
      { username, password },
      {
        onError: (error: any) => {
          const isUnauthorizedError =
            error?.response?.status === 403 || error?.response?.status === 400;
          if (isUnauthorizedError) {
            setError('username', {
              message: `${t('error.occurred')}. ${t('error.checkCredentials')}`,
              type: 'focus'
            });
          } else {
            handleError(error);
          }
        }
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        type="email"
        fullWidth
        defaultValue={defaultEmail || ''}
        autofocus={!defaultEmail}
        placeholder={tForm('placeholder.email')}
        label={tForm('label.email')}
        name="username"
        register={register}
        error={errors.username}
      />
      <TextInputGroup
        type="password"
        fullWidth
        placeholder="**********"
        label={tForm('label.password')}
        autofocus={!!defaultEmail}
        name="password"
        register={register}
        error={errors.password}
      />
      <a
        className="font-medium text-left -mt-4 hover:underline text-purple-900 mb-1"
        href="/password/request"
      >
        {t('password.forgotten')}
      </a>
      <Button
        isLoading={isLoading}
        fullWidth
        submit
        size="medium"
        variant="primary"
        label={t('general.continue')}
      />
    </form>
  );
}
