import { BellIcon, PhoneIcon } from '@heroicons/react/outline';
import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useCustomerAccount, useLocale } from 'hooks';

import { Paragraph } from 'features/shared/body';

import { formatDateToIntl } from 'utils/date';
import { formatQuestionStatusi18nKey } from 'utils/question';

import NotificationCard from './NotificationCard';

function NotificationList() {
  const { locale } = useLocale();
  const { t } = useTranslation('customer');
  const { currentQuestions, futureConsultations } = useCustomerAccount();

  const totalNotifications =
    currentQuestions.length + futureConsultations.length;

  return (
    <div className="pb-6 mt-3">
      <Title className="flex gap-3 items-center" variant="h2">
        <BellIcon className="block h-5 w-5" aria-hidden="true" />
        {t('account.home.h2.notification')}
        {totalNotifications !== 0 && (
          <div className="rounded-full text-sm bg-purple-900 text-white px-2 ">
            {totalNotifications}
          </div>
        )}
      </Title>
      {totalNotifications === 0 && (
        <Paragraph
          className="!text-gray-700 line-clamp-3"
          text={t('account.notification.empty')}
        />
      )}
      <div className="flex flex-col gap-4">
        {currentQuestions?.map((question) => (
          <NavLink to={`/questions/${question.id}`}>
            <NotificationCard
              key={question.id}
              title={t('account.notification.question')}
              content={question.content}
              date={t('account.notification.askedThe', {
                date: formatDateToIntl(question.createdDate, 'long', locale)
              })}
              status={t(
                `account.questions.status.${formatQuestionStatusi18nKey(
                  question.status
                )}`
              )}
            />
          </NavLink>
        ))}
        {futureConsultations?.map((consultation) => {
          const lastAppointment = consultation.appointments.filter(
            (appointment) => !appointment.isProcessed
          )[0];

          return (
            <NotificationCard
              key={consultation.id}
              title={t('account.notification.consultation')}
              content={consultation.linkedQuestion?.content || ''}
              icon={<PhoneIcon className="block h-5 w-5" />}
              date={t('account.notification.appointmentWith', {
                name: `${lastAppointment?.lawyer?.firstName ?? ''} ${
                  lastAppointment?.lawyer?.lastName ?? ''
                }`
              })}
              status={t('account.notification.appointmentAt', {
                date: formatDateToIntl(
                  lastAppointment?.appointmentDate,
                  'long',
                  locale
                )
              })}
            />
          );
        })}
      </div>
    </div>
  );
}

export default NotificationList;
