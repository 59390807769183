import { Chip, Empty, Title } from 'components';
import { Pagination } from 'components/pagination';
import { QuestionsListSkeleton } from 'components/skeleton';
import { INTENT_QUESTION_SOURCES } from 'constants/analytics';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useQuestionsCseQuery } from 'api/customer/questions';

import { useCreateHref, useCustomer } from 'hooks';

import { QuestionCard } from 'features/customer/account/questions';

import { Customer } from 'types/customer';

interface QuestionsCseListProps {
  memberCseId: Customer['id'];
  defaultPage: number;
}

const QuestionsCseList = ({
  memberCseId,
  defaultPage = 1
}: QuestionsCseListProps) => {
  const [page, setPage] = useState(defaultPage);
  const { id } = useCustomer();
  const { href } = useCreateHref({
    path: '/account/ask',
    params: {
      intentQuestionSource: INTENT_QUESTION_SOURCES.QUESTIONS
    }
  });
  const { data, isLoading, refetch, isSuccess } = useQuestionsCseQuery({
    memberCseId,
    page
  });

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  const { t } = useTranslation('customer');

  const onRefetch = async (newPage: number) => setPage(newPage);

  return (
    <div>
      {isLoading && <QuestionsListSkeleton />}
      {isSuccess && data.questions.length ? (
        <div>
          <Title gutterBottom variant="h2">
            {t('account.questions.nbQuestionsAsked')}
            <Chip color="purple" className="text-lg py-1.5 ml-2">
              {data.pagination.totalItems}
            </Chip>
          </Title>
          <div className="grid grid-cols-1 gap-10">
            {data.questions.map((question) => (
              <NavLink
                to={`/questions/${question.id}`}
                key={question.id}
                state={{ previousPage: page, type: 'cse' }}
              >
                <QuestionCard
                  key={question.id}
                  question={question}
                  withCta={question.customer?.id === id}
                />
              </NavLink>
            ))}
            <Pagination
              onRefetch={onRefetch}
              pageCount={data.pagination.last}
            />
          </div>
        </div>
      ) : (
        <Empty
          to={href}
          imgSrc="/images/empty-questions.webp"
          imgAlt={t('account.questions.empty.alt')}
          linkLabel={t('account.questions.empty.cta')}
        />
      )}
    </div>
  );
};

export default QuestionsCseList;
