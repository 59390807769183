import { useTranslation } from 'react-i18next';

import { BnpParisbasIcon, LockIcon, StripeIcon } from 'features/shared/icons';

export default function SecuredBy() {
  const { t } = useTranslation('customer');
  return (
    <div className="flex gap-2 items-center justify-center">
      <div className="flex gap-1">
        <LockIcon />
        <span>{t('securedby')}</span>
      </div>
      <div className="flex gap-1">
        <BnpParisbasIcon className="w-24" />
        <StripeIcon className="w-12 h-12 text-black" />
      </div>
    </div>
  );
}
