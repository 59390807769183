import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'features/shared/buttons';
import { LayoutDefault } from 'features/shared/layout';

export interface NotFoundErrorPageProps {
  handleResetError?: ({ reload }: { reload: boolean }) => void;
}

export default function NotFoundErrorPage({
  handleResetError
}: NotFoundErrorPageProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('customer');

  const handleResetNotFoundError = () => {
    if (handleResetError) {
      handleResetError({ reload: false });
    } else {
      navigate('/');
    }
  };

  return (
    <LayoutDefault illustration={false} minimal>
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-purple-800 sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-600 sm:pl-6">
                <div className="pb-2 smd:pb-4">
                  <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl">
                    {t('error.404.title')}
                  </h1>
                </div>
                <p className="mt-1 text-base text-gray-700">
                  {t('error.404.description')}
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:pl-6">
                <Button
                  label={t('general.back')}
                  variant="primary"
                  size="large"
                  onClick={() => handleResetNotFoundError()}
                />
                <a className="w-full" href="mailto:service-client@ekie.co">
                  <Button
                    label={t('general.contactus')}
                    variant="secondary"
                    size="large"
                  />
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </LayoutDefault>
  );
}
