import { useTranslation } from 'react-i18next';

import { Button } from 'features/shared/buttons';

interface AnswerUnsatisfiedChoiceProps {
  onNextStepClick: () => void;
  onConfirmClick: () => void;
  description: string;
}

export default function AnswerUnsatisfiedChoice({
  onConfirmClick,
  onNextStepClick,
  description
}: AnswerUnsatisfiedChoiceProps) {
  const { t } = useTranslation('customer');

  return (
    <div>
      <div className="bg-black flex justify-center h-36 rounded-lg text-center">
        <img
          alt="additional request"
          className="max-h-full"
          src="/images/illu_additional_request.png"
        />
      </div>
      <div className="font-bold my-8">{description}</div>
      <div className="flex gap-4">
        <Button size="medium" onClick={onConfirmClick} variant="outline">
          {t('account.question.vote.choiceUnsatisfied.confirm')}
        </Button>
        <Button
          onClick={onNextStepClick}
          size="medium"
          className="flex-1 justify-center"
          variant="tertiary"
        >
          {t('account.question.vote.choiceUnsatisfied.additionalRequest')}
        </Button>
      </div>
    </div>
  );
}
