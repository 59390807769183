import { Customer } from 'types/customer';
import { NewOrder } from 'types/order';
import { Product } from 'types/product';

// FROM SPA TO API
export function formatNewOrderForApi(
  paymentProvider: NewOrder['paymentProvider'],
  customerId: Customer['id'],
  productId: Product['id']
) {
  const newOrder: NewOrder = {
    customerId,
    paymentProvider,
    items: [
      {
        productId,
        amountHt: 0,
        vat: 0
      }
    ],
    tags: [],
    paymentMethod: 'subscription'
  };

  return { newOrder };
}
