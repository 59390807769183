import { ChatAlt2Icon, ClockIcon, MailIcon } from '@heroicons/react/solid';
import { DEFAULT_REDIRECT_URL } from 'constants/customer';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  QuestionFunnelIndications,
  QuestionFunnelStep
} from 'features/customer/funnels';
import { Button } from 'features/shared/buttons';
import { ContainerCard } from 'features/shared/container';

export default function ConfirmationSuccessQuestion() {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleQuestionSuccess = () => {
    // Invalidate customer usage meter to get fresh data
    queryClient.invalidateQueries(['customer-usage-meter']);
    navigate(DEFAULT_REDIRECT_URL);
  };

  return (
    <>
      <QuestionFunnelIndications hideSteps step={QuestionFunnelStep.Success} />
      <ContainerCard>
        <div className="flex flex-col items-center gap-8">
          <div className="flex flex-col gap-2">
            {[
              {
                id: 1,
                title: t('funnel.question.success.1'),
                icon: <MailIcon className="w-6" />
              },
              {
                id: 2,
                title: t('funnel.question.success.2'),
                icon: <ClockIcon className="w-6" />
              },
              {
                id: 3,
                title: t('funnel.question.success.3'),
                icon: <ChatAlt2Icon className="w-6" />
              }
            ].map((perk) => (
              <div
                key={perk.id}
                className="flex gap-2 items-start md:items-center"
              >
                <div className="text-black">{perk.icon}</div>
                <div>
                  <span>{perk.title}</span>
                </div>
              </div>
            ))}
          </div>

          <Button
            label={t('funnel.question.success.cta')}
            variant="primary"
            size="large"
            onClick={() => handleQuestionSuccess()}
          />
        </div>
      </ContainerCard>
    </>
  );
}
