import { useLocation } from 'react-router-dom';

import { useTracking } from 'context';

import Button, { ButtonProps } from './Button';

interface TrackedButtonProps extends ButtonProps {
  name: string;
}
const TrackedButton = ({ name, onClick, ...props }: TrackedButtonProps) => {
  const { pathname } = useLocation();
  const { analytics } = useTracking();
  const prefix = pathname.substring(1).replaceAll('/', '_');

  return (
    <Button
      {...props}
      onClick={() => {
        analytics?.track(`${prefix}_${name}_clicked`);
        onClick && onClick();
      }}
    />
  );
};

export default TrackedButton;
