import { SVGProps } from 'react';

export default function CoinIcon({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2108_42347)">
        <path
          d="M9.75 11.3125C13.547 11.3125 16.625 9.92738 16.625 8.21875C16.625 6.51012 13.547 5.125 9.75 5.125C5.95304 5.125 2.875 6.51012 2.875 8.21875C2.875 9.92738 5.95304 11.3125 9.75 11.3125Z"
          stroke="black"
          strokeWidth="1.03125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.875 8.21875V11.6562C2.875 13.3664 5.95156 14.75 9.75 14.75C13.5484 14.75 16.625 13.3664 16.625 11.6562V8.21875"
          stroke="black"
          strokeWidth="1.03125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.625 9.31018C19.7617 9.60237 22.125 10.8485 22.125 12.3438C22.125 14.0539 19.0484 15.4375 15.25 15.4375C13.5656 15.4375 12.0187 15.1625 10.8242 14.7157"
          stroke="black"
          strokeWidth="1.03125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.375 14.6898V15.7812C8.375 17.4914 11.4516 18.875 15.25 18.875C19.0484 18.875 22.125 17.4914 22.125 15.7812V12.3438"
          stroke="black"
          strokeWidth="1.03125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 15.1797V18.6172"
          stroke="black"
          strokeWidth="1.03125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 11.0547V18.6172"
          stroke="black"
          strokeWidth="1.03125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2108_42347">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(1.5 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
