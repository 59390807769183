import type { Customer } from './customer';
import type { Lawyer } from './lawyer';
import type { Question } from './question';

export interface ApiAnswer {
  id: number;
  status: number;
  publishedDate: Date;
  lawyer: Lawyer;
  question?: Question;
  content: string;
  nbUpvotes: number;
  isReviewedByRequester: boolean;
  answerVotes?: AnswerVotesEntity[] | null;
  voteLink?: null;
  createdDate: Date;
  lastUpdatedDate?: Date;
  formalNotice: {
    id: string;
    status: 'accepted' | 'pending' | 'refused' | 'canceled_lawyer';
    createdAt: Date;
  } | null;
  formalNoticeProposal: boolean;
}

export enum AnswerStatus {
  PENDING = 0,
  DRAFT = 9,
  PUBLISHED = 10,
  REJECTED = 99
}

export interface Answer {
  id: number;
  status: AnswerStatus;
  publishedDate?: Date;
  lawyer: Lawyer | null;
  answerVotes: {
    createdDate: string;
    customer: Customer;
    note: number;
    reason: string | null;
  }[];
  content: string;
  createdDate: Date;
  lastUpdatedDate?: Date;
  answeredDate?: Date;
  // On legacy answers, the question is sometimes empty
  question?: Question;
  additionalRequest?: AdditionalRequest;
  formalNotice: {
    id: string;
    status: 'accepted' | 'pending' | 'refused' | 'canceled_lawyer';
    createdAt: Date;
  } | null;
  formalNoticeProposal: boolean;
}

export interface AdditionalRequest {
  id: string;
  createdAt: Date;
  answer: string | null;
  answerDeadLine: Date;
  content: string;
  answeredAt: Date | null;
}

export interface AnswerVotesEntity {
  createdDate: string;
  lastUpdatedDate?: null;
  answer: string;
  customer: Customer;
  note: number;
  reason: string;
  isRequester: boolean;
  id: string;
  upVote: boolean;
}
