import { DEFAULT_REDIRECT_URL } from 'constants/customer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'features/shared/buttons';
import { LayoutCard } from 'features/shared/layout';

export default function ConfirmationErrorCannotAskQuestion() {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();

  return (
    <LayoutCard
      title={t('funnel.question.error.cannotAskQuestion.title')}
      body={
        <div>
          <p className="text-base font-medium text-gray-800 pb-6">
            {t('funnel.question.error.cannotAskQuestion.paragraph')}
          </p>
          <Button
            fullWidth
            variant="primary"
            size="large"
            label={t('general.accessMyAccount.cta')}
            onClick={() => navigate(DEFAULT_REDIRECT_URL)}
          />
        </div>
      }
    />
  );
}
