import { privateAxios, publicAxios } from 'context/auth/auth.utils';

import { Answer } from 'types/answer';
import {
  AnswerVote,
  AppointmentVote,
  NewAnswerVote,
  NewUnauthenticatedAnswerVote
} from 'types/question';

export async function createAnswerVote(
  answerId: Answer['id'],
  newAnswerVote: NewAnswerVote
) {
  const res = await privateAxios.post(
    `/answers/${answerId}/answer-votes`,
    newAnswerVote
  );
  return res.data;
}

export async function getAnswerVotes(): Promise<AnswerVote[]> {
  const res = await privateAxios.get(`/me/answer-votes`);

  return res.data.answerVotes;
}

export async function getAppointmentVotes(): Promise<AppointmentVote[]> {
  const res = await privateAxios.get(`/me/appointment-votes`);

  return res.data.appointmentVotes;
}

export async function handleFormalNoticeProposal(
  relatedAnswerId: number,
  formalNoticeId: string,
  proposalResponse: 'accepted' | 'refused'
) {
  const res = await privateAxios.post(`/formal-notice/response-to-proposal`, {
    relatedAnswerId,
    formalNoticeId,
    proposalResponse
  });
  return res.data;
}

export async function createUnauthenticatedAnswerVote(
  answerId: Answer['id'],
  newAnswerVote: NewUnauthenticatedAnswerVote
) {
  const res = await publicAxios.post(
    `/answers/${answerId}/answer-votes`,
    newAnswerVote
  );

  return res.data;
}

export async function getAnswerVoteLink(
  voteLinkId: string
): Promise<{ id: string; answerId: number; customerId: number }> {
  const res = await publicAxios.get(`/answer-vote-links/${voteLinkId}`);
  return res.data;
}
