import { Section, Title } from 'components';
import { DEFAULT_REDIRECT_URL } from 'constants/customer';
import { CustomerUpdatePassword } from 'containers/profile';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from 'features/customer/account/content';

const UpdatePasswordPage = () => {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();

  return (
    <PageLayout>
      <Section gutterBottom>
        <Title gutterBottom variant="h2">
          {t('password.update.title')}
        </Title>
        <div className="flex w-full flex-col space-y-2 max-w-[720px]">
          <p>{t('password.update.indications.p')}</p>
          <CustomerUpdatePassword
            onSuccess={() => navigate(DEFAULT_REDIRECT_URL)}
          />
        </div>
      </Section>
    </PageLayout>
  );
};

export default UpdatePasswordPage;
