import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCustomer } from 'hooks';

import { SectionLine } from 'features/customer/account/dashboard';
import { Button } from 'features/shared/buttons';

interface CustomerProfileProps {
  onEditClick: () => void;
}

function CustomerProfile({ onEditClick }: CustomerProfileProps) {
  const navigate = useNavigate();
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('customer');
  const { firstName, lastName, phoneCellphone, ...customer } = useCustomer();

  const handleUpdatePasswordClick = () => navigate('/account/password-new');

  return (
    <>
      <div className="flex flex-col space-y-6">
        <SectionLine
          title={tForm('label.lastName')}
          value={
            !firstName || !lastName
              ? t('general.undefined')
              : `${firstName} ${lastName}`
          }
        />
        <SectionLine
          title={tForm('label.phone')}
          value={!phoneCellphone ? t('general.undefined') : phoneCellphone}
        />
        <SectionLine
          title={t('account.profile.email.label')}
          value={customer.email}
        />
        {customer.isB2b && (
          <SectionLine
            title={t('account.profile.personalEmail.label')}
            value={customer.secondaryEmail || t('general.undefined')}
          />
        )}
      </div>
      <div className="pt-5">
        <div className="flex justify-end gap-4">
          <Button
            onClick={handleUpdatePasswordClick}
            variant="secondary"
            size="small"
            label={t('password.update.cta')}
          />
          <Button
            onClick={onEditClick}
            variant="secondary"
            size="small"
            label={t('account.profile.button.update')}
          />
        </div>
      </div>
    </>
  );
}

export default CustomerProfile;
