import { useQuery } from 'react-query';
import { graphQLRequest } from 'services/dato';

import { AllUserReviews } from 'types/cms';

const GET_USER_REVIEW = `
  query AllUserReviews($skip: IntType = 0, $locale: SiteLocale) {
    allUserReviews(first: 1, skip: $skip locale: $locale) {
      id
      note
      content
      fullName
    }
  }
`;

export function useGetRandomUserReview() {
  return useQuery(
    [`random-user-review`],
    (): Promise<AllUserReviews | null> =>
      graphQLRequest(GET_USER_REVIEW, { skip: Math.floor(Math.random() * 50) })
  );
}
