import { useQuery } from 'react-query';

import { getProductBySkuCode } from './api';

export function useProductQuery({ skuCode }: { skuCode: string | undefined }) {
  return useQuery(
    ['product', skuCode],
    () => getProductBySkuCode(skuCode as string),
    {
      enabled: !!skuCode
    }
  );
}
