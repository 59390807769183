import { Title } from 'components';
import { useTranslation } from 'react-i18next';

import { VoteButton } from 'features/customer/vote';
import { LoadingSpinner } from 'features/shared/loading';

import { SetState } from 'types/core';
import { NewAnswerVote } from 'types/question';
import { UnsatisfiedVotingValue, VotingStatus } from 'types/vote';

export interface AnswerVoteFormProps {
  isLoading: boolean;
  handleSatisfiedMutate: (note: NewAnswerVote['note']) => void;
  setAnswerVotingStatus: SetState<VotingStatus>;
  setUnsatisfiedVotingValue: SetState<UnsatisfiedVotingValue | undefined>;
  canCreateAdditionalRequest?: boolean;
}

function AnswerVoteForm({
  isLoading,
  handleSatisfiedMutate,
  setAnswerVotingStatus,
  setUnsatisfiedVotingValue,
  canCreateAdditionalRequest = false
}: AnswerVoteFormProps) {
  const { t } = useTranslation('customer');

  const handleUnsatisfied = (note: UnsatisfiedVotingValue) => {
    setUnsatisfiedVotingValue(note);
    setAnswerVotingStatus(
      canCreateAdditionalRequest ? 'unsatisfied-choice' : 'unsatisfied'
    );
  };

  return (
    <>
      <Title variant="h3">{t('account.question.vote.initial.title')}</Title>
      <div className="mt-2">
        {isLoading ? (
          <LoadingSpinner purple />
        ) : (
          <>
            <p className="text-md text-left font-medium">
              <span>{t('account.question.vote.initial.paragraph.1')}</span>{' '}
              <span>{t('account.question.vote.initial.paragraph.2')}</span>
            </p>
            <div className="py-3">
              <div className="grid grid-rows-vote max-w-fit">
                <div className="grid grid-cols-5 gap-4 w-full">
                  <VoteButton
                    vote={1}
                    handleClick={() => handleUnsatisfied(1)}
                  />
                  <VoteButton
                    vote={2}
                    handleClick={() => handleUnsatisfied(2)}
                  />
                  <VoteButton
                    vote={3}
                    handleClick={() => handleUnsatisfied(3)}
                  />
                  <VoteButton
                    vote={4}
                    handleClick={() => handleSatisfiedMutate(4)}
                  />
                  <VoteButton
                    vote={5}
                    handleClick={() => handleSatisfiedMutate(5)}
                  />
                </div>
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-700">
              {t('account.question.vote.initial.description')}
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default AnswerVoteForm;
