import clsx from 'clsx';
import { Divider } from 'components';
import { GoogleAuth } from 'containers/authentication';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useTriggerEvent, useUnguardedSearchParams } from 'hooks';

import { LayoutCard } from 'features/shared/layout';

import { EmailForm } from './form/email';

export default function MagicLoginCard() {
  const navigate = useNavigate();
  const { t } = useTranslation('customer');
  const isActivation =
    useUnguardedSearchParams({ param: 'activate' }) === 'true';
  const submitted = useUnguardedSearchParams({ param: 'submitted' });

  useTriggerEvent({
    eventName: 'funnel_business_activation_start',
    isDisabled: !isActivation
  });

  const getDescriptionLabel = () => {
    if (submitted) return 'general.emailSent.p';
    if (isActivation) return 'login.description.activate';

    return 'login.description.magic';
  };
  const handleSuccess = () => navigate('/login?submitted=true');

  return (
    <div>
      <LayoutCard
        title={
          isActivation ? t('login.title.activate') : t('login.title.default')
        }
        body={
          <div className="grid grid-cols-1 divide-y divide-gray-600 sm:mx-8">
            <div className="w-full">
              <p
                className={clsx(
                  'text-base font-medium text-gray-800',
                  !submitted && 'pb-8'
                )}
              >
                {t(getDescriptionLabel())}
              </p>
              {!submitted && (
                <div className="grid grid-cols-1 divide-y">
                  <div className="w-full">
                    <EmailForm handleSuccess={handleSuccess} />
                    <Link
                      className="border border-black block rounded mt-4 w-full text-black font-medium px-3 py-2 text-sm sm:px-4 sm:py-2 sm:text-base hover:bg-black hover:text-white"
                      to="/login?password=true"
                    >
                      {t('login.switch.password')}
                    </Link>
                  </div>
                  <Divider text="ou" />
                  <GoogleAuth />
                  <div className="flex flex-col -mx-14 pb-5 -mb-6 mt-6 bg-purple-200 text-center">
                    <div className="text-lg font-serif my-5 font-medium">
                      {t('login.redirect.title')}
                    </div>
                    <div>
                      <Trans
                        ns="customer"
                        i18nKey="login.link.onboarding"
                        components={{
                          1: (
                            <Link
                              className="text-purple-800 underline fontbold"
                              to="/onboarding/email"
                            />
                          )
                        }}
                      />
                    </div>
                    <div className="py-1">
                      <Trans
                        ns="customer"
                        i18nKey="login.link.offers"
                        components={{
                          1: (
                            // eslint-disable-next-line jsx-a11y/control-has-associated-label
                            <a
                              href={`${process.env.REACT_APP_EKIE_URL}/pricing/personal`}
                              target="_blank"
                              rel="noreferrer nofollow"
                              className="text-purple-800 underline fontbold"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}
