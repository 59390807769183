import { ANALYTICS } from 'constants/analytics';
import { useTranslation } from 'react-i18next';

import { useTracking } from 'context';

import {
  QuestionFunnelIndications,
  QuestionFunnelStep
} from 'features/customer/funnels';
import { OfferDetails } from 'features/customer/funnels/contact/subscribe';
import { Button } from 'features/shared/buttons';
import { ContainerCard } from 'features/shared/container';

import { SetState } from 'types/core';

export default function ConfirmationCustomerSubscription({
  localProduct,
  setIsOfferConfirmed
}: {
  localProduct: any;
  setIsOfferConfirmed: SetState<boolean>;
}) {
  const { analytics } = useTracking();
  const { t } = useTranslation('customer');

  const handleTrackConfirmOffer = () =>
    analytics?.track(ANALYTICS.FUNNEL_CUSTOMER_QUESTION_SUBSCRIPTION_CONFIRM);

  const handleConfirmOffer = () => {
    // Track that customer confirmed offer
    handleTrackConfirmOffer();
    // Set offer to confirmed
    setIsOfferConfirmed(true);
  };

  return (
    <>
      <QuestionFunnelIndications
        step={QuestionFunnelStep.ConfirmationInitial}
      />
      <ContainerCard>
        <div className="flex flex-col gap-8 lg:gap-6">
          <OfferDetails localProduct={localProduct} />
          <div className="grid place-items-center">
            <Button
              variant="primary"
              size="large"
              onClick={handleConfirmOffer}
              label={t('general.continue')}
            />
          </div>
        </div>
      </ContainerCard>
    </>
  );
}
