import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ConsultationAppointmentVotePage,
  NotFoundErrorPage,
  NpsVotePage,
  PrivateContentPage,
  QuestionAnswerVote,
  QuestionContactPage,
  SubscribeContactPage,
  SubscribePremiumPlusPage
} from 'pages/customer';
import {
  OnboardingEmailPage,
  ProfileCompletionPage
} from 'pages/customer/onboarding';
import { AuthenticatePage } from 'pages/shared/authenticate';
import {
  LawyerPendingApprovalPage,
  LawyerSubmissionPage
} from 'pages/shared/lawyer';
import { LoginPage } from 'pages/shared/login';
import {
  NewPasswordPage,
  RequestResetPasswordPage
} from 'pages/shared/reset-password';

import { PUBLIC_DEFAULT_REDIRECT_URL } from './constants';

const PublicRouter = () => (
  <Routes>
    <Route path="login" element={<LoginPage />} />
    <Route path="authenticate" element={<AuthenticatePage />} />
    <Route
      path="questions/vote/link/:voteLinkId"
      element={<QuestionAnswerVote />}
    />
    <Route
      path="consultations/vote/link/:voteLinkId"
      element={<ConsultationAppointmentVotePage />}
    />
    <Route path="funnel">
      <Route path="subscribe">
        <Route path="contact" element={<SubscribeContactPage />} />
        <Route path="premium-plus" element={<SubscribePremiumPlusPage />} />
      </Route>
      <Route path="question/contact" element={<QuestionContactPage />} />
    </Route>
    <Route path="nps/vote/:accountId" element={<NpsVotePage />} />
    <Route path="lawyer">
      <Route path="submission" element={<LawyerSubmissionPage />} />
      <Route path="pending" element={<LawyerPendingApprovalPage />} />
    </Route>
    <Route path="onboarding">
      <Route path="email" element={<OnboardingEmailPage />} />
      <Route path="profile" element={<ProfileCompletionPage />} />
    </Route>
    <Route path="password">
      <Route path="request" element={<RequestResetPasswordPage />} />
      <Route path="new" element={<NewPasswordPage />} />
    </Route>
    <Route path="content/:slug" element={<PrivateContentPage />} />
    <Route path="*" element={<NotFoundErrorPage />} />
    <Route
      path=""
      element={<Navigate to={PUBLIC_DEFAULT_REDIRECT_URL} replace />}
    />
    <Route
      path="account/*"
      element={<Navigate to={PUBLIC_DEFAULT_REDIRECT_URL} replace />}
    />
  </Routes>
);

export default PublicRouter;
