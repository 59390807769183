import { LocalProduct } from 'types/product';
import { TFunctionTranslationCustomer } from 'types/translation';

export const localProducts = (
  t: TFunctionTranslationCustomer
): LocalProduct[] => [
  {
    name: 'Legal Care Standard',
    offeredByPartner: {
      id: 1
    },
    isDiscounted: false,
    id: 101,
    isPayable: true,
    isPremium: true,
    skuCode: process.env.REACT_APP_PRODUCT_STANDARD_SKU_CODE as string,
    price: '9,90',
    perks: [
      {
        id: 1,
        title: t('funnel.subscription.customer.all.perk.1')
      },
      {
        id: 2,
        title: t('funnel.subscription.customer.all.perk.4')
      },
      {
        id: 3,
        title: t('funnel.subscription.customer.all.perk.2')
      },
      {
        id: 4,
        title: t('funnel.subscription.customer.all.perk.3')
      }
    ]
  },
  {
    name: 'Legal Care Standard',
    isDiscounted: false,
    id: 93,
    isPayable: true,
    isPremium: true,
    skuCode: process.env.REACT_APP_PRODUCT_STANDARD_PROMO_SKU_CODE as string,
    price: '9,90',
    offeredByPartner: {
      id: 1
    },
    perks: [
      {
        id: 1,
        title: t('funnel.subscription.customer.all.perk.1')
      },
      {
        id: 2,
        title: t('funnel.subscription.customer.all.perk.4')
      },
      {
        id: 3,
        title: t('funnel.subscription.customer.all.perk.2')
      },
      {
        id: 4,
        title: t('funnel.subscription.customer.all.perk.3')
      }
    ]
  },
  {
    name: 'Legal Care Premium',
    offeredByPartner: {
      id: 1
    },
    isDiscounted: false,
    id: 102,
    isPayable: true,
    isPremium: true,
    skuCode: process.env.REACT_APP_PRODUCT_PREMIUM_SKU_CODE as string,
    price: '14,90',
    perks: [
      {
        id: 1,
        title: t('funnel.subscription.customer.all.perk.1')
      },
      {
        id: 2,
        title: t('funnel.subscription.customer.all.perk.2')
      },
      {
        id: 3,
        title: t('funnel.subscription.customer.all.perk.3')
      },
      {
        id: 4,
        title: t('funnel.subscription.customer.premium.perk.1')
      }
    ]
  },
  {
    name: 'Legal Care Standard (pour Tempeos)',
    offeredByPartner: {
      id: 1
    },
    isDiscounted: true,
    id: 103,
    isPayable: true,
    isPremium: true,
    skuCode: 'TEMPEOS',
    price: '8.90',
    originalPrice: '9.90',
    perks: [
      {
        id: 1,
        title: t('funnel.subscription.customer.all.perk.1')
      },
      {
        id: 2,
        title: t('funnel.subscription.customer.all.perk.2')
      },
      {
        id: 3,
        title: t('funnel.subscription.customer.all.perk.3')
      }
    ]
  },
  {
    name: 'Legal Care Premium +',
    offeredByPartner: {
      id: 1
    },
    isDiscounted: false,
    id: 104,
    isPayable: true,
    isPremium: true,
    skuCode: process.env.REACT_APP_PREMIUMPLUS_SKU_CODE as string,
    price: '19,90',
    perks: [
      {
        id: 1,
        title: 'Assistance juridique illimitée'
      },
      {
        id: 2,
        title: 'Consultation par téléphone en illimitée'
      },
      {
        id: 3,
        title: 'Assurance protection judiciaire'
      }
    ]
  },
  {
    name: 'Legal Care Standard via Bimpli',
    offeredByPartner: {
      id: 1
    },
    isDiscounted: true,
    id: 105,
    isPayable: true,
    isPremium: true,
    skuCode: 'BIMPLI',
    price: '7.90',
    originalPrice: '9.90',
    perks: [
      {
        id: 1,
        title: t('funnel.subscription.customer.all.perk.1')
      },
      {
        id: 2,
        title: t('funnel.subscription.customer.all.perk.2')
      },
      {
        id: 3,
        title: t('funnel.subscription.customer.all.perk.3')
      }
    ]
  }
];
