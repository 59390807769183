import { HeroBanner, Section, Title } from 'components';
import HomeFooter from 'components/HomeFooter';
import { Fade } from 'components/transition';
import {
  LastArticlesList,
  LastCSEArticles,
  SuggestedArticles
} from 'containers/articles';
import LastArticlesRead from 'containers/articles/LastArticlesRead';
import { CommunicationList } from 'containers/communication';
import { NotificationList } from 'containers/notification';
import ResumeQuestions from 'containers/question/ResumeQuestions';
import RandomUserReview from 'containers/user-review/RandomUserReview';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomer, useCustomerAccount } from 'hooks';

import { useAuth } from 'context';

import { PageLayout } from 'features/customer/account/content';
import { ModalCompleteProfile } from 'features/customer/account/dashboard/modal';
import {
  SKIP_ALPHABET_BANNER,
  SKIP_PROFILE_COMPLETION
} from 'features/customer/profile/constants';

function HomePage() {
  const { t } = useTranslation('customer');
  const { firstName, lastName, phoneCellphone, isB2b } = useCustomer();
  const { currentQuestions } = useCustomerAccount();
  const { roles } = useAuth();
  const isCse = roles.includes('ROLE_CSE_MEMBER');

  const skipProfileCompletion = localStorage.getItem(SKIP_PROFILE_COMPLETION);
  const skipAlphabetBanner = localStorage.getItem(SKIP_ALPHABET_BANNER);

  const isProfileCompleted = !!(firstName && lastName && phoneCellphone);

  const [isModalCompleteprofileOpen, setIsModalCompleteProfileOpen] =
    useState<boolean>(!isProfileCompleted && !skipProfileCompletion);

  return (
    <PageLayout extra={<NotificationList />}>
      <Fade>
        {!skipAlphabetBanner && (
          <HeroBanner
            title={t('account.home.h2.alphabet')}
            to="/account/resources/alphabet"
            label={t('account.home.h2.alphabet.link')}
            imgSrc="/images/illu_alphabet.png"
            onClick={() => localStorage.setItem(SKIP_ALPHABET_BANNER, 'true')}
          />
        )}
        {currentQuestions.length > 0 && (
          <Section gutterBottom className="!py-3" color="white">
            <ResumeQuestions />
          </Section>
        )}
        <Section color="white" gutterBottom>
          <Title className="ml-1.5" variant="h2">
            {t('account.home.h2.articles')}
          </Title>
          <LastArticlesList />
          <RandomUserReview />
          <SuggestedArticles />
          <LastArticlesRead />
          {isCse && (
            <>
              <Title className="ml-1.5" variant="h2">
                {t('account.home.h2.cse')}
              </Title>
              <LastCSEArticles />
            </>
          )}
          {isB2b && (
            <>
              <Title className="ml-1.5" variant="h2">
                {t('account.home.h2.communication')}
              </Title>
              <CommunicationList />
            </>
          )}
        </Section>
        <Section color="white">
          <HomeFooter />
        </Section>
        <ModalCompleteProfile
          isModalOpen={isModalCompleteprofileOpen}
          setIsModalOpen={setIsModalCompleteProfileOpen}
        />
      </Fade>
    </PageLayout>
  );
}

export default HomePage;
