import { Title } from 'components';
import { addDays, format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CoinIcon, GiftIcon } from 'features/shared/icons';

import { LocalProduct } from 'types/product';

import OfferPerks from './OfferPerks';

interface OfferDetailsProps {
  localProduct: LocalProduct;
}

export default function OfferDetails({ localProduct }: OfferDetailsProps) {
  const { t } = useTranslation('customer');

  const newFormattedDate = useMemo(() => {
    const today = new Date();
    const dateIn30Days = addDays(today, 30);
    return format(new Date(dateIn30Days), 'dd/MM/yyyy');
  }, []);

  const convertPriceToNumber = (value: string) => {
    const formatted = parseFloat(value);
    if (formatted) return formatted;
    return 0;
  };

  return (
    <div>
      <div className="pb-6 flex flex-col md:flex-row md:justify-between md:items-center">
        <div className="pb-2 md:pb-0">
          <div className="pb-1">
            <Title variant="h3">{localProduct.name}</Title>
          </div>
        </div>
      </div>
      <OfferPerks perks={localProduct.perks} />
      <div className="flex h-px bg-[#CCCCCC] w-full my-6" />

      {process.env.REACT_APP_PRODUCT_STANDARD_PROMO_SKU_CODE !==
        localProduct.skuCode && (
        <div className="w-full flex flex-col">
          <div className="flex flex-row items-start">
            <CoinIcon
              className="h-6 w-6 min-w-6 min-h-6 stroke-black font-light mr-3.5 hidden sm:block"
              aria-hidden="true"
            />
            <div className="flex flex-col">
              <p className="w-full font-sans text-left text-base text-gray-900 mb-1">
                <span className="font-bold">
                  {t('funnel.subscription.offer.perMonth', {
                    total: `${localProduct.price}€`
                  })}
                  {` `}
                  {localProduct.price &&
                    localProduct.originalPrice &&
                    convertPriceToNumber(localProduct.price) <
                      convertPriceToNumber(
                        localProduct.originalPrice as any
                      ) && (
                      <span>
                        {t('funnel.subscription.offer.instead')}
                        {` ${localProduct.originalPrice}€`}
                      </span>
                    )}
                </span>
              </p>
              <span className="text-xs md:text-sm font-light">
                {localProduct.skuCode ===
                  process.env.REACT_APP_PRODUCT_STANDARD_SKU_CODE ||
                localProduct.isDiscounted ? (
                  <>
                    {t('funnel.subscription.offer.noCommitment')}.{' '}
                    {t('funnel.subscription.offer.withoutNotice')}
                  </>
                ) : (
                  <>{t('funnel.subscription.offer.engagement')}. </>
                )}
              </span>
            </div>
          </div>
        </div>
      )}

      {process.env.REACT_APP_PRODUCT_STANDARD_PROMO_SKU_CODE ===
        localProduct.skuCode && (
        <div className="w-full flex flex-col">
          <div className="flex flex-row items-start mb-[14px]">
            <GiftIcon
              className="h-6 w-6 min-w-6 min-h-6 stroke-black font-light mr-3.5 hidden sm:block"
              aria-hidden="true"
            />
            <p className="w-full font-sans text-left text-base text-gray-900">
              <span className="font-bold">
                {t('funnel.subscription.offer.offer')}
              </span>
              <span>{t('funnel.subscription.offer.company')}</span>
            </p>
          </div>

          <div className="flex flex-row items-start">
            <CoinIcon
              className="h-6 w-6 min-w-6 min-h-6 stroke-black font-light mr-3.5 hidden sm:block"
              aria-hidden="true"
            />
            <div className="flex flex-col">
              <p className="w-full font-sans text-left text-base text-gray-900 mb-1">
                <span className="font-bold">
                  {t('funnel.subscription.offer.prelevement')}{' '}
                  {localProduct.price}€{' '}
                </span>
                <span>
                  {'le '}
                  {newFormattedDate}
                </span>
              </p>
              <span className="text-xs md:text-sm font-light">
                {t('funnel.subscription.offer.noCommitment')}.{' '}
                {t('funnel.subscription.offer.withoutNotice')}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
