import { yupResolver } from '@hookform/resolvers/yup';
import { ANALYTICS } from 'constants/analytics';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { finishProfileSchema } from 'schemas';

import { useUpdateCustomerMutation } from 'api/customer/mutations.index';

import { useCustomer } from 'hooks';

import { useTracking } from 'context';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { SetState } from 'types/core';
import { CompleteProfileFormInputs } from 'types/inputs';

import { SKIP_PROFILE_COMPLETION } from './constants';

export default function CompleteProfileForm({
  setIsModalOpen
}: {
  setIsModalOpen: SetState<boolean>;
}) {
  const { analytics } = useTracking();
  const queryClient = useQueryClient();

  const { id: customerId, firstName, lastName, phoneCellphone } = useCustomer();

  const { mutate, isLoading } = useUpdateCustomerMutation({ customerId });

  const { t } = useTranslation('customer');

  const handleError = useErrorHandler();

  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CompleteProfileFormInputs>({
    resolver: yupResolver(finishProfileSchema(tForm))
  });

  const onSubmit: SubmitHandler<CompleteProfileFormInputs> = async (
    formData
  ) => {
    const {
      firstName: newFirstName,
      lastName: newLastName,
      phoneCellphone: newPhoneCellphone
    } = formData;
    mutate(
      {
        firstName: newFirstName,
        lastName: newLastName,
        phoneCellphone: newPhoneCellphone
      },
      {
        onSuccess: () => {
          // Refetch customer with latest data by invalidating the query
          queryClient.invalidateQueries(['customer']);
          // Remove item in local storage if user skipped it before
          localStorage.removeItem(SKIP_PROFILE_COMPLETION);
          // Send event that user completed profile
          analytics?.track(ANALYTICS.FINISH_PROFILE_COMPLETE);
          // Close the modal
          setIsModalOpen(false);
        },
        onError: (error) => handleError(error)
      }
    );
  };

  const handleSkipCompleteProfile = () => {
    // Store in local storage that the user has skipped the finish profile
    localStorage.setItem(SKIP_PROFILE_COMPLETION, 'true');
    // Send event that user skipped profile completion
    analytics?.track(ANALYTICS.FINISH_PROFILE_SKIP);
    // Close the modal
    setIsModalOpen(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <div>
        <TextInputGroup
          autofocus
          name="firstName"
          label={tForm('label.firstName')}
          register={register}
          placeholder={tForm('placeholder.firstName')}
          defaultValue={firstName}
          error={errors.firstName}
          disabled={isLoading}
        />
      </div>
      <div>
        <TextInputGroup
          name="lastName"
          label={tForm('label.lastName')}
          register={register}
          placeholder={tForm('placeholder.lastName')}
          defaultValue={lastName}
          error={errors.lastName}
          disabled={isLoading}
        />
      </div>
      <div>
        <TextInputGroup
          name="phoneCellphone"
          label={tForm('label.phone')}
          register={register}
          placeholder="+33601010101"
          defaultValue={phoneCellphone}
          error={errors.phoneCellphone}
          disabled={isLoading}
        />
      </div>
      <div className="flex justify-between items-center">
        <div>
          <Link
            to="#"
            onClick={handleSkipCompleteProfile}
            className="cursor-pointer text-gray-700 hover:underline"
          >
            {t('general.skip')}
          </Link>
        </div>
        <Button
          isLoading={isLoading}
          submit
          size="medium"
          variant="primary"
          label={t('general.continue')}
        />
      </div>
    </form>
  );
}
