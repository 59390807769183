import { publicAxios } from 'context/auth/auth.utils';

import { Partner } from 'types/partner';

import { formatPartnerFromApi } from './formatting';

export async function getPartnerFromApi(partnerSlug: string): Promise<Partner> {
  const res = await publicAxios.get(`/partner-logo/${partnerSlug}`);
  const { data } = res;

  const partner = formatPartnerFromApi(data);

  return partner;
}
