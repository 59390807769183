import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { AvatarIcon } from 'features/shared/icons';

import { formatDateToIntl, getDateAsHhMm } from 'utils/date';

import { AdditionalRequest } from 'types/answer';
import { Customer } from 'types/customer';

interface AdditionalRequestCardProps {
  additionalRequest: AdditionalRequest;
  customer: Customer;
}

export default function QuestionCard({
  customer,
  additionalRequest
}: AdditionalRequestCardProps) {
  const { t } = useTranslation('lawyer');

  const hasFullName = useMemo((): boolean => {
    if (customer.firstName && customer.lastName) return true;
    return false;
  }, [customer]);

  const customerName = useMemo(() => {
    if (hasFullName) {
      return `${customer!.firstName} ${customer!.lastName}`;
    }
    return t('general.ekieClient');
  }, [hasFullName, customer, t]);

  const formattedCreatedDateLabel: string = useMemo(() => {
    const day = formatDateToIntl(additionalRequest.createdAt);
    const time = getDateAsHhMm(additionalRequest.createdAt);
    return `${day} à ${time}`;
  }, [additionalRequest.createdAt]);

  return (
    <div className="bg-white p-6 lg:px-10 lg:py-8 rounded shadow-base">
      <div className="mb-6 border-b border-gray-500">
        <div className="flex justify-between items-center mb-6">
          <div className="flex gap-4 items-center">
            <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-400">
              <AvatarIcon />
            </span>
            <h3 className="text-lg font-sans font-bold">{customerName}</h3>
          </div>
          <span className="text-gray-700 text-sm">
            {formattedCreatedDateLabel}
          </span>
        </div>
      </div>
      <SanitizedContent content={additionalRequest.content} />
    </div>
  );
}
