import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useCustomerAccount, useLocale } from 'hooks';

import { formatDateToIntl } from 'utils/date';

const ResumeQuestions = () => {
  const { locale } = useLocale();
  const { t } = useTranslation('customer');
  const { currentQuestions } = useCustomerAccount();

  const [lastQuestion] = currentQuestions;

  return (
    <div className="flex justify-between">
      {t('account.home.resume.question', {
        askedAt: formatDateToIntl(lastQuestion.createdDate, 'long', locale)
      })}
      <NavLink
        className="text-purple-900 underline underline-offset-4	"
        to={`/questions/${lastQuestion.id}`}
      >
        Voir la question
      </NavLink>
    </div>
  );
};

export default ResumeQuestions;
