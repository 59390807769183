import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  useCreateQuestionMutation,
  useQuestionsQuery
} from 'api/customer/questions';

import { useCustomer } from 'hooks';

import { useAuth } from 'context';

import { QuestionForm } from 'features/shared/forms';
import { QuestionFormInput } from 'features/shared/forms/QuestionForm';
import Toast from 'features/shared/toasts/Toast';

import { Subscription } from 'types/subscription';

interface CreateQuestionProps {
  subscriptionId: Subscription['id'];
}

const CreateQuestion = ({ subscriptionId }: CreateQuestionProps) => {
  const queryClient = useQueryClient();
  const { roles } = useAuth();
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const { id: customerId } = useCustomer();
  const { refetch } = useQuestionsQuery({ customerId });
  const { mutate, isLoading } = useCreateQuestionMutation();

  const handleQuestionFormSubmit = (data: QuestionFormInput) => {
    mutate(
      {
        customerId,
        subscriptionId,
        questionContent: data.question,
        source: data.source
      },
      {
        onSuccess: (newQuestion) => {
          refetch();
          navigate(`/questions/${newQuestion.id}`);
          queryClient.invalidateQueries(['customer-usage-meter']);
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.ask.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        }
      }
    );
  };

  return (
    <QuestionForm
      ctaLabel={t('account.ask.cta')}
      personalOnly={!roles.includes('ROLE_CSE_MEMBER')}
      ctaIsLoading={isLoading}
      handleQuestionFormSubmit={handleQuestionFormSubmit}
    />
  );
};

export default CreateQuestion;
