import { LayoutCard, LayoutDefault } from 'features/shared/layout';

export default function LawyerPendingApprovalPage() {
  return (
    <LayoutDefault minimal>
      <div>
        <LayoutCard
          title="Merci pour votre inscription"
          body={
            <div className="w-full">
              <p>
                L'équipe légale d'Ekie a été notifiée et effectue une
                vérification d'identité avant que vous puissiez accéder à la
                plateforme. <br />
                Si votre compte n'a pas été validé après 48h, merci de contacter
                notre service client.
              </p>
            </div>
          }
        />
      </div>
    </LayoutDefault>
  );
}
