import { privateAxios } from 'context/auth/auth.utils';

import { Customer } from 'types/customer';
import { ApiOrder, NewOrder } from 'types/order';
import { Product } from 'types/product';

import { formatNewOrderForApi } from './formatting';

export async function createOrder(
  paymentProvider: NewOrder['paymentProvider'],
  customerId: Customer['id'],
  productId: Product['id']
): Promise<ApiOrder> {
  const { newOrder } = formatNewOrderForApi(
    paymentProvider,
    customerId,
    productId
  );
  const res = await privateAxios.post(`/orders`, newOrder);
  return res.data;
}
