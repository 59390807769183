import { Combobox } from '@headlessui/react';
import { ChevronDownIcon, SelectorIcon } from '@heroicons/react/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { Title } from 'components';
import { isString } from 'lodash';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { newLawyerSchema } from 'schemas';

import { useCreateLawyerRevendication } from 'api/lawyer/mutations.index';

import {
  DEFAULT_ADDRESS_PLACEHOLDER,
  DEFAULT_CITY_PLACEHOLDER,
  DEFAULT_LANGUAGES_PLACEHOLDER,
  DEFAULT_YEARPICKER_PLACEHOLDER
} from 'features/customer/profile/constants';
import { Button } from 'features/shared/buttons';
import { CheckboxInputGroup, TextInputGroup } from 'features/shared/forms';
import { LoadingSpinner } from 'features/shared/loading';

import { LanguageType, languages } from 'utils/languages';

import { SetState } from 'types/core';
import { NewLawyerFormInputs } from 'types/inputs';

import { YearPicker } from '../../picker/YearPicker';
import { AutocompleteSearch } from '../../select';
import { AutocompleteTags } from '../../select/AutocompleteTags';

export default function RevendicationForm({
  query,
  setQuery,
  isLoading,
  cities
}: {
  query: string;
  setQuery: SetState<string>;
  isLoading: boolean;
  cities: { id: number; name: string; zipCode: string }[];
}) {
  const navigate = useNavigate();
  const { mutate } = useCreateLawyerRevendication();

  const [selectedVille, setSelectedVille] = useState<{
    id: number;
    name: string;
    zipCode: string;
  }>();

  const [selectedLanguages, setSelectedLanguages] = useState<LanguageType[]>(
    []
  );

  const [searchParams] = useSearchParams();

  const { t } = useTranslation(['lawyer', 'form']);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue
  } = useForm<NewLawyerFormInputs>({
    resolver: zodResolver(newLawyerSchema(t)),
    reValidateMode: 'onChange'
  });

  const onSubmit = (values: NewLawyerFormInputs) =>
    mutate(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        officeAddress: {
          addressLine: values.officeAddress,
          city: values.city.id
        },
        phone: {
          mobile: values.phoneMobile,
          landline: values.phoneHome
        },
        civility: values.civility,
        password: values.password,
        languages: values.languages,
        sermentedDate: values.sermentedDate
      },
      {
        onSuccess: () => navigate('/lawyer/pending')
      }
    );

  const handleSelectedVille = (v: {
    id: number;
    name: string;
    zipCode: string;
  }) => {
    setSelectedVille(v);
    setValue('city.id', v.id.toString());
    setValue('city.name', v.name);
    trigger('city');
  };

  const handleFormattingLang = useCallback(
    (list: LanguageType[]) => {
      const formattedValue: any = [];
      list.map((item: LanguageType) => formattedValue.push(item.id));
      setValue('languages', formattedValue);
      trigger('languages');
    },
    [setValue, trigger]
  );

  const handleSelectedLanguage = useCallback(
    (langObj: LanguageType) => {
      if (selectedLanguages.find((item: any) => item.id === langObj.id)) return;
      const newList = [...selectedLanguages, langObj];
      setSelectedLanguages(newList);
      handleFormattingLang(newList);
    },
    [selectedLanguages, handleFormattingLang]
  );

  const handleDeleteLanguage = useCallback(
    (langObj: LanguageType) => {
      const newList = selectedLanguages.filter(
        (item: any) => item.id !== langObj.id
      );
      setSelectedLanguages(newList);
      handleFormattingLang(newList);
    },
    [selectedLanguages, handleFormattingLang]
  );

  const handleSelectedDate = useCallback(
    (date: any) => {
      if (isString(date)) {
        setValue('sermentedDate', moment(date).toISOString() as any);
        trigger('sermentedDate');
        return;
      }
      setValue('sermentedDate', date.toISOString());
      trigger('sermentedDate');
    },
    [setValue, trigger]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="flex flex-col gap-4">
        <div className="text-left">
          <Title variant="h2">
            <span className="text-left">
              {t('form:label.lawyer.submission.section.one')}
            </span>
          </Title>
        </div>
        <div>
          <TextInputGroup
            name="firstName"
            label={`${t('form:label.lawyer.submission.firstname')} *`}
            register={register}
            defaultValue={searchParams.get('firstName') || undefined}
            error={errors.firstName}
            placeholder={t('form:placeholder.firstName')}
          />
        </div>
        <div>
          <TextInputGroup
            name="lastName"
            label={`${t('form:label.lawyer.submission.lastname')} *`}
            register={register}
            defaultValue={searchParams.get('lastName') || undefined}
            error={errors.lastName}
            placeholder={t('form:placeholder.lastName')}
          />
        </div>
        <div>
          <TextInputGroup
            name="email"
            label={`${t('form:label.lawyer.submission.email')} *`}
            register={register}
            defaultValue={searchParams.get('email') || undefined}
            error={errors.email}
            placeholder={t('form:placeholder.email')}
          />
        </div>
        <div>
          {/* Civilité */}
          <label htmlFor="civility" className="input-label">
            {`${t('form:label.lawyer.submission.civility')} *`}
          </label>
          <div className="relative mt-1">
            <select
              defaultValue="1"
              {...register('civility')}
              className="input-select h-12"
            >
              <option value="1">
                {t('form:label.lawyer.submission.civility.mr')}
              </option>
              <option value="2">
                {t('form:label.lawyer.submission.civility.mrs')}
              </option>
            </select>
          </div>
        </div>
        <div>
          <TextInputGroup
            name="phoneMobile"
            label={`${t('form:label.lawyer.submission.mobile')} *`}
            type="tel"
            register={register}
            defaultValue={searchParams.get('phoneMobile') || undefined}
            error={errors.phoneMobile}
            placeholder="+33601020304"
          />
        </div>
        <div>
          <TextInputGroup
            name="phoneHome"
            type="tel"
            label={`${t('form:label.lawyer.submission.phone')} *`}
            register={register}
            error={errors.phoneHome}
            defaultValue={searchParams.get('phoneHome') || undefined}
            placeholder="+33101020304"
          />
        </div>
        <div>
          <TextInputGroup
            name="password"
            label={`${t('form:label.lawyer.submission.password')} *`}
            type="password"
            register={register}
            error={errors.password}
            placeholder="**********"
          />
        </div>
        <div>
          <TextInputGroup
            name="passwordConfirmation"
            label={`${t('form:label.lawyer.submission.confirmation')} *`}
            type="password"
            register={register}
            error={errors.passwordConfirmation}
            placeholder="**********"
          />
        </div>

        <div>
          <YearPicker
            onChange={handleSelectedDate}
            label={`${t('form:label.lawyer.submission.degree')} *`}
            error={errors.sermentedDate}
            placeholder={DEFAULT_YEARPICKER_PLACEHOLDER}
          />
        </div>

        <div>
          <AutocompleteSearch
            label={`${t('form:label.lawyer.submission.languages')} *`}
            onHandleChangeValue={handleSelectedLanguage}
            initialData={languages}
            selected={selectedLanguages}
            placeholder={DEFAULT_LANGUAGES_PLACEHOLDER}
            error={errors.languages}
          />
          <div className="w-full flex">
            <div className="w-full mt-3">
              <div className="flex gap-2 mt-1">
                {selectedLanguages.map((tag: any) => (
                  <AutocompleteTags
                    onDeleteTag={handleDeleteLanguage}
                    key={tag.id}
                    value={tag}
                    isDeleteable
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* <div></div> */}
        <div className="text-left">
          <Title variant="h2">
            <span className="text-left">
              {t('form:label.lawyer.submission.section.second')}
            </span>
          </Title>
        </div>

        <div className="text-left">
          <Combobox
            as="div"
            value={selectedVille}
            onChange={handleSelectedVille}
          >
            <Combobox.Label className="input-label">
              {t('form:label.lawyer.submission.city')}
            </Combobox.Label>
            <div className="relative mt-1">
              <Combobox.Input
                type="text"
                placeholder={DEFAULT_CITY_PLACEHOLDER}
                className={clsx({
                  'input-text h-12': true,
                  'focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900':
                    !errors.city,
                  'text-red-800 border-red-800 bg-red-400': errors.city
                })}
                defaultValue={searchParams.get('city') || undefined}
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(ville: any) =>
                  `${ville?.name} (${ville?.zipCode})`
                }
              />

              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>

              <Combobox.Options className="absolute z-10 mt-1 max-h-36 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-200 ring-opaville-5 focus:outline-none sm:text-sm">
                <Combobox.Option
                  value=""
                  disabled
                  className="text-gray-700 relative cursor-default select-none py-2 pl-3 pr-9"
                >
                  {isLoading && query.length < 3 ? (
                    <LoadingSpinner />
                  ) : (
                    <p>{t('form:label.lawyer.submission.city.rule')}</p>
                  )}
                </Combobox.Option>
                {cities?.map((ville) => (
                  <Combobox.Option
                    key={ville.id}
                    value={ville}
                    className={({ active }) =>
                      clsx(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-purple-900 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={clsx(
                            'cursor-pointer block truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {ville.name} ({ville.zipCode})
                        </span>

                        {selected && (
                          <span
                            className={clsx(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-purple-900'
                            )}
                          >
                            <ChevronDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </div>
          </Combobox>
          {errors.city && (
            <p className="mt-2 text-left text-sm text-red-800" id="city-error">
              {t('form:error.lawyer.submission.city')}
            </p>
          )}
        </div>
        <div className="mb-2">
          <TextInputGroup
            name="officeAddress"
            label={`${t('form:label.lawyer.submission.office.address')} *`}
            register={register}
            error={errors.officeAddress}
            placeholder={DEFAULT_ADDRESS_PLACEHOLDER}
          />
        </div>
        <div>
          <CheckboxInputGroup
            name="cguConsent"
            register={register}
            error={errors.cguConsent}
            label={
              <span className="text-sm">
                <span>{t('lawyer:label.cguConsent.1')}</span>
                {` `}
                <a
                  href="https://www.ekie.co/ressources/cgu-avocats"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  {t('lawyer:label.cguConsent.2')}
                </a>
                <span> {t('lawyer:label.cguConsent.3')}</span>
                <a
                  href="https://www.ekie.co/ressources/confidentialite-avocats"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  {t('lawyer:label.cguConsent.4')}
                </a>
                <span> {t('lawyer:label.cguConsent.5')}</span>
              </span>
            }
          />
        </div>
        <div className="mb-2">
          <CheckboxInputGroup
            name="newsletterConsent"
            register={register}
            error={errors.newsletterConsent}
            label={
              <span className="text-sm">
                {t('lawyer:label.newsletterConsent')}
              </span>
            }
          />
        </div>

        <div>
          <Button
            submit
            fullWidth
            isLoading={false}
            size="medium"
            variant="primary"
            label={t('lawyer:revendication.submission.title')}
          />
        </div>
      </div>
    </form>
  );
}
