import { useEffect } from 'react';

import { useTracking } from 'context';

// Custom hook to trigger on rendering an event ie. on page load, start of a funnel...
export default function useTriggerEvent({
  eventName,
  eventMetadata,
  isDisabled
}: {
  eventName: string;
  eventMetadata?: any;
  isDisabled: boolean;
}) {
  const { analytics } = useTracking();

  useEffect(() => {
    if (!isDisabled) {
      analytics?.track(eventName, { ...eventMetadata });
    }
  }, [analytics, eventMetadata, eventName, isDisabled]);
}
