import { ArticleListSkeleton } from 'components/skeleton';
import { RatingStars } from 'components/statistic';
import { useGetRandomUserReview } from 'services/dato/user-review';

const RandomUserReview = () => {
  const { data, isLoading } = useGetRandomUserReview();

  if (isLoading) return <ArticleListSkeleton />;
  if (data?.allUserReviews.length === 0) return <div />;

  return (
    <div className="mb-10 flex justify-center">
      {data?.allUserReviews.map((userReview) => (
        <div key={userReview.id} className="flex flex-col gap-5">
          <div className="prose text-center font-bold text-gray-700 italic">
            {`" ${userReview.content} "`}
          </div>
          <div>
            <RatingStars className="justify-center" value={userReview.note} />
            <div className="flex justify-center mt-1 font-medium text-sm">
              {userReview.fullName}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RandomUserReview;
