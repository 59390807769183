import { useMutation } from 'react-query';

import { Customer } from 'types/customer';
import { NewOrder } from 'types/order';
import { Product } from 'types/product';

import { createOrder } from './api';

export function useCreateOrderMutation() {
  return useMutation(
    ({
      paymentProvider,
      customerId,
      productId
    }: {
      paymentProvider: NewOrder['paymentProvider'];
      customerId: Customer['id'];
      productId: Product['id'];
    }) => createOrder(paymentProvider, customerId, productId),
    {
      useErrorBoundary: true
    }
  );
}
