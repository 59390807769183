import { Card, Divider, Section } from 'components';
import { Fade } from 'components/transition';

const QuestionSectionSkeleton = () => (
  <Fade>
    <Section className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-12">
      <div className="space-y-6 lg:col-span-8">
        <Card className="animate-pulse flex flex-row">
          <div className="h-3 w-2/3 mb-3 bg-gray-300 rounded" />
          <div className="h-7 w-full bg-gray-300 rounded" />
        </Card>
        <Card className="animate-pulse flex flex-row">
          <div className="h-7 w-full bg-gray-300 rounded" />
          <div className="h-12 w-1/3 bg-gray-300 rounded" />
          <Divider />
          <div className="h-12 w-1/3 mb-3 bg-gray-300 rounded" />
          <div className="h-32 w-full mb-3 bg-gray-300 rounded" />
        </Card>

        <Card className="animate-pulse flex flex-row">
          <div className="h-3 w-1/3 mb-3 bg-gray-300 rounded" />
          <div className="flex gap-4 items-center mb-3">
            <div className="h-16 w-16 bg-gray-300 rounded-full" />
            <div className="h-12 w-1/3 bg-gray-300 rounded" />
          </div>
          <div className="h-52 w-full mb-3 bg-gray-300 rounded" />
        </Card>
      </div>
      <div className="lg:col-span-4">
        <Card className="animate-pulse flex flex-row">
          <div className="h-3 w-1/3 mb-3 bg-gray-300 rounded" />
          <div className="h-7 w-full bg-gray-300 rounded" />
          <div className="h-7 w-full bg-gray-300 rounded" />
          <div className="h-7 w-full bg-gray-300 rounded" />
        </Card>
      </div>
    </Section>
  </Fade>
);

export default QuestionSectionSkeleton;
