import { useTranslation } from 'react-i18next';

import {
  FunnelCardCta,
  SubscriptionFunnelIndications,
  SubscriptionFunnelStep
} from 'features/customer/funnels';
import { Button } from 'features/shared/buttons';

export default function ConfirmationSuccessSubscription({
  handleRedirectToAccount
}: {
  handleRedirectToAccount: () => void;
}) {
  const { t } = useTranslation('customer');
  return (
    <>
      <SubscriptionFunnelIndications step={SubscriptionFunnelStep.Success} />
      <FunnelCardCta
        button={
          <Button
            label={t('general.accessMyAccount.cta')}
            variant="primary"
            size="large"
            onClick={() => handleRedirectToAccount()}
          />
        }
      />
    </>
  );
}
