import { yupResolver } from '@hookform/resolvers/yup';
import FormHelper from 'components/FormHelper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { additionalRequestSchema } from 'schemas';

import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';
import { QuestionTextArea } from 'features/shared/forms';

export interface AnswerAdditionalRequestFormInput {
  question: string;
}

interface AnswerAdditionalRequestFormProps {
  isLoading?: boolean;
  onSubmitAdditionalRequestForm: any;
}

export default function AnswerAdditionalRequestForm({
  isLoading = false,
  onSubmitAdditionalRequestForm
}: AnswerAdditionalRequestFormProps) {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AnswerAdditionalRequestFormInput>({
    resolver: yupResolver(additionalRequestSchema(tForm)),
    defaultValues: { question: '' }
  });

  const onSubmit: SubmitHandler<AnswerAdditionalRequestFormInput> = (
    formData
  ) => {
    onSubmitAdditionalRequestForm(formData.question);
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="w-full">
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-col gap-4"
        >
          <Alert
            title={t('account.question.additionalRequest.form.alert.title')}
          >
            <p>
              {t('account.question.additionalRequest.form.alert.description')}
            </p>
          </Alert>
          <QuestionTextArea register={register} error={errors.question} />
          <Button
            fullWidth
            submit
            size="medium"
            variant="tertiary"
            isLoading={isLoading}
            label={t('general.continue')}
          />
        </form>
      </div>
    </div>
  );
}
