import { CheckIcon } from 'features/shared/icons';

import { Perk } from 'types/product';

interface OfferPerksProps {
  perks: Perk[];
}

export default function OfferPerks({ perks }: OfferPerksProps) {
  return (
    <div className="flex flex-col gap-4">
      {perks.map((perk) => (
        <div key={perk.id} className="flex gap-3 md:items-center">
          <div className="flex-shrink-0 flex justify-center items-center">
            <CheckIcon className="h-4 w-4 stroke-purple-900 fill-purple-900 lg:h-5 lg:w-5 mt-1 sm:mt-0" />
          </div>
          <span className="text-base font-norma font-sans">{perk.title}</span>
        </div>
      ))}
    </div>
  );
}
