import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { requestResetPasswordSchema } from 'schemas';

import { useRequestNewPasswordMutation } from 'api/shared/login';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { RequestResetPasswordFormInputs } from 'types/inputs';

export default function RequestResetPasswordForm() {
  const [, setSearchParams] = useSearchParams();
  const { mutate, isLoading } = useRequestNewPasswordMutation();

  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const handleError = useErrorHandler();

  const {
    register,
    handleSubmit,
    // setError,
    formState: { errors }
  } = useForm<RequestResetPasswordFormInputs>({
    resolver: yupResolver(requestResetPasswordSchema(tForm))
  });

  const onSubmit: SubmitHandler<RequestResetPasswordFormInputs> = async (
    formData
  ) => {
    const { email } = formData;
    mutate(
      { email },
      {
        onError: (error: any) => {
          // const isUnauthorizedError = error?.response?.status === 403;
          // if (isUnauthorizedError) {
          //   setError('email', {
          //     message: `${t('error.occurred')}.`,
          //     type: 'focus'
          //   });
          // } else {
          handleError(error);
          // }
        },
        onSuccess: () => setSearchParams({ submitted: 'true' })
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        autofocus
        type="email"
        fullWidth
        placeholder={tForm('placeholder.email')}
        label={tForm('label.email')}
        name="email"
        register={register}
        error={errors.email}
      />
      <Button
        isLoading={isLoading}
        fullWidth
        submit
        size="medium"
        variant="primary"
        label={t('general.continue')}
      />
    </form>
  );
}
