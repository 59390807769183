import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Customer, NewCustomer, PreviousCustomer } from 'types/customer';
import { NewOnboarding } from 'types/onboarding';

import {
  completeCustomer,
  createEmailFromContent,
  createOnboarding,
  getCustomer,
  getCustomerUsageMeter,
  updateAccount,
  updateCustomer,
  updateCustomerPassword
} from './api';

export function useCustomerQuery() {
  return useQuery(['customer'], () => getCustomer(), {
    onSuccess: (customer) => {
      const { id, email, phoneCellphone } = customer;
      Sentry.setUser({ email });
      Sentry.setContext('customer', {
        id,
        email,
        phoneCellphone
      });
    }
  });
}

export function useCustomerUsageMeterQuery({
  customerId
}: {
  customerId: Customer['id'];
}) {
  return useQuery(['customer-usage-meter'], () =>
    getCustomerUsageMeter(customerId)
  );
}

export function useUpdateCustomerMutation({
  customerId
}: {
  customerId: Customer['id'];
}) {
  return useMutation((newCustomer: NewCustomer) =>
    updateCustomer(customerId, newCustomer)
  );
}

export function useUpdateCustomerPasswordMutation({
  customerId
}: {
  customerId: Customer['id'];
}) {
  return useMutation(({ newPassword }: { newPassword: string }) =>
    updateCustomerPassword(customerId, newPassword)
  );
}

export function useUpdateCustomerOptimisticMutation({
  prevCustomer
}: {
  prevCustomer: PreviousCustomer;
}) {
  const { id: customerId } = prevCustomer;
  const queryClient = useQueryClient();
  return useMutation(
    (newCustomer: NewCustomer) => updateCustomer(customerId, newCustomer),
    {
      onMutate: async (newCustomer: NewCustomer) => {
        await queryClient.cancelQueries(['customer']);
        const previousCustomer = queryClient.getQueryData<Customer>([
          'customer'
        ]);

        if (previousCustomer) {
          queryClient.setQueryData<PreviousCustomer>(['customer'], {
            ...previousCustomer,
            ...newCustomer
          });
        }

        return { previousCustomer };
      },
      onError: (_error, _variables, context) => {
        if (context?.previousCustomer) {
          queryClient.setQueryData<Customer>(
            ['customer'],
            context.previousCustomer
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['customer']);
      }
    }
  );
}

export function useUpdateAccountOptimisticMutation(accountId: number) {
  const queryClient = useQueryClient();

  return useMutation((data) => updateAccount(accountId, data), {
    onMutate: async (account: any) => {
      await queryClient.cancelQueries(['customer']);
      const previousCustomer = queryClient.getQueryData<Customer>(['customer']);
      if (previousCustomer) {
        queryClient.setQueryData<PreviousCustomer>(['customer'], {
          ...previousCustomer,
          secondaryEmail: account.secondaryEmail
        });
      }

      return { previousCustomer };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousCustomer) {
        queryClient.setQueryData<Customer>(
          ['customer'],
          context.previousCustomer
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['customer']);
    }
  });
}

export function useUpdateOnboardingCustomerOptimisticMutation({
  id
}: {
  id: number;
}) {
  const queryClient = useQueryClient();
  return useMutation(
    (newCustomer: NewCustomer) => completeCustomer(id, newCustomer),
    {
      onMutate: async (newCustomer: NewCustomer) => {
        await queryClient.cancelQueries(['customer']);
        const previousCustomer = queryClient.getQueryData<Customer>([
          'customer'
        ]);
        if (previousCustomer) {
          queryClient.setQueryData<NewCustomer>(['customer'], {
            id,
            ...newCustomer
          });
        }

        return { previousCustomer };
      },
      onError: (_error, _variables, context) => {
        if (context?.previousCustomer) {
          queryClient.setQueryData<Customer>(
            ['customer'],
            context.previousCustomer
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['customer']);
      }
    }
  );
}

export function useCreateOnboarding({
  customerId
}: {
  customerId: Customer['id'];
}) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ newOnboarding }: { newOnboarding: NewOnboarding }) =>
      createOnboarding(customerId, newOnboarding),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customer']);
      }
    }
  );
}

export function useCreateEmailFromContent() {
  return useMutation(({ leadEmail }: { leadEmail: string }) =>
    createEmailFromContent(leadEmail)
  );
}
