import { addDays, startOfDay, subYears } from 'date-fns';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import 'yup-phone';
import { z } from 'zod';

import {
  answerSchema,
  cguConsentSchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  passwordSchema,
  phoneCellphoneSchema,
  questionSchema,
  slotSchema,
  usernameSchema
} from './shared';

export type TFunctionTranslationFormValidation = TFunction<'form', undefined>;

export const profileSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      firstName: firstNameSchema(t),
      lastName: lastNameSchema(t),
      phoneCellphone: phoneCellphoneSchema(t),
      secondaryEmail: yup.string().email(t('schema.email.valid'))
    })
    .required();

export const loginMagicSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t)
  });

export const addCollaboratorSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    memberEmail: emailSchema(t)
  });

export const addPartnerCollaboratorSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    email: emailSchema(t),
    firstName: firstNameSchema(t),
    lastName: lastNameSchema(t)
  });

export const addBulkPartnerCollaboratorSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    file: yup.object()
  });

export const loginPartnerSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t),
    password: passwordSchema(t),
    partnerKey: yup.string().required(t('schema.partnerKey.login'))
  });

export const loginPasswordMagicSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    username: usernameSchema(t),
    password: passwordSchema(t)
  });

export const otpCodeSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    code: yup
      .string()
      .length(6, t('schema.otpCode.min'))
      .required(t('schema.otpCode.required'))
  });

export const contactSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t),
    phone: phoneCellphoneSchema(t)
  });

export const contactProSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t),
    phone: phoneCellphoneSchema(t),
    companyName: yup.string().required(),
    jobTitle: yup.string()
  });

export const funnelQuestionSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    question: questionSchema(t),
    source: yup.mixed().oneOf(['personal', 'cse']).required()
  });

export const additionalRequestSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    question: questionSchema(t)
  });

export const consultationSlotSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    slot: slotSchema(t)
  });

export const questionAnswerSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    answerContent: answerSchema(t)
  });

export const additionalRequestAnswerSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    answerContent: answerSchema(t)
  });

export const cancelConsultationIntentSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    intent: yup.string().required()
  });

// Zod is used here - it's a great library for schema validation
// with TS support - better than yup on package weight and performance
// TODO: if you juge it necessary, you can replace all yup schemas with zod ones :)
export const newLawyerSchema = (t: TFunctionTranslationFormValidation) =>
  z
    .object({
      email: z
        .string({
          required_error: 'Email requis',
          invalid_type_error: 'Email invalide'
        })
        .email('Email invalide'),
      firstName: z
        .string({
          required_error: 'Prénom requis'
        })
        .min(2, '2 caractères minimum'),
      lastName: z
        .string({
          required_error: 'Nom requis'
        })
        .min(2, '2 caractères minimum'),
      civility: z.string({
        required_error: 'Civilité requise'
      }),
      cguConsent: z.boolean(),
      newsletterConsent: z.boolean(),
      password: z
        .string({
          required_error: 'Mot de passe requis'
        })
        .min(8, '8 caractères minimum'),
      passwordConfirmation: z
        .string({
          required_error: 'Confirmation de mot de passe requis'
        })
        .min(8, '8 caractères minimum'),
      phoneMobile: z
        .string({
          required_error: 'Téléphone mobile requis'
        })
        .min(10, 'Téléphone mobile invalide'),
      phoneHome: z.string(),
      officeAddress: z
        .string({
          required_error: 'Adresse du cabinet requise'
        })
        .min(1, 'Adresse du cabinet requise'),
      sermentedDate: z.string({
        required_error: 'Année de prestation de serment requise'
      }),
      languages: z.array(z.number()).min(1, 'langues parlées requises'),
      city: z.object({
        name: z
          .string({
            required_error: 'Ville requise'
          })
          .min(1, 'Ville requise'),
        id: z
          .string({
            required_error: 'Ville requise'
          })
          .min(1, 'Ville requise')
      })
    })
    .refine(({ cguConsent }) => cguConsent === true, {
      message:
        'Vous devez accepter les CGU et la politique de confidentialité pour créer votre compte Ekie',
      path: ['cguConsent']
    })
    .refine(
      ({ password, passwordConfirmation }) => password === passwordConfirmation,
      {
        message: 'Les mots de passes doivent correspondre',
        path: ['passwordConfirmation']
      }
    );

export const finishProfileSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      firstName: firstNameSchema(t),
      lastName: lastNameSchema(t),
      phoneCellphone: phoneCellphoneSchema(t)
    })
    .required();

export const completeProfileSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      firstName: firstNameSchema(t),
      lastName: lastNameSchema(t),
      phoneCellphone: phoneCellphoneSchema(t),
      cguConsent: cguConsentSchema(t),
      newsletterConsent: yup.boolean().notRequired(),
      secondaryEmail: yup.string().email(t('schema.email.valid')).notRequired()
    })
    .required();

export const requestResetPasswordSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup
    .object({
      email: emailSchema(t)
    })
    .required();

export const newPasswordSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      password: passwordSchema(t)
    })
    .required();

// Schemas for authentified user
export const authNewPasswordSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    password: passwordSchema(t),
    passwordConfirmation: passwordSchema(t).oneOf(
      [yup.ref('password'), null],
      'Passwords must match'
    )
  });

export const premiumPlusRequiredDataSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    // Req. customer
    civility: yup.string().required(t('schema.civility.required')),
    firstName: firstNameSchema(t).required(t('schema.firstName.required')),
    lastName: lastNameSchema(t),
    birthDate: yup
      .date()
      .max(startOfDay(subYears(new Date(), 18)), 'Vous devez être majeur.')
      .required(t('schema.birthDate.required')),
    birthCity: yup.string().required(t('schema.birthCity.required')),
    birthZipCode: yup.string().required(t('schema.birthZipCode.required')),
    birthCountry: yup.string().required(t('schema.birthCountry.required')),
    address: yup.string().required(t('schema.address.required')),
    addressComplement: yup.string().optional(),
    zipCode: yup.string().required(t('schema.zipCode.required')),
    city: yup.string().required(t('schema.city.required')),
    country: yup.string().required(t('schema.country.required')),
    email: emailSchema(t),
    phoneCellphone: phoneCellphoneSchema(t),
    withPartnerData: yup.boolean(),
    // Opt. partner
    partnerFirstName: firstNameSchema(t).when('withPartnerData', {
      is: true,
      then: yup.string().required('Champ requis'),
      otherwise: yup.string().optional()
    }),
    partnerLastName: yup.string().when('withPartnerData', {
      is: true,
      then: yup.string().required('Champ requis'),
      otherwise: yup.string().optional()
    }),
    partnerBirthDate: yup.string().when('withPartnerData', {
      is: true,
      then: yup.string().required('Champ requis'),
      otherwise: yup.string().optional()
    }),
    partnerBirthCity: yup.string().when('withPartnerData', {
      is: true,
      then: yup.string().required('Champ requis'),
      otherwise: yup.string().optional()
    }),
    partnerBirthZipCode: yup.string().when('withPartnerData', {
      is: true,
      then: yup.string().required('Champ requis'),
      otherwise: yup.string().optional()
    }),
    partnerBirthCountry: yup.string().when('withPartnerData', {
      is: true,
      then: yup.string().required('Champ requis'),
      otherwise: yup.string().optional()
    })
  });

export const premiumPlusAdditionalDataSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    socioeconomicStatus: yup.string().required(),
    jobTitle: yup.string().required(),
    isInLitigation: yup.string().required(),
    partnerSocioeconomicStatus: yup.string(),
    partnerJobTitle: yup.string(),
    warrantyDate: yup
      .date()
      .required()
      .min(
        addDays(startOfDay(new Date()), 1),
        'Date invalide - le contrat doit commencer à minima demain'
      )
  });

export const premiumPlusAdditionalWithPartnerDataSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    socioeconomicStatus: yup.string().required(),
    jobTitle: yup.string().required(),
    isInLitigation: yup.string().required(),
    partnerSocioeconomicStatus: yup.string().required(),
    partnerJobTitle: yup.string().required(),
    warrantyDate: yup
      .date()
      .required()
      .min(
        addDays(startOfDay(new Date()), 1),
        'Date invalide - le contrat doit commencer à minima demain'
      )
  });

export const premiumPlusConfirmationDataSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    cguConsent: cguConsentSchema(t)
  });
