import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { completeProfileSchema } from 'schemas';

import { useUpdateOnboardingCustomerOptimisticMutation } from 'api/customer/customers';

import { useCustomer } from 'hooks';

import { Button } from 'features/shared/buttons';
import { CheckboxInputGroup, TextInputGroup } from 'features/shared/forms';

import { ProfileCompletionFormInputs } from 'types/inputs';

export default function ProfileCompletionForm() {
  const { t: tForm } = useTranslation('form');
  const { t } = useTranslation('customer');

  const navigate = useNavigate();
  const handleError = useErrorHandler();

  const { id, firstName, lastName, phoneCellphone, secondaryEmail } =
    useCustomer();

  const { mutate, isLoading } = useUpdateOnboardingCustomerOptimisticMutation({
    id
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ProfileCompletionFormInputs>({
    resolver: yupResolver(completeProfileSchema(tForm))
  });

  const onSubmit: SubmitHandler<ProfileCompletionFormInputs> = async (
    formData
  ) => {
    mutate(formData, {
      onSuccess: () => navigate('/onboarding/password'),
      onError: (error: any) => {
        const { data: errorData } = error.response;
        if (errorData.error.code === 'email_already_exists') {
          setError('secondaryEmail' as any, {
            message: 'Adresse email déjà utilisée',
            type: 'focus'
          });
        } else {
          handleError(error);
        }
      }
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <div>
        <TextInputGroup
          autofocus
          name="firstName"
          label={tForm('label.firstName')}
          register={register}
          placeholder={tForm('placeholder.firstName')}
          defaultValue={firstName}
          error={errors.firstName}
          disabled={isLoading}
        />
      </div>
      <div>
        <TextInputGroup
          name="lastName"
          label={tForm('label.lastName')}
          register={register}
          placeholder={tForm('placeholder.lastName')}
          defaultValue={lastName}
          error={errors.lastName}
          disabled={isLoading}
        />
      </div>
      <div>
        <TextInputGroup
          name="phoneCellphone"
          label={tForm('label.phone')}
          register={register}
          placeholder="+33601010101"
          defaultValue={phoneCellphone}
          error={errors.phoneCellphone}
          disabled={isLoading}
        />
      </div>
      <TextInputGroup
        name="secondaryEmail"
        label={tForm('label.email.perso')}
        register={register}
        defaultValue={secondaryEmail}
        error={errors.secondaryEmail}
        disabled={isLoading}
      />
      {/*  */}
      <div className="flex flex-col gap-2">
        <CheckboxInputGroup
          name="cguConsent"
          register={register}
          error={errors.cguConsent}
          label={
            <span className="text-sm">
              En activant mon abonnement Ekie, je reconnais avoir pris
              connaissance des{' '}
              <a
                href="https://www.ekie.co/cgu"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                CGUV
              </a>{' '}
              et de la{' '}
              <a
                href="https://www.ekie.co/vie-privee"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Politique de Confidentialité
              </a>{' '}
              et je reconnais les accepter sans réserve
            </span>
          }
        />
        <CheckboxInputGroup
          name="newsletterConsent"
          register={register}
          error={errors.newsletterConsent}
          label={
            <span className="text-sm">
              Oui, je veux recevoir les cas d’usages et actualités personnalisés
              d’Ekie. Désinscription possible à tout moment !
            </span>
          }
        />
      </div>
      {/*  */}
      <Button
        fullWidth
        isLoading={isLoading}
        submit
        size="medium"
        variant="primary"
        label={t('general.continue')}
      />
    </form>
  );
}
