import { Section } from 'components';
import { DEFAULT_REDIRECT_URL } from 'constants/customer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCustomerAccount } from 'hooks';

import { PageLayout } from 'features/customer/account/content';
import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

const MESSAGE_MAP = {
  consultation: 'account.consultations.error.unsubscribed'
} as { [key: string]: string };

const UnsubscriptionPage = () => {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const { state = 'consultation' }: { state: string } = useLocation();

  const {
    customerUsageMeter: { hasActiveSubscription },
    subscription
  } = useCustomerAccount();

  useEffect(() => {
    hasActiveSubscription && navigate(DEFAULT_REDIRECT_URL, { replace: true });
  }, [hasActiveSubscription, navigate]);

  return (
    <PageLayout>
      <Section>
        <Paragraph gutterBottom>{t(MESSAGE_MAP[state] as any)}</Paragraph>
        <a
          href={`/funnel/subscribe/contact?skuCode=${
            subscription
              ? subscription.product.skuCode
              : process.env.REACT_APP_PRODUCT_STANDARD_SKU_CODE
          }`}
        >
          <Button
            variant="tertiary"
            size="medium"
            fullWidth
            label={t('general.subscribe')}
          />
        </a>
      </Section>
    </PageLayout>
  );
};

export default UnsubscriptionPage;
