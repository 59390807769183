import { Stripe } from '@stripe/stripe-js';
import { ANALYTICS, INTENT_QUESTION_SOURCES } from 'constants/analytics';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useCreateQuestionMutation } from 'api/customer/mutations.index';

import { useCustomer } from 'hooks';

import { useAuth, useTracking } from 'context';

import {
  QuestionFunnelIndications,
  QuestionFunnelStep
} from 'features/customer/funnels/FunnelStepIndications';
import { ConfirmationCustomerSubscription } from 'features/customer/funnels/confirmation/customer';
import { ConfirmationSuccessQuestion } from 'features/customer/funnels/confirmation/success';
import { LoadingCard } from 'features/shared/loading';

import { CustomerUsageMeter } from 'types/customer';
import { FunnelConsistencyMetadata } from 'types/funnel';
import { Subscription } from 'types/subscription';

import { useLocalProductBySkuCode } from '../../funnel.utils';
import { TrustworthyPaymentForm } from '../payment';

interface CustomerQuestionPaymentProps {
  stripePromise: Promise<Stripe | null>;
  intentClientSecret: string;
  subscriptionId: string;
  questionContent: string;
  productSkuCode: FunnelConsistencyMetadata['product']['skuCode'];
  distinctId?: string;
}

export default function CustomerQuestionPayment({
  stripePromise,
  intentClientSecret,
  subscriptionId,
  questionContent,
  productSkuCode,
  distinctId
}: CustomerQuestionPaymentProps) {
  const { analytics } = useTracking();
  const queryClient = useQueryClient();
  const { id: customerId } = useCustomer();
  const { userId } = useAuth();
  const [isFunnelSuccess, setIsFunnelSuccess] = useState<boolean>(false);
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const [isOfferConfirmed, setIsOfferConfirmed] = useState<boolean>(false);

  const localProduct = useLocalProductBySkuCode(productSkuCode);

  const { mutate: createQuestionMutate, isLoading: isCreateQuestionLoading } =
    useCreateQuestionMutation();

  const handleTrackQuestionFunnelSuccess = () => {
    analytics?.identify(distinctId);
    analytics?.track(ANALYTICS.FUNNEL_CUSTOMER_QUESTION_SUBSCRIPTION_SUCCESS, {
      id: userId
    });
  };

  const handleTrackQuestionAskedSuccess = () =>
    analytics?.track(ANALYTICS.QUESTION_SUCCESS, {
      intentQuestionSource: INTENT_QUESTION_SOURCES.FUNNEL
    });

  const handleOptimisticSubscriptionSuccess = () => {
    const currentSubscription: Subscription = queryClient.getQueryData([
      'subscription'
    ])!;
    const currentCustomerUsageMeter: CustomerUsageMeter =
      queryClient.getQueryData(['customer-usage-meter'])!;
    // Set subscription as active
    queryClient.setQueryData<Subscription>(['subscription'], {
      ...currentSubscription,
      status: 'active',
      active: true
    });
    // Set customer usage meter with hasActiveSubscription
    queryClient.setQueryData<CustomerUsageMeter>(['customer-usage-meter'], {
      ...currentCustomerUsageMeter,
      hasActiveSubscription: true
    });
    queryClient.invalidateQueries(['questions']);
  };

  const handlePaymentSuccess = () => {
    setCustomLoading(true);
    createQuestionMutate(
      {
        customerId,
        subscriptionId,
        questionContent
      },
      {
        onSuccess: () => {
          //
          handleOptimisticSubscriptionSuccess();
          //
          handleTrackQuestionFunnelSuccess();
          handleTrackQuestionAskedSuccess();
          //
          setCustomLoading(false);
          //
          setIsFunnelSuccess(true);
        }
      }
    );
  };

  if (isCreateQuestionLoading || customLoading) {
    return (
      <>
        <QuestionFunnelIndications
          step={QuestionFunnelStep.ConfirmationPayment}
        />
        <LoadingCard />
      </>
    );
  }

  if (isFunnelSuccess) {
    return <ConfirmationSuccessQuestion />;
  }

  if (isOfferConfirmed) {
    return (
      <>
        <QuestionFunnelIndications
          step={QuestionFunnelStep.ConfirmationPayment}
        />
        <TrustworthyPaymentForm
          stripePromise={stripePromise}
          intentClientSecret={intentClientSecret}
          localProduct={localProduct}
          handlePaymentSuccess={handlePaymentSuccess}
        />
      </>
    );
  }

  return (
    <ConfirmationCustomerSubscription
      localProduct={localProduct}
      setIsOfferConfirmed={setIsOfferConfirmed}
    />
  );
}
