import { Title } from 'components';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useUpsellSubscriptionOptimisticMutation } from 'api/customer/subscriptions';

import { Button } from 'features/shared/buttons';
import { SunIllustration } from 'features/shared/illustrations';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

import { SetState } from 'types/core';
import { CustomerUsageMeter } from 'types/customer';
import { Subscription } from 'types/subscription';

interface ModalSubscriptionUpsellProps {
  subscriptionId: Subscription['id'];
  productSku: string;
  isModalSubscriptionUpsellOpen: boolean;
  setIsModalSubscriptionUpsellOpen: SetState<boolean>;
  isFunnelContext: boolean;
  customerUsageMeter?: CustomerUsageMeter;
}

export default function ModalSubscriptionUpsell({
  subscriptionId,
  productSku,
  isModalSubscriptionUpsellOpen,
  setIsModalSubscriptionUpsellOpen,
  isFunnelContext,
  customerUsageMeter
}: ModalSubscriptionUpsellProps) {
  const { t } = useTranslation('customer');

  const { mutate, isLoading } = useUpsellSubscriptionOptimisticMutation();
  const handleModalClose = () => setIsModalSubscriptionUpsellOpen(false);
  const queryClient = useQueryClient();

  const handleUpsellSubscription = () =>
    mutate(
      { subscriptionId, productSku },
      {
        onSuccess: () => {
          if (isFunnelContext) {
            // Set customer usage meter with canRequestConsultationForFree
            queryClient.setQueryData<CustomerUsageMeter>(
              ['customer-usage-meter'],
              {
                ...(customerUsageMeter as CustomerUsageMeter),
                canRequestConsultationForFree: true
              }
            );
          }
          toast.custom(
            <Toast
              type="success"
              title={t('general.congratulation')}
              message={t('account.subscription.upsell.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        },
        onError: () => {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('account.subscription.upsell.error')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        },
        onSettled: () => setIsModalSubscriptionUpsellOpen(false)
      }
    );

  return (
    <LayoutModal
      isModalOpen={isModalSubscriptionUpsellOpen}
      handleModalClose={handleModalClose}
      withDefaultPadding={false}
    >
      <div>
        <div className="grid place-items-center bg-purple-300">
          <SunIllustration className="w-32 h-32" />
        </div>
        <div className="px-6 pt-5 pb-4">
          <div className="flex flex-col items-center">
            <Title variant="h3">
              {t('account.subscription.upsell.confirmation.title')}
            </Title>
          </div>
          <div className="text-left">
            <p className="text-md mt-2 font-light ">
              {t('account.subscription.upsell.confirmation.body.1', {
                ekiePremiumPrice: process.env.REACT_APP_PRODUCT_PREMIUM_PRICE
              })}
            </p>
            <p className="text-md mt-2 font-light ">
              {t('account.subscription.upsell.confirmation.body.2')}
            </p>
            <div className="ml-4">
              <ul className="list-disc text-md mt-2 font-medium ">
                <li>Poser toutes vos questions à un avocat en illimité</li>
                <li>
                  Accéder aux consultations téléphoniques avec un avocat en
                  illimité
                </li>
                <li>
                  Faire procéder à une revue de documents simples par un avocat,
                  en amont d’une consultation
                </li>
              </ul>
            </div>
          </div>
          <div className="text-left">
            <p className="mt-2 font-extralight">
              {t('account.subscription.upsell.confirmation.body.3')}
            </p>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <div className="flex justify-end">
              <Button
                onClick={handleModalClose}
                variant="secondary"
                size="small"
                label={t('general.cancel')}
                disabled={isLoading}
              />
              <div className="ml-3">
                <Button
                  submit
                  variant="primary"
                  size="small"
                  onClick={handleUpsellSubscription}
                  label={t('account.subscription.upsell.cta')}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutModal>
  );
}
