/* eslint-disable no-underscore-dangle */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

import { useLocale } from 'hooks';

interface CookiesSettings {
  googleAnalytics: boolean;
  segment: boolean;
  hotJar: boolean;
}

interface TrackingContextValue {
  analytics: any | undefined;
}

const TrackingContext = createContext<TrackingContextValue | undefined>(
  undefined
);

interface TrackingProviderProps {
  children: React.ReactNode;
}

function TrackingProvider({ children }: TrackingProviderProps) {
  const { locale } = useLocale();
  const [analytics, setAnalytics] = useState<any | undefined>(undefined);
  const mixpanel = useMixpanel();

  const isAxeptioLoaded = useRef(false);

  const isHotJarLoaded = useRef(false);

  const [writeKey] = useState(process.env.REACT_APP_MIXPANEL as string);

  const loadAxeptioScript = () => {
    const axeptioId = process.env.REACT_APP_AXEPTIO_CLIENT_ID as string;
    const script = document.createElement('script');

    script.innerHTML = `
    window.axeptioSettings = {
      clientId: "${axeptioId}",
      cookiesVersion: "ekie app-${locale}",
      jsonCookieName: 'axeptio_cookies'
    };
     
    (function(d, s) {
      var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
      e.async = true; e.src = "//static.axept.io/sdk.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");
    `;
    document.head.appendChild(script);

    isAxeptioLoaded.current = true;
  };

  useEffect(() => {
    const axeptioWindowIsReady =
      window !== undefined && !(window as any).axeptioSettings;
    if (axeptioWindowIsReady && !isAxeptioLoaded.current) {
      loadAxeptioScript();
    }
  }, [isAxeptioLoaded]);

  const loadHotJarScript = () => {
    const hotJarId = process.env.REACT_APP_HOTJAR_ID as string;
    const script = document.createElement('script');
    script.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotJarId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.head.appendChild(script);

    isHotJarLoaded.current = false;
  };

  const enableHotJar = () => loadHotJarScript();

  const enableMixpanel = async () => {
    await mixpanel.init(process.env.REACT_APP_MIXPANEL);
    setAnalytics(mixpanel);
  };

  useEffect(() => {
    const windowIsReady = window !== undefined;
    if (windowIsReady) {
      // @ts-ignore
      window._axcb = window._axcb || [];

      // @ts-ignore
      window._axcb.push((axeptio) => {
        axeptio.on('cookies:complete', async (choices: CookiesSettings) => {
          if (choices) {
            // SEGMENT
            if (choices.segment && analytics === undefined) {
              await enableMixpanel();
            }
            // HOTJAR
            if (choices.hotJar && analytics === undefined) {
              enableHotJar();
            }
          }
        });
      });
    }
  }, [writeKey]);

  const value = useMemo(
    () => ({
      analytics
    }),
    [analytics]
  );

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
}

const useTracking = () => {
  const context = useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within a Provider.');
  }
  return context;
};

export { TrackingProvider, TrackingContext, useTracking };
