import { Stripe } from '@stripe/stripe-js';
import { ANALYTICS } from 'constants/analytics';
import { DEFAULT_REDIRECT_URL } from 'constants/customer';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useTracking } from 'context';

import {
  SubscriptionFunnelIndications,
  SubscriptionFunnelStep
} from 'features/customer/funnels';
import { ConfirmationSuccessSubscription } from 'features/customer/funnels/confirmation/success';

import { CustomerUsageMeter } from 'types/customer';
import { Subscription } from 'types/subscription';

import { useLocalProductBySkuCode } from '../../funnel.utils';
import { TrustworthyPaymentForm } from '../payment';

interface CustomerSubscriptionPaymentProps {
  stripePromise: Promise<Stripe | null>;
  intentClientSecret: string;
  productSkuCode: string;
}

export default function CustomerSubscriptionPayment({
  stripePromise,
  intentClientSecret,
  productSkuCode
}: CustomerSubscriptionPaymentProps) {
  const { analytics } = useTracking();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isFunnelSuccess, setIsFunnelSuccess] = useState<boolean>(false);

  const localProduct = useLocalProductBySkuCode(productSkuCode);

  const handleOptimisticSubscriptionSuccess = () => {
    const currentSubscription: Subscription = queryClient.getQueryData([
      'subscription'
    ])!;
    const currentCustomerUsageMeter: CustomerUsageMeter =
      queryClient.getQueryData(['customer-usage-meter'])!;
    // Set subscription as active
    queryClient.setQueryData<Subscription>(['subscription'], {
      ...currentSubscription,
      status: 'active',
      active: true
    });
    // Set customer usage meter with hasActiveSubscription
    queryClient.setQueryData<CustomerUsageMeter>(['customer-usage-meter'], {
      ...currentCustomerUsageMeter,
      hasActiveSubscription: true
    });
  };

  const handleTrackSubscriptionSuccess = () =>
    analytics?.track(ANALYTICS.FUNNEL_CUSTOMER_SUBSCRIPTION_SUCCESS, {
      productSkuCode
    });

  const handleRedirectToAccount = () => {
    handleOptimisticSubscriptionSuccess();
    navigate(DEFAULT_REDIRECT_URL);
  };

  const handlePaymentSuccess = () => {
    //
    handleTrackSubscriptionSuccess();
    //
    setIsFunnelSuccess(true);
  };

  if (isFunnelSuccess) {
    return (
      <ConfirmationSuccessSubscription
        handleRedirectToAccount={handleRedirectToAccount}
      />
    );
  }

  return (
    <>
      <SubscriptionFunnelIndications
        step={SubscriptionFunnelStep.ConfirmationPayment}
      />
      <TrustworthyPaymentForm
        localProduct={localProduct}
        stripePromise={stripePromise}
        intentClientSecret={intentClientSecret}
        handlePaymentSuccess={handlePaymentSuccess}
      />
    </>
  );
}
