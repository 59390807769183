import { Divider } from 'components';
import { GoogleAuth } from 'containers/authentication';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useUnguardedSearchParams } from 'hooks';

import { LayoutCard } from 'features/shared/layout';

import { PasswordLoginForm } from './form';

export default function PasswordLoginCard() {
  const { t } = useTranslation('customer');
  const newPassword =
    useUnguardedSearchParams({ param: 'newPassword' }) === 'true';

  return (
    <div>
      <LayoutCard
        title={t('login.title.default')}
        body={
          <div className="w-full sm:px-8">
            <p className="text-base font-medium text-gray-800 pb-8">
              {t('login.description.password')}
            </p>
            {newPassword && <div>{t('password.reset.success.paragraph')}</div>}
            <div className="pb-4">
              <PasswordLoginForm />
            </div>
            <p className="font-medium text-gray-800">
              {t('login.switch.magic')},{' '}
              <Link className="text-purple-900" to="/login">
                {t('general.clickhere')}
              </Link>
            </p>
            <Divider text="ou" />
            <GoogleAuth />
          </div>
        }
      />
    </div>
  );
}
