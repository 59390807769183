/* eslint-disable react-hooks/exhaustive-deps */
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import { useCreateOrderMutation } from 'api/customer/mutations.index';
import { useSubscriptionQuery } from 'api/customer/subscriptions';

import { useCustomer } from 'hooks';

import { LoadingCard } from 'features/shared/loading';

import { FunnelConsistencyMetadata } from 'types/funnel';

import CustomerQuestionPayment from './CustomerQuestionPayment';
import CustomerSubscriptionPayment from './CustomerSubscriptionPayment';

export interface ConfirmationCustomerProps {
  productId: number;
  context: FunnelConsistencyMetadata['context'];
  questionContent: string;
  productSkuCode: FunnelConsistencyMetadata['product']['skuCode'];
  distinctId?: string;
}

export default function ConfirmationCustomer({
  productId,
  context,
  questionContent,
  productSkuCode,
  distinctId
}: ConfirmationCustomerProps) {
  const { id: customerId } = useCustomer();
  const [intentClientSecret, setIntentClientSecret] = useState<string>('');

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUB_KEY as string
  );

  const {
    mutate: createOrderMutate,
    isLoading: isCreateOrderLoading,
    isSuccess: isCreateOrderSuccess
  } = useCreateOrderMutation();

  useEffect(() => {
    createOrderMutate(
      {
        paymentProvider: 'stripe',
        customerId,
        productId
      },
      {
        onSuccess: (order) => {
          const { paymentData } = order;
          setIntentClientSecret(paymentData.payment_intent.client_secret);
        }
      }
    );
  }, []);

  const subscription = useSubscriptionQuery({
    customerId,
    enabled: isCreateOrderSuccess
  });

  const isFunnelQuestion = context === 'question_funnel';

  if (isCreateOrderLoading || !subscription.isSuccess) {
    return <LoadingCard />;
  }

  if (isFunnelQuestion) {
    return (
      <CustomerQuestionPayment
        subscriptionId={subscription?.data?.id as string}
        stripePromise={stripePromise}
        intentClientSecret={intentClientSecret}
        questionContent={questionContent}
        productSkuCode={productSkuCode}
        distinctId={distinctId}
      />
    );
  }

  return (
    <CustomerSubscriptionPayment
      productSkuCode={productSkuCode}
      stripePromise={stripePromise}
      intentClientSecret={intentClientSecret}
    />
  );
}
