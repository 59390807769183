import { useQuery } from 'react-query';

import { getFunnelConsistencyMetadata } from './api';

export function useFunnelConsistencyMetadataQuery({
  funnelId
}: {
  funnelId: string;
}) {
  return useQuery(['funnel-consistency-metadata', funnelId], () =>
    getFunnelConsistencyMetadata(funnelId)
  );
}
