import { useTranslation } from 'react-i18next';

import { useUnguardedSearchParams } from 'hooks';

import { Alert } from 'features/shared/alerts';
import { LayoutCard } from 'features/shared/layout';

import { OtpLoginCodeForm, OtpLoginEmailForm } from './form';

function OtpLoginCard() {
  const { t } = useTranslation('customer');

  const isCodeSent = useUnguardedSearchParams({ param: 'code' });

  return (
    <div>
      <LayoutCard
        title={t('login.title.default')}
        body={
          <div className="w-full">
            <p className="text-base font-medium text-gray-800 pb-8">
              {t('login.description.otp')}
            </p>
            <div className="pb-4">
              {isCodeSent ? (
                <div>
                  <div className="mb-4">
                    <Alert>
                      <p>{t('funnel.otp.submitted')}</p>
                    </Alert>
                  </div>
                  <OtpLoginCodeForm />
                </div>
              ) : (
                <OtpLoginEmailForm />
              )}
            </div>

            <p className="font-medium text-gray-800">
              {t('login.switch.code')},{' '}
              <a className="text-purple-900" href="/login?otp=true">
                {t('general.clickhere')}
              </a>
            </p>
          </div>
        }
      />
    </div>
  );
}

export { OtpLoginCard };
