export const SKIP_PROFILE_COMPLETION = 'skipProfileCompletion';
export const SKIP_ALPHABET_BANNER = 'skipAlphabetBanner';

export const SKIP_ONBOARDING_COMPLETION = 'skipOnboardingCompletion';

export const DEFAULT_CITY_PLACEHOLDER = 'Paris';
export const DEFAULT_LANGUAGES_PLACEHOLDER = 'Français';
export const DEFAULT_YEARPICKER_PLACEHOLDER = '2012';

export const DEFAULT_ADDRESS_PLACEHOLDER = '123 rue de France';

export const POSTALCODE_LENGTH = 5;
