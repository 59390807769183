import { PropsWithChildren } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

import { useTracking } from 'context';

interface TrackedLinkProps extends LinkProps {
  name: string;
  external?: boolean;
  metadata?: Object;
}

function TrackedLink({
  to,
  children,
  name,
  external = false,
  metadata = {},
  ...props
}: PropsWithChildren<TrackedLinkProps>) {
  const { pathname } = useLocation();
  const { analytics } = useTracking();
  const prefix = pathname.substring(1).replaceAll('/', '_');
  const Component = external ? 'a' : Link;

  return (
    <Component
      {...(external && {
        target: '_blank',
        rel: 'noreferrer',
        href: to as string
      })}
      to={to}
      onClick={() => {
        analytics?.track(`${prefix}_${name}_clicked`, metadata);
      }}
      {...props}
    >
      {children}
    </Component>
  );
}

export default TrackedLink;
