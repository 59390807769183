import { yupResolver } from '@hookform/resolvers/yup';
import { Title } from 'components';
import { addDays, format } from 'date-fns';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  premiumPlusAdditionalDataSchema,
  premiumPlusAdditionalWithPartnerDataSchema
} from 'schemas';

import { Button } from 'features/shared/buttons';
import { SelectInputGroup, TextInputGroup } from 'features/shared/forms';

import { FunnelStep, StepStatus } from 'types/funnel';
import { SubscribePremiumPlusAdditionalInputs } from 'types/inputs';

import { Steps, TitleCard } from '../funnels';
import {
  SubscribePremiumPlusStep,
  useSubscribePremiumPlusContext
} from './premium-plus.context';

const socioeconomicOptions = [
  { label: 'Agriculteurs exploitants', value: '10' },
  { label: 'Artisans, commerçants et chefs d’entreprise', value: '21' },
  {
    label: 'Cadres et professions intellectuelles supérieures',
    value: '36'
  },
  { label: 'Professions intermédiaires', value: '46' },
  { label: 'Employés', value: '54' },
  { label: 'Ouvriers', value: '60' },
  { label: 'Retraités', value: '70' },
  { label: 'Autres personnes sans activité professionelles', value: '81' }
];

export default function AdditionalDataStep() {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const { setCurrentStep, additionalData, requiredData, setAdditionalData } =
    useSubscribePremiumPlusContext();

  const withPartner = useMemo(
    () => !!requiredData.partnerLastName,
    [requiredData]
  );

  const funnelSteps: FunnelStep[] = useMemo(
    () => [
      {
        id: 1,
        label: t('subscribe.premiumPlus.step.1'),
        status: StepStatus.COMPLETE
      },
      {
        id: 2,
        label: t('subscribe.premiumPlus.step.2'),
        status: StepStatus.CURRENT
      },
      {
        id: 3,
        label: t('subscribe.premiumPlus.step.3'),
        status: StepStatus.UPCOMING
      },
      {
        id: 4,
        label: t('subscribe.premiumPlus.step.4'),
        status: StepStatus.UPCOMING
      }
    ],
    [t]
  );

  const funnelTitle = [
    'Nous avons besoin de quelques',
    'informations supplémentaires'
  ];

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SubscribePremiumPlusAdditionalInputs>({
    resolver: yupResolver(
      withPartner
        ? premiumPlusAdditionalWithPartnerDataSchema(tForm)
        : premiumPlusAdditionalDataSchema(tForm)
    ),
    defaultValues: {
      socioeconomicStatus: additionalData.socioeconomicStatus,
      jobTitle: additionalData.jobTitle,
      partnerSocioeconomicStatus: additionalData.partnerSocioeconomicStatus,
      partnerJobTitle: additionalData.partnerJobTitle,
      isInLitigation: additionalData.isInLitigation,
      warrantyDate: additionalData.warrantyDate
        ? format(new Date(additionalData.warrantyDate), 'yyyy-MM-dd')
        : format(addDays(new Date(), 1), 'yyyy-MM-dd')
    }
  });

  const onSubmit = (data: SubscribePremiumPlusAdditionalInputs) => {
    // Store additional data from form in context
    setAdditionalData({ ...data });
    // Increment step
    setCurrentStep(SubscribePremiumPlusStep.Confirmation);
  };

  const handlePreviousStep = () =>
    setCurrentStep(SubscribePremiumPlusStep.RequiredData);

  return (
    <>
      <Steps funnelSteps={funnelSteps} variant="vertical" />
      <TitleCard title={funnelTitle} />
      <div className="w-full md:max-w-3xl mb-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Qualitative informations */}
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6 mb-2">
            <div className="mb-4">
              <Title variant="h3">{t('subscribe.personalInformations')}</Title>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between gap-8 mb-4">
              <div className="w-full lg:w-1/2">
                <SelectInputGroup
                  options={socioeconomicOptions}
                  name="socioeconomicStatus"
                  label={tForm('label.socioeconomicStatus')}
                  error={errors.socioeconomicStatus}
                  register={register}
                />
              </div>
              <div className="w-full lg:w-1/2">
                <TextInputGroup
                  label={tForm('label.jobTitle')}
                  name="jobTitle"
                  error={errors.jobTitle}
                  register={register}
                />
              </div>
            </div>
            {withPartner && (
              <div className="flex flex-col lg:flex-row items-center justify-between gap-8 mb-4">
                <div className="w-full lg:w-1/2">
                  <SelectInputGroup
                    options={socioeconomicOptions}
                    name="partnerSocioeconomicStatus"
                    label={tForm('label.socioeconomicStatus.partner')}
                    error={errors.partnerSocioeconomicStatus}
                    register={register}
                  />
                </div>
                <div className="w-full lg:w-1/2">
                  <TextInputGroup
                    label={tForm('label.jobTitle.partner')}
                    name="partnerJobTitle"
                    error={errors.partnerJobTitle}
                    register={register}
                  />
                </div>
              </div>
            )}

            <div>
              <fieldset>
                <legend className="mb-2">Avez-vous un litige en cours ?</legend>
                <div className="flex items-center gap-2">
                  <input
                    id="no"
                    {...register('isInLitigation')}
                    type="radio"
                    value="false"
                  />
                  <label htmlFor="no">Non</label>
                </div>

                <div className="flex items-center gap-2">
                  <input
                    id="yes"
                    {...register('isInLitigation')}
                    type="radio"
                    value="true"
                  />
                  <label htmlFor="yes">
                    Oui (l’assurance de Protection Juridique ne couvre pas les
                    litiges ou différends antérieurs à l'adhésion au contrat)
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6 mb-2">
            <div className="mb-4">
              <Title variant="h3">{t('subscribe.startOfContract')}</Title>
            </div>
            <div className="flex items-center justify-between gap-8 mb-4">
              <div className="w-full">
                <TextInputGroup
                  type="date"
                  name="warrantyDate"
                  label={tForm('label.warrantyDate')}
                  error={errors.warrantyDate}
                  register={register}
                />
              </div>
            </div>
          </div>
          {/* Submit / Previous section */}
          <div className="shadow-base w-full rounded bg-white px-6 py-5 sm:p-6">
            <div className="flex items-center gap-4">
              <div className="w-1/3">
                <Button
                  size="medium"
                  variant="secondary"
                  fullWidth
                  label={t('general.back')}
                  onClick={handlePreviousStep}
                />
              </div>
              <div className="w-2/3">
                <Button
                  submit
                  size="medium"
                  variant="tertiary"
                  fullWidth
                  label={t('general.continue')}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="text-center w-full md:max-w-3xl">
        <p className="text-sm text-gray-700">
          Les informations recueillies font l’objet de traitements informatiques
          par la SAS AFAAFF! et ses partenaires pour les finalités de
          souscription à l’abonnement et gestion de l’assistance juridique, de
          passation et gestion des adhésions au contrat collectif d’assurance de
          Protection Juridique, et par Matmut Protection Juridique pour la
          gestion des sinistres. Vous pouvez exercer vos droits auprès du
          Délégué à la Protection des Données d’ekie : CABINET BOUCHARA –
          AVOCATS, Service DPO, 17 rue du Colisée – 75008 PARIS -
          contact-avocat@ekie.co Pour en savoir plus sur l’usage de vos données
          et vos droits, consultez la Charte de la SAS AFAAFF! sur le respect de
          la vie privée disponible sur le site www.avostart.fr., rubrique «Vie
          privée».
        </p>
        <br />
        <p className="text-sm text-gray-700">
          Enfin, vous avez la faculté de vous inscrire gratuitement sur la liste
          d’opposition au démarchage téléphonique sur www.bloctel.gouv.fr.
          Néanmoins nous pouvons toujours vous téléphoner lorsqu’il s’agit de
          sollicitations intervenant dans le cadre de l’exécution d’un contrat
          en cours et ayant un rapport avec l’objet de ce contrat, y compris
          lorsqu’il s’agit de vous proposer des produits ou des services
          afférents ou complémentaires à l’objet du contrat en cours ou de
          nature à améliorer ses performances ou sa qualité.
        </p>
      </div>
    </>
  );
}
