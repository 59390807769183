import { GoogleRating } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateUnauthenticatedAppointmentVoteMutation } from 'api/customer/mutations.index';

import { LayoutCard } from 'features/shared/layout';
import { LoadingSpinner } from 'features/shared/loading';

interface ConsultationAppointmentVoteSatisfiedCardProps {
  voteLinkId: string;
  appointmentId: number;
  note: number;
}

export default function ConsultationAppointmentVoteSatisfiedCard({
  voteLinkId,
  appointmentId,
  note
}: ConsultationAppointmentVoteSatisfiedCardProps) {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const { mutate, isLoading: isCreateAppointmentVoteLoading } =
    useCreateUnauthenticatedAppointmentVoteMutation();

  useEffect(() => {
    mutate({
      appointmentId,
      newAppointmentVote: {
        voteLinkId,
        note
      }
    });
  }, [appointmentId, mutate, navigate, note, voteLinkId]);

  return (
    <LayoutCard
      title={`${t('general.thank')} 🥳`}
      body={
        isCreateAppointmentVoteLoading ? (
          <LoadingSpinner purple />
        ) : (
          <div className="flex flex-col gap-4">
            <GoogleRating isB2b={false} />
          </div>
        )
      }
    />
  );
}
