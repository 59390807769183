import { SVGProps } from 'react';

export default function France5Logo({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 96 " {...props}>
      <g fill="none">
        <path
          d="M30 66.66c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.715 15 15"
          fill="#00d182"
        />
        <path
          d="M83.42 46.531c-2.346-1.897-7.89-6.338-18.16-6.226-3.317.036-5.77.525-7.253.931l1.228-12.545H88.24V15H46.963l-5.02 42.325 10.766 1.124c4.04-3.392 8.368-4.346 11.49-4.365 3.542-.02 6.983 1.015 9.483 3.216 2.817 2.48 4.797 5.94 4.797 10.521 0 8.556-7.251 14.489-14.615 14.489-5.912 0-11.268-3.308-14.502-7.986l-10.822 9.24C45.457 91.552 54.716 96 64.423 96 80.599 96 93.54 84.706 93.54 67.821c0-8.786-3.887-16.247-10.12-21.29"
          fill="#232323"
        />
      </g>
    </svg>
  );
}
