import { formatProductFromApi } from 'api/customer/products/formatting';

import {
  ApiFunnelConsistencyMetadata,
  FunnelConsistencyMetadata
} from 'types/funnel';

export function formatFunnelConsistencyMetadataFromApi(
  apiFunnelConsistencyMetadata: ApiFunnelConsistencyMetadata
): FunnelConsistencyMetadata {
  const product = formatProductFromApi(apiFunnelConsistencyMetadata.product);
  return {
    ...apiFunnelConsistencyMetadata,
    product
  };
}
