import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { Title } from 'components';
import { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { contactProSchema } from 'schemas';

import { useCreateFunnelProLoginOtpMutation } from 'api/shared/login';

import { QuestionFunnelProductType } from 'features/customer/funnels/FunnelStepIndications';
import { Alert } from 'features/shared/alerts';
import { Button } from 'features/shared/buttons';
import { ContainerCard } from 'features/shared/container';
import { TextInputGroup } from 'features/shared/forms';
import { GiftIcon } from 'features/shared/icons';

import { FunnelSharedLoginConsistencyMetadata } from 'types/funnel';
import { LoginProFormFunnelInputs } from 'types/inputs';

interface EmailProFormProps {
  handleChangeProductTypeSubmit?: (
    currentType: QuestionFunnelProductType
  ) => void;
  consistencyMetadata: FunnelSharedLoginConsistencyMetadata;
}

export default function EmailProForm({
  handleChangeProductTypeSubmit,
  consistencyMetadata: {
    context,
    userType = undefined,
    skuCode = undefined,
    questionContent = undefined,
    partnerSlug = undefined
  }
}: EmailProFormProps) {
  const { mutate, isLoading } = useCreateFunnelProLoginOtpMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginProFormFunnelInputs>({
    resolver: yupResolver(contactProSchema(tForm))
  });

  const handleError = useErrorHandler();

  const onSubmit: SubmitHandler<LoginProFormFunnelInputs> = async (
    formData
  ) => {
    const { email, phone, companyName, jobTitle } = formData;
    mutate(
      {
        email,
        userType,
        companyName,
        skuCode,
        partnerSlug,
        context,
        phone,
        questionContent,
        jobTitle
      },
      {
        onSuccess: (data) => {
          const { methodId, consistencyId } = data;
          searchParams.append('methodId', methodId);
          searchParams.append('consistencyId', consistencyId);
          searchParams.append('email', email);
          setSearchParams(searchParams);
        },
        onError: (error: any) => {
          const isUnauthorizedError = error?.response?.status === 403;
          if (isUnauthorizedError) {
            setError(
              'email',
              {
                message: "Merci d'entrer votre email professionnel.",
                type: 'focus'
              },
              { shouldFocus: true }
            );
          } else {
            handleError(error);
          }
        }
      }
    );
  };

  const handleBackToStandardOffer = useCallback(() => {
    if (handleChangeProductTypeSubmit)
      handleChangeProductTypeSubmit(QuestionFunnelProductType.Standard);
  }, [handleChangeProductTypeSubmit]);

  return (
    <ContainerCard>
      {partnerSlug ? (
        <div className="mb-4 w-full">
          <Alert>
            <p>{t('alert.workEmail')}</p>
          </Alert>
        </div>
      ) : null}
      <div className="mb-9 text-black stroke-purple-900">
        <div className="w-full flex flex-col items-start justify-start">
          <div className="mb-4">
            <Title variant="h3">{t('funnel.question.promo.title')}</Title>
          </div>
          <div className="flex flex-row items-start pr-10 md:pr-20">
            <GiftIcon
              className="h-6 w-6 min-w-6 min-h-6 font-light mr-3.5 hidden sm:block"
              aria-hidden="true"
            />
            <p className="w-full font-sans text-left text-base">
              <span>{t('funnel.question.promo.paragraph.step2')}</span>
              {` `}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-col gap-x-4 gap-y-6"
        >
          <div className="flex flex-col justify-between gap-4 md:flex-row">
            <TextInputGroup
              autofocus
              type="email"
              fullWidth
              placeholder={tForm('placeholder.email')}
              label={tForm('label.email.pro')}
              name="email"
              register={register}
              error={errors.email}
            />
            <TextInputGroup
              type="phone"
              fullWidth
              placeholder="+33601010101"
              label={tForm('label.phone')}
              name="phone"
              register={register}
              error={errors.phone}
            />
          </div>
          <div className="flex flex-col justify-between gap-4 md:flex-row">
            <TextInputGroup
              type="text"
              fullWidth
              placeholder={tForm('placeholder.companyName')}
              label={tForm('label.companyName')}
              name="companyName"
              register={register}
              error={errors.companyName}
            />
            <TextInputGroup
              type="text"
              fullWidth
              placeholder={tForm('placeholder.jobTitle')}
              label={tForm('label.jobTitle_optional')}
              name="jobTitle"
              register={register}
              error={errors.jobTitle}
            />
          </div>
          <div
            className={clsx('w-full flex flex-row items-center', {
              'gap-x-4': !!handleChangeProductTypeSubmit
            })}
          >
            {!!handleChangeProductTypeSubmit && (
              <Button
                onClick={handleBackToStandardOffer}
                size="medium"
                variant="secondary"
                label={t('general.back')}
              />
            )}
            <Button
              isLoading={isLoading}
              fullWidth
              submit
              size="medium"
              variant="primary"
              label={t('general.continue')}
            />
          </div>
        </form>
      </div>
    </ContainerCard>
  );
}
