import {
  ConfirmationErrorAlreadySubscribed,
  ConfirmationErrorCannotAskQuestion
} from 'features/customer/funnels/confirmation/error';

import { CustomerUsageMeter } from 'types/customer';
import { FunnelConsistencyMetadata } from 'types/funnel';

import ConfirmationCustomer, {
  ConfirmationCustomerProps
} from './ConfirmationCustomer';
import ConfirmationQuestionWithoutOrder from './ConfirmationCustomerQuestionWithoutOrder';

interface ConfirmationCustomerGuardProps extends ConfirmationCustomerProps {
  activeSubscriptionId: CustomerUsageMeter['activeSubscriptionId'];
  canAskNewQuestion: CustomerUsageMeter['canAskNewQuestion'];
  hasActiveSubscription: CustomerUsageMeter['hasActiveSubscription'];
  productSkuCode: FunnelConsistencyMetadata['product']['skuCode'];
  distinctId?: string;
}

export default function ConfirmationCustomerGuard({
  productId,
  questionContent,
  context,
  activeSubscriptionId,
  canAskNewQuestion,
  hasActiveSubscription,
  productSkuCode,
  distinctId
}: ConfirmationCustomerGuardProps) {
  const isFunnelQuestion = context === 'question_funnel';
  const isFunnelSubscription = context === 'subscription_funnel';
  const isAlreadySubscribed = hasActiveSubscription && !!activeSubscriptionId;

  // Questions
  if (isFunnelQuestion && isAlreadySubscribed && canAskNewQuestion) {
    return (
      <ConfirmationQuestionWithoutOrder
        questionContent={questionContent}
        subscriptionId={activeSubscriptionId}
      />
    );
  }

  // Error - Cannot Ask Question
  if (isFunnelQuestion && isAlreadySubscribed && !canAskNewQuestion) {
    return <ConfirmationErrorCannotAskQuestion />;
  }

  // Subscription
  // Error - Already Subscribed
  if (isFunnelSubscription && isAlreadySubscribed) {
    return <ConfirmationErrorAlreadySubscribed />;
  }

  return (
    <ConfirmationCustomer
      productSkuCode={productSkuCode}
      context={context}
      productId={productId}
      questionContent={questionContent}
      distinctId={distinctId}
    />
  );
}
