import { Title } from 'components';
import { Card, CardContent } from 'components/card';
import { ReactNode } from 'react';

interface NotificationCardProps {
  title: ReactNode;
  content: string;
  status: ReactNode;
  date: string;
  icon?: ReactNode;
}

function NotificationCard({
  title,
  content,
  date,
  status,
  icon
}: NotificationCardProps) {
  return (
    <Card>
      <Title variant="h3" className="!text-sm uppercase tracking-wider">
        {title}
      </Title>
      <CardContent className="!text-gray-700 line-clamp-3 px-0">
        {content}
      </CardContent>
      <CardContent className="px-0 gap-4">
        {icon && (
          <div className="self-center">
            <div className="rounded-full flex items-center text-sm bg-purple-900 text-white px-2 w-8 h-8">
              {icon}
            </div>
          </div>
        )}
        <div>
          <div>{date}</div>
          <div className="normal-case first-letter:uppercase text-gray-700">
            {status}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default NotificationCard;
