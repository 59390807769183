import clsx from 'clsx';
import { Title } from 'components';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { useCustomerAccount } from 'hooks';

interface AccountContentLayoutProps {
  title: string;
  children: ReactNode;
  full?: Boolean;
}

export default function AccountContentLayout({
  title,
  children,
  full = false
}: AccountContentLayoutProps) {
  const { pathname } = useLocation();
  const { questions } = useCustomerAccount();

  const hasQuestions = questions.questions.length > 0;
  const isQuestionPage = pathname === '/account/questions';

  return (
    <>
      <div className="max-w-[688px] px-4 sm:px-6 lg:pr-8 lg:ml-[120px]">
        <div className="mb-4 md:mb-6">
          <Title variant="h1">{title}</Title>
        </div>
      </div>
      <div className="mb-6 border-b border-[#D0D0D0]" />
      <div
        className={clsx(
          'px-4 sm:px-6 ',
          { 'max-w-[688px]': !full },
          { 'max-w-[1280px]': full },
          isQuestionPage && !hasQuestions
            ? 'md:mx-auto'
            : 'lg:mr-8 lg:ml-[120px]'
        )}
      >
        {children}
      </div>
    </>
  );
}
