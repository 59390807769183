import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AccountContentLayout } from 'features/customer/account/content';
import { Button } from 'features/shared/buttons';

export default function ConsultationRescheduleBlockedBooking() {
  const { t } = useTranslation('customer');
  const naviagte = useNavigate();
  return (
    <AccountContentLayout title={t('account.consultations.reschedule.title')}>
      <div className="mb-4">
        <p>{t('account.consultations.reschedule.blocked')}</p>
      </div>
      <div className="mb-4">
        <p>{t('account.consultations.reschedule.blocked.text')}</p>
      </div>

      <div className="grid place-items-center">
        <Button
          onClick={() => naviagte('/account/consultations')}
          variant="primary"
          size="medium"
          fullWidth
          label={t('account.consultations.reschedule.blocked.cta')}
        />
      </div>
    </AccountContentLayout>
  );
}
