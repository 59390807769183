import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { MouseEventHandler, PropsWithChildren } from 'react';

import Title from './Title';

type TabItem = {
  name: string;
  id: string;
  path?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};
interface TabsProps {
  items: TabItem[];
  defaultIndex?: number;
  className?: string;
  title?: string;
}

const Tabs = ({
  items,
  children,
  defaultIndex = 0,
  title,
  className
}: PropsWithChildren<TabsProps>) => (
  <Tab.Group defaultIndex={defaultIndex}>
    <Tab.List
      className={classNames(
        'flex items-center justify-between rounded-xl p-1 mb-4',
        className
      )}
    >
      {title && (
        <Title component="h3" variant="h3" className="!font-bold -mb-2">
          {title}
        </Title>
      )}
      <div className="flex gap-8">
        {items.map((item) => (
          <Tab
            key={item.id}
            onClick={item.onClick}
            className={({ selected }) =>
              classNames(
                'w-full md:w-fit py-2 text-base font-medium leading-5 text-black border-b-2 outline-none',
                selected
                  ? 'text-purple-900 border-purple-900'
                  : 'hover:bg-white/[0.12] hover:text-purple-900 border-gray-300'
              )
            }
          >
            {item.name}
          </Tab>
        ))}
      </div>
    </Tab.List>
    {children}
  </Tab.Group>
);

export default Tabs;
