import clsx from 'clsx';
import moment from 'moment';
import { useCallback } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { FieldError } from 'react-hook-form';

import { InputErrorMessage } from 'features/shared/forms';

interface CustomInputType {
  label: string;
  error: FieldError | undefined;
  disabled?: boolean;
  placeholder?: string;
}

export function CustomInput(props: CustomInputType) {
  const { error, disabled, placeholder, label } = props;
  return (
    <div className="w-full">
      <div id="input-group" className={clsx({ 'w-full': true })}>
        <label htmlFor="languages" className="input-label">
          {label}
        </label>
        <input
          {...props}
          type="text"
          placeholder={placeholder}
          className={clsx(
            'input-text w-full focus:drop-shadow-input focus:border-purple-900 focus:shadow-purple-900 h-12',
            {
              'text-red-800 border-red-800 bg-red-400': error
            },
            { 'text-gray-700': disabled }
          )}
        />
      </div>
      {error && <InputErrorMessage error={error} />}
    </div>
  );
}

interface YearPickerProps {
  onChange: (value: any) => void;
  error: FieldError | undefined;
  label: string;
  disabled?: boolean;
  placeholder?: string;
}

function YearPicker({
  onChange,
  label,
  error,
  disabled = false,
  placeholder = ''
}: YearPickerProps) {
  const disableFutureDt = useCallback((current: any) => {
    const today = moment();
    return current.isBefore(today);
  }, []);
  return (
    <Datetime
      onChange={onChange}
      dateFormat="YYYY"
      timeFormat={false}
      renderInput={(props) =>
        CustomInput({ ...props, label, error, disabled, placeholder })
      }
      closeOnSelect
      closeOnClickOutside
      isValidDate={disableFutureDt}
    />
  );
}

export { YearPicker };
