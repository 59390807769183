import { ArrowRightIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { Title } from 'components';
import { useCallback, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { contactSchema } from 'schemas';

import { useCreateFunnelLoginOtpMutation } from 'api/shared/login';

import {
  QuestionFunnelProductType,
  QuestionFunnelStep
} from 'features/customer/funnels/FunnelStepIndications';
import { Button } from 'features/shared/buttons';
import { ContainerCard } from 'features/shared/container';
import { TextInputGroup } from 'features/shared/forms';
import { GiftIcon } from 'features/shared/icons';

import { FunnelSharedLoginConsistencyMetadata } from 'types/funnel';
import { LoginFormFunnelInputs } from 'types/inputs';

interface EmailFormProps {
  handleChangeProductTypeSubmit?: (
    currentType: QuestionFunnelProductType
  ) => void;
  handleChangeStep?(
    step: QuestionFunnelStep.Content | QuestionFunnelStep.Login
  ): void;
  consistencyMetadata: FunnelSharedLoginConsistencyMetadata;
}

export default function EmailForm({
  handleChangeProductTypeSubmit,
  handleChangeStep,
  consistencyMetadata: {
    context,
    userType = undefined,
    skuCode = undefined,
    questionContent = undefined,
    partnerSlug = undefined
  }
}: EmailFormProps) {
  const location = useLocation();

  const { mutate, isLoading } = useCreateFunnelLoginOtpMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginFormFunnelInputs>({
    resolver: yupResolver(contactSchema(tForm))
  });

  const handleError = useErrorHandler();

  const onSubmit: SubmitHandler<LoginFormFunnelInputs> = async (formData) => {
    const { email, phone } = formData;
    mutate(
      {
        email,
        userType,
        skuCode,
        partnerSlug,
        context,
        phone,
        questionContent
      },
      {
        onSuccess: (data) => {
          const { methodId, consistencyId } = data;
          searchParams.append('methodId', methodId);
          searchParams.append('consistencyId', consistencyId);
          searchParams.append('email', email);
          setSearchParams(searchParams);
        },
        onError: (error: any) => {
          const isUnauthorizedError = error?.response?.status === 403;
          if (isUnauthorizedError) {
            setError(
              'email',
              {
                message: partnerSlug
                  ? "Une erreur s'est produite."
                  : "Merci d'entrer votre email professionnel.",
                type: 'focus'
              },
              { shouldFocus: true }
            );
          } else {
            handleError(error);
          }
        }
      }
    );
  };

  const handleChangeProductTypeSubmitCall = useCallback(() => {
    if (handleChangeProductTypeSubmit)
      handleChangeProductTypeSubmit(QuestionFunnelProductType.Promo);
  }, [handleChangeProductTypeSubmit]);

  const onPrevStep = useCallback(() => {
    handleChangeStep && handleChangeStep(QuestionFunnelStep.Content);
  }, [handleChangeStep]);

  const isQuestionContactPage = useMemo(
    () => location.pathname === '/funnel/question/contact' && !partnerSlug,
    [location, partnerSlug]
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="gap-2 w-full md:max-w-3xl flex flex-col"
    >
      <ContainerCard>
        <div className="flex flex-col">
          <div className="flex w-full flex-col gap-4">
            <div className="flex flex-col justify-between gap-4 md:flex-row">
              <TextInputGroup
                autofocus
                type="email"
                fullWidth
                placeholder={tForm('placeholder.email')}
                label={tForm('label.email')}
                name="email"
                register={register}
                error={errors.email}
              />
              <TextInputGroup
                type="phone"
                fullWidth
                placeholder="+33601010101"
                label={tForm('label.phone')}
                name="phone"
                register={register}
                error={errors.phone}
              />
            </div>
          </div>
          {isQuestionContactPage && (
            <>
              <div className="flex flex-row items-center gap-x-4 my-6">
                <p className="text-gray-700 text-sm font-medium">OU</p>
                <div className="h-px w-full bg-gray-500 " />
              </div>
              <div className="w-full flex flex-col items-start justify-start bg-purple-200 p-8 rounded">
                <div className="mb-4">
                  <Title variant="h3">{t('funnel.question.promo.title')}</Title>
                </div>

                <div className="flex flex-row items-start pr-1 stroke-purple-900 mb-6">
                  <GiftIcon
                    className="h-6 w-6 min-w-6 min-h-6 font-light mr-3.5 hidden sm:block"
                    aria-hidden="true"
                  />
                  <p className="w-full font-sans text-left text-base">
                    <span>{t('funnel.question.promo.paragraph')}</span>
                    <span className="font-bold">
                      {t('funnel.question.promo.paragraph.bold')}
                    </span>
                    <span>{t('funnel.question.promo.paragraph.end')}</span>
                  </p>
                </div>
                <button
                  type="button"
                  onClick={handleChangeProductTypeSubmitCall}
                  className="bg-transparent text-black border border-gray-700 hover:opacity-60 font-bold px-4 py-2 text-sm sm:px-4 sm:py-2 sm:text-lg flex items-center rounded focus:outline-none gap-2 w-full justify-center"
                >
                  <p className="mr-1">{t('funnel.question.promo.cta')}</p>
                  <ArrowRightIcon
                    className="h-4 w-4 font-light mr-3.5 hidden sm:block"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </>
          )}
        </div>
      </ContainerCard>
      <ContainerCard>
        <div
          className={clsx('w-full flex items-center', {
            'gap-x-4': !!handleChangeStep
          })}
        >
          {!!handleChangeStep && (
            <Button
              isLoading={isLoading}
              onClick={onPrevStep}
              size="medium"
              variant="secondary"
              label={t('general.back')}
            />
          )}
          <Button
            isLoading={isLoading}
            fullWidth
            submit
            size="medium"
            variant="primary"
            label={t('general.continue')}
          />
        </div>
      </ContainerCard>
    </form>
  );
}
