import { TrashIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useCallback } from 'react';

interface TagsProps {
  onDeleteTag?(value: any): void;
  value: any;
  isDeleteable?: boolean;
}

function AutocompleteTags({
  onDeleteTag,
  value = {},
  isDeleteable = false
}: TagsProps) {
  const onDelete = useCallback(() => {
    if (onDeleteTag) onDeleteTag(value);
  }, [onDeleteTag, value]);
  return (
    <div
      id="tags"
      className={clsx(
        `flex items-center justify-center w-fit max-h-8  font-body py-1 pl-2 px-1 rounded-md text-sm`,
        'bg-purple-700 text-white hover:bg-opacity-80 focus:bg-opacity-80 focus:ring-2 focus:ring-opacity-70 focus:ring-offset-2 focus:text-white'
      )}
    >
      <p>{`${value.name}`}</p>
      <div className="ml-4 flex items-center">
        {isDeleteable && (
          <button type="button" onClick={onDelete} className="mx-1">
            <TrashIcon className="h-5 w-5 text-white" />
          </button>
        )}
      </div>
    </div>
  );
}

export { AutocompleteTags };
