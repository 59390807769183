import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { SecuredBy } from 'features/customer/funnels/confirmation/credentials';
import { Button } from 'features/shared/buttons';
import { LoadingSpinner } from 'features/shared/loading';
import { Toast } from 'features/shared/toasts';

import { useSubscribePremiumPlusContext } from '../premium-plus/premium-plus.context';
import { useOptions } from './payment.constants';

export enum FormType {
  Card = 'cardForm',
  Element = 'paymentElement'
}

export enum PaymentType {
  Subscription = 'subscription',
  OneTime = 'oneTime'
}

interface StripeFormProps {
  paymentType: PaymentType;
  stripePromise: Promise<Stripe | null>;
  intentClientSecret: string;
  formType?: FormType;
  handlePaymentSuccess: () => void;
}

export default function StripeForm({
  formType = FormType.Card,
  paymentType,
  stripePromise,
  intentClientSecret,
  handlePaymentSuccess
}: StripeFormProps) {
  if (!intentClientSecret.length) {
    return <LoadingSpinner purple large />;
  }

  if (formType === FormType.Element) {
    return (
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: intentClientSecret }}
      >
        <PaymentElementForm handlePaymentSuccess={handlePaymentSuccess} />
      </Elements>
    );
  }

  // Default is card
  return (
    <Elements stripe={stripePromise}>
      <CardForm
        paymentType={paymentType}
        handlePaymentSuccess={handlePaymentSuccess}
        clientSecret={intentClientSecret}
      />
    </Elements>
  );
}

interface PaymentElementFormProps {
  handlePaymentSuccess: () => void;
}

function PaymentElementForm({ handlePaymentSuccess }: PaymentElementFormProps) {
  const {
    confirmationData: { signatureLink },
    additionalData: { warrantyDate }
  } = useSubscribePremiumPlusContext();
  const { t } = useTranslation('customer');
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const params = new URLSearchParams({
      success: 'true',
      signatureLink,
      warrantyDate
    });

    const returnUrl = `${
      process.env.REACT_APP_BASE_URL
    }/funnel/subscribe/premium-plus?${params.toString()}`;

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error) {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('funnel.payment.error')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
    }

    setIsLoading(false);

    handlePaymentSuccess();
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4">
        <PaymentElement id="payment-element" />
        <SecuredBy />
        <div className="text-center">
          <div className="mb-2">
            <Button
              submit
              variant="primary"
              label={t('general.pay')}
              size="large"
              fullWidth
              isLoading={isLoading}
              disabled={!stripe || !elements}
            />
          </div>
          <span className="text-gray-700">
            {t('payment.cgu.confirm', { ctaLabel: t('general.pay') })}{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="/cgu.pdf"
              className="underline"
            >
              {t('payment.cgu')}
            </a>
          </span>
        </div>
      </div>
    </form>
  );
}

interface CardFormProps {
  paymentType: StripeFormProps['paymentType'];
  clientSecret: string;
  handlePaymentSuccess: () => void;
}

function CardForm({
  paymentType,
  clientSecret,
  handlePaymentSuccess
}: CardFormProps) {
  const { t } = useTranslation('customer');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const card = elements.getElement(CardNumberElement);

    if (card == null) {
      return;
    }

    const { error: createPaymentMethodError } =
      await stripe.createPaymentMethod({
        type: 'card',
        card
      });

    if (createPaymentMethodError) {
      throw Error('Payment Method error.');
    } else {
      // eslint-disable-next-line no-lonely-if
      if (clientSecret.startsWith('pi_')) {
        // if no trial - storage card + payment
        const { paymentIntent, error: confirmCardPaymentError } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card
            }
          });

        if (confirmCardPaymentError) {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('funnel.payment.error')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        } else if (paymentIntent?.status === 'succeeded') {
          handlePaymentSuccess();
        }
      } else if (clientSecret.startsWith('seti_')) {
        // if trial - storage card only
        const { setupIntent, error: confirmCardSetupError } =
          await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card
            }
          });

        if (confirmCardSetupError) {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('funnel.payment.error')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        } else if (setupIntent?.status === 'succeeded') {
          handlePaymentSuccess();
        }
      }
    }

    setIsLoading(false);
  };

  const ctaLabel = useMemo(
    () =>
      paymentType === 'subscription'
        ? t('funnel.subscriptionConfirmation.cta')
        : t('general.pay'),
    [paymentType, t]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4">
        <div>
          <div className="pb-4">
            <label className="input-label">
              {t('card.number')}
              <CardNumberElement
                options={{ ...options, showIcon: true }}
                className="input-text selection:bg-white"
              />
            </label>
          </div>
          <div className="flex w-full gap-4">
            <div className="flex-1">
              <label className="input-label">
                {t('card.expirationDate')}
                <CardExpiryElement
                  options={options}
                  className="input-text bg-white"
                />
              </label>
            </div>
            <div className="flex-1">
              <label className="input-label">
                {t('card.cvc')}
                <CardCvcElement
                  className="input-text bg-white"
                  options={options}
                />
              </label>
            </div>
          </div>
        </div>
        <SecuredBy />
        <div className="text-center">
          <div className="mb-2">
            <Button
              submit
              variant="primary"
              label={ctaLabel}
              size="large"
              fullWidth
              isLoading={isLoading}
              disabled={!stripe || !elements}
            />
          </div>
          <span className="text-gray-700">
            {t('payment.cgu.confirm', { ctaLabel })}{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="/cgu.pdf"
              className="underline"
            >
              {t('payment.cgu')}
            </a>
          </span>
        </div>
      </div>
    </form>
  );
}
