import { yupResolver } from '@hookform/resolvers/yup';
import { ANALYTICS } from 'constants/analytics';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { otpCodeSchema } from 'schemas';

import { useSendFunnelOtpMutation } from 'api/shared/mutations.index';

import { useGuardedSearchParams } from 'hooks';

import { useTracking } from 'context';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

import { FunnelSharedLoginConsistencyMetadata } from 'types/funnel';
import { OtpFormInputs } from 'types/inputs';

interface OtpFormProps {
  consistencyMetadata: FunnelSharedLoginConsistencyMetadata;
}

export default function OtpForm({ consistencyMetadata }: OtpFormProps) {
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const { analytics } = useTracking();

  const navigate = useNavigate();

  const { mutate, isLoading } = useSendFunnelOtpMutation();

  const { t } = useTranslation('customer');

  const [searchParams] = useSearchParams();

  const methodId = useGuardedSearchParams({ param: 'methodId' });

  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<OtpFormInputs>({
    resolver: yupResolver(otpCodeSchema(tForm))
  });

  useEffect(() => {
    if (isLoading) {
      setCustomLoading(true);
    }

    if (!isLoading) {
      setTimeout(() => {
        setCustomLoading(false);
      }, 2100);
    }
  }, [isLoading]);

  const handleAnalyticsTrack = () => {
    if (
      consistencyMetadata.partnerSlug &&
      consistencyMetadata.context === 'question_funnel'
    ) {
      analytics?.track(ANALYTICS.FUNNEL_BUSINESS_QUESTION_CONTACT, {
        partnerSlug: consistencyMetadata.partnerSlug
      });
    }
    if (
      !consistencyMetadata.partnerSlug &&
      consistencyMetadata.context === 'question_funnel'
    ) {
      analytics?.track(ANALYTICS.FUNNEL_CUSTOMER_QUESTION_CONTACT);
    }
    if (
      !consistencyMetadata.partnerSlug &&
      consistencyMetadata.context === 'subscription_funnel'
    ) {
      analytics?.track(ANALYTICS.FUNNEL_CUSTOMER_SUBSCRIPTION_CONTACT, {
        productSkuCode: consistencyMetadata.skuCode
      });
    }
  };

  const onSubmit: SubmitHandler<OtpFormInputs> = async (formData) => {
    const { code } = formData;
    const email = searchParams.get('email') as string;
    const distinctId = searchParams.get('distinctId') as string;

    mutate(
      { methodId, code, email },
      {
        onError: () => {
          setError('code', {
            message: `${t('otp.code.invalid')}`,
            type: 'focus'
          });
        },
        onSuccess: async (data) => {
          handleAnalyticsTrack();
          const consistencyId = searchParams.get('consistencyId') as string;

          const params = new URLSearchParams({
            consistencyId
          });
          // settimeout glitch to fix a long api call
          await setTimeout(() => {
            navigate(
              `/funnel/confirmation?${params.toString()}&distinctId=${distinctId}`
            );
          }, 2100);
        }
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        autofocus
        type="Code"
        placeholder="123456"
        label="Code"
        name="code"
        register={register}
        error={errors.code}
        fullWidth
      />
      <Button
        fullWidth
        submit
        size="medium"
        variant="primary"
        label={t('general.continue')}
        isLoading={customLoading}
      />
    </form>
  );
}
